<template>
  <main class="App">
    <!-- {{ $page.props.content }} -->
    <TheLoader />

    <transition>
      <slot />
    </transition>

    <transition name="fade">
      <TheCookieBanner
        v-if="$store.state.isShownTheCookieBanner || showBanner"
      />
    </transition>

    <!-- <TheIntroVideo v-if="$store.state.isShownIntroVideo && $store.state.isShownTheCookieBanner" /> -->
    <!-- <TheIntroVideo v-if="$store.state.isShownIntroVideo" /> -->
  </main>
</template>

<script>
import useCookies from "@/helpers/vue-cookies";
import { useState, useGtag } from "vue-gtag-next";
import { Inertia } from "@inertiajs/inertia";

export default {
  // metaInfo() {
  //   return {
  //     title: this.$page.props.meta.title,
  //     description: this.$page.props.meta.title
  //   };
  // },
  setup() {
    const { showBanner } = useCookies();
    const { pageview } = useGtag();
    const { isEnabled } = useState();

    return { showBanner, isEnabled, pageview };
  },
  watch: {
    isEnabled(newValue) {
      if (newValue === true) {
        Inertia.remember(
          this.$page.props.request.route_name,
          this.$store.state.lastUrl
        );
        Inertia.on("navigate", (event) => {
          this.pageview({ page_path: this.$page.props.request.path });
        });
      }
    }
  },

  mounted() {
    this.$gsap.from(".App", { background: "black", duration: 0.3 });
    this.$gsap.from(".MainLayout", { opacity: 0, duration: 0.3 });
  }
};
</script>

<style lang="scss">
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  width: 100%;

  .transitionAvatar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    opacity: 0;
    width: 100vw;
    height: 100vh;

    svg {
      height: 40px;
      width: auto;
    }
  }
}
</style>
