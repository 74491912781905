<template>
  <div
    id="error-wrapper"
    class="Error404"
  >
    <div
      id="error-content"
      class="Error404__container"
    >
      <p class="main-text Error404__header">
        Error 404
      </p>
      <p class="Error404__title">
        Houston<span class="orange">,</span> <br>
        we have a problem<span class="orange">.</span>
      </p>
      <p class="main-text Error404__desc">
        We're sorry, the page you're looking for
        doesn't exist anymore.
      </p>

      <InertiaLink
        :href="route('homepage')"
        class="main-text Error404__btn"
      >
        Back to homepage
      </InertiaLink>
    </div>
  </div>
</template>

<script>
/* Vendor */
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";

export default {
  components: {
    InertiaLink,
  },

  mixins: [page, scrollGlobal],

  layout: mainLayout,

  data() {
    return {};
  },

  created() {
    seo.seoContent(null, "404: Page Not Found");
  },

  mounted() {
    this.toggleMenuColor("white", 0, 0);
    this.$store.commit('TOGGLE_IS_LOADING', false);
  },
};
</script>

<style lang="scss">
.Error404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 12vh 5vw;
  background: $black;
  width: 100vw;

  &__header {
    margin-bottom: 1vh;
    color: $orange;
    text-align: left;
    font-variation-settings: "wght" 600, "wdth" 100, "opsz" 12;

    @include tablet-m {
       margin-bottom: 2vh;
    }
  }

  &__title {
    margin-bottom: 2vh;
    margin-left: -0.5vw;
    font-size: 9.8vw;
    line-height: 0.9;
    color: $white;
    letter-spacing: -0.3vw;
    font-variation-settings: "wght" 800, "wdth" 100, "opsz" 12;

    @include tablet-m {
       margin-bottom: 3vh;
      font-size: 8vw;
    }

    @include desktop-l {
      font-size: 140px;
    }
  }

  &__desc {
    margin-bottom: 2vh;
    color: $white;
    text-align: left;
    font-variation-settings: "wght" 800, "wdth" 100, "opsz" 12;

    @include tablet-m {
       margin-bottom: 3vh;
    }
  }

  &__btn {
    display: block;
    // padding: 12px 35px;
    color: $grey;
    // background: $orange;
    width: fit-content;
    font-variation-settings: "wght" 700, "wdth" 100, "opsz" 12;

    @include transition;

    &:hover {
      color: $orange;
    }
  }
}
</style>
