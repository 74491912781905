import { inject } from '@vue/runtime-core';

import Blazy from 'blazy';

export default {
  data() {
    return {
      scrollOffset: 0,
      headerColorInit: null,
      footerScrollAnimation: null,
      initPaddingAnimation: null,
    };
  },

  setup() {
    const scrollTest = this.$ScrollSmoother;
    return { scrollTest, triggerControl };
  },

  watch: {
    '$store.state.isModalVideo': function () {
      // reset scroll on page change
      if (this.$store.state.isModalVideo) {
        this.pauseScroll();
      } else {
        this.playScroll();
      }
    },

    '$store.state.isPauseScroll': function () {
      // reset scroll on page change
      if (this.$store.state.isPauseScroll) {
        this.pauseScroll();
      } else {
        this.playScroll();
      }
    },
  },

  beforeUnmount() {
    this.deleteScroll();
  },

  methods: {
    setScroller(wrapper, container) {
      if (!this.$store.state.isMobile) {
        this.scrollTest = this.$ScrollSmoother.create({
          wrapper: wrapper,
          content: container,
          smooth: 0.8,
          ignoreMobileResize: true,
        });

        if (this.$store.state.isMountedIntroVideo) {
          this.$store.commit('TOGGLE_IS_PAUSE_SCROLL', true);
        }
      }

      // HEADER BG
      if (!this.$store.state.isMobile) {
        this.initPaddingAnimation = this.$gsap.to('.TheHeader', {
          scrollTrigger: {
            trigger: container,
            markers: false,
            start: '50px top',
            end: 'top bottom',
            toggleActions: 'play none reverse none',
          },
          paddingTop: 0,
          duration: 0.2,
          ease: 'Power2.out',
        });
      }

      this.footerScrollAnimation = this.$gsap.to('.TheHeader', {
        scrollTrigger: {
          trigger: container + '-footer',
          markers: false,
          onEnter: this.onFooterEnter,
          onEnterBack: this.onFooterLeave,
          start: '-30px top',
          end: '-30px top',
          toggleActions: 'play none reverse none',
        },
        duration: 0,
        ease: 'Power2.out',
      });

      // Lazy loading images
      var bLazy = new Blazy({
        // Options
        selector: 'img[data-src]',
        container: wrapper, //#
        offset: 2000,
        success: function (ele) {
          // Image has loaded
          ele.classList.add('animate-lazy-load');
          // Si no eliminamos el data-src, en gallery se queda semi transparente
          //delete ele.dataset.src
        },
        error: function (ele, msg) {
          if (msg === 'missing') {
            // Data-src is missing
          } else if (msg === 'invalid') {
            // Data-src is invalid
          }
        },
      });
    },

    onFooterEnter() {
      this.headerColorInit = this.$store.state.menuColor;
      this.tweenMenuColor('white', 0.3, 0);
    },

    onFooterLeave() {
      this.$store.state.menuColor = this.headerColorInit;
      this.tweenMenuColor(this.headerColorInit, 0.3, 0);
    },

    pauseScroll() {
      if (!this.$store.state.isMobile) {
        this.scrollTest.paused(true);
      }
    },

    goToScroll(id) {
      // console.log(id);
      this.scrollTest.scrollTo(`#${id}`, false, 'center top');
    },

    playScroll() {
      if (!this.$store.state.isMobile) {
        this.scrollTest.paused(false);
      }
    },

    updateScroll() {
      if (!this.$store.state.isMobile) {
        this.scrollTest.update();
      }
    },

    deleteScroll() {
      this.$gsap.to('.showBgHeader', { height: 0, duration: 0 });
      this.$gsap.to('.TheHeader', { paddingTop: '0.7vw', duration: 0 });

      this.footerScrollAnimation.scrollTrigger.kill();
      if (!this.$store.state.isMobile) {
        this.initPaddingAnimation.scrollTrigger.kill();
      }
      if (!this.$store.state.isMobile) {
        this.scrollTest.kill();
      }
    },

    toggleMenuColor(col, dur, del) {
      this.tweenMenuColor(col, dur, del);
      setTimeout(() => {
        this.$store.commit('TOGGLE_COLOR_MENU', col);
      }, 300);
    },

    tweenMenuColor(col, dur, del) {
      let colInverted = col === 'white' ? 'black' : 'white';
      this.$gsap.to('.toggleLogo', { fill: col, duration: dur });
      this.$gsap.to('.toggleMenuItems', { color: col, duration: dur });
      this.$gsap.to('.toggleBurger', { background: col, duration: dur });
      this.$gsap.killTweensOf(".showBgHeader", "background");
      this.$gsap.to('.showBgHeader', {
        background: colInverted,
        duration: dur,
        delay: del,
      });
    },
  },
};
