<template>
  <div
    :id="`download-${dynamicNumber}`"
    class="ProjectDownloads"
    :class="`${content.horizontal_margin} ${content.vertical_margin}`"
    :style="`background : ${customColor}; ${customGradient} `"
  >
    <h4
      v-for="(download, idx) in content.downloads"
      :key="`award${idx}`"
      class="ProjectDownloads__item"
    >
      <a
        :href="`/assets/${download.item_file}`"
        download
        class="main-text-bold orange ProjectDownloads__item--download"
      >
        {{ download.item_label }}


        <IconDownload />
      </a>
    </h4>
  </div>
</template>

<script>

export default {

  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    dynamicNumber(){
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if(this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customDescColor() {
      if (this.content.color) {
        return this.content.color;
      } else {
        return "#000";
      }
    },
  },

};
</script>

<style lang="scss">
.ProjectDownloads {
  position: relative;
  display: block;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100vw;
  overflow-x: hidden;
  opacity: 1;
  width: 100%;

  &.super {
    @include paddingMobile;

    @include tablet {
      @include paddingSuper;
    }

  }

  &.big {


    @include paddingMobile;

    @include tablet {
      @include paddingBig;
    }
  }

  &.standard {
    @include paddingMobile;

    @include tablet {
      @include paddingMedium;
    }
  }

  &.small {
    @include paddingMobile;

    @include tablet {
     @include paddingSmall;
    }
  }

    &.bottom-text {

    @include paddingMobileBottom;

    @include tablet {
       @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }

  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {

    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {

    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

  &__item {
    margin-bottom: 10px;
    line-height: 1.4;

    &--download {
      svg {
        margin-left: 10px;
        opacity: 0;
        transform: translateY(2px);
        height: 16px;

        @include transition;


         @include tablet {
            height: 19px
          }

          @include desktop-sm {
            height: 20px;
          }

          @include desktop-l {
            height: 26px;
          }

          @include desktop-xl {
            height: 29px;
          }
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
  }
}
</style>
