<template>
  <div class="TheFooter">
    <section class="TheFooter__main">
      <div class="TheFooter__contact">
        <TheSafeEmail
          title="Get in Touch"
          :email="email"
          class="TheFooter__contact--title"
        >
          <TextFooter />
        </TheSafeEmail>

        <div class="TheFooter__contact--newsletter">
          <FormNewsletter />
        </div>
      </div>

      <div class="TheFooter__locations">
        <div
          v-for="location in globals.address.content"
          :key="location.city"
          class="TheFooter__locations--item"
        >
          <p class="light-text orange">
            {{ location.city }}
          </p>
          <!-- <p class="main-text">
            {{ location.phone }}
          </p> -->
          <a
            href="tel://+34910743133"
            target="_blank"
            class="main-text-link"
          >
            {{ location.phone }}
          </a>
          <a
            :href="location.google_maps_link"
            target="_blank"
            class="main-text-link"
          >
            <IconLocation /> {{ location.street }}
          </a>
          <p class="main-text">
            {{ location.postcode }}
          </p>
        </div>
      </div>

      <div class="TheFooter__rrss">
        <a
          v-for="(item, index) in globals.social_media.social_media"
          :key="`item${index}`"
          target="_blank"
          class="main-text-bold white rrss-link"
          :href="`${item.external_link}`"
        >
          {{ item.name }}
        </a>
      </div>
    </section>

    <div class="TheFooter__copyright">
      <InertiaLink
        :href="`${route('privacy-policy')}/`"
        class="main-text-link white"
      >
        Terms <span class="orange">&</span> Privacy Policy
      </InertiaLink>
      <p class="main-text">
        <span class="orange">©</span>{{ globals.footer.copyright }}
      </p>
    </div>
  </div>
</template>

<script>
import { InertiaLink } from "@inertiajs/inertia-vue3";

export default {
  components: {
    InertiaLink
  },
  data() {
    return {
      email: 'hola@binalogue.com',
    }
  },

  computed: {
    globals() {
      return this.$page.props.globals;
    },
  },
};
</script>

<style lang="scss" >
.TheFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100vw;
  min-height: 100vh;
  color: $white;
  background: $black;
  width: 100%;

  .main-text-link {
    display: block;
    color: $white;
    cursor: pointer;

    &:hover {
      color: $orange;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    margin: auto 0;
    width: 100%;

    @include paddingMobile;

    @include tablet {
      @include paddingBig;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    // background: green;
    width: 100%;

    @include tablet {
      width: 70%;
      margin-top: 0;
    }

    @include desktop-s {
      width: 50%;
    }

    svg {
      max-width: 550px;
      width: 100%;
      height: auto;
      margin-bottom: 5vh;

      @include tablet {
        width: 80%;
        margin-bottom: initial;
      }

      @include desktop-xl {
        width: 80%;
        max-width: 700px;
        margin-bottom: initial;
      }
    }

    &--newsletter {
      max-width: 700px;
      width: 100%;

      @include tablet {
        width: 80%;
      }
    }
  }

  &__locations {
    width: 100%;
    // background: red;

    @include tablet {
      width: 65%;
    }

    @include desktop-s {
       width: 35%;
    }

    * {
      line-height: 1.6;
    }

    &--item {
      margin-bottom: 30px;

        @include desktop-l {
          margin-bottom: 70px;
        }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__rrss {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    // background: blue;
    width: 100%;

    @include tablet {
      padding-left: 2vw;
      margin: 0;
      width: 35%;
    }

    @include desktop-s {
       width: 15%;
    }

    * {
      display: block;
      margin-bottom: 10px;
      line-height: 1.6;
    }
  }




  &__copyright {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vh;
    width: 100%;
    @include paddingMobile;
  }
}
</style>
