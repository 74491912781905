import { InertiaProgress } from '@inertiajs/progress';
import { Inertia } from '@inertiajs/inertia';
import { store } from '@/store/store';

export default {
  install: () => {
    InertiaProgress.init({
      // The delay after which the progress bar will
      // appear during navigation, in milliseconds.
      delay: 0,

      // The color of the progress bar.
      color: '#ff7f40',

      // Whether to include the default NProgress styles.
      includeCSS: true,

      // Whether the NProgress spinner will be shown.
      showSpinner: false,
    });

    Inertia.on('before', () => {
      store.commit('TOGGLE_IS_LOADING', true);
    });

    Inertia.on('start', () => {
      store.commit('TOGGLE_BG_BACKGROUND_HOME', false);
    });

    Inertia.on('finish', () => {
      store.commit('TOGGLE_IS_PX_LOADING', true);
    });
  },
};
