<template>
  <div
    :id="`two-columns-${dynamicNumber}`"
    class="twoColumns"
    :style="`background : ${customColor}; ${customGradient}`"
    :class="`${content.horizontal_margin} ${content.vertical_margin} ${content.vertical_align} middle-${content.middle_margin} mobile-remove-${content.mobile_remove_margin} reverse-${content.mobile_reverse_order}`"
  >
    <div
      class="twoColumns__left"
      :class="columnLeft.mobile_settings"
      :style="`width : ${customWidth}%; padding-bottom: ${mobileMargin}`"
    >
      <div
        v-if="columnLeft.content === 'image' || columnLeft.content === 'lossless-image' || columnLeft.content === 'original-image' || columnLeft.content === 'hq-image'"
        class="twoColumns__left--image"
        :style="customPaddingLeft"
      >
        <img
          v-if="$store.state.isMobile && columnLeft.alternate_content && columnLeft.mobile_image"
          :data-src="getImageKitURL(columnLeft.mobile_image, 1, columnLeft.content,false,position)"
          :src="getImageKitURL(columnLeft.mobile_image, 1, columnLeft.content,true,position)"
          :alt="columnLeft.mobile_image.alt"
          class="oneColumn__image--item"
        >
        <img
          v-else
          :data-src="getImageKitURL(columnLeft.image, 2, columnLeft.content,false,position)"
          :src="getImageKitURL(columnLeft.image, 2, columnLeft.content,true,position)"
          :alt="columnLeft.image.alt"
          class="twoColumns__left--image--item"
        >
      </div>

      <div
        v-if="columnLeft.content === 'description'"
        class="main-text oneColumn__description"
        :style="`color : ${customLeftColor}`"
        :class="columnLeft.description_padding"
        v-html="markdownToHtml(columnLeft.description)"
      />

      <div
        v-if="columnLeft.content === 'custom-description'"
        class="main-text oneColumn__custom-description"
        :style="`color : ${customLeftColor}`"
        :class="columnLeft.description_padding"

        v-html="columnLeft.custom_description"
      />

      <div
        v-if="columnLeft.content === 'empty'"
        :class="{'hidden': $store.state.isMobile}"
      />

      <div
        v-if="columnLeft.content === 'bg-video'"
        class="twoColumn__video"
      >
        <!-- {{ content }} -->
        <backgroundVideo
          v-if="$store.state.isMobile && columnLeft.alternate_content && columnLeft.mobile_vimeo_id"
          :vimeo_id="columnLeft.mobile_vimeo_id"
          :vimeo_ratio="columnLeft.mobile_vimeo_ratio"
          :content-width="customWidth"
        />

        <backgroundVideo
          v-else-if="columnLeft.vimeo_id"
          :vimeo_id="columnLeft.vimeo_id"
          :vimeo_ratio="columnLeft.vimeo_ratio"
          :content-width="customWidth"
        />
      </div>

      <div
        v-if="columnLeft.content === 'html-video'"
        class="twoColumn__video"
      >
        <htmlVideo
          v-if="$store.state.isMobile && columnLeft.alternate_content && columnLeft.html_video_url_mb"
          :html-url="columnLeft.html_video_url_mb"
          :html-ratio="columnLeft.html_ratio_mb"
        />
        <htmlVideo
          v-else
          :html-url="columnLeft.html_video_url"
          :html-ratio="columnLeft.html_ratio"
          :html-width="customWidth"
        />
      </div>

      <div
        v-if="columnLeft.content === 'pop-up-video'"
        :style="customPaddingLeft"
        class="oneColumn__pop"
        @click="shoModalVideoLeft"
      >
        <!-- {{column}} -->
        <img
          v-if="columnLeft.pop_up_image"
          :data-src="getImageKitURL(columnLeft.pop_up_image, 2, columnLeft.content,false,position)"
          :src="getImageKitURL(columnLeft.pop_up_image, 2, columnLeft.content,true,position)"
          :alt="columnLeft.pop_up_image.alt"
          class="oneColumn__pop--image"
        >
        <img
          class="oneColumn__pop--play"
          src="/fonts/play_white.png"
          alt="indicators"
        >
        <img
          v-if="columnLeft.pop_up_rollover"
          :style="` ${customPaddingLeft} `"
          :data-src="getImageKitURL(columnLeft.pop_up_rollover, 2, columnLeft.content,false,position)"
          :src="getImageKitURL(columnLeft.pop_up_rollover, 2, columnLeft.content,true,position)"
          :alt="columnLeft.pop_up_rollover.alt"
          class="oneColumn__pop--rollover"
        >
      </div>
    </div>

    <div
      class="twoColumns__right"
      :class="columnRight.mobile_settings"
      :style="`width : ${customRightWidth}%`"
    >
      <div
        v-if="columnRight.content === 'image' || columnRight.content === 'lossless-image' || columnRight.content === 'original-image' || columnRight.content === 'hq-image'"
        class="twoColumns__right--image"
        :style="customPaddingRight"
      >
        <img
          v-if="$store.state.isMobile && columnRight.alternate_content && columnRight.mobile_image"
          :data-src="getImageKitURL(columnRight.mobile_image, 1, columnRight.content,false,position)"
          :src="getImageKitURL(columnRight.mobile_image, 1, columnRight.content,true,position)"
          :alt="columnRight.mobile_image.alt"
          class="oneColumn__image--item"
        >
        <img
          v-else
          :data-src="getImageKitURL(columnRight.image, 2, columnRight.content,false,position)"
          :src="getImageKitURL(columnRight.image, 2, columnRight.content,true,position)"
          :alt="columnRight.image.alt"
          class="twoColumns__right--image--item"
        >
      </div>

      <div
        v-if="columnRight.content === 'description'"
        class="main-text oneColumn__description"
        :style="`color : ${customRightColor}`"
        :class="columnRight.description_padding"
        v-html="markdownToHtml(columnRight.description)"
      />

      <div
        v-if="columnRight.content === 'custom-description'"
        class="main-text oneColumn__custom-description"
        :style="`color : ${customRightColor}`"
        :class="columnRight.description_padding"

        v-html="columnRight.custom_description"
      />

      <div
        v-if="columnRight.content === 'empty'"
        :class="{'hidden': $store.state.isMobile}"
      />

      <div
        v-if="columnRight.content === 'bg-video'"
        class="twoColumn__video"
      >
        <backgroundVideo
          v-if="$store.state.isMobile && columnRight.alternate_content && columnRight.mobile_vimeo_id"
          :vimeo_id="columnRight.mobile_vimeo_id"
          :vimeo_ratio="columnRight.mobile_vimeo_ratio"
          :content-width="c"
        />

        <backgroundVideo
          v-else-if="columnRight.vimeo_id"
          :vimeo_id="columnRight.vimeo_id"
          :vimeo_ratio="columnRight.vimeo_ratio"
          :content-width="customRightWidth"
        />
      </div>

      <div
        v-if="columnRight.content === 'html-video'"
        class="twoColumn__video"
      >
        <htmlVideo
          v-if="$store.state.isMobile && columnRight.alternate_content && columnRight.html_video_url_mb"
          :html-url="columnRight.html_video_url_mb"
          :html-ratio="columnRight.html_ratio_mb"
        />
        <htmlVideo
          v-else
          :html-url="columnRight.html_video_url"
          :html-ratio="columnRight.html_ratio"
          :html-width="customRightWidth"
        />
      </div>

      <div
        v-if="columnRight.content === 'pop-up-video'"
        :style="customPaddingRight"
        class="oneColumn__pop"
        @click="shoModalVideoRight"
      >
        <!-- {{column}} -->
        <img
          v-if="columnRight.pop_up_image"
          :data-src="getImageKitURL(columnRight.pop_up_image, 2, columnRight.content,false,position)"
          :src="getImageKitURL(columnRight.pop_up_image, 2, columnRight.content,true,position)"
          :alt="columnRight.pop_up_image.alt"
          class="oneColumn__pop--image"
        >
        <img
          class="oneColumn__pop--play"
          src="/fonts/play_white.png"
          alt="indicators"
        >
        <img
          v-if="columnRight.pop_up_rollover"
          :style="` ${customPaddingRight} `"
          :data-src="getImageKitURL(columnRight.pop_up_rollover, 2, columnRight.content,false,position)"
          :src="getImageKitURL(columnRight.pop_up_rollover, 2, columnRight.content,true,position)"
          :alt="columnRight.pop_up_rollover.alt"
          class="oneColumn__pop--rollover"
        >
      </div>
    </div>
    <!-- {{ content }} -->
  </div>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";
import imageKit from "@/mixins/image-kit";

export default {
  mixins: [marked, imageKit],
  props: {
    position:{
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {}
    },
    isProject: {
      type: Boolean
    }
  },

  data() {
    return {
      showVideoModalLeft: false,
      showVideoModalRight: false,
      // mouse
      mouse: {},
    }
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if (this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customLeftColor() {
      if (this.columnLeft.description_color) {
        return this.columnLeft.description_color;
      } else {
        return "#000";
      }
    },

    customRightColor() {
      if (this.columnRight.description_color) {
        return this.columnRight.description_color;
      } else {
        return "#000";
      }
    },

    customPaddingLeft() {
      if (this.$store.state.isMobile) {
        return "padding:0";
      } else if (this.columnLeft.padding_settings === "img-pd-left") {
        return `padding-left : ${this.columnLeft.padding_width}vw`;
      } else if (this.columnLeft.padding_settings === "img-pd-right") {
        return `padding-right : ${this.columnLeft.padding_width}vw`;
      } else if (this.columnLeft.padding_settings === "img-pd-top") {
        return `padding-top : ${this.columnLeft.padding_width}vw`;
      } else if (this.columnLeft.padding_settings === "img-pd-bottom") {
        return `padding-bottom : ${this.columnLeft.padding_width}vw`;
      } else {
        return "padding:0";
      }
    },

    customPaddingRight() {
      if (this.$store.state.isMobile) {
        return "padding:0";
      } else if (this.columnRight.padding_settings === "img-pd-left") {
        return `padding-left : ${this.columnRight.padding_width}vw`;
      } else if (this.columnRight.padding_settings === "img-pd-right") {
        return `padding-right : ${this.columnRight.padding_width}vw`;
      } else if (this.columnRight.padding_settings === "img-pd-top") {
        return `padding-top : ${this.columnRight.padding_width}vw`;
      } else if (this.columnRight.padding_settings === "img-pd-bottom") {
        return `padding-bottom : ${this.columnRight.padding_width}vw`;
      } else {
        return "padding:0";
      }
    },

    customWidth() {
      if (this.$store.state.isMobile) {
        return "100";
      } else if (this.content.middle_margin) {
        if (this.content.width == 33) {
          return "32.333";
        } else if (this.content.width == 66) {
          return "65.666";
        } else {
          const getWidth = this.content.width - 1 ;
          return getWidth;
        }
      } else {
        if (this.content.width == 33) {
          return "33.333";
        } else if (this.content.width == 66) {
          return "66.666";
        } else {
          const getWidth = this.content.width;
          return getWidth;
        }
      }
    },

    customRightWidth() {
      if (this.$store.state.isMobile) {
        return "100";
      } else if (this.content.middle_margin) {
        const getWidth = 99 - this.content.width;
        return getWidth;
      } else {
        if (this.content.width == 33) {
          return "66.66";
        } else if (this.content.width == 66) {
          return "33.33";
        } else {
          const getWidth = 100 - this.content.width;
          return getWidth;
        }
      }
    },

    mobileMargin() {
      if (this.content.middle_margin && this.$store.state.isMobile) {
        return "5vw";
      } else {
        return 0;
      }
    },

    columnLeft() {
      return this.content.column_left[0];
    },

    columnRight() {
      return this.content.column_right[0];
    }
  },

  methods: {
    shoModalVideoLeft() {
      this.$store.state.video_id = this.columnLeft.pop_up_vimeo_id;;
      this.$store.state.video_ratio = this.columnLeft.pop_up_video_ratio;
      if(this.showVideoModalLeft){
        setTimeout(() => {
          // this.showVideoModal = false;
          this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
        }, 800);
      }else {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", true);
      }

      window.history.pushState({id:1}, null, null);
    },

    shoModalVideoRight() {
      this.$store.state.video_id = this.columnRight.pop_up_vimeo_id;;
      this.$store.state.video_ratio = this.columnRight.pop_up_video_ratio;
      if(this.showVideoModalRight){
        setTimeout(() => {
          // this.showVideoModal = false;
          this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
        }, 800);
      }else {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", true);
      }

      window.history.pushState({id:1}, null, null);
    },
  },
};
</script>

<style lang="scss">
.twoColumns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;

  &.reverse-true {
    flex-direction: column-reverse;

    @include tablet {
      flex-direction: row;
    }
  }

  &.super {
    @include paddingSuper;
  }

  &.big {
    @include paddingBig;
  }

  &.standard {
    @include paddingMedium;
  }

  &.small {
    @include paddingSmall;
  }

  &.bottom-text {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }
  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

  &.top {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }

  &__left,
  &__right {
    &--image {
      width: 100%;
      display: flex;
      flex-grow: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.stretch {
    align-items: stretch;

    .twoColumns__right--image,
    .twoColumns__left--image {
      height: 100%;
    }

    .twoColumns__right--image--item,
    .twoColumns__left--image--item {
      height: 100%;
      object-fit: cover;
    }
  }

  &.hide-mobile {
    display: none;

    @include tablet {
      display: flex;
    }
  }

  &.mobile-remove-true {
    @media (max-width: 576px) {
      padding-bottom: 0;
    }
  }
}
</style>
