<template>
  <section
    class="TheMenu"
    @touchmove="removeScroll"
  >
    <ul class="TheMenu__links">
      <li
        v-for="(item, index) in mainNavbar"
        :key="`item${index}`"
        class="TheMenu__links--item"
        @click="$parent.leaveMenu"
      >
        <InertiaLink
          class="TheMenu__links--link"
          :href="`${route(`${item.url}`)}/`"
        >
          {{ item.title }}<span class="dot">.</span>
        </InertiaLink>
      </li>
    </ul>
    <ul class="TheMenu__rrss">
      <li
        v-for="(item, index) in $page.props.globals.social_media.social_media"
        :key="`rrss${index}`"
        class="TheMenu__rrss--item"
      >
        <a
          target="_blank"
          class="TheMenu__rrss--link"
          :href="`${item.external_link}`"
        >
          {{ item.name }}
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
/* Vendor */
import { InertiaLink } from "@inertiajs/inertia-vue3";

export default {
  components: {
    InertiaLink,
  },
  computed: {
    mainNavbar() {
      return this.$page.props.main_navbar;
    },
  },

  // created() {
  //   window.addEventListener('resize', this.handleHeight);
  // },

  // beforeUnmount() {
  //   window.removeEventListener('resize', this.handleHeight);
  // },

  mounted(){
    this.removeSrcoll;
    //this.handleHeight();
  },

  methods: {
    removeScroll(e) {
      e.preventDefault();
    },

    handleHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },

};
</script>

<style lang="scss">
.TheMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  background: $black;
  opacity:0;
  transform: translateY(-100vh);
  width: 100%;
  height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);

  @include paddingMobile;

  @include tablet {
     @include paddingBig;

     flex-direction: row;
     align-items: center;
     justify-content: space-between;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-bottom: 7vh;
    opacity:0;
    transform: translateY(-15px);

    @include tablet {
      margin-bottom: 0;
    }

    &--item {
      list-style: none;
    }

    &--link {
      font-size: 48px;
      line-height: 1.4;
      color: $white;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
      @include transition;

      @include tablet {
        font-size: 4.6vw;
        line-height: 1.5;
      }

      @include desktop-l {
        font-size: 74px;
      }

      .dot {
        color: $orange;
      }

      &:hover {
        color: $orange;

        .dot {
          color: $white;
        }
      }
    }
  }

  &__rrss {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity:0;
    transform: translateY(-10px);

    @include tablet {
       align-items: flex-end;
    }

    &--item {
      list-style: none;
    }

    &--link {
      font-size: 18px;
      line-height: 2.1;
      color: $white;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
      @include transition;

      @include tablet {
        font-size: 1.8vw;
      }

      @include desktop-l {
        font-size: 24px;
      }

      &:hover {
        color: $orange;
      }
    }
  }
}
</style>
