<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 526 220"
  >
    <g id="txt">
      <g>
        <g>
          <path
            d="M22.82,82.35.4.5H18.68L28.88,42q1.35,6,2.92,13.75T35,72H31.32q1.82-9.35,3.35-17.1t2.77-13.08L47.65.5H65.58L75.84,41.89Q77.54,49.48,79,56.75T81.85,72H78.34Q80,63.35,81.4,56.26t3-14.43L94.47.5h18.34L90.39,82.35H71L59.38,36.28q-1.12-4.74-2.3-10.46T54.61,13l4.22-.78q-1.38,7.53-2.56,13.34T53.88,36.22L42.25,82.35Z"
            fill="#fff"
          />
          <path
            d="M139.53,83.73A33.14,33.14,0,0,1,123.46,80,26,26,0,0,1,112.8,69.25,34.21,34.21,0,0,1,109,52.78a34.34,34.34,0,0,1,3.74-16.31,26.64,26.64,0,0,1,10.59-10.94,31.88,31.88,0,0,1,16-3.88,31.54,31.54,0,0,1,16,3.88,26.24,26.24,0,0,1,10.43,10.88,34.33,34.33,0,0,1,3.65,16.08c0,.71,0,1.47-.06,2.27s-.09,1.66-.14,2.56h-51V46.94h39l-4.11,2.33a19.55,19.55,0,0,0-1.62-8.09,12.2,12.2,0,0,0-4.7-5.4,14,14,0,0,0-7.56-1.94,14.24,14.24,0,0,0-7.6,1.93,12.4,12.4,0,0,0-4.86,5.79,24.36,24.36,0,0,0-1.74,9.7v4.25a20.76,20.76,0,0,0,1.74,9A11.89,11.89,0,0,0,131.8,70a16.28,16.28,0,0,0,8,1.82,16.86,16.86,0,0,0,5.88-1,11.07,11.07,0,0,0,4.23-2.72,11.19,11.19,0,0,0,2.51-4.27l15.66,0a24.82,24.82,0,0,1-15.19,17.5A34.74,34.74,0,0,1,139.53,83.73Z"
            fill="#fff"
          />
          <path
            d="M172.32,35.39V26.71l3.16-.06a6.09,6.09,0,0,0,3.19-1,5.1,5.1,0,0,0,1.84-2.54,13.08,13.08,0,0,0,.6-4.33V14.9L184,17.8H172.31V.5h18.91V18.12a22.66,22.66,0,0,1-1.74,9.54,11.84,11.84,0,0,1-5.19,5.6,19.72,19.72,0,0,1-8.68,2.07Z"
            fill="#ff7f40"
          />
          <path
            d="M197.87,82.35V23H214V38.41l.43,0V82.35Zm14-37A31.1,31.1,0,0,1,214,33.71a18.68,18.68,0,0,1,6.52-8.44,18.43,18.43,0,0,1,11-3.16c.65,0,1.27,0,1.87.07s1.21.11,1.84.19V37.52h-4a20.65,20.65,0,0,0-9.08,1.81,12.58,12.58,0,0,0-5.72,5.5,19.53,19.53,0,0,0-2,9.3h-2.62Z"
            fill="#fff"
          />
          <path
            d="M267.74,83.73A33.14,33.14,0,0,1,251.67,80,26,26,0,0,1,241,69.25a34.21,34.21,0,0,1-3.77-16.47A34.34,34.34,0,0,1,241,36.47a26.64,26.64,0,0,1,10.59-10.94,31.88,31.88,0,0,1,16-3.88,31.54,31.54,0,0,1,15.95,3.88,26.24,26.24,0,0,1,10.43,10.88,34.33,34.33,0,0,1,3.65,16.08c0,.71,0,1.47-.06,2.27s-.09,1.66-.14,2.56h-51V46.94h39l-4.11,2.33a19.55,19.55,0,0,0-1.62-8.09,12.2,12.2,0,0,0-4.7-5.4,14,14,0,0,0-7.56-1.94,14.24,14.24,0,0,0-7.6,1.93A12.4,12.4,0,0,0,255,41.56a24.36,24.36,0,0,0-1.74,9.7v4.25a20.76,20.76,0,0,0,1.74,9A11.89,11.89,0,0,0,260,70a16.28,16.28,0,0,0,8,1.82,16.86,16.86,0,0,0,5.88-1,11.07,11.07,0,0,0,4.23-2.72,11.19,11.19,0,0,0,2.51-4.27l15.66,0a24.82,24.82,0,0,1-15.19,17.5A34.74,34.74,0,0,1,267.74,83.73Z"
            fill="#fff"
          />
        </g>
        <g>
          <rect
            x="509.22"
            y="180.65"
            width="16.38"
            height="16.38"
            fill="#ff7f40"
          />
          <rect
            x="79.22"
            y="115.17"
            width="16.38"
            height="16.38"
            fill="#ff7f40"
          />
          <path
            d="M3.9,115.17H42.42c15.6,0,26.14,5,26.14,20.3,0,8-4,13.64-11.12,17.08,10,2.87,15,10.55,15,20.75,0,16.62-14.1,23.73-28.89,23.73H3.9Zm18,33.13H40.14c6.31,0,11-2.87,11-9.75,0-7.8-6-9.4-12.38-9.4H21.91Zm0,34.74H41.17c7.11,0,13.3-2.29,13.3-10.77s-5.27-11.69-12.95-11.69H21.91Z"
            fill="#fff"
          />
          <rect
            x="79.23"
            y="137.75"
            width="16.37"
            height="59.27"
            fill="#fff"
          />
          <path
            d="M103.69,137.75h15.48V146h.34a21.15,21.15,0,0,1,18.12-9.86c17.31,0,21.67,9.74,21.67,24.42V197H143V163.54c0-9.75-2.86-14.56-10.44-14.56-8.83,0-12.61,4.93-12.61,17V197H103.69V137.75Z"
            fill="#fff"
          />
          <path
            d="M167.66,156c.92-15.25,14.56-19.83,27.86-19.83,11.81,0,26,2.63,26,16.85v30.84c0,5.39.57,10.78,2.06,13.19H207.1A24.58,24.58,0,0,1,206,191.3c-5.16,5.39-12.73,7.34-19.95,7.34-11.23,0-20.18-5.62-20.18-17.77,0-13.42,10.09-16.62,20.18-18s19.26-1.15,19.26-7.8c0-7-4.82-8-10.55-8-6.19,0-10.21,2.52-10.78,8.94H167.66Zm37.61,12c-2.75,2.41-8.49,2.52-13.53,3.44s-9.63,2.75-9.63,8.72,4.7,7.57,10,7.57c12.73,0,13.19-10.09,13.19-13.65Z"
            fill="#fff"
          />
          <rect
            x="229.73"
            y="115.17"
            width="16.28"
            height="81.86"
            fill="#fff"
          />
          <path
            d="M283.35,136.15c18.69,0,30.73,12.38,30.73,31.3s-12,31.19-30.73,31.19-30.61-12.38-30.61-31.19,12-31.3,30.61-31.3m0,50.22c11.12,0,14.45-9.52,14.45-18.92s-3.33-19-14.45-19-14.33,9.51-14.33,19,3.33,18.92,14.33,18.92"
            fill="#fff"
          />
          <path
            d="M378.62,193.25c0,9.74-3.44,26.25-30.73,26.25-11.69,0-25.34-5.5-26.14-19h16.17c1.49,6.08,6.42,8.14,12.15,8.14,9.05,0,13.18-6.19,13.07-14.68v-7.8h-.23c-3.55,6.19-10.67,9.17-17.77,9.17-17.77,0-25.34-13.53-25.34-29.81,0-15.36,8.83-29.35,25.45-29.35,7.8,0,13.76,2.64,17.66,9.51h.23v-7.91h15.48v55.51Zm-15.47-26.6c0-9.63-3.33-18.23-13.87-18.23-9.17,0-13.19,8-13.19,16.85,0,8.48,3.21,17.77,13.19,17.77,9.28,0,13.87-7.91,13.87-16.39"
            fill="#fff"
          />
          <path
            d="M440.6,197H425.12v-8.25h-.35a21.15,21.15,0,0,1-18.12,9.86c-17.31,0-21.67-9.75-21.67-24.42V137.76h16.28v33.48c0,9.74,2.87,14.56,10.44,14.56,8.83,0,12.61-4.93,12.61-17V137.76H440.6V197Z"
            fill="#fff"
          />
          <path
            d="M476.8,136.15c-18,0-30.27,13.53-30.27,31.3,0,18.34,11.58,31.19,30.27,31.19,12.76,0,22.19-5.42,27-18h-14.6c-2,3-6.49,5.71-11.86,5.71-9.05,0-14.1-4.7-14.56-15h42.77c1.14-18.23-8.61-35.19-28.78-35.19m-14,24.88c.23-4.59,3.21-12.61,13.53-12.61,7.91,0,11.46,4.35,13,12.61Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
