<template>
  <div id="news-filter-wrapper">
    <div
      id="news-filter-content"
      class="NewsFilter News news-filter-scroller"
    >
      <!-- SEO TITLE -->
      <h1 class="hidden">
        Press
      </h1>

      <section class="filters">
        <InertiaLink
          class="filters__item"
          :href="`${route('news.index')}/`"
          :class="{ active: $page.url === '/press' }"
        >
          All
        </InertiaLink>
        <InertiaLink
          v-for="filter in content.news_filters"
          :key="filter"
          class="filters__item"
          :href="`/press/${filter}/`"
          :class="{
            active: $page.url.includes(filter),
          }"
        >
          <span
            v-for="tax in taxonomies"
            :key="tax.title"
          >
            <span v-if="filter === tax.slug">
              {{ tax.title }}
            </span>
          </span>
        </InertiaLink>
      </section>

      <!-- TODO: delete when merge to master -->
      <section
        v-if="$page.url === `/new-press/${$page.props.filter}/`"
        class="filters"
      >
        <InertiaLink
          class="filters__item"
          :href="`${route('newnews.index')}/`"
          :class="{ active: $page.url === `/new-press/` }"
        >
          new All
        </InertiaLink>
        <InertiaLink
          v-for="filter in content.news_filters"
          :key="filter"
          class="filters__item"
          :href="`/new-press/${filter}/`"
          :class="{
            active: $page.url.includes(filter),
          }"
        >
          <span
            v-for="tax in taxonomies"
            :key="tax.title"
          >
            <span v-if="filter === tax.slug">
              new {{ tax.title }}
            </span>
          </span>
        </InertiaLink>
      </section>

      <section
        v-if="$store.state.isMobile"
        class="News__grid"
      >
        <div class="News__column">
          <div
            v-for="(news, index) in content.news_taxonomies"
            :key="`news02${index}`"
            class="News__item"
          >
            <InertiaLink
              class="News__item--link"
              :href="`/press/${news.slug}/`"
            >
              <div class="News__item--image-container">
                <img
                  v-if="news.square_thumbnail"
                  :data-src="getImageKitURL(news.square_thumbnail, 1, news.image_type,false,index)"
                  :src="getImageKitURL(news.square_thumbnail, 1, news.image_type,true,index)"
                  :alt="news.title"
                  class="News__item--image"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default.png`"
                  class="News__item--image"
                  alt="default image"
                >
              </div>
              <div class="News__text">
                <p class="small-subtitle grey">
                  <span
                    v-for="category in customCategory(news.news_filters)"
                    :key="category"
                  >
                    <span class="orange">{{ category }}</span>. {{ formatDate(news.publication_date) }}
                  </span>
                </p>

                <h3 class="small-title">
                  {{ news.title }}
                </h3>
              </div>
            </InertiaLink>
          </div>
        </div>
      </section>

      <section
        v-else
        class="News__grid"
      >
        <div class="News__column">
          <div
            v-for="(news, index) in content.news_taxonomies"
            v-show="index % 3 === 0"
            :key="`news02${index}`"
            class="News__item"
          >
            <InertiaLink
              class="News__item--link"
              :href="`/press/${news.slug}/`"
            >
              <div class="News__item--image-container">
                <img
                  v-if="news.thumbnail"
                  :data-src="getImageKitURL(news.thumbnail, 1, news.image_type,false,index)"
                  :src="getImageKitURL(news.thumbnail, 1, news.image_type,true,index)"
                  :alt="news.title"
                  class="News__item--image"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default.png`"
                  class="News__item--image"
                  alt="default image"
                >
              </div>
              <p class="small-subtitle grey">
                <span
                  v-for="category in customCategory(news.news_filters)"
                  :key="category"
                >
                  <span class="orange">{{ category }}</span>. {{ formatDate(news.publication_date) }}
                </span>
              </p>

              <h3 class="small-title">
                {{ news.title }}
              </h3>
            </InertiaLink>
          </div>
        </div>

        <div class="News__column">
          <div
            v-for="(news, index) in content.news_taxonomies"
            v-show="(index + 1) % 3 !== 0 && index % 3 !== 0"
            :key="`news${index}`"
            class="News__item"
          >
            <InertiaLink
              class="News__item--link"
              :href="`/press/${news.slug}/`"
            >
              <div class="News__item--image-container">
                <img
                  v-if="news.thumbnail"
                  :data-src="getImageKitURL(news.thumbnail, 1, news.image_type,false,index)"
                  :src="getImageKitURL(news.thumbnail, 1, news.image_type,true,index)"
                  :alt="news.title"
                  class="News__item--image"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default.png`"
                  class="News__item--image"
                  alt="default image"
                >
              </div>

              <p class="small-subtitle grey">
                <span
                  v-for="category in customCategory(news.news_filters)"
                  :key="category"
                >
                  <span class="orange">{{ category }}</span>. {{ formatDate(news.publication_date) }}
                </span>
              </p>

              <h3 class="small-title">
                {{ news.title }}
              </h3>
            </InertiaLink>
          </div>
        </div>

        <div class="News__column">
          <div
            v-for="(news, index) in content.news_taxonomies"
            v-show="(index + 1) % 3 === 0"
            :key="`news${index}`"
            class="News__item"
          >
            <InertiaLink
              class="News__item--link"
              :href="`/press/${news.slug}/`"
            >
              <div class="News__item--image-container">
                <img
                  v-if="news.thumbnail"
                  :data-src="getImageKitURL(news.thumbnail, 1, news.image_type,false,index,'images')"
                  :src="getImageKitURL(news.thumbnail, 1, news.image_type,true,index,'images')"
                  :alt="news.title"
                  class="News__item--image"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default.png`"
                  class="News__item--image"
                  alt="default image"
                >
              </div>

              <p class="small-subtitle grey">
                <span
                  v-for="category in customCategory(news.news_filters)"
                  :key="category"
                >
                  <span class="orange">{{ category }}</span>. {{ formatDate(news.publication_date) }}
                </span>
              </p>
              <h3 class="small-title">
                {{ news.title }}
              </h3>
            </InertiaLink>
          </div>
        </div>
      </section>

      <span id="news-filter-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";
import { InertiaLink } from "@inertiajs/inertia-vue3";
import moment from "moment";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import page from "@/mixins/page";
import imageKit from "@/mixins/image-kit";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [page, imageKit, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    },
    taxonomies: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    let filter = this.taxonomies.find(
      (tax) => tax.slug === this.$page.props.filter
    );
    seo.seoContent(this.content, filter.title);
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();
    });
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.toggleMenuColor("black", 0, 0);
      this.setScroller("#news-filter-wrapper", "#news-filter-content");

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#news-filter-content",
          markers: false,
          start: "50px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });
    },

    formatDate(date) {
      return moment(date).format("MMMM DD, YYYY");
    },

    customCategory(categories) {
      let returnCats = [];
      for (let i = 0; i < categories.length; i++) {
        const cat = categories[i];
        switch (cat) {
          case "awards":
            returnCats.push("Awards");
            break;
          case "news":
            returnCats.push("News");
            break;
          case "project-feature":
            returnCats.push("Project Feature");
            break;
          case "talk":
            returnCats.push("Talk");
            break;
          case "interview":
            returnCats.push("Interview");
            break;
          default:
            returnCats.push(cat);
        }
      }

      return returnCats;
    }
  }
};
</script>

<style lang="scss">
.News {
  // style in News
}
</style>
