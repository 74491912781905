<template>
  <a
    id="safeEmail"
    title="get in touch"
    class="safe-mail"
    :href="'mailto:' + reversed"
    target="_blank"
    @click="mailClicked($event)"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    email: {
      type:String,
      required: true
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      emailAlreadyClicked: false,
    };
  },
  computed: {
    reversed() {
      return this.email.split("").reverse().join("");
    },
  },
  methods: {
    mailClicked(e) {
      const el =  document.getElementById("safeEmail");
      if (this.emailAlreadyClicked) return;
      else {
        el.preventDefault;
        this.emailAlreadyClicked = true;
        el.href = "mailto:" + this.email;
        el.click();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
a.safe-mail {
  unicode-bidi: bidi-override;
}
</style>
