import { createStore } from 'vuex';

export const store = createStore({
  state: {
    /*
    |---------------------------------------------------------------------------
    | Backend Static Data
    |
    | See:
    |  - app/Support/View/AppViewComposer.php
    |---------------------------------------------------------------------------
    */

    // App.
    env: 'production',
    url: 'https://example.com',

    // ImageKit base URL
    baseimageUrl: 'https://cdn.binalogue.com/',

    // Config
    globals: {},

    // Localization.
    locale: 'en',

    /*
    |---------------------------------------------------------------------------
    | Backend Inertia.js Data
    |
    | See:
    |  - app/Support/Providers/InertiaServiceProvider.php
    |---------------------------------------------------------------------------
    */

    // Auth.
    user: undefined,

    // Flash.
    flash: {},
    isShownTheFlashStatus: false,

    /*
    |---------------------------------------------------------------------------
    | Frontend Data
    |
    | See:
    |  - resources/js/app.js
    |---------------------------------------------------------------------------
    */

    //anchor
    anchorName: '',

    // Device.
    isMobile: false,
    isPhone: false,

    // Loader.
    isLoading: true,
    isPxLoading: true,
    isProjectLoading: false,

    //cover
    isShownCover: false,

    //Logo
    menuColor: 'black',

    //search
    isSearchActive: false,
    toSearchHidden: false,

    //modalVideo
    isModalVideo: false,

    //modalVideo
    isPauseScroll: false,

    //url
    lastUrl: 'homepage',

    // Cookies.
    isShownTheCookieBanner: false,
    isShownIntroVideo: true,
    isMountedIntroVideo: false,

    // modalContactForm
    isModalContactForm: false,
    // Video vars
    video_id: '406370133',
    video_ratio: '',
    video_autoplay: false,
    video_loop: false,
    video_controls: false,
    bgVideoUpdate: false,

    //check bg
    bgBackgroundHome: false,
  },

  getters: {
    isAuth: ({ user }) => !!user,

    isGuest: ({ user }) => !user,
  },

  actions: {
    addBackendInertiajsData({ commit }, { auth, flash }) {
      if (auth) {
        commit('SET_AUTH_USER', auth.user);
      }

      if (flash) {
        commit('SET_FLASH_MESSAGE', flash);
        commit('TOGGLE_THE_FLASH_STATUS', !!flash.message);
      }
    },
  },

  mutations: {
    ADD_BACKEND_STATIC_DATA(state, serverPayload) {
      if (serverPayload.env) {
        state.env = serverPayload.env;
      }

      if (serverPayload.url) {
        state.url = serverPayload.url;
      }

      if (serverPayload.globals) {
        state.globals = serverPayload.globals;
      }

      if (serverPayload.locale) {
        state.locale = serverPayload.locale;
      }
    },

    SET_AUTH_USER(state, payload) {
      state.user = payload;
    },

    SET_FLASH_MESSAGE(
      state,
      { message, level = 'success', class: c = 'success' }
    ) {
      state.flash.message = message;
      state.flash.level = level;
      state.flash.class = c;
    },

    TOGGLE_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },

    TOGGLE_IS_PX_LOADING(state, payload) {
      state.isPxLoading = payload;
    },

    TOGGLE_IS_PROJECT_LOADING(state, payload) {
      state.isProjectLoading = payload;
    },

    TOGGLE_COLOR_MENU(state, payload) {
      state.menuColor = payload;
    },

    TOGGLE_SEARCH_ACTIVE(state, payload) {
      state.isSearchActive = payload;
    },

    TOGGLE_SEARCH_HIDDEN(state, payload) {
      state.toSearchHidden = payload;
    },

    TOGGLE_IS_MOBILE(state, payload) {
      state.isMobile = payload;
    },

    TOGGLE_BG_VIDEO(state, payload) {
      state.bgVideoUpdate = payload;
    },

    TOGGLE_IS_MODAL_VIDEO(state, payload) {
      state.isModalVideo = payload;
    },

    TOGGLE_IS_MODAL_CONTACT_FORM(state, payload) {
      state.isModalContactForm = payload;
    },

    TOGGLE_IS_PHONE(state, payload) {
      state.isPhone = payload;
    },

    TOGGLE_IS_PAUSE_SCROLL(state, payload) {
      state.isPauseScroll = payload;
    },

    TOGGLE_THE_FLASH_STATUS(state, payload) {
      state.isShownTheFlashStatus = payload;
    },

    TOGGLE_THE_COOKIE_BANNER(state, payload) {
      state.isShownTheCookieBanner = payload;
    },

    TOGGLE_THE_INTRO_VIDEO(state, payload) {
      state.isShownIntroVideo = payload;
    },

    TOGGLE_MOUNTED_VIDEO(state, payload) {
      state.isMountedIntroVideo = payload;
    },

    TOGGLE_COVER(state, payload) {
      state.isShownCover = payload;
    },

    TOGGLE_BG_VIDEO_UPDATE(state, payload) {
      state.bgVideoUpdate = payload;
    },
    TOGGLE_LAST_URL(state, payload) {
      state.lastUrl = payload;
    },

    TOGGLE_BG_BACKGROUND_HOME(state, payload) {
      state.bgBackgroundHome = payload;
    },
  },
});

export default store;
