<template>
  <div
    :id="`metadata${dynamicNumber}`"
    class="metadata metadata-scroll"
    :class="`remove-${contentMetadata.remove_margin_bottom}`"
    :style="`background : ${customColor}`"
  >
    <div class="metadata__main">
      <h3 class="indexProject__client">
        {{ content.client_subtitle }}
      </h3>
      <h1 class="project-title">
        {{ content.title }}
      </h1>
      <h2 class="indexProject__subtitle">
        {{ content.subtitle }}
      </h2>

      <a
        v-if="content.external_url"
        :href="content.external_url"
        target="_blank"
        class="indexProject__url"
      >
        {{ content.external_url.replace(/(^\w+:|^)\/\//, '') }}
      </a>
    </div>
    <div class="metadata__left">
      <div
        v-if="content.blurb"
        class="metadata__description main-text"
        v-html="markdownToHtml(content.blurb)"
      />
    </div>

    <div class="metadata__right">
      <div
        v-for="(item, index) in content.metadata"
        :key="`item${index}`"
        class="metadata__right--item"
      >
        <p
          v-if="item.title"
          class="main-text-bold"
          v-html="customTitle(item.title)"
        />
        <h4
          v-if="item.title === 'role'"
          class="regular-text grey"
        >
          {{ item.role }}
        </h4>

        <div
          v-if="item.title === 'client' || 'agency' || 'production' || 'artist'"
          class="credit"
        >
          <h4
            v-for="(contact, idx) in item.contacts"
            :key="`contact${idx}`"
            class="regular-text grey inline"
          >
            <span v-if="idx>0 && idx !== item.contacts.length -1">, </span>
            <span v-if="idx === item.contacts.length -1 && idx !==0"> &nbsp; & &nbsp;</span>
            <a
              v-if="contact.external_link"
              :href="contact.external_link"
              target="_blank"
              class="regular-text grey"
            >
              {{ contact.title }}
            </a>

            <span
              v-else
              target="_blank"
              class="regular-text grey"
            >
              {{ contact.title }}
            </span>
          </h4>
        </div>

        <h4
          v-if="item.title === 'credits'"
          class="regular-text grey credit"
          v-html="markdownToHtml(item.credits)"
        />

        <div
          v-if="item.title === 'Featured Projects'"
          class="regular-text grey"
        >
          <h4>
            <InertiaLink
              v-for="(featured, idx) in item.featured_projects"
              :key="`featured${idx}`"
              :href="`/work/${featured.slug}/`"
              target="_blank"
              class="regular-text grey"
            >
              {{ featured.title }} - {{ featured.subtitle }}
            </InertiaLink>
          </h4>
        </div>

        <div
          v-if="item.title === 'Mentions'"
          class="regular-text grey"
        >
          <h4>
            <a
              v-for="(mention, idx) in item.mentions"
              :key="`mention${idx}`"
              :href="mention.external_link"
              target="_blank"
              class="regular-text grey"
            >
              {{ mention.title }}
            </a>
          </h4>
        </div>

        <div v-if="item.custom_content !== 'default'">
          <div
            v-if="item.custom_content"
            class="regular-text grey credit"
            v-html="markdownToHtml(item.custom_content)"
          />
        </div>
      </div>

      <div
        v-if="content.awards"
        class="metadata__right--item"
      >
        <p class="main-text-bold">
          Awards
        </p>
        <h4
          v-for="(award, i) in content.awards"
          :key="`award${i}`"
          class="regular-text grey award"
        >
          <strong
            v-if="award.single_title"
            :class="{firstAward : i ===0}"
          >
            {{ award.single_title }}
          </strong>
          <span
            v-if="award.single_description"
          >
            {{ award.single_description }} <br>
          </span>
          <a
            :href="award.single_link"
            target="_blank"
            class="link"
          >
            {{ award.single_label }}
          </a>
        </h4>
      </div>

      <div
        v-if="content.publications"
        class="metadata__right--item"
      >
        <p class="main-text-bold">
          Publications
        </p>
        <h4
          v-for="(publication, idx) in content.publications"
          :key="`award${idx}`"
          class="regular-text grey publication"
        >
          <strong
            v-if="publication.single_title"
            :class="{firstAward : idx ===0}"
          >
            {{ publication.single_title }}
          </strong>
          <span
            v-if="publication.single_description"
          >
            {{ publication.single_description }} <br>
          </span>
          <a
            :href="publication.single_link"
            target="_blank"
            class="link"
          >
            {{ publication.single_label }}
          </a>
        </h4>
      </div>
      <div
        v-if="content.download_items"
      >
        <h4
          v-for="(download, idx) in content.download_items"
          :key="`award${idx}`"
          class="metadata__right--item"
        >
          <a
            :href="`/assets/${download.download_asset}`"
            download
            class="main-text-bold orange download"
          >
            {{ download.download_label }}
            <IconDownload />
          </a>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
/* Vendor */
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Mixins */
import marked from "@/mixins/marked";

export default {
  components: {
    InertiaLink,
  },

  mixins: [marked],
  props: {
    content: {
      type: Object,
      default: () => {},
    },

    contentMetadata: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.contentMetadata.bg_color) {
        return this.contentMetadata.bg_color;
      } else {
        return "transparent";
      }
    },
  },


  methods: {
    customTitle(title) {
      switch (title) {
        case 'agency':
          return "Agency";
          break;
        case 'artist':
          return "Artist";
          break;
        case 'client':
          return "Client";
          break;
        case 'credits':
          return "Credits";
          break;
        case 'featured_projects':
          return "Featured Projects";
          break;
        case 'mentions':
          return "Mentions";
          break;
        case 'production':
          return "Production Company";
          break;
        case 'role':
          return "Our Role";
          break;
        default:
          return title;
      }
    },
  },
};
</script>

<style lang="scss">
.metadata {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100vw;
  padding-top: 5vw;
  // @include paddingTop;
  padding-bottom: 7.7vw;
  overflow-x: hidden;

  &.remove-true {
    padding-bottom: 0;
  }

  @include paddingMobile;

  @include tablet {
    @include paddingBig;

    padding-top: 0;
  }

  &__main {
    padding-bottom: 5vw;
    width: 100%;

    @include desktop-s {
      max-width: 68%;
      @include paddingBottomSmall;
    }
  }

  &__left {
    width: 100%;
    flex-grow: 0;

    @include tablet-m {
      width: 53%;
    }

    @include desktop-l {
      width: 51%;
    }
  }

  &__description {
    p {
      margin-bottom: 15px;
    }

    pre  * {
      font-family: $helvetica;
      font-size: 17px;
      line-height: 1.4;
      font-variation-settings: 'wght' 500, 'wdth' 100, 'opsz' 30;

      @include tablet {
        font-size: 20px;
      }

      @include desktop-sm {
        font-size: 22px;
      }

      @include desktop-l {
        font-size: 26px;
      }

      @include desktop-xl {
        font-size: 29px;
      }
    }

    a {
      color: $grey;

      &:hover {
        color: $orange;
      }
    }
  }

  &__right {
    width: 100%;
    flex-grow: 0;

    @include tablet-m {
      width: 35%;
    }

    @include desktop-l {
      width: 31%;
    }

    &--item {
      margin-bottom: 20px;
    }

    .download {

      svg {
        margin-left: 10px;
        opacity: 0;
        transform: translateY(2px);
        height: 16px;

        @include transition;


         @include tablet {
            height: 19px
          }

          @include desktop-sm {
            height: 20px;
          }

          @include desktop-l {
            height: 26px;
          }

          @include desktop-xl {
            height: 29px;
          }
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }

    }

    .credit {
      strong {
        display: block;
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
      }

      p {
        margin-bottom: 20px;
      }

      a {
        color: $grey;
        &:hover {
          color: $orange;
        }
      }
    }

    .award {
      strong {
        display: block;
        margin-top: 15px;
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;

        &.firstAward {
           margin-top: 0;
        }
      }
    }

    .publication {
      strong {
        display: block;
        margin-top: 15px;
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;

        &.firstAward {
           margin-top: 0;
        }
      }
    }

  }

  .link {
    color: $grey;

    &:hover {
      color: $orange;
    }
  }
}
</style>
