<template>
  <div
    :id="`interview-${dynamicNumber}`"
    class="InterviewTitle"
  >
    <div class="InterviewTitle__left">
      <h2 class="medium-title">
        {{ content.title }}
      </h2>
      <h4 class="main-text-bold">
        {{ content.subtitle }}
      </h4>
      <p class="light-text date">
        {{ content.date }}
      </p>
    </div>
    <div class="InterviewTitle__right">
      <img
        v-if="content.thumbnail"
        :src="$store.state.baseimageUrl + content.thumbnail + '?tr=w-500'"
        :alt="content.thumbnail.alt"
        class="InterviewTitle__right--image"
      >
    </div>
    <!-- {{ content }} -->
  </div>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";


export default {
  mixins: [marked],
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },
  },

};
</script>

<style lang="scss">
.InterviewTitle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;

  @include paddingMobile;
  @include marginBottomSmall;

  @include tablet {
    @include paddingSuper;
  }

  &__left {
    width: 70%;

    * {
      margin-bottom: 6px;
      line-height: 1.1;

      @include tablet {
        margin-bottom: 8px;
        line-height: 1.2;
      }

    }

    .date {
      margin-top: 10px;

      @include tablet {
        margin-top: 0;
      }
    }
  }

  &__right {
    max-width: 125px;
    width: 20%;

    &--image {
      width: 100%;
      height: auto;
    }
  }
}
</style>
