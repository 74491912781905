<template>
  <div
    :id="`question-${dynamicNumber}`"
    class="InterviewQuestion"
    :class="`remove-margin-${content.remove_margin_bottom}`"
  >
    <div
      v-if="content.question"
      class="InterviewQuestion__question"
      v-html="markdownToHtml(content.question)"
    />
    <div
      v-if="content.answer"
      class="InterviewQuestion__answer main-text"
      v-html="markdownToHtml(content.answer)"
    />
    <div
      v-if="content.answer_second_lang"
      class="InterviewQuestion__answer main-text-italic grey"
      v-html="markdownToHtml(content.answer_second_lang)"
    />
  </div>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";


export default {
  mixins: [marked],
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },
  },
};
</script>

<style lang="scss">
.InterviewQuestion {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  @include paddingMobile;

  @include tablet {
    @include paddingSuper;
    @include marginBottom;

    &.remove-margin-true {
      margin-bottom: 0;
    }
  }

  &__question {
    margin-bottom: 25px;

    strong {
      color: $orange;
    }

    h2,h3, p {
      font-size: 21px;
      line-height: 1.4;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;

      @include tablet {
        font-size: 23px;
      }

      @include desktop-sm {
        font-size: 28px;
      }

      @include desktop-l {
        font-size: 36px;
      }

      @include desktop-xl {
        font-size: 39px;
      }
    }
  }

  &__answer {
    p {
      margin-bottom: 25px;
      font-variation-settings: 'wght' 400, 'wdth' 100, 'opsz' 30;
    }

    a {
      color: $grey;
      @include transition;

      &:hover {
        color: $orange;
      }
    }
  }
}
</style>
