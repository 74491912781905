<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="178.93"
    height="87.21"
    viewBox="0 0 178.93 87.21"
  >
    <g>
      <path
        d="M32.25,96.58V28.94H62.81a28.93,28.93,0,0,1,13.27,2.8,19.5,19.5,0,0,1,8.35,7.86,23.62,23.62,0,0,1,2.85,11.78,23.83,23.83,0,0,1-2.85,11.87,19.46,19.46,0,0,1-8.35,7.87,29,29,0,0,1-13.29,2.8H47V96.58ZM61.41,61.41a13.35,13.35,0,0,0,5.89-1.17,7.87,7.87,0,0,0,3.61-3.39,11.27,11.27,0,0,0,1.21-5.44,11.2,11.2,0,0,0-1.22-5.47,7.88,7.88,0,0,0-3.61-3.37,13.58,13.58,0,0,0-5.88-1.14H47v20Z"
        transform="translate(-32.25 -28.94)"
        fill="#fff"
      />
      <path
        d="M92,96.58V28.94h13.71V96.58Z"
        transform="translate(-32.25 -28.94)"
        fill="#fff"
      />
      <path
        d="M128.17,97.93a24,24,0,0,1-9.28-1.66,13.46,13.46,0,0,1-6.19-5,15.81,15.81,0,0,1-.36-15.45,13.62,13.62,0,0,1,5.94-5.16A32.16,32.16,0,0,1,129,68l7.93-.78a14.72,14.72,0,0,0,3.79-.82A4.76,4.76,0,0,0,143,64.73a4.83,4.83,0,0,0,.77-2.75l2.35,3a19.77,19.77,0,0,1-.84,5.73,5.61,5.61,0,0,1-2.33,3,11.22,11.22,0,0,1-4.55,1.34l-5.86.72A15.69,15.69,0,0,0,127.78,77a5.71,5.71,0,0,0-2.53,2.13,6.16,6.16,0,0,0-.78,3.19,6.22,6.22,0,0,0,.87,3.36,5.36,5.36,0,0,0,2.6,2.11,11.27,11.27,0,0,0,4.32.72A13.47,13.47,0,0,0,138,87.29a9.51,9.51,0,0,0,4.15-3.67,11.24,11.24,0,0,0,1.54-6.11h2.8v6.22a18,18,0,0,1-3.17,7.66,15,15,0,0,1-6.2,4.87A22.23,22.23,0,0,1,128.17,97.93Zm28-.9a15.61,15.61,0,0,1-6.92-1.3,8,8,0,0,1-3.84-4.07,18.06,18.06,0,0,1-1.21-7.22l-.5-1.18V62a5.52,5.52,0,0,0-.82-3.13A4.82,4.82,0,0,0,140.28,57a15.37,15.37,0,0,0-4.79-.61,15.57,15.57,0,0,0-5.05.67,5.33,5.33,0,0,0-2.89,2.24,9.3,9.3,0,0,0-1.18,4.29H112.69a18.82,18.82,0,0,1,2.65-9.32,15,15,0,0,1,7.38-5.81,34.85,34.85,0,0,1,12.91-2A35.92,35.92,0,0,1,148,48.22a14.09,14.09,0,0,1,7,5.13,14.15,14.15,0,0,1,2.22,8V84a3.53,3.53,0,0,0,.32,1.58,2.12,2.12,0,0,0,1,1,3.87,3.87,0,0,0,1.66.32h2v9.76c-1,.14-2,.25-3.07.33S157.18,97,156.2,97Z"
        transform="translate(-32.25 -28.94)"
        fill="#fff"
      />
      <path
        d="M163.51,105.53h6.39a9.68,9.68,0,0,0,3.65-.61,6,6,0,0,0,2.45-1.84,10.49,10.49,0,0,0,1.53-3l2.35-7,.14,8.27L160,47.56h14.7l8.05,25.84q1.22,4,2.28,8.18t2.07,8.59l-2.57,0q1.29-5.19,2.38-9.38t2-7.43l7.91-25.84h14.32l-19.62,53.79a30.8,30.8,0,0,1-4.75,9,14.21,14.21,0,0,1-6.5,4.45A33,33,0,0,1,170,116.15c-1.09,0-2.14,0-3.16-.05s-2.1-.08-3.28-.14Z"
        transform="translate(-32.25 -28.94)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
