<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1182"
    height="234"
    viewBox="0 0 1182 234"
  >
    <g id="txt">
      <path
        d="M23.38,83.59,1.35,3.13h18L29.34,44q1.34,5.88,2.87,13.52t3.18,16H31.74q1.79-9.18,3.3-16.81t2.72-12.85l10-40.63H65.42L75.5,43.81q1.66,7.45,3.11,14.61t2.8,15H78q1.62-8.51,3-15.48t3-14.18L93.81,3.13h18l-22,80.46H70.7L59.32,38.3Q58.21,33.65,57.06,28T54.63,15.45l4.15-.76q-1.35,7.4-2.51,13.1T53.92,38.25L42.48,83.59Z"
        fill="#fff"
      />
      <path
        d="M136.41,84.94a32.51,32.51,0,0,1-15.79-3.68,25.61,25.61,0,0,1-10.48-10.55,33.63,33.63,0,0,1-3.7-16.19,33.73,33.73,0,0,1,3.67-16,26.12,26.12,0,0,1,10.41-10.75,31.39,31.39,0,0,1,15.72-3.82,31.08,31.08,0,0,1,15.68,3.82,25.83,25.83,0,0,1,10.25,10.69,33.62,33.62,0,0,1,3.59,15.81c0,.69,0,1.44,0,2.23s-.09,1.63-.15,2.51H115.48V48.78h38.3l-4,2.29a19.07,19.07,0,0,0-1.59-8,12.09,12.09,0,0,0-4.62-5.31,13.75,13.75,0,0,0-7.43-1.91,14.05,14.05,0,0,0-7.47,1.89,12.28,12.28,0,0,0-4.78,5.7A23.93,23.93,0,0,0,122.14,53V57.2A20.4,20.4,0,0,0,123.85,66a11.69,11.69,0,0,0,5,5.38,16.06,16.06,0,0,0,7.83,1.79,16.49,16.49,0,0,0,5.78-.93,10.78,10.78,0,0,0,4.15-2.67,11.09,11.09,0,0,0,2.48-4.19l15.39,0a24.4,24.4,0,0,1-14.93,17.2A34.43,34.43,0,0,1,136.41,84.94Z"
        fill="#fff"
      />
      <path
        d="M168.64,37.43V28.9l3.11-.06a6.11,6.11,0,0,0,3.14-1,5,5,0,0,0,1.8-2.5,12.65,12.65,0,0,0,.6-4.25V17.29l2.88,2.85H168.64v-17h18.59V20.45a22.31,22.31,0,0,1-1.71,9.38,11.69,11.69,0,0,1-5.1,5.51,19.39,19.39,0,0,1-8.53,2Z"
        fill="#ff7f40"
      />
      <path
        d="M193.76,83.59V25.28h15.87V40.39l.43,0V83.59Zm13.72-36.33a30.67,30.67,0,0,1,2.08-11.48,18.43,18.43,0,0,1,6.41-8.3,18.16,18.16,0,0,1,10.84-3.1c.64,0,1.25,0,1.83.07s1.19.11,1.81.18V39.52l-2,0h-1.88a20.24,20.24,0,0,0-8.92,1.78A12.39,12.39,0,0,0,212,46.68a19.3,19.3,0,0,0-1.94,9.14h-2.58Z"
        fill="#fff"
      />
      <path
        d="M262.43,84.94a32.51,32.51,0,0,1-15.79-3.68,25.61,25.61,0,0,1-10.48-10.55,33.63,33.63,0,0,1-3.7-16.19,33.73,33.73,0,0,1,3.67-16,26.12,26.12,0,0,1,10.41-10.75,31.39,31.39,0,0,1,15.72-3.82,31.05,31.05,0,0,1,15.68,3.82,25.83,25.83,0,0,1,10.25,10.69,33.62,33.62,0,0,1,3.59,15.81c0,.69,0,1.44,0,2.23s-.09,1.63-.14,2.51H241.5V48.78h38.3l-4,2.29a19.07,19.07,0,0,0-1.59-8,12.09,12.09,0,0,0-4.62-5.31,13.75,13.75,0,0,0-7.43-1.91,14.05,14.05,0,0,0-7.47,1.89,12.28,12.28,0,0,0-4.78,5.7A23.93,23.93,0,0,0,248.16,53V57.2A20.4,20.4,0,0,0,249.87,66a11.69,11.69,0,0,0,5,5.38,16.08,16.08,0,0,0,7.84,1.79,16.47,16.47,0,0,0,5.77-.93,10.78,10.78,0,0,0,4.15-2.67,11.09,11.09,0,0,0,2.48-4.19l15.39,0a24.4,24.4,0,0,1-14.93,17.2A34.43,34.43,0,0,1,262.43,84.94Z"
        fill="#fff"
      />
      <path
        d="M342.92,85.2a28.48,28.48,0,0,1-11-2,15.86,15.86,0,0,1-7.36-5.92,17.06,17.06,0,0,1-2.61-9.64,16.89,16.89,0,0,1,2.19-8.73,16.08,16.08,0,0,1,7.06-6.13,37.93,37.93,0,0,1,12.75-3.22l9.44-.94a17.21,17.21,0,0,0,4.51-1,5.67,5.67,0,0,0,2.65-2,5.78,5.78,0,0,0,.92-3.28l2.8,3.59a23.2,23.2,0,0,1-1,6.8,6.58,6.58,0,0,1-2.77,3.58A13.53,13.53,0,0,1,355,58l-7,.85a18.76,18.76,0,0,0-5.61,1.44,6.75,6.75,0,0,0-3,2.54,7.27,7.27,0,0,0-.93,3.79,7.39,7.39,0,0,0,1,4,6.44,6.44,0,0,0,3.09,2.51,13.3,13.3,0,0,0,5.14.86,16.08,16.08,0,0,0,6.88-1.44,11.31,11.31,0,0,0,4.93-4.36,13.41,13.41,0,0,0,1.84-7.28h3.33v7.41A21.59,21.59,0,0,1,361,77.42a17.88,17.88,0,0,1-7.37,5.79A26.71,26.71,0,0,1,342.92,85.2Zm33.34-1.08A18.49,18.49,0,0,1,368,82.57a9.41,9.41,0,0,1-4.57-4.83A21.58,21.58,0,0,1,362,69.15l-.59-1.41V42.4a6.61,6.61,0,0,0-1-3.73,5.77,5.77,0,0,0-3.13-2.19,18.46,18.46,0,0,0-5.69-.72,18.68,18.68,0,0,0-6,.79,6.41,6.41,0,0,0-3.44,2.67,11,11,0,0,0-1.4,5.1H324.51a22.33,22.33,0,0,1,3.15-11.09,17.87,17.87,0,0,1,8.78-6.9c4-1.6,9.08-2.41,15.36-2.41q9,0,14.76,2.15a16.73,16.73,0,0,1,8.36,6.1,16.81,16.81,0,0,1,2.64,9.55V68.59a4.19,4.19,0,0,0,.38,1.88,2.52,2.52,0,0,0,1.17,1.13,4.63,4.63,0,0,0,2,.38h2.37V83.59c-1.22.17-2.44.3-3.64.39S377.43,84.12,376.26,84.12Z"
        fill="#fff"
      />
      <path
        d="M437.4,83.87A23.19,23.19,0,0,1,415.3,69.7a37.89,37.89,0,0,1-3.1-15.83,37.58,37.58,0,0,1,3.11-15.79A23.2,23.2,0,0,1,437.4,23.92a21.42,21.42,0,0,1,12.9,3.82,23.09,23.09,0,0,1,7.92,10.51,42.3,42.3,0,0,1,2.72,15.39v.23a42.62,42.62,0,0,1-2.63,15.55A23.15,23.15,0,0,1,450.4,80,21.38,21.38,0,0,1,437.4,83.87Zm5.4,24.63A38.22,38.22,0,0,1,428.21,106a22,22,0,0,1-9.62-7.27,22.52,22.52,0,0,1-4.13-11.52l16.36,0a10.4,10.4,0,0,0,1.81,5.07,8.82,8.82,0,0,0,4,3,16.57,16.57,0,0,0,6.06,1,15.93,15.93,0,0,0,7.19-1.46,9.38,9.38,0,0,0,4.33-4.4,16.89,16.89,0,0,0,1.44-7.42V67.46h1.7V38.76h-1.24l0-13.48h16.07v56.9a27.94,27.94,0,0,1-3.35,14.06,22.06,22.06,0,0,1-9.94,9.09Q452.26,108.51,442.8,108.5Zm-.42-37a12.71,12.71,0,0,0,7.28-2,12.88,12.88,0,0,0,4.69-6A25,25,0,0,0,456,53.81a24.92,24.92,0,0,0-1.62-9.48,13.05,13.05,0,0,0-4.69-6,12.88,12.88,0,0,0-7.33-2.06,12.44,12.44,0,0,0-7.25,2.12,13.34,13.34,0,0,0-4.7,6.11,25.27,25.27,0,0,0-1.64,9.57,24.61,24.61,0,0,0,1.64,9.5,12.59,12.59,0,0,0,4.69,5.91A13,13,0,0,0,442.38,71.47Z"
        fill="#fff"
      />
      <path
        d="M479.72,83.59V3.13H496V83.59Z"
        fill="#fff"
      />
      <path
        d="M532.83,84.94a33.2,33.2,0,0,1-16-3.75A26.78,26.78,0,0,1,506,70.54a32.33,32.33,0,0,1-3.87-16.08,32.24,32.24,0,0,1,3.87-16,26.84,26.84,0,0,1,10.84-10.7,35.6,35.6,0,0,1,31.93,0,26.74,26.74,0,0,1,10.81,10.69,32.41,32.41,0,0,1,3.86,16,32.4,32.4,0,0,1-3.86,16.06,26.63,26.63,0,0,1-10.81,10.67A33,33,0,0,1,532.83,84.94Zm0-13.1a13.85,13.85,0,0,0,7.36-1.92,12.91,12.91,0,0,0,4.93-5.82,23,23,0,0,0,1.78-9.58,23.25,23.25,0,0,0-1.77-9.58A13,13,0,0,0,540.2,39a13.59,13.59,0,0,0-7.37-2,13.94,13.94,0,0,0-7.49,2,12.77,12.77,0,0,0-4.92,5.87,23.59,23.59,0,0,0-1.74,9.61,23.2,23.2,0,0,0,1.75,9.56,12.75,12.75,0,0,0,4.91,5.82A14.09,14.09,0,0,0,532.83,71.84Z"
        fill="#fff"
      />
      <path
        d="M569.5,83.59V3.13h16.3v36.3h-1.7V69.32h1.16l0,14.27Zm34.1,1.35A20.54,20.54,0,0,1,590.81,81a23.19,23.19,0,0,1-7.68-10.76,45.54,45.54,0,0,1-2.53-15.84v-.22a44.32,44.32,0,0,1,2.52-15.72,23,23,0,0,1,7.71-10.7,20.69,20.69,0,0,1,12.77-3.87A24,24,0,0,1,617,27.68a24.25,24.25,0,0,1,8.82,10.65,38.9,38.9,0,0,1,3.1,16.1,39.19,39.19,0,0,1-3.08,16.12A23.25,23.25,0,0,1,603.6,84.94Zm-4.81-13.16A12.57,12.57,0,0,0,606,69.69a13.24,13.24,0,0,0,4.72-6.06,24.75,24.75,0,0,0,1.65-9.51,23.58,23.58,0,0,0-1.65-9.32,12.62,12.62,0,0,0-4.69-5.8,13.12,13.12,0,0,0-7.28-2,12.25,12.25,0,0,0-12,7.95,24.22,24.22,0,0,0-1.64,9.4,24.4,24.4,0,0,0,1.64,9.42,12.19,12.19,0,0,0,12,8Z"
        fill="#fff"
      />
      <path
        d="M654.25,85.2a28.57,28.57,0,0,1-11-2,16,16,0,0,1-7.36-5.92,17.06,17.06,0,0,1-2.61-9.64,16.89,16.89,0,0,1,2.19-8.73,16.08,16.08,0,0,1,7.06-6.13,37.93,37.93,0,0,1,12.76-3.22l9.43-.94a17.21,17.21,0,0,0,4.51-1,5.62,5.62,0,0,0,2.65-2,5.7,5.7,0,0,0,.92-3.28l2.8,3.59a23.6,23.6,0,0,1-1,6.8,6.67,6.67,0,0,1-2.77,3.58A13.58,13.58,0,0,1,666.37,58l-7,.85a18.92,18.92,0,0,0-5.61,1.44,6.86,6.86,0,0,0-3,2.54,7.27,7.27,0,0,0-.93,3.79,7.48,7.48,0,0,0,1,4,6.42,6.42,0,0,0,3.1,2.51,13.24,13.24,0,0,0,5.14.86A16.14,16.14,0,0,0,666,72.54a11.42,11.42,0,0,0,4.93-4.36,13.49,13.49,0,0,0,1.83-7.28h3.34v7.41a21.48,21.48,0,0,1-3.78,9.11,17.88,17.88,0,0,1-7.37,5.79A26.66,26.66,0,0,1,654.25,85.2Zm33.33-1.08a18.49,18.49,0,0,1-8.23-1.55,9.43,9.43,0,0,1-4.56-4.83,21.58,21.58,0,0,1-1.44-8.59l-.6-1.41V42.4a6.61,6.61,0,0,0-1-3.73,5.8,5.8,0,0,0-3.14-2.19,23,23,0,0,0-11.69.07,6.39,6.39,0,0,0-3.45,2.67,11.08,11.08,0,0,0-1.4,5.1H635.83A22.53,22.53,0,0,1,639,33.23a17.85,17.85,0,0,1,8.79-6.9q5.94-2.4,15.35-2.41,9,0,14.76,2.15a16.73,16.73,0,0,1,8.36,6.1,16.81,16.81,0,0,1,2.64,9.55V68.59a4.19,4.19,0,0,0,.38,1.88,2.54,2.54,0,0,0,1.18,1.13,4.55,4.55,0,0,0,2,.38h2.38V83.59c-1.23.17-2.44.3-3.65.39S688.75,84.12,687.58,84.12Z"
        fill="#fff"
      />
      <path
        d="M701.09,83.59V3.13h16.3V83.59Z"
        fill="#fff"
      />
      <path
        d="M783.28,85a31.73,31.73,0,0,1-15.67-3.74,25.64,25.64,0,0,1-10.35-10.64,34,34,0,0,1-3.65-16.13,34,34,0,0,1,3.65-16.11A25.47,25.47,0,0,1,767.65,27.7,32.1,32.1,0,0,1,783.47,24a33.23,33.23,0,0,1,14.33,2.9A23.8,23.8,0,0,1,807.5,35a25.75,25.75,0,0,1,4.31,12H795.4A13.38,13.38,0,0,0,793,41.44a10.18,10.18,0,0,0-4.19-3.26,14,14,0,0,0-5.52-1.06,12.65,12.65,0,0,0-7,1.89,12.05,12.05,0,0,0-4.51,5.75,25.79,25.79,0,0,0-1.58,9.7,26,26,0,0,0,1.58,9.78,11.89,11.89,0,0,0,4.49,5.7,12.56,12.56,0,0,0,7,1.87,13.9,13.9,0,0,0,5.72-1.12,10.4,10.4,0,0,0,4.25-3.44,14.15,14.15,0,0,0,2.38-5.92h16.24a26.94,26.94,0,0,1-4.53,12.46,24.82,24.82,0,0,1-9.86,8.26A32.47,32.47,0,0,1,783.28,85Z"
        fill="#fff"
      />
      <path
        d="M818.17,83.62V25.31H834V40.42l.43,0V83.62Zm13.72-36.33A30.76,30.76,0,0,1,834,35.8a18.4,18.4,0,0,1,6.41-8.29,18.16,18.16,0,0,1,10.84-3.11c.64,0,1.25,0,1.83.07s1.19.11,1.81.19V39.55l-2,0H851A20.24,20.24,0,0,0,842,41.3a12.34,12.34,0,0,0-5.63,5.41,19.28,19.28,0,0,0-1.94,9.14h-2.58Z"
        fill="#fff"
      />
      <path
        d="M886.84,85a32.62,32.62,0,0,1-15.79-3.68,25.52,25.52,0,0,1-10.48-10.56,33.56,33.56,0,0,1-3.7-16.18,33.7,33.7,0,0,1,3.67-16A26.12,26.12,0,0,1,871,27.77a34.17,34.17,0,0,1,31.4,0A25.77,25.77,0,0,1,912.6,38.46a33.58,33.58,0,0,1,3.59,15.8c0,.7,0,1.45-.05,2.24s-.09,1.63-.15,2.51H865.91V48.81h38.3l-4,2.29a19.07,19.07,0,0,0-1.59-8A12.09,12.09,0,0,0,894,37.84a13.77,13.77,0,0,0-7.43-1.91,14.05,14.05,0,0,0-7.47,1.89,12.28,12.28,0,0,0-4.78,5.7,23.93,23.93,0,0,0-1.71,9.53v4.18a20.43,20.43,0,0,0,1.71,8.82,11.72,11.72,0,0,0,5,5.38,16.13,16.13,0,0,0,7.84,1.79,16.53,16.53,0,0,0,5.78-.93A10.78,10.78,0,0,0,897,69.62a11.05,11.05,0,0,0,2.48-4.2l15.39,0A24.4,24.4,0,0,1,900,82.6,34.43,34.43,0,0,1,886.84,85Z"
        fill="#fff"
      />
      <path
        d="M941.5,85.23a28.48,28.48,0,0,1-11-2,15.86,15.86,0,0,1-7.36-5.92,17.09,17.09,0,0,1-2.62-9.65A16.94,16.94,0,0,1,922.67,59a16.23,16.23,0,0,1,7.07-6.13,38.17,38.17,0,0,1,12.75-3.22l9.44-.93a17.54,17.54,0,0,0,4.5-1,5.6,5.6,0,0,0,2.66-2,5.75,5.75,0,0,0,.92-3.27L962.8,46a22.94,22.94,0,0,1-1,6.81A6.7,6.7,0,0,1,959,56.43,13.53,13.53,0,0,1,953.62,58l-7,.85a18.77,18.77,0,0,0-5.6,1.44,6.75,6.75,0,0,0-3,2.54,7.27,7.27,0,0,0-.93,3.79,7.42,7.42,0,0,0,1,4,6.39,6.39,0,0,0,3.09,2.51,13.5,13.5,0,0,0,5.14.86,16.08,16.08,0,0,0,6.88-1.44,11.37,11.37,0,0,0,4.93-4.36A13.49,13.49,0,0,0,960,60.93h3.33v7.4a21.54,21.54,0,0,1-3.77,9.11,17.88,17.88,0,0,1-7.37,5.8A26.74,26.74,0,0,1,941.5,85.23Zm33.34-1.08a18.54,18.54,0,0,1-8.24-1.55A9.51,9.51,0,0,1,962,77.77a21.62,21.62,0,0,1-1.44-8.59L960,67.77V42.43a6.61,6.61,0,0,0-1-3.73,5.77,5.77,0,0,0-3.13-2.19,23.05,23.05,0,0,0-11.7.07,6.43,6.43,0,0,0-3.45,2.67,11.08,11.08,0,0,0-1.39,5.1H923.08a22.5,22.5,0,0,1,3.15-11.09A17.91,17.91,0,0,1,935,26.35Q941,24,950.37,24q9,0,14.76,2.15a16.7,16.7,0,0,1,8.37,6.1,16.81,16.81,0,0,1,2.64,9.55V68.62a4,4,0,0,0,.38,1.87,2.43,2.43,0,0,0,1.17,1.13,4.48,4.48,0,0,0,2,.39H982V83.62c-1.22.17-2.44.3-3.64.39S976,84.15,974.84,84.15Z"
        fill="#fff"
      />
      <path
        d="M980.94,37.82V25.31h37V37.82h-37ZM1009.81,84a33.35,33.35,0,0,1-11.1-1.54,12,12,0,0,1-6.41-4.91,16.39,16.39,0,0,1-2.12-8.86V3.27h16.3V66.1a5.78,5.78,0,0,0,.69,3.08,3.61,3.61,0,0,0,2.1,1.54,13.71,13.71,0,0,0,3.87.44H1018V83.62l-.41,0-.38,0c-1.25.12-2.49.2-3.73.24S1011,84,1009.81,84Z"
        fill="#fff"
      />
      <rect
        x="1024.76"
        y="25.31"
        width="16.3"
        height="58.31"
        fill="#fff"
      />
      <path
        d="M1067.44,83.62l-21.92-58.31H1063l10.29,30.79q1.64,5.25,3.11,10.62c1,3.58,2.06,7.86,3.24,12.83l-5.7,0q1.66-6.85,3.22-12.54c1-3.78,2.11-7.46,3.22-11l10.11-30.74h17l-21.89,58.31Z"
        fill="#fff"
      />
      <path
        d="M1135.41,85a32.62,32.62,0,0,1-15.79-3.68,25.52,25.52,0,0,1-10.48-10.56,33.56,33.56,0,0,1-3.7-16.18,33.7,33.7,0,0,1,3.67-16,26.12,26.12,0,0,1,10.41-10.75,34.17,34.17,0,0,1,31.4,0,25.77,25.77,0,0,1,10.25,10.69,33.7,33.7,0,0,1,3.59,15.8c0,.7,0,1.45-.06,2.24s-.08,1.63-.14,2.51h-50.08V48.81h38.3l-4,2.29a19.23,19.23,0,0,0-1.59-8,12.09,12.09,0,0,0-4.62-5.31,13.77,13.77,0,0,0-7.43-1.91,14.05,14.05,0,0,0-7.47,1.89,12.22,12.22,0,0,0-4.78,5.7,23.93,23.93,0,0,0-1.71,9.53v4.18a20.43,20.43,0,0,0,1.71,8.82,11.72,11.72,0,0,0,5,5.38,16.13,16.13,0,0,0,7.84,1.79,16.53,16.53,0,0,0,5.78-.93,10.78,10.78,0,0,0,4.15-2.67,11,11,0,0,0,2.47-4.2l15.4,0a24.4,24.4,0,0,1-14.93,17.2A34.43,34.43,0,0,1,1135.41,85Z"
        fill="#fff"
      />
      <path
        d="M29.71,208.37a34.25,34.25,0,0,1-14.63-3A23.9,23.9,0,0,1,5,196.94a21.9,21.9,0,0,1-3.64-12.5,21.63,21.63,0,0,1,2.4-10.23,24.18,24.18,0,0,1,6.93-8,47.36,47.36,0,0,1,11.52-6.11L31,167.94a43.3,43.3,0,0,0-7,4.35,16.13,16.13,0,0,0-4.14,4.78,12.14,12.14,0,0,0-1.47,6,11.14,11.14,0,0,0,1.6,5.82,11.4,11.4,0,0,0,4.66,4.28A15.73,15.73,0,0,0,32,194.75a20.26,20.26,0,0,0,5.68-.76,18.82,18.82,0,0,0,4.83-2.15,19.63,19.63,0,0,0,4-3.3l9.21,10.17A33.84,33.84,0,0,1,48.3,204a38.42,38.42,0,0,1-8.74,3.27A41,41,0,0,1,29.71,208.37ZM18.52,161.78a49.55,49.55,0,0,1-4.77-6.17A24.33,24.33,0,0,1,11,149.93a19.1,19.1,0,0,1-.89-5.77A18.37,18.37,0,0,1,13,134a19.72,19.72,0,0,1,8.3-7.09,28.64,28.64,0,0,1,12.47-2.57,30,30,0,0,1,12.33,2.4,19.4,19.4,0,0,1,8.35,6.77,17.44,17.44,0,0,1,3,10,18.13,18.13,0,0,1-2.23,8.69,25.13,25.13,0,0,1-6.6,7.67,41.58,41.58,0,0,1-10.63,6l-7.86-8.59a36.89,36.89,0,0,0,7-3.81,15.14,15.14,0,0,0,4.16-4.25,9.15,9.15,0,0,0,1.4-4.85,6.92,6.92,0,0,0-1.06-3.76,7.08,7.08,0,0,0-2.93-2.58,10,10,0,0,0-8.4,0,6.81,6.81,0,0,0-2.83,2.55,7.19,7.19,0,0,0-1,3.82,8.81,8.81,0,0,0,.57,3,16,16,0,0,0,1.73,3.38,31.79,31.79,0,0,0,3,3.8l46.87,52H58.47Zm34.19,19.86a29.61,29.61,0,0,0,2-4.9,32.41,32.41,0,0,0,1.16-5.11,43.89,43.89,0,0,0,.45-5.1H71a49.5,49.5,0,0,1-1.24,9.56,47,47,0,0,1-2.94,8.69,38.87,38.87,0,0,1-4.46,7.51Z"
        fill="#ff7f40"
      />
      <g>
        <rect
          x="1159.86"
          y="189.58"
          width="17.01"
          height="17.01"
          fill="#ff7f40"
        />
        <path
          d="M107.87,229.64V148.28H123.6l0,14h-1.16v29.92h1.7v37.4ZM142,208A20.54,20.54,0,0,1,129.18,204a23.26,23.26,0,0,1-7.68-10.76A45.54,45.54,0,0,1,119,177.43v-.22a44.32,44.32,0,0,1,2.52-15.72,23,23,0,0,1,7.71-10.7A20.69,20.69,0,0,1,142,146.92a24,24,0,0,1,13.42,3.76,24.25,24.25,0,0,1,8.82,10.65,43.54,43.54,0,0,1,0,32.22A23.28,23.28,0,0,1,142,208Zm-4.81-13.17a12.57,12.57,0,0,0,7.25-2.09,13.24,13.24,0,0,0,4.72-6.06,24.71,24.71,0,0,0,1.65-9.51,23.58,23.58,0,0,0-1.65-9.32,12.62,12.62,0,0,0-4.69-5.8,13.12,13.12,0,0,0-7.28-2,12.24,12.24,0,0,0-12,8,27.79,27.79,0,0,0,0,18.81,12.19,12.19,0,0,0,12,8Z"
          fill="#fff"
        />
        <path
          d="M174,206.59V148.28h15.87v15.11l.43,0v43.17Zm13.73-36.33a30.67,30.67,0,0,1,2.07-11.48,18.43,18.43,0,0,1,6.41-8.3,18.18,18.18,0,0,1,10.84-3.1c.64,0,1.25,0,1.83.07l1.81.18v14.89l-2,0h-1.88a20.27,20.27,0,0,0-8.92,1.78,12.32,12.32,0,0,0-5.62,5.41,19.18,19.18,0,0,0-1.95,9.14h-2.57Z"
          fill="#fff"
        />
        <path
          d="M243.36,208a33.24,33.24,0,0,1-16-3.76,26.82,26.82,0,0,1-10.83-10.65,35.15,35.15,0,0,1,0-32.07,26.88,26.88,0,0,1,10.83-10.7,35.63,35.63,0,0,1,31.94,0,26.74,26.74,0,0,1,10.8,10.7,35.24,35.24,0,0,1,0,32.07,26.67,26.67,0,0,1-10.8,10.67A33,33,0,0,1,243.36,208Zm0-13.11a13.84,13.84,0,0,0,7.35-1.92,12.74,12.74,0,0,0,4.93-5.82,23,23,0,0,0,1.78-9.58,23.25,23.25,0,0,0-1.76-9.58,13.06,13.06,0,0,0-4.93-5.9,13.61,13.61,0,0,0-7.37-2,13.91,13.91,0,0,0-7.49,2,12.81,12.81,0,0,0-4.93,5.88,27.17,27.17,0,0,0,0,19.16,12.71,12.71,0,0,0,4.92,5.82A14.06,14.06,0,0,0,243.36,194.84Z"
          fill="#fff"
        />
        <path
          d="M304.8,208a24,24,0,0,1-13.42-3.76,24.3,24.3,0,0,1-8.84-10.67,43.2,43.2,0,0,1,0-32.19,24.36,24.36,0,0,1,8.84-10.65,23.92,23.92,0,0,1,13.39-3.76,20.6,20.6,0,0,1,12.74,3.89,23.2,23.2,0,0,1,7.74,10.69,44.63,44.63,0,0,1,2.6,15.71v.22a45.46,45.46,0,0,1-2.54,15.82A23.32,23.32,0,0,1,317.6,204,20.61,20.61,0,0,1,304.8,208Zm4.8-13.11a12.24,12.24,0,0,0,12-8,27.87,27.87,0,0,0,0-18.81,12.73,12.73,0,0,0-4.69-5.95,13,13,0,0,0-7.33-2,12.56,12.56,0,0,0-7.24,2.09,13.18,13.18,0,0,0-4.71,6,24.86,24.86,0,0,0-1.64,9.52,23.72,23.72,0,0,0,1.64,9.33,12.58,12.58,0,0,0,4.69,5.79A13.2,13.2,0,0,0,309.6,194.84Zm13.53,11.75,0-14.27h1.16V162.43h-1.69v-36.3h16.3v80.46Z"
          fill="#fff"
        />
        <path
          d="M366.64,208a22.73,22.73,0,0,1-10.81-2.41,16.22,16.22,0,0,1-6.95-7.27,26.91,26.91,0,0,1-2.44-12.13V148.28h16.3v35.43a12.64,12.64,0,0,0,1.14,5.69,7.84,7.84,0,0,0,3.36,3.45,11.57,11.57,0,0,0,5.44,1.17,12.13,12.13,0,0,0,5.75-1.43,11,11,0,0,0,4.41-4.39,14.47,14.47,0,0,0,1.71-7.32h4.89v14h-2.89a22.27,22.27,0,0,1-4.62,7,19.85,19.85,0,0,1-6.77,4.53A22.28,22.28,0,0,1,366.64,208ZM385,206.59,385,194h-.48V148.28h16.3v58.31Z"
          fill="#fff"
        />
        <path
          d="M436.58,208a31.56,31.56,0,0,1-15.66-3.75,25.62,25.62,0,0,1-10.35-10.63,37.44,37.44,0,0,1,0-32.25A25.47,25.47,0,0,1,421,150.67a32.07,32.07,0,0,1,15.82-3.75,33.26,33.26,0,0,1,14.33,2.9,23.8,23.8,0,0,1,9.7,8.11,25.75,25.75,0,0,1,4.31,12H448.7a13.56,13.56,0,0,0-2.4-5.51,10.33,10.33,0,0,0-4.19-3.26,14,14,0,0,0-5.53-1.06,12.65,12.65,0,0,0-7,1.89,12,12,0,0,0-4.5,5.75,30.81,30.81,0,0,0,0,19.48,11.91,11.91,0,0,0,4.49,5.71,12.64,12.64,0,0,0,6.95,1.86,13.9,13.9,0,0,0,5.72-1.12,10.46,10.46,0,0,0,4.25-3.44,14.15,14.15,0,0,0,2.38-5.92h16.24a26.9,26.9,0,0,1-4.53,12.46A24.74,24.74,0,0,1,450.7,205,32.48,32.48,0,0,1,436.58,208Z"
          fill="#fff"
        />
        <path
          d="M467.43,160.8V148.28h37V160.8h-37ZM496.3,207a33.65,33.65,0,0,1-11.1-1.54,12.09,12.09,0,0,1-6.41-4.92,16.48,16.48,0,0,1-2.12-8.86V126.25H493v62.82a5.78,5.78,0,0,0,.69,3.08,3.64,3.64,0,0,0,2.11,1.54,13.26,13.26,0,0,0,3.87.44h4.8v12.46l-.41,0-.38,0c-1.24.11-2.49.19-3.73.24S497.47,207,496.3,207Z"
          fill="#fff"
        />
        <rect
          x="512.38"
          y="148.28"
          width="16.3"
          height="58.31"
          fill="#fff"
        />
        <path
          d="M565.49,208a33.27,33.27,0,0,1-16-3.76,26.82,26.82,0,0,1-10.83-10.65,35.15,35.15,0,0,1,0-32.07,26.88,26.88,0,0,1,10.83-10.7,35.63,35.63,0,0,1,31.94,0,26.77,26.77,0,0,1,10.81,10.7,35.32,35.32,0,0,1,0,32.07,26.69,26.69,0,0,1-10.81,10.67A33,33,0,0,1,565.49,208Zm0-13.11a13.88,13.88,0,0,0,7.36-1.92,12.8,12.8,0,0,0,4.93-5.82,26.74,26.74,0,0,0,0-19.16,13.06,13.06,0,0,0-4.93-5.9,13.61,13.61,0,0,0-7.37-2,13.91,13.91,0,0,0-7.49,2,12.81,12.81,0,0,0-4.93,5.88,23.5,23.5,0,0,0-1.74,9.6,23.19,23.19,0,0,0,1.76,9.56A12.63,12.63,0,0,0,558,192.9,14.09,14.09,0,0,0,565.49,194.84Z"
          fill="#fff"
        />
        <path
          d="M602.16,206.59V148.28H618l0,12.63.43,0v45.65Zm38.11-35.43a12.64,12.64,0,0,0-1.15-5.69,7.81,7.81,0,0,0-3.35-3.45,11.5,11.5,0,0,0-5.45-1.17,12.14,12.14,0,0,0-5.73,1.43,10.91,10.91,0,0,0-4.42,4.39,14.47,14.47,0,0,0-1.71,7.32H613.6V160h2.85a22,22,0,0,1,4.59-6.95,20.28,20.28,0,0,1,6.79-4.53,22.43,22.43,0,0,1,8.54-1.6,22.8,22.8,0,0,1,10.82,2.41,16.12,16.12,0,0,1,6.95,7.27,27,27,0,0,1,2.43,12.13v37.86h-16.3Z"
          fill="#fff"
        />
        <path
          d="M718.26,208a31.56,31.56,0,0,1-15.66-3.75,25.64,25.64,0,0,1-10.36-10.63,37.54,37.54,0,0,1,0-32.25,25.49,25.49,0,0,1,10.4-10.65,32.07,32.07,0,0,1,15.82-3.75,33.19,33.19,0,0,1,14.32,2.9,23.83,23.83,0,0,1,9.71,8.11,25.75,25.75,0,0,1,4.31,12H730.38a13.56,13.56,0,0,0-2.4-5.51,10.33,10.33,0,0,0-4.19-3.26,14,14,0,0,0-5.53-1.06,12.65,12.65,0,0,0-7,1.89,12,12,0,0,0-4.5,5.75,30.81,30.81,0,0,0,0,19.48,11.91,11.91,0,0,0,4.49,5.71,12.61,12.61,0,0,0,6.95,1.86,13.94,13.94,0,0,0,5.72-1.12,10.53,10.53,0,0,0,4.25-3.44,14,14,0,0,0,2.37-5.92h16.25a26.8,26.8,0,0,1-4.54,12.46,24.6,24.6,0,0,1-9.86,8.26A32.44,32.44,0,0,1,718.26,208Z"
          fill="#fff"
        />
        <path
          d="M782.47,208a33.23,33.23,0,0,1-16-3.76,26.82,26.82,0,0,1-10.83-10.65,35.15,35.15,0,0,1,0-32.07,26.88,26.88,0,0,1,10.83-10.7,35.63,35.63,0,0,1,31.94,0,26.74,26.74,0,0,1,10.8,10.7,35.24,35.24,0,0,1,0,32.07,26.67,26.67,0,0,1-10.8,10.67A33.07,33.07,0,0,1,782.47,208Zm0-13.11a13.85,13.85,0,0,0,7.36-1.92,12.74,12.74,0,0,0,4.93-5.82,23,23,0,0,0,1.78-9.58,23.42,23.42,0,0,0-1.76-9.58,13.06,13.06,0,0,0-4.93-5.9,13.62,13.62,0,0,0-7.38-2,13.9,13.9,0,0,0-7.48,2,12.81,12.81,0,0,0-4.93,5.88,27.17,27.17,0,0,0,0,19.16A12.71,12.71,0,0,0,775,192.9,14.05,14.05,0,0,0,782.47,194.84Z"
          fill="#fff"
        />
        <path
          d="M819.14,206.59V148.28H835l0,15.11.42,0v43.17Zm36.28-35.51a13.62,13.62,0,0,0-1-5.64,7.2,7.2,0,0,0-3.06-3.42,10.1,10.1,0,0,0-5-1.14,10.42,10.42,0,0,0-5.28,1.43,10.28,10.28,0,0,0-4,4.52,17.11,17.11,0,0,0-1.56,7.75h-5.36V160.65h3.5a23.43,23.43,0,0,1,4.39-7.48,18.36,18.36,0,0,1,6.33-4.65,19.69,19.69,0,0,1,8-1.6,20.93,20.93,0,0,1,10.29,2.42,16.28,16.28,0,0,1,6.71,7,23.22,23.22,0,0,1,2.35,10.8v39.49H855.42Zm36.27,0a13.85,13.85,0,0,0-1-5.65,7.15,7.15,0,0,0-3-3.42,10.28,10.28,0,0,0-5.05-1.13,10.09,10.09,0,0,0-9.33,6,17.18,17.18,0,0,0-1.54,7.74h-5.37V160.85l2.06,0a27,27,0,0,1,4.66-7.26,20,20,0,0,1,15.12-6.62,21.49,21.49,0,0,1,10.62,2.49,16.72,16.72,0,0,1,6.84,7.16A24.28,24.28,0,0,1,908,167.66v38.93h-16.3Z"
          fill="#fff"
        />
        <path
          d="M915.56,229.64V148.28h15.73l0,14h-1.16v29.92h1.7v37.4ZM949.66,208A20.54,20.54,0,0,1,936.87,204a23.26,23.26,0,0,1-7.68-10.76,45.54,45.54,0,0,1-2.53-15.84v-.22a44.32,44.32,0,0,1,2.52-15.72,23,23,0,0,1,7.71-10.7,20.69,20.69,0,0,1,12.77-3.87,24,24,0,0,1,13.42,3.76,24.25,24.25,0,0,1,8.82,10.65,43.54,43.54,0,0,1,0,32.22A23.28,23.28,0,0,1,949.66,208Zm-4.8-13.17a12.56,12.56,0,0,0,7.24-2.09,13.24,13.24,0,0,0,4.72-6.06,24.71,24.71,0,0,0,1.65-9.51,23.58,23.58,0,0,0-1.65-9.32,12.62,12.62,0,0,0-4.69-5.8,13.11,13.11,0,0,0-7.27-2,12.24,12.24,0,0,0-12,8,27.79,27.79,0,0,0,0,18.81,12.19,12.19,0,0,0,12,8Z"
          fill="#fff"
        />
        <path
          d="M1000.31,208.2a28.57,28.57,0,0,1-11-2,16,16,0,0,1-7.36-5.92,18.81,18.81,0,0,1-.42-18.37,16.08,16.08,0,0,1,7.06-6.13,37.93,37.93,0,0,1,12.76-3.22l9.43-.94a17.21,17.21,0,0,0,4.51-1,5.7,5.7,0,0,0,2.66-2,5.78,5.78,0,0,0,.91-3.28l2.8,3.59a23.25,23.25,0,0,1-1,6.8,6.67,6.67,0,0,1-2.77,3.58,13.58,13.58,0,0,1-5.41,1.59l-7,.85a18.92,18.92,0,0,0-5.61,1.44,6.82,6.82,0,0,0-3,2.55,7.21,7.21,0,0,0-.93,3.78,7.48,7.48,0,0,0,1,4,6.42,6.42,0,0,0,3.1,2.51,13.27,13.27,0,0,0,5.14.86,16.11,16.11,0,0,0,6.88-1.44,11.42,11.42,0,0,0,4.93-4.36,13.49,13.49,0,0,0,1.83-7.28h3.34v7.41a21.48,21.48,0,0,1-3.78,9.11,17.88,17.88,0,0,1-7.37,5.79A26.49,26.49,0,0,1,1000.31,208.2Zm33.33-1.07a18.51,18.51,0,0,1-8.23-1.56,9.43,9.43,0,0,1-4.56-4.83,21.58,21.58,0,0,1-1.44-8.59l-.6-1.41V165.4a6.61,6.61,0,0,0-1-3.73,5.8,5.8,0,0,0-3.14-2.19,18.46,18.46,0,0,0-5.69-.72,18.86,18.86,0,0,0-6,.79,6.39,6.39,0,0,0-3.45,2.67,11.08,11.08,0,0,0-1.4,5.1H981.89A22.53,22.53,0,0,1,985,156.23a17.85,17.85,0,0,1,8.79-6.9q5.94-2.4,15.35-2.41,9,0,14.76,2.15a15.39,15.39,0,0,1,11,15.65v26.87a4.06,4.06,0,0,0,.39,1.88,2.47,2.47,0,0,0,1.17,1.13,4.57,4.57,0,0,0,2,.38h2.37v11.61c-1.23.17-2.44.3-3.65.39S1034.81,207.13,1033.64,207.13Z"
          fill="#fff"
        />
        <path
          d="M1047.4,206.59V148.28h15.85l0,12.63.42,0v45.65Zm38.11-35.43a12.64,12.64,0,0,0-1.14-5.69A7.87,7.87,0,0,0,1081,162a11.53,11.53,0,0,0-5.45-1.17,12.18,12.18,0,0,0-5.74,1.43,11,11,0,0,0-4.42,4.39,14.47,14.47,0,0,0-1.71,7.32h-4.86V160h2.86a22,22,0,0,1,4.59-6.95,20.18,20.18,0,0,1,6.79-4.53,22.38,22.38,0,0,1,8.53-1.6,22.78,22.78,0,0,1,10.82,2.41,16.07,16.07,0,0,1,7,7.27,27,27,0,0,1,2.43,12.13v37.86h-16.3Z"
          fill="#fff"
        />
        <path
          d="M1108.17,217.24h7.6a11.39,11.39,0,0,0,4.33-.74,6.8,6.8,0,0,0,2.91-2.18,12.09,12.09,0,0,0,1.82-3.61l2.8-8.33.17,9.83L1104,148.28h17.48l9.58,30.74q1.44,4.71,2.71,9.73t2.46,10.21l-3,.06q1.53-6.2,2.82-11.16c.87-3.32,1.68-6.26,2.43-8.84l9.41-30.74h17l-23.33,64a36.83,36.83,0,0,1-5.65,10.72,17,17,0,0,1-7.74,5.3,39.44,39.44,0,0,1-12.32,1.58q-2,0-3.75-.06c-1.21,0-2.51-.09-3.9-.17Z"
          fill="#fff"
        />
        <rect
          x="512.38"
          y="126.25"
          width="16.3"
          height="16.3"
          fill="#ff7f40"
        />
      </g>
      <rect
        x="1024.76"
        y="3.27"
        width="16.3"
        height="16.3"
        fill="#ff7f40"
      />
    </g>
  </svg>
</template>
