<template>
  <div id="directors-filter-wrapper">
    <div
      id="directors-filter-content"
      class="DirectorsFilter WorkFilter directors-filter-scroller"
    >
      <!-- SEO TITLE -->
      <h1 class="hidden">
        Directors
      </h1>
      <section class="filters">
        <InertiaLink
          class="filters__item"
          :href="`${route('directors.index')}/`"
          :class="{ active: $page.url === '/directors' }"
        >
          All
        </InertiaLink>

        <InertiaLink
          v-for="filter in content.director_filters"
          :key="filter"
          class="filters__item"
          :href="`/directors/${filter}/`"
          :class="{
            active: $page.url.includes(filter),
          }"
        >
          <span
            v-for="tax in taxonomies"
            :key="tax.title"
          >
            <span v-if="filter === tax.slug">
              {{ tax.title }}
            </span>
          </span>
        </InertiaLink>
      </section>

      <!-- TODO: delete -->
      <section
        v-if="$page.url === `/new-directors/${$page.props.filter}/`"
        class="filters"
      >
        <InertiaLink
          class="filters__item"
          :href="`${route('newdirectors.index')}/`"
          :class="{ active: $page.url === '/new-directors/' }"
        >
          New All
        </InertiaLink>
        <InertiaLink
          v-for="filter in content.director_filters"
          :key="filter"
          class="filters__item"
          :href="`/new-directors/${filter}/`"
          :class="{
            active: $page.url.includes(filter),
          }"
        >
          <span
            v-for="tax in taxonomies"
            :key="tax.title"
          >
            <span v-if="filter === tax.slug">
              New {{ tax.title }}
            </span>
          </span>
        </InertiaLink>
      </section>
      <section class="WorkFilter__container">
        <div
          v-for="(filteredProject, index) in content.projects_taxonomies"
          :key="`filteredProject${index}`"
          class="WorkFilter__item"
        >
          <InertiaLink
            class="WorkFilter__item--link"
            :href="`/work/${filteredProject.slug}/`"
          >
            <div class="WorkFilter__item--image-container">
              <img
                v-if="filteredProject.thumbnail_4_5"
                :data-src="getImageKitURL(filteredProject.thumbnail_4_5, 3, filteredProject.image_type,false,index,'images')"
                :src="getImageKitURL(filteredProject.thumbnail_4_5, 3, filteredProject.image_type, true,index,'images')"
                :alt="filteredProject.title"
                class="WorkFilter__item--image"
              >
              <img
                v-else
                :src="`${$store.state.baseimageUrl}default.png`"
                class="WorkFilter__item--image"
                alt="default image"
              >
            </div>
            <h5 class="small-subtitle grey WorkFilter__text">
              {{ filteredProject.client_subtitle }}
            </h5>
            <h3 class="small-title black WorkFilter__text">
              {{ filteredProject.title }}
            </h3>
            <h4 class="small-subtitle orange WorkFilter__text">
              {{ filteredProject.subtitle }}
            </h4>
          </InertiaLink>
        </div>
      </section>

      <!-- {{ content }} -->
      <span id="directors-filter-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [imageKit, page, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    },
    taxonomies: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    let filter = this.taxonomies.find(
      (tax) => tax.slug === this.$page.props.filter
    );
    seo.seoContent(this.content, filter.title);
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();
    });
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.toggleMenuColor("black", 0, 0);
      this.setScroller(
        "#directors-filter-wrapper",
        "#directors-filter-content"
      );

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#directors-filter-content",
          markers: false,
          start: "50px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });
    }
  }
};
</script>

<style lang="scss">
.DirectorsFilter {
  // Styles in WorkFilter Page
}
</style>
