<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="773.14"
    height="195.07"
    viewBox="0 0 773.14 195.07"
  >
    <g id="txt">
      <path
        d="M23.37,81.69,1.34,1.23h18l10,40.82q1.34,5.88,2.87,13.52t3.18,15.95H31.73Q33.52,62.33,35,54.71t2.73-12.86l10-40.62H65.41L75.49,41.91q1.67,7.46,3.11,14.6t2.8,15H78Q79.55,63,81,56t3-14.18L93.8,1.23h18l-22,80.46H70.69L59.31,36.4q-1.11-4.66-2.26-10.28T54.62,13.55l4.15-.77c-.91,4.94-1.74,9.31-2.52,13.11s-1.55,7.29-2.34,10.45L42.47,81.69Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M124.49,83.3a28.38,28.38,0,0,1-11-2,15.9,15.9,0,0,1-7.36-5.92,17.12,17.12,0,0,1-2.61-9.65A16.85,16.85,0,0,1,105.66,57a16.09,16.09,0,0,1,7.06-6.13,38.31,38.31,0,0,1,12.76-3.22l9.43-.93a17.43,17.43,0,0,0,4.51-1A5.19,5.19,0,0,0,143,40.53l2.8,3.58a23.11,23.11,0,0,1-1,6.81A6.73,6.73,0,0,1,142,54.5a13.71,13.71,0,0,1-5.41,1.59l-7,.85A18.65,18.65,0,0,0,124,58.38a6.8,6.8,0,0,0-3,2.54,7.28,7.28,0,0,0-.93,3.79,7.38,7.38,0,0,0,1,4,6.42,6.42,0,0,0,3.09,2.52,13.48,13.48,0,0,0,5.15.86,16.11,16.11,0,0,0,6.87-1.44,11.38,11.38,0,0,0,4.93-4.36A13.44,13.44,0,0,0,143,59h3.33v7.4a21.36,21.36,0,0,1-3.77,9.11,17.66,17.66,0,0,1-7.37,5.79A26.5,26.5,0,0,1,124.49,83.3Zm33.33-1.08a18.75,18.75,0,0,1-8.23-1.55A9.56,9.56,0,0,1,145,75.84a21.56,21.56,0,0,1-1.44-8.59L143,65.84V40.5a6.55,6.55,0,0,0-1-3.73,5.82,5.82,0,0,0-3.14-2.19,23,23,0,0,0-11.69.07,6.43,6.43,0,0,0-3.44,2.67,11.11,11.11,0,0,0-1.4,5.1H106.07a22.35,22.35,0,0,1,3.15-11.09A17.9,17.9,0,0,1,118,24.42q6-2.4,15.36-2.4,9,0,14.76,2.15a16.65,16.65,0,0,1,8.36,6.1,16.8,16.8,0,0,1,2.64,9.55V66.68a4.2,4.2,0,0,0,.38,1.88,2.49,2.49,0,0,0,1.18,1.13,4.43,4.43,0,0,0,2,.38H165V81.69c-1.23.17-2.44.3-3.65.39S159,82.22,157.82,82.22Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M169.29,81.69V23.38h15.85V36h.42V81.69ZM207.4,46.26a12.53,12.53,0,0,0-1.14-5.69,7.66,7.66,0,0,0-3.35-3.45A11.53,11.53,0,0,0,197.46,36a12.23,12.23,0,0,0-5.74,1.43,11,11,0,0,0-4.42,4.39,14.37,14.37,0,0,0-1.71,7.32h-4.86v-14h2.86a22.07,22.07,0,0,1,4.59-7A20,20,0,0,1,195,23.62,22.3,22.3,0,0,1,203.5,22a22.78,22.78,0,0,1,10.82,2.4,16.07,16.07,0,0,1,7,7.28,27,27,0,0,1,2.43,12.13V81.69H207.4Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M225,35.89V23.38h37V35.89Zm28.87,46.16a33.16,33.16,0,0,1-11.1-1.54,12,12,0,0,1-6.41-4.91,16.54,16.54,0,0,1-2.12-8.86V1.31h16.3V64.17a5.79,5.79,0,0,0,.69,3.08,3.65,3.65,0,0,0,2.11,1.54,13.66,13.66,0,0,0,3.87.44H262V81.69h-.79c-1.24.12-2.49.19-3.73.24s-2.41.12-3.58.12Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M289.65,35.89V23.38h37V35.89Zm28.87,46.16a33.16,33.16,0,0,1-11.1-1.54A12,12,0,0,1,301,75.6a16.43,16.43,0,0,1-2.12-8.86V1.31h16.3V64.17a5.79,5.79,0,0,0,.69,3.08,3.67,3.67,0,0,0,2.1,1.54,13.66,13.66,0,0,0,3.87.44h4.81V81.69h-.79c-1.25.12-2.49.19-3.73.24S319.69,82.05,318.52,82.05Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M358.58,83a33.11,33.11,0,0,1-16-3.76,26.7,26.7,0,0,1-10.83-10.65,32.28,32.28,0,0,1-3.87-16.07,32.23,32.23,0,0,1,3.87-16,27,27,0,0,1,10.83-10.71,35.69,35.69,0,0,1,31.94,0,26.72,26.72,0,0,1,10.8,10.69,32.43,32.43,0,0,1,3.86,16,32.44,32.44,0,0,1-3.86,16.06,26.58,26.58,0,0,1-10.8,10.66A33,33,0,0,1,358.58,83Zm0-13.11A13.88,13.88,0,0,0,365.94,68a12.72,12.72,0,0,0,4.93-5.82,22.94,22.94,0,0,0,1.78-9.57A23.27,23.27,0,0,0,370.89,43,13,13,0,0,0,366,37.1a13.62,13.62,0,0,0-7.38-2,13.87,13.87,0,0,0-7.48,2,12.75,12.75,0,0,0-4.93,5.9,23.44,23.44,0,0,0-1.74,9.61,23.38,23.38,0,0,0,1.75,9.56A12.66,12.66,0,0,0,351.1,68a14,14,0,0,0,7.48,1.93Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M410.24,92.34H413a9.38,9.38,0,0,0,3.28-.47,3.68,3.68,0,0,0,1.93-1.67,6.58,6.58,0,0,0,.69-3.29V23.38h16.3v65.2A18.69,18.69,0,0,1,433.1,98a12.28,12.28,0,0,1-6.31,5.27,29.36,29.36,0,0,1-10.67,1.7h-3.19c-.86,0-1.76-.1-2.69-.17Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M469.63,83a33.11,33.11,0,0,1-16-3.76,26.6,26.6,0,0,1-10.84-10.65,32.28,32.28,0,0,1-3.87-16.07,32.23,32.23,0,0,1,3.87-16,26.93,26.93,0,0,1,10.84-10.71,35.69,35.69,0,0,1,31.94,0,26.72,26.72,0,0,1,10.8,10.69,32.43,32.43,0,0,1,3.86,16,32.44,32.44,0,0,1-3.86,16.06,26.58,26.58,0,0,1-10.8,10.66A33,33,0,0,1,469.63,83Zm0-13.11A13.82,13.82,0,0,0,477,68a12.72,12.72,0,0,0,4.93-5.82,22.94,22.94,0,0,0,1.78-9.57A23.29,23.29,0,0,0,481.93,43,12.89,12.89,0,0,0,477,37.1a13.62,13.62,0,0,0-7.38-2,13.87,13.87,0,0,0-7.48,2,12.69,12.69,0,0,0-4.93,5.9,23.44,23.44,0,0,0-1.74,9.61,23.22,23.22,0,0,0,1.75,9.56A12.66,12.66,0,0,0,462.15,68a14,14,0,0,0,7.48,1.93Z"
        transform="translate(-1.34 -1.23)"
      />
      <rect
        x="502.78"
        y="22.15"
        width="16.3"
        height="58.31"
      />
      <path
        d="M526.25,81.69V23.38H542.1V36h.42V81.69Zm38.11-35.43a12.53,12.53,0,0,0-1.14-5.69,7.76,7.76,0,0,0-3.35-3.45A11.53,11.53,0,0,0,554.42,36a12.23,12.23,0,0,0-5.74,1.43,11,11,0,0,0-4.42,4.39,14.37,14.37,0,0,0-1.71,7.32h-4.86v-14h2.86a21.74,21.74,0,0,1,4.59-7,20.08,20.08,0,0,1,6.79-4.53,22.25,22.25,0,0,1,8.53-1.6,22.78,22.78,0,0,1,10.82,2.4,16.17,16.17,0,0,1,7,7.28,27,27,0,0,1,2.43,12.13V81.69h-16.3Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M643.4,83a33.11,33.11,0,0,1-16-3.76,26.7,26.7,0,0,1-10.83-10.65,32.28,32.28,0,0,1-3.87-16.07,32.23,32.23,0,0,1,3.87-16A27,27,0,0,1,627.4,25.86a35.69,35.69,0,0,1,31.94,0,26.72,26.72,0,0,1,10.8,10.69,32.43,32.43,0,0,1,3.86,16,32.44,32.44,0,0,1-3.86,16.06,26.58,26.58,0,0,1-10.8,10.66A33,33,0,0,1,643.4,83Zm0-13.11A13.88,13.88,0,0,0,650.76,68a12.72,12.72,0,0,0,4.93-5.82,22.94,22.94,0,0,0,1.78-9.57A23.27,23.27,0,0,0,655.71,43a13,13,0,0,0-4.93-5.9,13.62,13.62,0,0,0-7.38-2,13.87,13.87,0,0,0-7.48,2A12.67,12.67,0,0,0,631,43a23.44,23.44,0,0,0-1.74,9.61A23.23,23.23,0,0,0,631,62.18,12.7,12.7,0,0,0,635.92,68a14,14,0,0,0,7.48,1.93Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M698.07,83a22.75,22.75,0,0,1-10.81-2.4,16.3,16.3,0,0,1-7-7.27,27,27,0,0,1-2.44-12.14V23.38h16.3V58.8a12.6,12.6,0,0,0,1.14,5.7,7.78,7.78,0,0,0,3.37,3.44,11.47,11.47,0,0,0,5.43,1.17,12.15,12.15,0,0,0,5.75-1.42,11.09,11.09,0,0,0,4.41-4.39A14.41,14.41,0,0,0,716,56h4.89V70H718a22.62,22.62,0,0,1-4.62,7,20,20,0,0,1-6.78,4.53A22.39,22.39,0,0,1,698.07,83Zm18.36-1.35V69H716V23.38h16.3V81.69Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M737.8,81.69V23.38h15.88V38.49h.42v43.2Zm13.73-36.33a30.49,30.49,0,0,1,2.08-11.49,17.42,17.42,0,0,1,17.23-11.4,15.48,15.48,0,0,1,1.83.07c.58.07,1.19.11,1.81.19V37.62H770.6a20.33,20.33,0,0,0-8.93,1.78,12.37,12.37,0,0,0-5.62,5.41A19.27,19.27,0,0,0,754.1,54h-2.57Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M2.55,148.89V136.38h37v12.51Zm28.87,46.16a33.16,33.16,0,0,1-11.1-1.54,12,12,0,0,1-6.41-4.91,16.43,16.43,0,0,1-2.12-8.86V114.31h16.3v62.86a5.79,5.79,0,0,0,.69,3.08,3.65,3.65,0,0,0,2.11,1.54,13.66,13.66,0,0,0,3.87.44h4.8v12.46h-.79c-1.25.12-2.49.19-3.73.24S32.59,195.05,31.42,195.05Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M71.2,196a32.6,32.6,0,0,1-15.79-3.69A25.46,25.46,0,0,1,44.93,181.8a37.08,37.08,0,0,1,0-32.22,26.14,26.14,0,0,1,10.41-10.74A31.36,31.36,0,0,1,71,135a31,31,0,0,1,15.68,3.82A25.73,25.73,0,0,1,97,149.53a33.71,33.71,0,0,1,3.59,15.8c0,.7,0,1.44-.06,2.24s-.09,1.62-.14,2.51H50.27v-10.2H88.58l-4,2.29a19.07,19.07,0,0,0-1.6-7.95,12,12,0,0,0-4.62-5.31A13.71,13.71,0,0,0,70.89,147a14.08,14.08,0,0,0-7.47,1.89,12.24,12.24,0,0,0-4.77,5.7,23.92,23.92,0,0,0-1.71,9.53v4.18a20.35,20.35,0,0,0,1.71,8.81,11.67,11.67,0,0,0,5,5.39,16.07,16.07,0,0,0,7.84,1.79,16.43,16.43,0,0,0,5.77-.93,10.86,10.86,0,0,0,4.16-2.67,11.17,11.17,0,0,0,2.47-4.2h15.4A24.46,24.46,0,0,1,84.36,193.7,34.4,34.4,0,0,1,71.2,196Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M124.11,196.3a28.38,28.38,0,0,1-11-2,15.84,15.84,0,0,1-7.36-5.92,18.85,18.85,0,0,1-.42-18.38,16.09,16.09,0,0,1,7.06-6.13,38.31,38.31,0,0,1,12.75-3.22l9.44-.93a17.35,17.35,0,0,0,4.5-1,5.25,5.25,0,0,0,3.58-5.27l2.8,3.58a23.11,23.11,0,0,1-1,6.81,6.68,6.68,0,0,1-2.77,3.58,13.54,13.54,0,0,1-5.41,1.59l-7,.85a18.78,18.78,0,0,0-5.61,1.44,6.74,6.74,0,0,0-3,2.54,7.28,7.28,0,0,0-.93,3.79,7.47,7.47,0,0,0,1,4,6.46,6.46,0,0,0,3.09,2.51A13.46,13.46,0,0,0,129,185a16.16,16.16,0,0,0,6.88-1.44,11.38,11.38,0,0,0,4.93-4.36,13.47,13.47,0,0,0,1.84-7.28H146v7.4a21.61,21.61,0,0,1-3.77,9.11,17.66,17.66,0,0,1-7.37,5.79A26.61,26.61,0,0,1,124.11,196.3Zm33.34-1.08a18.75,18.75,0,0,1-8.23-1.55,9.48,9.48,0,0,1-4.57-4.83,21.56,21.56,0,0,1-1.44-8.59l-.59-1.41V153.5a6.55,6.55,0,0,0-1-3.73,5.76,5.76,0,0,0-3.13-2.19,23,23,0,0,0-11.69.07,6.43,6.43,0,0,0-3.44,2.67,11,11,0,0,0-1.4,5.1H105.7a22.35,22.35,0,0,1,3.15-11.09,17.9,17.9,0,0,1,8.78-6.91q6-2.4,15.35-2.4,9,0,14.77,2.15a15.39,15.39,0,0,1,11,15.65v26.86a4.2,4.2,0,0,0,.38,1.88,2.51,2.51,0,0,0,1.17,1.13,4.44,4.44,0,0,0,2,.39h2.37v11.61c-1.22.17-2.44.3-3.64.39S158.62,195.22,157.45,195.22Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M168.92,194.69V136.38h15.85v15.11h.42v43.17Zm36.28-35.51a13.62,13.62,0,0,0-1-5.64,7.07,7.07,0,0,0-3.06-3.42,10.61,10.61,0,0,0-14.32,4.8,17.16,17.16,0,0,0-1.56,7.76h-5.37V148.75h3.51a23.64,23.64,0,0,1,4.39-7.49,18.51,18.51,0,0,1,6.33-4.64,19.46,19.46,0,0,1,8-1.6,21,21,0,0,1,10.29,2.42,16.25,16.25,0,0,1,6.71,7,23.16,23.16,0,0,1,2.35,10.79v39.5H205.2Zm36.27,0a13.66,13.66,0,0,0-1-5.65,7.15,7.15,0,0,0-3.05-3.42A10.61,10.61,0,0,0,223.09,155a17.22,17.22,0,0,0-1.54,7.74H216.1V149l2.06-.06a27,27,0,0,1,4.66-7.26,19.95,19.95,0,0,1,15.12-6.61,21.45,21.45,0,0,1,10.62,2.49,16.7,16.7,0,0,1,6.84,7.16,24.17,24.17,0,0,1,2.37,11.09v38.93h-16.3Z"
        transform="translate(-1.34 -1.23)"
      />
      <path
        d="M279,169.35a49.11,49.11,0,0,1,.83-10.12,15.77,15.77,0,0,1,3.15-6.58,33.31,33.31,0,0,1,7.09-6.1l.68-.47.68-.49a33.94,33.94,0,0,0,3.77-3.1,12.39,12.39,0,0,0,2.44-3.22,8.06,8.06,0,0,0,.94-3.88,10.38,10.38,0,0,0-1.09-4.92,7.25,7.25,0,0,0-3.42-3.12,14.33,14.33,0,0,0-6-1.09,16,16,0,0,0-6.83,1.25,8.17,8.17,0,0,0-3.94,4.07,20.41,20.41,0,0,0-1.52,7.71H259.54q0-9,3.4-15a21,21,0,0,1,9.78-8.94q6.42-3,15.74-3A39.65,39.65,0,0,1,303,114.89a19.7,19.7,0,0,1,11.68,26.31,23.84,23.84,0,0,1-4.17,6.58,47.06,47.06,0,0,1-7.16,6.44,48.35,48.35,0,0,0-5.67,4.73,9.49,9.49,0,0,0-2.28,3.8,20.4,20.4,0,0,0-.64,5.82v1.55H279Z"
        transform="translate(-1.34 -1.23)"
      />
      <rect
        x="277.69"
        y="177.66"
        width="15.79"
        height="15.79"
        fill="#ff7f40"
      />
      <rect
        x="417.58"
        y="0.08"
        width="16.33"
        height="16.33"
        fill="#ff7f40"
      />
      <rect
        x="502.77"
        y="0.08"
        width="16.33"
        height="16.33"
        fill="#ff7f40"
      />
    </g>
  </svg>
</template>
