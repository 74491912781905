<template>
  <div
    class="VerticalSpace"
    :style="`height: ${customHeight} ;background : ${customColor}; ${customGradient}`"
  />
</template>

<script>

export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    customHeight(){
      if(this.$store.state.isMobile) {
        return this.content.range_mobile + 'vw'
      } else {
        return this.content.range_field + 'vw'
      }

    },
    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if(this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },
  }

};
</script>

<style lang="scss">
.VerticalSpace {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}
</style>
