import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother"

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

export default {
  install: (app) => {
    app.config.globalProperties.$gsap = gsap;
    app.config.globalProperties.$ScrollTrigger = ScrollTrigger;
    app.config.globalProperties.$ScrollSmoother = ScrollSmoother;
    app.provide("$gsap", app.config.globalProperties.$gsap);
    app.provide("$ScrollTrigger", app.config.globalProperties.$ScrollTrigger);
    app.provide("$ScrollSmoother", app.config.globalProperties.$ScrollSmoother);
  },
};
