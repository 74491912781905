<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="194.61"
    height="68.78"
    viewBox="0 0 194.61 68.78"
  >
    <g>
      <path
        d="M95.74,96.58V28.94h16l21.66,34.94q1.92,3.23,4,7.18t4.45,8.56l.37.81c.13.27.26.54.38.81L140.8,87.6c-.06-.8-.12-1.6-.17-2.4s-.09-1.57-.12-2.35c-.22-3.84-.36-7.26-.44-10.26s-.1-5.88-.1-8.67v-35h14.37V96.58h-16L118.21,64.33Q116,60.63,113.84,57t-4-7.14c-1.27-2.33-2.38-4.54-3.33-6.61l2.83-2.76c.21,3,.37,5.86.5,8.68s.21,5.46.26,7.89.07,4.84.07,7.23v32.3Z"
        transform="translate(-95.74 -28.94)"
      />
      <path
        d="M185.09,97.72a27.3,27.3,0,0,1-13.27-3.1A21.54,21.54,0,0,1,163,85.75a28.31,28.31,0,0,1-3.11-13.61A28.37,28.37,0,0,1,163,58.66a22,22,0,0,1,8.75-9A26.39,26.39,0,0,1,185,46.42a26.08,26.08,0,0,1,13.18,3.21,21.6,21.6,0,0,1,8.62,9,28.29,28.29,0,0,1,3,13.28c0,.59,0,1.22-.05,1.88s-.07,1.37-.12,2.11H167.49V67.32H199.7l-3.4,1.92A16,16,0,0,0,195,62.56a10.14,10.14,0,0,0-3.88-4.47,11.55,11.55,0,0,0-6.25-1.6,11.81,11.81,0,0,0-6.28,1.59,10.29,10.29,0,0,0-4,4.79,19.86,19.86,0,0,0-1.44,8V74.4a17.12,17.12,0,0,0,1.44,7.41,9.73,9.73,0,0,0,4.18,4.52,13.4,13.4,0,0,0,6.59,1.51,13.9,13.9,0,0,0,4.85-.78,9.12,9.12,0,0,0,3.49-2.25,9.27,9.27,0,0,0,2.08-3.52l13,0a20.52,20.52,0,0,1-12.56,14.47A28.91,28.91,0,0,1,185.09,97.72Z"
        transform="translate(-95.74 -28.94)"
      />
      <path
        d="M207.34,96.58l20-27.81-.21,4.94L208.5,47.56h15.37l6,8.86q1.35,2.11,2.57,4.18t2.32,4.06l-3.52,0c.76-1.33,1.57-2.67,2.41-4s1.73-2.75,2.65-4.16l6.06-8.86H257.5L238.57,73.71l.26-4.94,19.52,27.81H243l-7.17-10.66-2.38-4.11c-.77-1.35-1.52-2.65-2.23-3.92h3.47c-.71,1.3-1.46,2.6-2.24,3.92L229.88,86l-7.3,10.57Z"
        transform="translate(-95.74 -28.94)"
      />
      <path
        d="M259.24,58.08V47.56h31.12V58.08H259.24Zm24.28,38.81a28,28,0,0,1-9.34-1.3,10.17,10.17,0,0,1-5.39-4.13A13.8,13.8,0,0,1,267,84V32.55h13.7V81.86a4.81,4.81,0,0,0,.59,2.58,3,3,0,0,0,1.77,1.3,11.55,11.55,0,0,0,3.25.37h4V96.58l-.35,0-.32,0c-1,.09-2.09.16-3.13.2S284.5,96.89,283.52,96.89Z"
        transform="translate(-95.74 -28.94)"
      />
    </g>
  </svg>
</template>
