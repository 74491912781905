<template>
  <div
    v-if="isShown && $page.props.flash.message"
    class="TheFlash"
    @click="hide"
  >
    <div class="TheFlash__container">
      <p class="section-title white">
        <!-- <component
          :is="icon"
          class="TheFlash__icon"
          aria-hidden="true"
        /> -->
        {{ $page.props.flash.message }}
      </p>
    </div>
  </div>
</template>

<script>
const levelsLookupTable = {
  info: {
    color_class: "blue",
    icon: "IconInformationCircle"
  },
  success: {
    color_class: "green",
    icon: "IconCheckCircle"
  },
  warning: {
    color_class: "yellow",
    icon: "IconExclamationCircle"
  },
  error: {
    color_class: "red",
    icon: "IconXCircle"
  }
};

export default {
  data() {
    return {
      isShown: true
    };
  },

  computed: {
    colorClass() {
      return levelsLookupTable[this.$page.props.flash.level]?.color_class;
    },

    icon() {
      return levelsLookupTable[this.$page.props.flash.level]?.icon;
    }
  },

  watch: {
    "$page.props.flash": {
      handler: "show",
      deep: true,
      immediate: true
    }
  },

  methods: {
    show({ message }) {
      if (!message) {
        return;
      }

      this.isShown = true;

      setTimeout(() => {
        this.hide();
      }, 10000);
    },

    hide() {
      this.isShown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.TheFlash {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background: $black;
  width:100vw;
  height: 100vh;

  &__icon {
    margin-right: 20px;
    fill: $orange;
    height: 50px;
    width: auto;
  }

  &__container {
    display: flex;
    justify-content: center;
    width: 90%;

    .section-title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
</style>
