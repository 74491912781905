<template>
  <label :class="classes">
    <template v-if="value">{{ value }}</template>
    <template v-else><slot /></template>
  </label>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['value', 'classes']
})
</script>
