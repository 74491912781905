<template>
  <div id="subscribe-wrapper">
    <div
      id="subscribe-content"
      class="subscribePage"
    >
      <h1 class="hidden">
        Subscribe
      </h1>

      <span id="subscribe-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";


/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {


  mixins: [imageKit, page, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    },
    search: {
      type: String,
      default: null
    },
    results: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    seo.seoContent(
      this.content,
      `Search results for: ${this.search} - Binalogue - Global Creative & Production Company`
    );
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();


    });

    if(this.$store.state.isMobile) {
      // alert('mobile');
      window.scrollTo(0,0);
    }


  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.setScroller("#subscribe-wrapper", "#subscribe-content");
      this.toggleMenuColor("black", 0, 0);

      window.scrollTo(0,0);

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#subscribe-content",
          markers: false,
          start: "50px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });
    },
  }
};
</script>

<style lang="scss">
.subscribePage{
  background: $black;
}
</style>
