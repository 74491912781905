import PxLoader from 'pxloader';
import 'pxloader/PxLoaderImage';

export default {
  data() {
    return {
      pxloader: null,
      imagesToPreload: [],
      bgImagesToPreload: [],
    };
  },
  methods: {
    getBackgroundImagesUrl() {
      const elements = document.querySelectorAll(
        "div[style^='background-image: url']"
      );

      for (let i = 0; i < elements.length; i++) {
        let style =
          elements[i].currentStyle ||
          window.getComputedStyle(elements[i], false);
        let bi = style.backgroundImage.slice(4, -1).replace(/"/g, '');
        this.bgImagesToPreload.push(bi);
      }
    },
    loadImages() {
      this.$store.commit('TOGGLE_IS_LOADING', true);
      return new Promise((resolve) => {
        this.pxloader = new PxLoader();
        for (let i = 0; i < this.imagesToPreload.length; i++) {
          this.pxloader.addImage(this.imagesToPreload[i].src);
        }

        for (let i = 0; i < this.bgImagesToPreload.length; i++) {
          this.pxloader.addImage(this.bgImagesToPreload[i]);
        }

        if (
          this.imagesToPreload.length == 0 ||
          this.imagesToPreload.length === 'undefined'
        ) {
          this.$store.commit('TOGGLE_IS_LOADING', false);

          this.init();
        }
        this.pxloader.addCompletionListener(() => {
          this.$store.commit('TOGGLE_IS_LOADING', false);
          this.$store.commit('TOGGLE_BG_VIDEO_UPDATE', true);
          this.init();
        });

        this.pxloader.start();
        //this.pxloader.log();
      });
    },
  },
};
