<template>
  <div id="contact-wrapper">
    <div
      id="contact-content"
      class="Contact contact-scroller"
    >
      <!-- SEO TITLE -->
      <h1 class="hidden">
        Contact
      </h1>
      <h2 class="hidden">
        Let's talk!
      </h2>
      <section class="Contact__main">
        <div class="Contact__contact">
          <TextFooter />
          <TheSafeEmail
            :email="email"
            class="Contact__contact--title"
          />

          <p
            class="medium-text-bold orange pointer hover-white"
            @click="toggleModalContactForm"
          >
            Or complete this form
          </p>
        </div>


        <div class="Contact__locations">
          <div
            v-for="location in globals.address.content"
            :key="location.city"
            class="Contact__locations--item"
          >
            <p class="light-text orange">
              {{ location.city }}
            </p>
            <a
              href="tel://+34910743133"
              target="_blank"
              class="main-text-link"
            >
              {{ location.phone }}
            </a>
            <a
              :href="location.google_maps_link"
              target="_blank"
              class="main-text-link"
            >
              <IconLocation /> {{ location.street }}
            </a>
            <p class="main-text white">
              {{ location.postcode }}
            </p>
          </div>
        </div>
      </section>

      <section class="Contact__jobs scroll-contact-jobs">
        <div class="Contact__jobs--lang regular-text grey">
          <button
            class="regular-text"
            :class="{ orange: langEN }"
            @click="toogleLang"
          >
            en
          </button>
          /
          <button
            class="regular-text"
            :class="{ orange: !langEN }"
            @click="toogleLang"
          >
            es
          </button>
        </div>

        <div class="job">
          <div class="job__left">
            <h3 class="title job-title-svg">
              <TextJoinTeam />
            </h3>
          </div>

          <div
            v-if="content.intro_english"
            class="job__right js-english"
            v-html="markdownToHtml(content.intro_english)"
          />
          <div
            v-if="content.intro_spanish"
            class="job__right js-spanish"
            v-html="markdownToHtml(content.intro_spanish)"
          />
        </div>

        <div
          v-for="(job, index) in content.jobs"
          :key="job.title"
          class="job"
        >
          <div class="job__header js-english">
            <InertiaLink
              :href="`/jobs/${job.slug}/`"
              class="job__left title"
            >
              {{ job.title }}<span class="orange">.</span>
            </InertiaLink>
          </div>
          <div class="job__header js-spanish">
            <InertiaLink
              :href="`/jobs/${job.slug}/`"
              class="job__left title"
            >
              {{ job.spanish_title }}<span class="orange">.</span>
            </InertiaLink>
          </div>
          <div class="job__left">
            <div class="js-english">
              <h4
                v-if="job.job_reference"
                class="main-text-bold orange"
              >
                Reference {{ job.job_reference }}
              </h4>
              <p
                v-if="job.publication_date"
                class="regular-text grey"
              >
                {{ formatDate(job.publication_date) }}
              </p>
              <h4
                v-if="job.english_location"
                class="main-text-bold"
              >
                Location
              </h4>
              <p
                v-if="job.english_location"
                class="regular-text grey"
              >
                {{ job.english_location }}
              </p>

              <h4
                v-if="job.english_type"
                class="main-text-bold"
              >
                Type
              </h4>
              <p
                v-if="job.english_type"
                class="regular-text grey"
              >
                {{ job.english_type }}
              </p>
              <h4
                v-if="job.english_duration"
                class="main-text-bold"
              >
                Duration
              </h4>
              <p
                v-if="job.english_duration"
                class="regular-text grey"
              >
                {{ job.english_duration }}
              </p>
              <h4
                v-if="job.english_workday"
                class="main-text-bold"
              >
                Workday
              </h4>
              <p
                v-if="job.english_workday"
                class="regular-text grey"
              >
                {{ job.english_workday }}
              </p>

              <h4
                v-if="job.english_salary"
                class="main-text-bold"
              >
                Salary
              </h4>
              <p
                v-if="job.english_salary"
                class="regular-text grey"
              >
                {{ job.english_salary }}
              </p>

              <h4
                v-if="job.english_incorporation"
                class="main-text-bold"
              >
                Incorporation
              </h4>
              <p
                v-if="job.english_incorporation"
                class="regular-text grey"
              >
                {{ job.english_incorporation }}
              </p>

              <h4
                class="main-text-bold"
              >
                Apply
              </h4>
              <a
                :href="customMailTo(index)"
                target="_blank"
                class="regular-text orange"
              >
                jobs@binalogue.com
              </a>
            </div>

            <div class="js-spanish">
              <h4
                v-if="job.job_reference"
                class="main-text-bold orange"
              >
                Referencia {{ job.job_reference }}
              </h4>
              <p
                v-if="job.publication_date"
                class="regular-text grey"
              >
                {{ formatDate(job.publication_date) }}
              </p>
              <h4
                v-if="job.spanish_location"
                class="main-text-bold"
              >
                Ubicación
              </h4>
              <p
                v-if="job.spanish_location"
                class="regular-text grey"
              >
                {{ job.spanish_location }}
              </p>

              <h4
                v-if="job.spanish_type"
                class="main-text-bold"
              >
                Tipo
              </h4>
              <p
                v-if="job.spanish_type"
                class="regular-text grey"
              >
                {{ job.spanish_type }}
              </p>
              <h4
                v-if="job.spanish_duration"
                class="main-text-bold"
              >
                Duración
              </h4>
              <p
                v-if="job.spanish_duration"
                class="regular-text grey"
              >
                {{ job.spanish_duration }}
              </p>
              <h4
                v-if="job.spanish_workday"
                class="main-text-bold"
              >
                Horario
              </h4>
              <p
                v-if="job.spanish_workday"
                class="regular-text grey"
              >
                {{ job.spanish_workday }}
              </p>

              <h4
                v-if="job.spanish_salary"
                class="main-text-bold"
              >
                Salario
              </h4>
              <p
                v-if="job.spanish_salary"
                class="regular-text grey"
              >
                {{ job.spanish_salary }}
              </p>

              <h4
                v-if="job.spanish_incorporation"
                class="main-text-bold"
              >
                Incorporación
              </h4>
              <p
                v-if="job.spanish_incorporation"
                class="regular-text grey"
              >
                {{ job.spanish_incorporation }}
              </p>

              <h4
                class="main-text-bold"
              >
                Aplicar
              </h4>
              <a
                :href="customMailTo(index)"
                target="_blank"
                class="regular-text orange"
              >
                jobs@binalogue.com
              </a>
            </div>
          </div>

          <div class="job__right">
            <div class=" job__right--container js-english">
              <!-- {{ markdownToHtml(job.english_content) }} -->
              <div
                v-if="job.english_content"
                v-html="markdownToHtml(job.english_content)"
              />
              <div class="mail-container">
                <a
                  :href="customMailTo(index)"
                  target="_blank"
                  class="main-text-bold"
                >
                  Apply: <span class="regular-text orange">jobs@binalogue.com</span>
                </a>
              </div>
            </div>

            <div class="job__right--container js-spanish">
              <div
                v-if="job.spanish_content"
                v-html="markdownToHtml(job.spanish_content)"
              />
              <div class="mail-container">
                <a
                  :href="customMailTo(index)"
                  target="_blank"
                  class="main-text-bold"
                >
                  Aplicar: <span class="regular-text orange">jobs@binalogue.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <span id="contact-content-footer" />
      <TheFooterContact />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from 'vue';
import { InertiaLink } from "@inertiajs/inertia-vue3";
import moment from "moment";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import page from "@/mixins/page";
import marked from "@/mixins/marked";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [page, marked, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      langEN: true,
      email: "hola@binalogue.com",

      // scroll animations
      bgScrollAnimation:null,
    };
  },

  computed: {
    globals() {
      return this.$page.props.globals;
    },
  },

  created() {
    seo.seoContent(this.content);
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();
    });
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.toggleMenuColor("white", 0,0);
      this.setScroller('#contact-wrapper', '#contact-content' );

      setTimeout(() => {
        this.toggleMenuColor("white", 0,0);
      }, 200);


      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to('.showBgHeader', {
        scrollTrigger: {
          trigger: '#contact-content',
          markers: false,
          onEnter: this.updateHeaderState,
          onEnterBack: this.updateHeaderStateBack,
          start: '50px top',
          end: 'top bottom',
          toggleActions: 'play none reverse none',
        },
        height: '100%',
        duration: 0.2,
        ease: 'Power2.out',
      });

    },

    customMailTo(i){
      let customUrl= "mailto:jobs@binalogue.com?subject=";
      let encodeUrl = encodeURIComponent(this.content.jobs[i].job_reference + " - " + this.content.jobs[i].title + " - Binasite");
      return customUrl + encodeUrl ;
    },

    formatDate(date) {
      return moment(date).format("MMMM DD, YYYY");
    },

    updateHeaderState(){
      this.toggleMenuColor('white', 0.2, 0);
    },

    updateHeaderStateBack(){
      this.toggleMenuColor('white', 0.2, 0.4);
    },


    toggleModalContactForm() {
      this.$store.commit("TOGGLE_IS_MODAL_CONTACT_FORM", true);
      this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", true);
    },

    toogleLang(){
      this.langEN = !this.langEN;

      if(this.langEN) {
        this.$gsap.to('.js-spanish', { opacity:0, duration: 0.3})
        this.$gsap.to('.js-english', { display: 'block', duration: 0, delay:0.3});
        this.$gsap.to('.js-spanish', { display:'none', duration: 0, delay:0.3});
        this.$gsap.to('.js-english', { opacity:1, duration: 0.3, delay:0.4})
      } else {
        this.$gsap.to('.js-english', { opacity:0, duration: 0.3})
        this.$gsap.to('.js-spanish', { display: 'block', duration: 0, delay:0.3});
        this.$gsap.to('.js-english', { display:'none', duration: 0, delay:0.3});
        this.$gsap.to('.js-spanish', { opacity:1, duration: 0.3, delay:0.4})
      }

    },
  }
};
</script>

<style lang="scss">
.Contact {
  display: flex;
  flex-direction: column;
  // background: pink;
  width: 100%;
  @include headerMargin;

  .main-text-link {
    display: block;
    color: $white;
    cursor: pointer;

    &:hover {
      color: $orange;
    }
  }

  .hover-white {
    @include transition;

    margin-bottom: 80px;

    @include tablet-m {
      margin-bottom: 0;
    }

    &:hover {
      color: $white;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20vh;
    padding-bottom: 10vh;
    margin: auto 0;
    background: $black;
    width: 100%;

    @include paddingMobile;

    @include tablet {
      @include paddingBig;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    // background: green;
    width: 100%;

    @include tablet {
      width: 70%;
      margin-top: 0;
    }

    @include desktop-s {
      width: 65%;
    }

    svg {
      max-width: 550px;
      width: 100%;
      height: auto;
      margin-bottom: 5vh;

      @include tablet {
        width: 80%;
        margin-bottom: initial;
      }

      @include desktop-xl {
        width: 80%;
        max-width: 700px;
        margin-bottom: initial;
      }
    }

    &--newsletter {
      max-width: 700px;
      width: 100%;

      @include tablet {
        width: 80%;
      }
    }
  }

  &__locations {
    width: 100%;
    // background: red;

    @include tablet {
      width: 65%;
    }

    @include desktop-s {
      width: 35%;
    }

    * {
      line-height: 1.6;
    }

    &--item {
      margin-bottom: 30px;

      @include desktop-l {
        margin-bottom: 70px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .job-title-svg {
    svg {
      width: 90%;
      height: auto;
      margin-bottom: 10px;

      @include tablet {
        width: 100%;
      }
    }
  }

  &__jobs {
    @include paddingMobile;
    @include paddingPageTop;
    @include paddingBottom;

    @include tablet {
      @include paddingBig;
    }

    &--lang {
      margin-bottom: 3vh;
      width: 100%;
    }
  }
}

.job {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background: pink;
  width: 100%;
  @include marginBottom;

  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__left {
    width: 100%;
    margin-bottom: 15px;

    @include tablet {
      width: 35%;
    }

    .regular-text {
      margin-bottom: 15px;

      @include tablet {
        margin-bottom: 23px;
      }
    }
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;

    .regular-text {
      margin-bottom: 15px;

      @include tablet {
        margin-bottom: 23px;
      }
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
  }

  &__right {
    width: 100%;

    @include tablet {
      width: 62%;
    }

    &--container {
      display: flex;
      flex-direction: column;
    }

    h2,
    h3 {
      margin-bottom: 15px;
      font-size: 21px;
      line-height: 1.4;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      strong {
        font-variation-settings: "wght" 800, "wdth" 100, "opsz" 30;
      }

      @include tablet {
        font-size: 23px;
      }

      @include desktop-sm {
        font-size: 28px;
      }

      @include desktop-l {
        font-size: 36px;
      }

      @include desktop-xl {
        font-size: 39px;
      }
    }

    p,
    li,
    a {
      margin-bottom: 20px;
      font-size: 17px;
      line-height: 1.4;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

       strong {
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
      }

      @include tablet {
        font-size: 20px;
      }

      @include desktop-sm {
        font-size: 22px;
      }

      @include desktop-l {
        font-size: 26px;
      }

      @include desktop-xl {
        font-size: 29px;
      }
    }

    ul {
      margin-bottom: 20px;
      list-style: none;


      ul {
        padding-left: 15px;
        margin-top: 15px;

      }
    }

    li {
      margin-bottom: 10px;
      p {
        display: inline-block;
      }
    }

    li::before {
      position: relative;
      top: 50%;
      display: inline-block;
      margin-right: 10px;
      content: "";
      background: $orange;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
    }

    a {
      color: $orange;
      @include transition;

      &:hover {
        color: $grey;
      }
    }

    .mail-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 15px;
    }

    a.main-text-bold {
      display: inline-block;
      margin-left: auto;
      text-align: right;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
    }


  }
}

.js-spanish {
  display: none;
  opacity: 0;
}
</style>
