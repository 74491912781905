/* global route */

export default {
  methods: {
    route,

    back() {
      window.history.back();
    },
  },
};
