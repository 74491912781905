<template>
  <div :id="content.anchor_text">
    <!-- {{ content }} -->
  </div>
</template>

<script>
import { nextTick } from 'vue';
import scrollGlobal from "@/mixins/scroll-global";

export default {
  mixins: [scrollGlobal],

  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {
    this.$nextTick(() => {
      if(window.location.href.includes(this.content.anchor_text)){
        this.$store.state.anchorName = this.content.anchor_text;
      }
    });
  },
};
</script>

<style lang="scss">
.VerticalSpace {
//
}
</style>
