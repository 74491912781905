<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 899 109"
  >
    <g id="txt">
      <g>
        <g>
          <path
            d="M23.46,85.53,0,0h19.1L29.79,43.4q1.41,6.24,3.05,14.37t3.38,16.95H32.35q1.89-9.75,3.5-17.86t2.9-13.66L49.4,0H68.14L78.86,43.25q1.77,7.92,3.3,15.52t3,15.94H81.47q1.71-9,3.2-16.45t3.17-15.07L98.31,0h19.16L94.05,85.53H73.75L61.65,37.4q-1.17-4.95-2.4-10.93T56.67,13.11l4.41-.81q-1.44,7.86-2.67,13.93T55.92,37.34L43.75,85.53Z"
            fill="#fff"
          />
          <path
            d="M145.39,87a34.58,34.58,0,0,1-16.78-3.92,27.17,27.17,0,0,1-11.14-11.21,35.75,35.75,0,0,1-3.93-17.2,35.91,35.91,0,0,1,3.9-17A27.79,27.79,0,0,1,128.5,26.18a33.35,33.35,0,0,1,16.71-4.05,33,33,0,0,1,16.66,4.05,27.44,27.44,0,0,1,10.9,11.36,35.8,35.8,0,0,1,3.81,16.8q0,1.11-.06,2.37t-.15,2.67H123.14V48.54h40.71L159.56,51a20.34,20.34,0,0,0-1.7-8.45A12.88,12.88,0,0,0,153,36.87a14.61,14.61,0,0,0-7.9-2,14.94,14.94,0,0,0-7.94,2,13,13,0,0,0-5.07,6A25.42,25.42,0,0,0,130.22,53v4.44A21.71,21.71,0,0,0,132,66.84a12.41,12.41,0,0,0,5.28,5.72,17.06,17.06,0,0,0,8.33,1.91,17.53,17.53,0,0,0,6.14-1,11.55,11.55,0,0,0,4.41-2.84,11.79,11.79,0,0,0,2.63-4.46l16.36,0a26.4,26.4,0,0,1-5.64,11.08,26.68,26.68,0,0,1-10.22,7.21A36.38,36.38,0,0,1,145.39,87Z"
            fill="#fff"
          />
          <path
            d="M179.65,36.47V27.4l3.3-.06a6.37,6.37,0,0,0,3.33-1,5.31,5.31,0,0,0,1.92-2.66,13.62,13.62,0,0,0,.63-4.52V15.06l3.06,3H179.64V0H199.4V18.42a23.72,23.72,0,0,1-1.82,10,12.4,12.4,0,0,1-5.42,5.85,20.58,20.58,0,0,1-9.07,2.16Z"
            fill="#ff7f40"
          />
          <path
            d="M206.34,85.53v-62h16.87V39.61l.45,0V85.52H206.34Zm14.59-38.62a32.55,32.55,0,0,1,2.21-12.2A18.57,18.57,0,0,1,241.47,22.6c.68,0,1.33,0,1.95.07s1.26.12,1.92.2V38.69l-2.15,0h-2a21.64,21.64,0,0,0-9.49,1.89,13.2,13.2,0,0,0-6,5.75A20.39,20.39,0,0,0,223.65,56h-2.73v-9.1Z"
            fill="#fff"
          />
          <path
            d="M279.33,87a34.58,34.58,0,0,1-16.78-3.92,27.17,27.17,0,0,1-11.14-11.21,35.75,35.75,0,0,1-3.93-17.2,35.91,35.91,0,0,1,3.9-17,27.79,27.79,0,0,1,11.06-11.42,33.35,33.35,0,0,1,16.71-4.05,33,33,0,0,1,16.66,4.05,27.44,27.44,0,0,1,10.9,11.36,35.8,35.8,0,0,1,3.81,16.8q0,1.11-.06,2.37t-.15,2.67H257.08V48.54h40.71L293.5,51a20.34,20.34,0,0,0-1.7-8.45,12.88,12.88,0,0,0-4.91-5.65,14.61,14.61,0,0,0-7.9-2,14.94,14.94,0,0,0-7.94,2,13,13,0,0,0-5.07,6A25.42,25.42,0,0,0,264.16,53v4.44A21.71,21.71,0,0,0,266,66.84a12.41,12.41,0,0,0,5.28,5.72,17.06,17.06,0,0,0,8.33,1.91,17.53,17.53,0,0,0,6.14-1,11.55,11.55,0,0,0,4.41-2.84,11.79,11.79,0,0,0,2.63-4.46l16.36,0a26.4,26.4,0,0,1-5.64,11.08,26.68,26.68,0,0,1-10.22,7.21A36.38,36.38,0,0,1,279.33,87Z"
            fill="#fff"
          />
        </g>
        <g>
          <rect
            x="881.85"
            y="68.41"
            width="17.11"
            height="17.11"
            fill="#ff7f40"
          />
          <rect
            x="432.63"
            y="0.01"
            width="17.11"
            height="17.11"
            fill="#ff7f40"
          />
          <path
            d="M353.94,0h40.24c16.29,0,27.31,5.27,27.31,21.2,0,8.38-4.19,14.25-11.62,17.84,10.42,3,15.69,11,15.69,21.68,0,17.37-14.73,24.8-30.19,24.8H353.93V0Zm18.81,34.62H391.8c6.59,0,11.5-3,11.5-10.18,0-8.14-6.23-9.82-12.94-9.82H372.75Zm0,36.29h20.13c7.42,0,13.89-2.39,13.89-11.26s-5.51-12.22-13.53-12.22H372.75V70.91Z"
            fill="#fff"
          />
          <rect
            x="432.63"
            y="23.6"
            width="17.1"
            height="61.92"
            fill="#fff"
          />
          <path
            d="M458.18,23.6h16.17v8.62h.36a22.08,22.08,0,0,1,18.93-10.3c18.09,0,22.64,10.18,22.64,25.51V85.52h-17v-35c0-10.18-3-15.21-10.9-15.21-9.22,0-13.17,5.15-13.17,17.73V85.53h-17V23.6Z"
            fill="#fff"
          />
          <path
            d="M525,42.64c1-15.93,15.21-20.72,29.11-20.72,12.34,0,27.19,2.75,27.19,17.6V71.74c0,5.63.6,11.26,2.15,13.77H566.21a25.26,25.26,0,0,1-1.2-6c-5.39,5.63-13.3,7.66-20.84,7.66-11.73,0-21.08-5.87-21.08-18.57,0-14,10.54-17.37,21.08-18.81s20.13-1.2,20.13-8.15c0-7.3-5-8.38-11-8.38-6.47,0-10.66,2.63-11.26,9.34H525Zm39.3,12.58c-2.88,2.52-8.87,2.63-14.14,3.59s-10.06,2.87-10.06,9.1,4.91,7.9,10.42,7.9c13.3,0,13.78-10.54,13.78-14.26Z"
            fill="#fff"
          />
          <rect
            x="589.86"
            width="17.01"
            height="85.52"
            fill="#fff"
          />
          <path
            d="M645.88,21.92c19.53,0,32.1,12.93,32.1,32.7S665.41,87.2,645.88,87.2s-32-12.94-32-32.58,12.58-32.7,32-32.7m0,52.46c11.62,0,15.09-9.94,15.09-19.77s-3.48-19.88-15.09-19.88-15,9.94-15,19.88,3.48,19.77,15,19.77"
            fill="#fff"
          />
          <path
            d="M745.41,81.57c0,10.18-3.59,27.43-32.1,27.43-12.22,0-26.47-5.75-27.31-19.88h16.89c1.55,6.35,6.7,8.5,12.7,8.5,9.46,0,13.77-6.47,13.65-15.33V74.15H729c-3.71,6.47-11.14,9.58-18.57,9.58C691.86,83.73,684,69.6,684,52.59c0-16.05,9.22-30.66,26.59-30.66,8.15,0,14.37,2.75,18.45,9.94h.24V23.6h16.17v58ZM729.25,53.78c0-10.06-3.48-19-14.5-19-9.58,0-13.77,8.39-13.77,17.61,0,8.86,3.35,18.56,13.77,18.56,9.7,0,14.5-8.26,14.5-17.13"
            fill="#fff"
          />
          <path
            d="M810.17,85.52H794V76.9h-.36a22.08,22.08,0,0,1-18.93,10.3c-18.09,0-22.64-10.18-22.64-25.51V23.6h17v35c0,10.18,3,15.21,10.9,15.21,9.22,0,13.17-5.15,13.17-17.73V23.6h17V85.52Z"
            fill="#fff"
          />
          <path
            d="M848,21.92c-18.81,0-31.62,14.13-31.62,32.7,0,19.17,12.1,32.58,31.62,32.58,13.33,0,23.19-5.66,28.23-18.79H861c-2.1,3.14-6.78,6-12.39,6-9.46,0-14.73-4.91-15.21-15.69H878c1.2-19-9-36.77-30.06-36.77m-14.62,26c.24-4.79,3.35-13.17,14.13-13.17,8.26,0,12,4.55,13.53,13.17Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
