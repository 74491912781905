import VueGtag from 'vue-gtag-next';

export default {
  install: (app) => {
    app.use(VueGtag, {
      property:{
        id: process.env.MIX_APP_GOOGLE_ANALYTICS_ID,
        params:{
          send_page_view: true
        }
      },
      isEnabled: false,
    });
    app.provide('$gtag', app.config.globalProperties.$gtag);
  },
}
