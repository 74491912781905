<template>
  <div
    class="MainLayout"
  >
    <TheHeader />

    <Flash aria-live="assertive" />

    <TheModalContactForm v-if="$store.state.isModalContactForm" />

    <div class="js-modal-video">
      <ModalVideo
        v-if="$store.state.isModalVideo"
        :vimeo="$store.state.video_id"
        :ratio="$store.state.video_ratio"
        :autoplay="$store.state.video_autoplay"
        :controls="$store.state.video_controls"
        :loop="$store.state.video_loop"
      />
    </div>
    <!-- <div
      v-if="$store.state.isShownCover"
      class="page-cover"
    /> -->

    <div
      id="loaderCover"
    />
    <div
      id="app-container"
      class="app-container"
    >
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return {
      currentWidth: 0,
    };
  },

  mounted() {
    this.currentWidth = window.innerWidth;
    window.addEventListener("resize", this.resizeMobile);

    if(window.innerWidth < 576 || this.$isMobile()) {
      this.$store.commit("TOGGLE_IS_MOBILE", true);
    } else {
      this.$store.commit("TOGGLE_IS_MOBILE", false);
    }
  },

  methods: {
    resizeMobile(){
      if(!this.$isMobile() && window.innerWidth < 600) {
        if(this.currentWidth !== window.innerWidth) {
          this.$store.commit('TOGGLE_IS_LOADING', true);
          location.reload();
        }
      }
      this.currentWidth = window.innerWidth;

      if(window.innerWidth < 576 || this.$isMobile()) {
        this.$store.commit("TOGGLE_IS_MOBILE", true);
      } else {
        this.$store.commit("TOGGLE_IS_MOBILE", false);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.MainLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // height: 100vh;

  @include tablet-m {
    height: 100vh;

    .search {
      overflow-y: hidden;
      height: 100vh;
    }
  }

  .page-cover {
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 3;
    margin-top: -5px;
    background: $black;
    width: 100%;
    height: 300%;
     transition: 0.2s all ease;
  }

  .loader-cover {
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 3;
    margin-top: -5px;
    background: $black;
    width: 100%;
    height: 9000%;
    transition: 0.2s all ease;
  }

  // &.horizontal {
  //   overflow-y: hidden;
  //   background: $black;
  //   height: 100vh;
  //   width: 100vw;
  // }
}

</style>
