<template>
  <div
    v-show="message"
    class="input-error"
    role="alert"
  >
    *{{ message }}
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ["message"],
});
</script>

<style lang="scss">
.input-error {
  // position: absolute;
  font-size: 16px;
  color: $orange;
}
</style>
