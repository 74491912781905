<template>
  <svg viewBox="0 0 11.31 20.08">
    <polyline
      points="10.8 19.57 1.03 9.8 10.32 0.52"
      fill="none"
      stroke-miterlimit="10"
      stroke-width="1.46"
    />
  </svg>
</template>
