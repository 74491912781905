<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="700.85"
    height="103.78"
    viewBox="0 0 700.85 103.78"
  >
    <g id="txt">
      <path
        d="M1.15,82.08V1.62H37.51A34.38,34.38,0,0,1,53.28,5a23.16,23.16,0,0,1,9.93,9.35,28,28,0,0,1,3.39,14,28.22,28.22,0,0,1-3.39,14.11,23.1,23.1,0,0,1-9.93,9.37,34.36,34.36,0,0,1-15.8,3.33H18.72V82.08ZM35.84,40.24a16,16,0,0,0,7-1.38,9.39,9.39,0,0,0,4.29-4,13.44,13.44,0,0,0,1.44-6.47,13.46,13.46,0,0,0-1.45-6.5,9.41,9.41,0,0,0-4.3-4,16.17,16.17,0,0,0-7-1.36H18.72V40.24Z"
        transform="translate(-1.15 -1.61)"
      />
      <path
        d="M71.5,82.08V23.77H87.38V38.89h.42V82.08ZM85.23,45.75a30.48,30.48,0,0,1,2.08-11.48,17.47,17.47,0,0,1,17.25-11.4,15.56,15.56,0,0,1,1.83.07l1.81.18V38h-3.88a20.36,20.36,0,0,0-8.93,1.78,12.37,12.37,0,0,0-5.62,5.41,19.14,19.14,0,0,0-2,9.14H85.23Z"
        transform="translate(-1.15 -1.61)"
      />
      <rect
        x="111.95"
        y="22.16"
        width="16.3"
        height="58.31"
      />
      <path
        d="M154.77,82.08,132.85,23.77h17.48l10.29,30.8q1.63,5.25,3.1,10.62T167,78l-5.71.06q1.68-6.87,3.23-12.54t3.22-11l10.11-30.74h17l-22,58.3Z"
        transform="translate(-1.15 -1.61)"
      />
      <path
        d="M211.76,83.69a28.38,28.38,0,0,1-11-2,15.88,15.88,0,0,1-7.36-5.91,17.14,17.14,0,0,1-2.61-9.65A17,17,0,0,1,193,57.4a16.13,16.13,0,0,1,7-6.11,38.31,38.31,0,0,1,12.75-3.22l9.44-.93a17.35,17.35,0,0,0,4.5-1,5.57,5.57,0,0,0,2.66-2,5.78,5.78,0,0,0,.92-3.28l2.8,3.59a23.11,23.11,0,0,1-1,6.81,6.56,6.56,0,0,1-2.77,3.57,13.72,13.72,0,0,1-5.41,1.6l-7,.84a18.79,18.79,0,0,0-5.61,1.45,6.63,6.63,0,0,0-3,2.54,7.24,7.24,0,0,0-.93,3.78,7.38,7.38,0,0,0,1,4,6.46,6.46,0,0,0,3.09,2.51,13.28,13.28,0,0,0,5.14.87A16,16,0,0,0,223.5,71a11.4,11.4,0,0,0,4.93-4.37,13.43,13.43,0,0,0,1.84-7.27h3.33v7.4a21.61,21.61,0,0,1-3.77,9.11,17.88,17.88,0,0,1-7.37,5.79A26.53,26.53,0,0,1,211.76,83.69Zm33.34-1.07a18.48,18.48,0,0,1-8.23-1.56,9.4,9.4,0,0,1-4.57-4.83,21.48,21.48,0,0,1-1.44-8.58l-.59-1.42V40.89a6.63,6.63,0,0,0-1-3.73A5.79,5.79,0,0,0,226.16,35a18.57,18.57,0,0,0-5.69-.72,19,19,0,0,0-6,.79A6.4,6.4,0,0,0,211,37.71a11,11,0,0,0-1.4,5.1H193.35a22.28,22.28,0,0,1,3.15-11.08,17.84,17.84,0,0,1,8.78-6.91q6-2.4,15.35-2.4,9,0,14.77,2.14a16.73,16.73,0,0,1,8.36,6.11,16.77,16.77,0,0,1,2.64,9.54V67.08a4.2,4.2,0,0,0,.38,1.88A2.57,2.57,0,0,0,248,70.09a4.72,4.72,0,0,0,2,.38h2.37V82.08c-1.22.17-2.44.3-3.64.4s-2.46.14-3.63.14Z"
        transform="translate(-1.15 -1.61)"
      />
      <path
        d="M400.29,82.08V1.62h36.36A34.45,34.45,0,0,1,452.43,5a23.16,23.16,0,0,1,9.93,9.35,28,28,0,0,1,3.39,14,28.22,28.22,0,0,1-3.39,14.11,23.1,23.1,0,0,1-9.93,9.37,34.4,34.4,0,0,1-15.81,3.33H417.86V82.08ZM435,40.24a16,16,0,0,0,7-1.38,9.39,9.39,0,0,0,4.29-4,13.44,13.44,0,0,0,1.44-6.47,13.46,13.46,0,0,0-1.45-6.5,9.37,9.37,0,0,0-4.3-4,16.17,16.17,0,0,0-7-1.36H417.86V40.24Z"
        transform="translate(-1.15 -1.61)"
      />
      <path
        d="M495.73,83.44a33.22,33.22,0,0,1-16-3.76A26.77,26.77,0,0,1,468.9,69,32.12,32.12,0,0,1,465,53a32.13,32.13,0,0,1,3.88-16,26.84,26.84,0,0,1,10.83-10.71,35.56,35.56,0,0,1,31.94,0A26.66,26.66,0,0,1,522.45,37a32.43,32.43,0,0,1,3.86,16,32.41,32.41,0,0,1-3.84,16,26.58,26.58,0,0,1-10.8,10.66A33,33,0,0,1,495.73,83.44Zm0-13.11a13.9,13.9,0,0,0,7.36-1.92A12.84,12.84,0,0,0,508,62.59,23,23,0,0,0,509.8,53a23.2,23.2,0,0,0-1.8-9.56,13,13,0,0,0-4.92-5.91,13.62,13.62,0,0,0-7.38-2,13.87,13.87,0,0,0-7.48,2,12.75,12.75,0,0,0-4.93,5.88,23.53,23.53,0,0,0-1.74,9.6,23.22,23.22,0,0,0,1.75,9.56,12.7,12.7,0,0,0,4.92,5.82A14,14,0,0,0,495.73,70.33Z"
        transform="translate(-1.15 -1.61)"
      />
      <path
        d="M530.42,82.08V1.62h16.31V82.08Z"
        transform="translate(-1.15 -1.61)"
      />
      <rect
        x="551.6"
        y="22.16"
        width="16.3"
        height="58.31"
      />
      <path
        d="M602.6,83.44a31.57,31.57,0,0,1-15.67-3.75,25.61,25.61,0,0,1-10.35-10.63,33.92,33.92,0,0,1-3.65-16.13,34.14,34.14,0,0,1,3.65-16.12A25.59,25.59,0,0,1,587,26.16a32.16,32.16,0,0,1,15.82-3.74,33.19,33.19,0,0,1,14.33,2.89,23.87,23.87,0,0,1,9.7,8.11,25.78,25.78,0,0,1,4.31,12H614.72a13.83,13.83,0,0,0-2.41-5.51,10.23,10.23,0,0,0-4.19-3.26,14.12,14.12,0,0,0-5.52-1.06,12.61,12.61,0,0,0-7,1.89,12,12,0,0,0-4.51,5.75,25.69,25.69,0,0,0-1.58,9.71,25.8,25.8,0,0,0,1.58,9.77,11.82,11.82,0,0,0,4.49,5.71,12.61,12.61,0,0,0,7,1.86,14.06,14.06,0,0,0,5.72-1.11,10.66,10.66,0,0,0,4.25-3.45,14.05,14.05,0,0,0,2.38-5.92H631.1a26.87,26.87,0,0,1-4.53,12.46,24.79,24.79,0,0,1-9.86,8.26A32.4,32.4,0,0,1,602.6,83.44Z"
        transform="translate(-1.15 -1.61)"
      />
      <path
        d="M632.6,92.73h7.6a11.41,11.41,0,0,0,4.33-.73,6.78,6.78,0,0,0,2.91-2.19,12,12,0,0,0,1.82-3.6l2.8-8.34.17,9.83L628.42,23.77H645.9l9.58,30.74q1.44,4.71,2.71,9.73t2.46,10.21l-3,.06q1.53-6.18,2.82-11.16t2.43-8.84l9.41-30.74h17L666,87.76a37,37,0,0,1-5.65,10.72,16.91,16.91,0,0,1-7.74,5.3,39.51,39.51,0,0,1-12.32,1.58q-1.95,0-3.75-.06c-1.21,0-2.51-.09-3.9-.17Z"
        transform="translate(-1.15 -1.61)"
      />
      <rect
        x="551.28"
        y="0.13"
        width="16.3"
        height="16.3"
        fill="#ff7f40"
      />
      <rect
        x="683.84"
        y="63.47"
        width="17.01"
        height="17.01"
        fill="#ff7f40"
      />
      <path
        d="M282,83.44a31.57,31.57,0,0,1-15.67-3.75A25.62,25.62,0,0,1,256,69.06a33.92,33.92,0,0,1-3.65-16.13A34.14,34.14,0,0,1,256,36.81a25.58,25.58,0,0,1,10.39-10.65,32.16,32.16,0,0,1,15.82-3.74,33.34,33.34,0,0,1,14.33,2.89,23.87,23.87,0,0,1,9.7,8.11,25.78,25.78,0,0,1,4.31,12H294.08a13.61,13.61,0,0,0-2.4-5.51,10.23,10.23,0,0,0-4.19-3.26A14,14,0,0,0,282,35.58a12.61,12.61,0,0,0-7,1.89,12,12,0,0,0-4.51,5.75,25.69,25.69,0,0,0-1.58,9.71,25.8,25.8,0,0,0,1.58,9.77A11.82,11.82,0,0,0,275,68.41a12.61,12.61,0,0,0,6.95,1.86,14.06,14.06,0,0,0,5.72-1.11,10.6,10.6,0,0,0,4.25-3.45,14.05,14.05,0,0,0,2.38-5.92h16.24A26.87,26.87,0,0,1,306,72.25a24.79,24.79,0,0,1-9.86,8.26A32.47,32.47,0,0,1,282,83.44Z"
        transform="translate(-1.15 -1.61)"
      />
      <path
        d="M312,92.73h7.6a11.25,11.25,0,0,0,4.3-.73,6.78,6.78,0,0,0,2.91-2.19,12,12,0,0,0,1.82-3.6l2.8-8.34.17,9.83L307.79,23.77h17.48l9.58,30.74q1.44,4.71,2.71,9.73T340,74.45l-3,.06q1.53-6.18,2.82-11.16t2.43-8.84l9.41-30.74h17l-23.33,64a37,37,0,0,1-5.65,10.72,16.91,16.91,0,0,1-7.74,5.3,39.51,39.51,0,0,1-12.32,1.58c-1.3,0-2.55,0-3.76-.06s-2.5-.09-3.89-.17Z"
        transform="translate(-1.15 -1.61)"
      />
      <rect
        x="111.95"
        y="0.13"
        width="16.3"
        height="16.3"
        fill="#ff7f40"
      />
    </g>
  </svg>
</template>
