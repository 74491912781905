<template>
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.3125 21.4737H19.6875C20.0356 21.4737 20.3694 21.6068 20.6156 21.8437C20.8617 22.0805 21 22.4018 21 22.7368C21 23.0719 20.8617 23.3931 20.6156 23.63C20.3694 23.8669 20.0356 24 19.6875 24H1.3125C0.964403 24 0.630564 23.8669 0.384423 23.63C0.138281 23.3931 0 23.0719 0 22.7368C0 22.4018 0.138281 22.0805 0.384423 21.8437C0.630564 21.6068 0.964403 21.4737 1.3125 21.4737ZM11.8125 14.1158L16.0689 10.0206L17.9248 11.8067L10.5 18.9524L3.07519 11.8067L4.93106 10.0206L9.1875 14.1158V0H11.8125V14.1158Z"
      fill="#FF7F40"
    />
  </svg>
</template>
