<template>
  <div
    v-if="hasErrors"
    class="input-error"
    role="alert"
  >
    <p
      v-for="(error, key) in errors"
      :key="key"
    >
      *{{ error }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    errors() {
      return this.$page.props.errors;
    },

    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
  },
});
</script>
