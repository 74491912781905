<template>
  <div
    :id="`gallery-${dynamicNumber}`"
    class="projectGallery gallery-scroll"
    :style="`background : ${customColor}; ${customGradient}`"
  >
    <div
      class="projectGallery"
      :class="`between-container-${content.margin_in_between}`"
    >
      <div
        class="projectGallery"
        :class="[content.horizontal_margin, content.vertical_margin,content.horizontal_align, `alternate-${content.alternate_mobile}`,content.mobile_settings]"
      >
        <div
          v-for="(image, index) in content.images"
          :key="`image${index}`"
          :style="`width : ${customWidth}%`"
          class="projectGallery__item"
          :class="`
            mb-middle-${content.mobile_middle_margin}
            mb-between-${content.mobile_margin_in_between}
            middle-${content.middle_margin}
            between-${content.margin_in_between}`"
        >
          <img
            :data-src="getImageKitURL(image, dynamicCol, content.image_type,false,position)"
            :src="getImageKitURL(image, dynamicCol, content.image_type,true,position)"
            :alt="image.alt"
            class="projectGallery__item--image"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";
import imageKit from "@/mixins/image-kit";

export default {
  mixins: [marked, imageKit],
  props: {
    position:{
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {}
    },
    isProject: {
      type: Boolean
    }
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },
    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if (this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customWidth() {
      if (this.$store.state.isMobile && this.content.alternate_mobile) {
        return this.content.mobile_image_width;
      } else if(this.$store.state.isMobile){
        return '100';
      } else if(this.content.images_width === '33' || this.content.images_width === 33) {
        return '33.333';
      } else {
        return this.content.images_width;
      }
    },

    dynamicCol() {
      const num = parseInt(this.content.images_width);
      if (num < 11) {
        return 10;
      } else if (num < 20) {
        return 5;
      } else if (num < 50) {
        return 2;
      } else {
        return 1;
      }
    }
  },
};
</script>

<style lang="scss">
.projectGallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // max-width: 100vw;
  overflow-x: hidden;

  &.between-container-true {
    @include tablet {
      flex-grow: 1;
      margin: 0 -1vw;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;

    &.mb-middle-true {
      margin-bottom: 5vw;
    }

    &.mb-middle-false {
      margin-bottom: 0;
    }

    &.middle-true {
      margin-bottom: 5vw;
      @include tablet {
        margin-bottom: 2vw;
      }
    }

    &.middle-false {
      margin-bottom: 0;
    }

    &.mb-between-true {
      padding: 0 1vw;
    }

    &.between-true {
      padding: 0 1vw;
    }

    &--image {
      width: 100%;
      height: auto;
    }
  }

  &.super {
    @include paddingSuper;
  }

  &.big {
    @include paddingBig;
  }

  &.standard {
    @include paddingMedium;
  }

  &.small {
    @include paddingSmall;
  }

  &.bottom-text {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }
  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.hide-mobile {
    display: none;

    @include tablet {
      display: flex;
    }
  }

  &.alternate-true {
    .mb-middle-true.middle-false {
      margin-bottom: 5vw;
      @include tablet {
        margin-bottom: 0;
      }
    }

    .mb-middle-false.middle-true {
      margin-bottom: 0;
      @include tablet {
        margin-bottom: 2vw;
      }
    }

    .mb-between-true.between-false {
      padding: 0 1vw;

      @include tablet {
        padding: 0 0;
      }
    }

    .mb-between-false.between-true {
      padding: 0 0;

      @include tablet {
        padding: 0 1vw;
      }
    }
  }
}
</style>
