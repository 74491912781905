import VueCookies from 'vue3-cookies'

export default {
  install: (app) => {
    app.use(VueCookies, {
      expireTimes: 15552000,
      path: "/",
      domain: "",
      secure: true,
      sameSite: "None"
    });
    app.provide('$cookies', app.config.globalProperties.$cookies)
  }
}
