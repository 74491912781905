<template>
  <input
    ref="inputSearchPage"
    class=""
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  >
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type:String,
      default:''
    }
  },

  emits: ["update:modelValue"],

  mounted(){
    // this.$refs.inputSearch.focus();
  },

  methods: {
    focus() {
      this.$refs.inputSearchPage.focus();
    },
  },
});
</script>
