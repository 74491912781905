<template>
  <div
    class="TheIntroVideo"
    @touchmove="removeScroll"
  >
    <div class="TheIntroVideo__container videoContainer">
      <vueVimeoPlayer
        v-if="$store.state.isMobile && $page.props.globals.brand.intro_video_id_mb"
        ref="introPlayer"
        :video-id="$page.props.globals.brand.intro_video_id_mb"
        muted
        :controls="false"
        @ready="startVideo"
        @playing="startAnimation"
      />
      <vueVimeoPlayer
        v-else
        ref="introPlayer"
        :video-id="$page.props.globals.brand.intro_video_id"
        muted
        :controls="false"
        @ready="startVideo"
        @playing="startAnimation"
      />
    </div>


    <div
      class="TheIntroVideo__cover"

      @click="goToNextStep"
    >
      <!-- {{ $page.props.globals.brand.intro_video_id }}
      {{ $page.props.globals.brand.intro_video_id_mb }} -->
      <div
        v-show="$store.state.isMobile"
        class="TheIntroVideo__text"
      >
        <div class="step01">
          <TextHolaIntroResponsive />
        </div>
        <class class="step02">
          <TextWeAreResponsive />
        </class>
      </div>

      <div
        v-show="!$store.state.isMobile"
        class="TheIntroVideo__text"
      >
        <div class="step01">
          <TextHolaIntro />
        </div>
        <class class="step02">
          <TextWeAre />
        </class>
      </div>
    </div>
  </div>
</template>

<script>
/* Vendor */
import imageKit from "@/mixins/image-kit";
import { inject } from "@vue/runtime-core";
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  components: {
    vueVimeoPlayer
  },
  mixins: [imageKit],

  setup() {
    const gsap = inject("$gsap");
    const tlIntro = gsap.timeline();
    const tlIntroText = gsap.timeline();
    const time = 7.3;
    const secondTime = 18.5;

    return {
      tlIntroText,
      tlIntro,
      time,
      secondTime
    };
  },
  data() {
    return {
      firstStep: true,
      runningAnimation: false,
    };
  },

  mounted() {
    this.$store.commit("TOGGLE_MOUNTED_VIDEO", true);
  },

  methods: {
    removeScroll(e) {
      e.preventDefault();
    },

    handleHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },

    startVideo() {
      this.$refs.introPlayer.mute();
      this.$refs.introPlayer.play();

      this.$store.commit("TOGGLE_COVER", true);

      setTimeout(() => {
        if(!this.runningAnimation) {
          this.skipVideo();
          console.log('not animated');
        }
      }, 2000);
    },

    startAnimation(){
      this.runningAnimation = true;
      /* eslint-disable */
      this.tlIntro.to('.TheIntroVideo__container', {opacity:1, duration:0.4}, 0.3);
      this.tlIntroText.to('.step01-a', {opacity:1, duration:0.8}, 0.6);
      this.tlIntroText.to('.step01-b', {opacity:1, duration:0.8}, 1.2);
      this.tlIntroText.to('.step01', {opacity:0, duration:0, onComplete:this.updateStep}, this.time);
      this.tlIntroText.to('.step02', {opacity:1, duration:1.5}, this.time + 0.5);
      this.tlIntro.to('.step02', {opacity:0, duration: 0.6, onComplete:this.hideCover}, this.secondTime);
      this.tlIntro.to('.TheIntroVideo', {opacity:0, duration: 1, onComplete:this.hideIntroVideo}, this.secondTime+1.3);
      /* eslint-enable */
    },

    hideCover() {
      this.$store.commit("TOGGLE_COVER", false);
      this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", false);
    },

    hideIntroVideo() {
      this.$store.commit("TOGGLE_COVER", false);
      this.$store.commit("TOGGLE_THE_INTRO_VIDEO", false);
      this.$store.commit('TOGGLE_IS_PAUSE_SCROLL', false);
      this.$store.commit("TOGGLE_MOUNTED_VIDEO", false);
    },

    updateStep() {
      this.firstStep = false;
    },

    goToNextStep(){
      if(this.firstStep) {
        this.firstStep = false;
        this.tlIntroText.play(this.time);
      } else {
        this.tlIntroText.pause();
        this.tlIntro.pause();
        this.$gsap.to('.step01', {opacity:0, duration:0});
        this.$gsap.to('.step02', {opacity:0, duration:0.8}, 0.4);
        this.$gsap.to('.videoContainer', {opacity:0, duration:1.5, onComplete:this.hideCover});
        this.$gsap.to('.TheIntroVideo', {opacity:0, duration: 1, onComplete:this.hideIntroVideo, delay:1.8});
      }
    },

    skipVideo() {
      this.$gsap.to('.videoContainer', {opacity:0, duration:0.5, onComplete:this.hideCover});
      this.$gsap.to('.TheIntroVideo', {opacity:0, duration: 0.5, onComplete:this.hideIntroVideo, delay:0.5});
    },
  },
};
</script>

<style lang="scss" >
.TheIntroVideo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  overflow: hidden;
  background: $black;
  width: 100vw;
  height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 177.78vh;
    min-height: 56.25vw;
    transform: translate(-50%, -50%) scale(1);
    width: 100vw;
    height: 100vh;
  }

  &__container{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    opacity:0;
    -webkit-touch-callout: none;
  }

  &__text {
    display: flex;
  }

  &__cover {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    //background: rgba(black, 0.8);
    width: 100vw;
    height: 100vh;

    @include tablet {
      align-items: center;
    }

    .step01-a,
    .step01-b {
      opacity: 0;
    }

    .step01 {
      position: absolute;
      //top: 10%;
      top: 50%;
      left: 10%;
      display: flex;
      justify-content: center;
      transform: translate(0%, -50%);

      @include tablet {
        left: 50%;
        transform: translate(-50%, -50%);
      }

      svg {
        max-width: 1200px;
        width: 70vw;
        @include tablet {
          width: 65vw;
        }
      }
    }

    .step02 {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-right: auto;
      // margin-bottom: 35vh;
      margin-left: 10vw;
      opacity: 0;

      @include tablet {
        margin-left: 0;
      }

      svg {
        max-width: 1200px;
        width: 75vw;

        @include tablet {
          width: 70vw;
        }
      }
    }
  }
}
</style>
