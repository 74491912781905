<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="652.03"
    height="218.39"
    viewBox="0 0 652.03 218.39"
  >
    <path
      d="M23.56,82,1.52,1.54h18l10,40.82q1.32,5.88,2.87,13.52t3.18,15.95H31.92Q33.71,62.65,35.21,55t2.73-12.85L48,1.54H65.59L75.68,42.22q1.66,7.45,3.11,14.61t2.79,15H78.14q1.6-8.51,3-15.48t3-14.18L94,1.54h18L90,82H70.88L59.49,36.71q-1.1-4.65-2.26-10.28T54.8,13.86L59,13.1q-1.36,7.4-2.52,13.1T54.1,36.66L42.66,82Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M115.91,82V1.54h16.3V82ZM154,46.57a12.54,12.54,0,0,0-1.15-5.69,7.66,7.66,0,0,0-3.35-3.45,11.53,11.53,0,0,0-5.45-1.17,12.17,12.17,0,0,0-5.73,1.43,11,11,0,0,0-4.42,4.39,14.57,14.57,0,0,0-1.71,7.32h-4.86v-14h3a22.31,22.31,0,0,1,4.55-6.95,19.9,19.9,0,0,1,6.71-4.53,22,22,0,0,1,8.49-1.6,22.78,22.78,0,0,1,10.82,2.4,16.14,16.14,0,0,1,7,7.28,27,27,0,0,1,2.43,12.13V82H154Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M196.42,83.61a28.75,28.75,0,0,1-11-2,16,16,0,0,1-7.42-5.9,17.12,17.12,0,0,1-2.61-9.65,17,17,0,0,1,2.19-8.72,16,16,0,0,1,7.06-6.13A37.93,37.93,0,0,1,197.41,48l9.43-.93a17.43,17.43,0,0,0,4.51-1,5.55,5.55,0,0,0,2.65-2,5.73,5.73,0,0,0,.92-3.27l2.8,3.59a23,23,0,0,1-1,6.8A6.71,6.71,0,0,1,214,54.81a13.71,13.71,0,0,1-5.41,1.59l-7,.85A19,19,0,0,0,196,58.69a6.87,6.87,0,0,0-3,2.54A7.25,7.25,0,0,0,192,65a7.47,7.47,0,0,0,1,4,6.48,6.48,0,0,0,3.1,2.51,13.27,13.27,0,0,0,5.14.86A16.15,16.15,0,0,0,208.16,71a11.38,11.38,0,0,0,4.93-4.36,13.46,13.46,0,0,0,1.83-7.28h3.34v7.4a21.38,21.38,0,0,1-3.78,9.11,17.76,17.76,0,0,1-7.37,5.8A26.78,26.78,0,0,1,196.42,83.61Zm33.33-1.08A18.48,18.48,0,0,1,221.52,81,9.5,9.5,0,0,1,217,76.15a21.56,21.56,0,0,1-1.44-8.59l-.6-1.41V40.81a6.55,6.55,0,0,0-1-3.73,5.82,5.82,0,0,0-3.14-2.19,23,23,0,0,0-11.69.07,6.37,6.37,0,0,0-3.45,2.67,11.11,11.11,0,0,0-1.4,5.1H178a22.56,22.56,0,0,1,3.15-11.09,18,18,0,0,1,8.79-6.91q5.94-2.4,15.35-2.4,9,0,14.76,2.15a16.71,16.71,0,0,1,8.36,6.1,16.8,16.8,0,0,1,2.64,9.55V67a4.2,4.2,0,0,0,.38,1.88A2.61,2.61,0,0,0,232.61,70a4.43,4.43,0,0,0,2,.38H237V82c-1.23.17-2.44.3-3.65.39S230.92,82.53,229.75,82.53Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M234.87,36.2V23.69h37V36.2Zm28.87,46.16a33.67,33.67,0,0,1-11.1-1.53,12.11,12.11,0,0,1-6.42-4.92,16.54,16.54,0,0,1-2.11-8.86V5.84h16.3V64.48a5.79,5.79,0,0,0,.69,3.08,3.56,3.56,0,0,0,2.1,1.54,13.66,13.66,0,0,0,3.87.44h4.8V82h-.79q-1.86.18-3.72.24C266.11,82.34,264.91,82.36,263.74,82.36Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M318.46,83.14a28.38,28.38,0,0,1-11-2,15.84,15.84,0,0,1-7.36-5.92,17,17,0,0,1-2.62-9.65,17,17,0,0,1,2.19-8.73,16.18,16.18,0,0,1,7.07-6.13,38.31,38.31,0,0,1,12.75-3.22l9.43-.93a17.43,17.43,0,0,0,4.51-1A5.16,5.16,0,0,0,337,40.37L339.8,44a23.11,23.11,0,0,1-1,6.81,6.73,6.73,0,0,1-2.8,3.58,13.54,13.54,0,0,1-5.41,1.59l-7,.85a18.72,18.72,0,0,0-5.6,1.44,6.74,6.74,0,0,0-3,2.54,7.17,7.17,0,0,0-.94,3.79,7.47,7.47,0,0,0,1,4,6.48,6.48,0,0,0,3.1,2.51,13.46,13.46,0,0,0,5.14.86,16.16,16.16,0,0,0,6.88-1.44,11.38,11.38,0,0,0,4.93-4.36,13.5,13.5,0,0,0,1.9-7.28h3.34v7.4a21.61,21.61,0,0,1-3.77,9.11,17.85,17.85,0,0,1-7.38,5.8A26.64,26.64,0,0,1,318.46,83.14Zm33.34-1.08a18.51,18.51,0,0,1-8.24-1.55A9.5,9.5,0,0,1,339,75.68a21.56,21.56,0,0,1-1.44-8.59l-.6-1.41V40.34a6.55,6.55,0,0,0-1-3.73,5.82,5.82,0,0,0-3.14-2.19,18.27,18.27,0,0,0-5.69-.72,18.45,18.45,0,0,0-6,.79,6.37,6.37,0,0,0-3.45,2.67,11.11,11.11,0,0,0-1.4,5.1H300a22.56,22.56,0,0,1,3.15-11.09A18,18,0,0,1,312,24.26q5.94-2.4,15.35-2.4,9,0,14.76,2.15a16.71,16.71,0,0,1,8.36,6.1,16.79,16.79,0,0,1,2.65,9.55V66.53a3.9,3.9,0,0,0,.38,1.87,2.42,2.42,0,0,0,1.17,1.13,4.44,4.44,0,0,0,2,.39H359V81.53c-1.22.17-2.44.3-3.64.39S353,82.06,351.8,82.06Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M364.67,82V23.69h15.88V38.8H381V82ZM378.4,45.67a30.49,30.49,0,0,1,2.08-11.49,17.45,17.45,0,0,1,17.24-11.39,15.74,15.74,0,0,1,1.84.07c.59.07,1.19.1,1.81.18V37.93h-3.88a20.33,20.33,0,0,0-8.93,1.78,12.37,12.37,0,0,0-5.62,5.41A19.14,19.14,0,0,0,381,54.23h-2.6Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M430.52,83.35a32.59,32.59,0,0,1-15.79-3.68,25.55,25.55,0,0,1-10.48-10.55,33.59,33.59,0,0,1-3.7-16.19,33.8,33.8,0,0,1,3.67-16,26.17,26.17,0,0,1,10.41-10.75,34.19,34.19,0,0,1,31.4,0,25.76,25.76,0,0,1,10.25,10.69,33.75,33.75,0,0,1,3.59,15.81c0,.69,0,1.44-.05,2.23s-.09,1.63-.15,2.51H409.59V47.19h38.3l-4,2.29a19.25,19.25,0,0,0-1.59-7.95,12.09,12.09,0,0,0-4.62-5.31,13.75,13.75,0,0,0-7.43-1.91,14.08,14.08,0,0,0-7.47,1.89A12.21,12.21,0,0,0,418,41.9a23.92,23.92,0,0,0-1.71,9.53v4.18A20.42,20.42,0,0,0,418,64.43a11.74,11.74,0,0,0,5,5.38,16.18,16.18,0,0,0,7.84,1.79,16.48,16.48,0,0,0,5.78-.93A10.8,10.8,0,0,0,440.7,68a11,11,0,0,0,2.48-4.2h15.39A24.4,24.4,0,0,1,443.64,81,34.44,34.44,0,0,1,430.52,83.35Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M481.76,92.65h7.6a11.31,11.31,0,0,0,4.34-.74,6.78,6.78,0,0,0,2.91-2.19,12.21,12.21,0,0,0,1.82-3.6l2.8-8.33.17,9.83L477.58,23.69h17.49l9.58,30.74q1.44,4.71,2.71,9.73t2.46,10.21l-3.05.06q1.51-6.2,2.82-11.16c.87-3.32,1.68-6.26,2.43-8.84l9.41-30.74h17l-23.33,64a37,37,0,0,1-5.65,10.72,17,17,0,0,1-7.74,5.29,39.19,39.19,0,0,1-12.32,1.59c-1.3,0-2.55,0-3.76-.06l-3.9-.17Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M565,83.35a33.24,33.24,0,0,1-16-3.75A26.8,26.8,0,0,1,538.18,69a32.32,32.32,0,0,1-3.87-16.08,32.23,32.23,0,0,1,3.87-16A26.83,26.83,0,0,1,549,26.18a35.63,35.63,0,0,1,31.94,0,26.78,26.78,0,0,1,10.8,10.69,32.43,32.43,0,0,1,3.86,16,32.44,32.44,0,0,1-3.86,16.06A26.58,26.58,0,0,1,581,79.6,33,33,0,0,1,565,83.35Zm0-13.1a13.78,13.78,0,0,0,7.36-1.93,12.72,12.72,0,0,0,4.93-5.82,22.94,22.94,0,0,0,1.78-9.57,23.46,23.46,0,0,0-1.76-9.58,13,13,0,0,0-4.93-5.9,13.62,13.62,0,0,0-7.38-2,13.87,13.87,0,0,0-7.48,2,12.83,12.83,0,0,0-4.93,5.87,23.44,23.44,0,0,0-1.74,9.61,23.22,23.22,0,0,0,1.75,9.56,12.7,12.7,0,0,0,4.92,5.82A14,14,0,0,0,565,70.25Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M619.37,83.35A22.73,22.73,0,0,1,608.56,81a16.24,16.24,0,0,1-6.95-7.27,26.87,26.87,0,0,1-2.44-12.14V23.69h16.3V59.12a12.53,12.53,0,0,0,1.14,5.69A7.78,7.78,0,0,0,620,68.25a11.52,11.52,0,0,0,5.44,1.18A12.13,12.13,0,0,0,631.16,68a11.09,11.09,0,0,0,4.41-4.39,14.57,14.57,0,0,0,1.71-7.32h4.89v14h-2.89a22.62,22.62,0,0,1-4.62,7,19.8,19.8,0,0,1-6.78,4.53A22.31,22.31,0,0,1,619.37,83.35ZM637.73,82V69.37h-.48V23.69h16.3V82Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M6.78,195V114.54h16.3V195Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M57.35,196.35a33.24,33.24,0,0,1-16-3.75A26.85,26.85,0,0,1,30.51,182a35.15,35.15,0,0,1,0-32.07,26.82,26.82,0,0,1,10.83-10.7,32.89,32.89,0,0,1,16-3.79,33,33,0,0,1,15.93,3.77,26.78,26.78,0,0,1,10.8,10.69,35.24,35.24,0,0,1,0,32.08,26.67,26.67,0,0,1-10.8,10.67A33,33,0,0,1,57.35,196.35Zm0-13.1a13.76,13.76,0,0,0,7.35-1.93,12.74,12.74,0,0,0,4.93-5.81,26.81,26.81,0,0,0,0-19.16,13,13,0,0,0-4.93-5.9,13.59,13.59,0,0,0-7.37-2,13.9,13.9,0,0,0-7.49,2,12.83,12.83,0,0,0-4.93,5.87,27.16,27.16,0,0,0,0,19.17,12.7,12.7,0,0,0,4.92,5.82A14,14,0,0,0,57.35,183.25Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M122.21,196.35a33.24,33.24,0,0,1-16-3.75A26.85,26.85,0,0,1,95.37,182a35.15,35.15,0,0,1,0-32.07,26.82,26.82,0,0,1,10.83-10.7,32.89,32.89,0,0,1,16-3.79,32.85,32.85,0,0,1,15.93,3.77A26.89,26.89,0,0,1,149,149.85a35.36,35.36,0,0,1,0,32.08,26.76,26.76,0,0,1-10.81,10.67A32.91,32.91,0,0,1,122.21,196.35Zm0-13.1a13.78,13.78,0,0,0,7.36-1.93,12.86,12.86,0,0,0,4.93-5.81,26.7,26.7,0,0,0,0-19.16,13,13,0,0,0-4.93-5.9,13.59,13.59,0,0,0-7.37-2,13.9,13.9,0,0,0-7.49,2,12.83,12.83,0,0,0-4.93,5.87,23.44,23.44,0,0,0-1.74,9.61,23,23,0,0,0,1.76,9.56,12.62,12.62,0,0,0,4.91,5.82A14.11,14.11,0,0,0,122.21,183.25Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M156.36,195V114.54h16.25V195Zm14.35-15.37-1.55-15.93,11.33-11.84,14.66-15.17h19L186.3,164.83h-.71ZM196.39,195l-10.08-16.36-8.11-13.28v-7.65l12.54-.2L215,195Z"
      transform="translate(-1.52 -1.54)"
    />
    <rect
      x="217.95"
      y="135.15"
      width="16.3"
      height="58.31"
    />
    <path
      d="M241.42,195V136.69h15.84v12.63h.43V195Zm38.1-35.43a12.53,12.53,0,0,0-1.14-5.69,7.76,7.76,0,0,0-3.35-3.45,11.53,11.53,0,0,0-5.45-1.17,12.17,12.17,0,0,0-5.73,1.43,11,11,0,0,0-4.43,4.39,14.56,14.56,0,0,0-1.7,7.32h-4.86v-14h2.85a21.86,21.86,0,0,1,4.59-7,20.23,20.23,0,0,1,6.79-4.53,22.52,22.52,0,0,1,8.54-1.6,22.78,22.78,0,0,1,10.82,2.4A16.21,16.21,0,0,1,293.4,145a27.23,27.23,0,0,1,2.43,12.13V195H279.52Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M325.4,195.28a23.2,23.2,0,0,1-22.1-14.17,41.8,41.8,0,0,1,0-31.62,23.2,23.2,0,0,1,22.09-14.16,21.36,21.36,0,0,1,12.9,3.82,23.06,23.06,0,0,1,7.92,10.51,42.15,42.15,0,0,1,2.71,15.39v.23a42.74,42.74,0,0,1-2.62,15.55,23.18,23.18,0,0,1-7.91,10.59A21.44,21.44,0,0,1,325.4,195.28Zm5.4,24.63a38.25,38.25,0,0,1-14.59-2.51,22,22,0,0,1-9.62-7.27,22.59,22.59,0,0,1-4.13-11.52h16.36a10.39,10.39,0,0,0,1.81,5.07,8.88,8.88,0,0,0,4,3,16.49,16.49,0,0,0,6.06,1,15.91,15.91,0,0,0,7.19-1.46,9.43,9.43,0,0,0,4.33-4.4,16.9,16.9,0,0,0,1.44-7.42V178.87h1.7V150.16H344.1V136.69h16.07v56.9a28,28,0,0,1-3.35,14.06,22.05,22.05,0,0,1-9.94,9.09A36.9,36.9,0,0,1,330.8,219.91Zm-.42-37a12.68,12.68,0,0,0,7.28-2,12.85,12.85,0,0,0,4.69-6,25.27,25.27,0,0,0,1.64-9.58,24.91,24.91,0,0,0-1.62-9.48,13,13,0,0,0-4.69-6,12.85,12.85,0,0,0-7.33-2.06,12.46,12.46,0,0,0-7.25,2.12,13.38,13.38,0,0,0-4.7,6.11,25.19,25.19,0,0,0-1.64,9.57,24.49,24.49,0,0,0,1.64,9.5,12.57,12.57,0,0,0,4.69,5.91,13.07,13.07,0,0,0,7.29,1.88Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M388.4,149.2V136.69h37.85V149.2Zm9.24,45.8V132.68a21.81,21.81,0,0,1,2.23-10.53,13.58,13.58,0,0,1,6.71-6,28.74,28.74,0,0,1,11.45-2c1.15,0,2.44,0,3.86.09s2.8.14,4.14.25h.48V127H424.9l-2-.06H421a10.07,10.07,0,0,0-4.13.73,4.62,4.62,0,0,0-2.26,2.21,8.87,8.87,0,0,0-.7,3.81V195Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M455.92,196.35a33.24,33.24,0,0,1-16-3.75A26.85,26.85,0,0,1,429.08,182a35.15,35.15,0,0,1,0-32.07,26.82,26.82,0,0,1,10.83-10.7,35.63,35.63,0,0,1,31.94,0,26.78,26.78,0,0,1,10.8,10.69,35.24,35.24,0,0,1,0,32.08,26.67,26.67,0,0,1-10.8,10.67A33,33,0,0,1,455.92,196.35Zm0-13.1a13.76,13.76,0,0,0,7.35-1.93,12.74,12.74,0,0,0,4.93-5.81,23,23,0,0,0,1.78-9.58,23.46,23.46,0,0,0-1.76-9.58,13,13,0,0,0-4.93-5.9,13.59,13.59,0,0,0-7.37-2,13.9,13.9,0,0,0-7.49,2,12.83,12.83,0,0,0-4.93,5.87,27.16,27.16,0,0,0,0,19.17,12.7,12.7,0,0,0,4.92,5.82A14,14,0,0,0,455.92,183.25Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M490.32,195V136.69H506.2V151.8h.42V195Zm13.73-36.33a30.69,30.69,0,0,1,2.08-11.49,18.34,18.34,0,0,1,6.41-8.29,18.09,18.09,0,0,1,10.84-3.1,15.48,15.48,0,0,1,1.83.07c.58.07,1.19.1,1.81.18v14.89h-3.88a20.33,20.33,0,0,0-8.93,1.78,12.37,12.37,0,0,0-5.62,5.41,19.27,19.27,0,0,0-1.95,9.14h-2.57Z"
      transform="translate(-1.52 -1.54)"
    />
    <path
      d="M553.94,172.82c0-4.26.28-10.79.83-13.28a15.77,15.77,0,0,1,3.15-6.58,33.37,33.37,0,0,1,7.1-6.1l.67-.47c.23-.16.46-.32.68-.49a33.85,33.85,0,0,0,3.77-3.09,13,13,0,0,0,2.45-3.22,8.36,8.36,0,0,0,.93-3.89,10.2,10.2,0,0,0-1.09-4.91,7.08,7.08,0,0,0-3.42-3.13,14.2,14.2,0,0,0-6-1.08,15.93,15.93,0,0,0-6.82,1.24,8.13,8.13,0,0,0-3.94,4.07,20.2,20.2,0,0,0-1.53,7.71H534.45q.06-9,3.4-15a21,21,0,0,1,9.78-8.94q6.42-3,15.75-3A39.46,39.46,0,0,1,578,115.21a19.68,19.68,0,0,1,11.68,26.3,23.24,23.24,0,0,1-4.17,6.58,46.54,46.54,0,0,1-7.16,6.44,50.12,50.12,0,0,0-5.66,4.73,9.62,9.62,0,0,0-2.29,3.8c-.42,1.38-.64,6.48-.64,9v1.55H553.94Z"
      transform="translate(-1.52 -1.54)"
    />
    <rect
      x="552.45"
      y="177.74"
      width="15.72"
      height="15.72"
      fill="#ff7f40"
    />
    <rect
      x="217.81"
      y="113"
      width="16.44"
      height="16.51"
      fill="#ff7f40"
    />
  </svg>
</template>
