<template>
  <div id="about-wrapper">
    <div
      id="about-content"
      class="About about-scroller"
      @click="getScrollOffset"
    >
      <!-- SEO TITLE -->
      <h1 class="hidden">
        About
      </h1>
      <section class="About__header scroll-about-header">
        <iframe
          v-if="$store.state.isMobile && content.header_vimeo_id_mb"
          :src="`https://player.vimeo.com/video/${content.header_vimeo_id_mb}?background=1`"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
          title="About Video"
        />
        <iframe
          v-else
          :src="`https://player.vimeo.com/video/${content.header_vimeo_id}?background=1`"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
          title="About Video"
        />

        <div
          v-if="$store.state.isMobile"
          class="About__header--content"
        >
          <TextWeAreBinalogue />
          <!-- <TextWeAreBinalogueResponsive /> -->
        </div>

        <div
          v-else
          class="About__header--content"
        >
          <TextWeAreBinalogue />
        </div>
      </section>

      <main class="About__main scroll-about-main">
        <div class="About__main--title">
          <h3 class="title title-svg">
            <TextHola />
          </h3>
        </div>

        <div class="About__main--content">
          <h2
            class="medium-text"
            v-html="markdownToHtml(content.main_description)"
          />
        </div>
      </main>

      <section class="About__partners scroll-about-partners">
        <div
          v-for="(partner, index) in content.partner"
          :key="`partner${index}`"
          class="About__partners--item"
        >
          <div
            class="About__partners--title medium-title white"
            :class="`partner${index}`"
            v-html="markdownToHtml(partner.partner_title)"
          />
          <div
            class="About__partners--description main-text white"
            v-html="markdownToHtml(partner.partner_description)"
          />
        </div>
      </section>

      <section class="About__clients scroll-about-clients">
        <h3 class="section-title">
          Clients<span class="orange">.</span>
        </h3>

        <div class="About__clients--content">
          <InertiaLink
            v-for="(client, index) in content.clients"
            :key="`client${index}`"
            :href="`/clients/${client.slug}/`"
            class="About__clients--item"
          >
            {{ client.title }}
          </InertiaLink>
        </div>
      </section>

      <AboutAwards :awards="content.award_group" />

      <AboutPublications :publications="content.publications" />
      <span id="about-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from 'vue';
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import page from "@/mixins/page";
import marked from "@/mixins/marked";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [page, marked, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      headerColorinit: null,
      bgScrollAnimation:null,
    };
  },

  created() {
    seo.seoContent(this.content);
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();
    });
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
    this.$gsap.to('.hideInAvatar', {opacity:1, duration:0.4});
  },

  methods: {
    init() {
      this.toggleMenuColor("white", 0,0);
      this.$gsap.to('.hideInAvatar', {opacity:0, duration:0.4});
      this.setScroller('#about-wrapper', '#about-content' );

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to('.showBgHeader', {
        scrollTrigger: {
          trigger: '#about-content',
          markers: false,
          onEnter: this.updateHeaderState,
          onEnterBack: this.updateHeaderStateBack,
          start: '50px top',
          end: 'top bottom',
          toggleActions: 'play none reverse none',
        },
        height: '100%',
        duration: 0.2,
        ease: 'Power2.out',
      });
    },

    updateHeaderState(){
      this.toggleMenuColor('black', 0.2, 0);
      this.$gsap.to('.hideInAvatar', {opacity:1, duration:0.4});
    },

    updateHeaderStateBack(){
      this.toggleMenuColor('white', 0.2, 0.4);
      this.$gsap.to('.hideInAvatar', {opacity:0, duration:0.4});
    },
  }
};
</script>

<style lang="scss">
.About {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  // background: pink;
  @include headerMargin;

  .section-title {
    padding-bottom: 3vw;
  }

  &__header {
    position: relative;
    display: flex;
    background: $black;
    width: 100vw;
    height: 100vh;

    @include tablet {
      height: initial;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      width: 177.7vh;
      height: 100vh;

      @include tablet {
        position: relative;
        width: 100vw;
        height: 56.25vw;
      }
    }

    &--content {
      position: relative;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;

      @include tablet {
        position: absolute;
        width: 100vw;
        height: 56.25vw;
      }

      svg {
        max-width: 900px;
        width: 85vw;

        @include tablet {
          width: 49vw;
        }
      }
    }

    &--title {
      padding-left: 5vw;
      font-size: 15vw;
      line-height: 1;
      color: $white;
      text-shadow: 0.3vw 0.3vw 2vw rgba($black, 0.7);
      font-variation-settings: "wght" 800, "wdth" 100, "opsz" 30;

      strong {
        margin-left: -1vw;
        color: $orange;

        @include desktop-l {
          margin-left: -18px;
        }
      }

      em {
        font-style: normal;
        color: $orange;
      }

      @include tablet {
        padding-left: 0;
        font-size: 5vw;
      }

      @include desktop-l {
        font-size: 90px;

        p {
          width: 110%;
        }
      }
    }
  }

  &__main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5vh;
    padding-bottom: 3vh;

    @include paddingMobile;


    @include tablet {
      @include paddingPageTop;
      @include paddingBig;
      @include paddingSectionBottom;
    }

    &--title {
      width: 100%;
      margin-bottom: 5vw;

      @include tablet {
        width: 35%;
      }

      @include desktop-sm {
        width: 50%;
      }
    }

    &--content {
      width: 100%;

      @include tablet {
        width: 65%;
      }

      @include desktop-sm {
        width: 50%;
      }

      p {
        margin-bottom: 25px;
      }
    }

    .small-title {
      margin-bottom: 3vh;
    }
  }

  &__clients {
    display: flex;
    flex-direction: column;
    padding-top: 2vh;
    background: $white;
    width: 100%;
    @include paddingSectionBottom;

    @include tablet {
      @include paddingPageTop;
    }

    &--content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      @include paddingMobile;

      @include tablet {
        @include paddingBig;

        justify-content: flex-start;
      }
    }

    &--item {
      margin-bottom: 15px;
      font-size: 16.5px;
      line-height: 1.4;
      color: $black;
      width: 48.5%;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
      @include transition;

      @include tablet {
        margin-bottom: 20px;
        font-size: 23px;
        width: 33.33%;
      }

      @include desktop-sm {
        font-size: 28px;
      }

      @include desktop-l {
        font-size: 36px;
      }

      @include desktop-xl {
        font-size: 39px;
      }

      &:hover {
        color: $orange;
      }
    }
  }

  &__partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 8vh;
    background: $black;
    @include paddingMobile;
    @include paddingSectionBottom;

    @include tablet {
      @include paddingPageTop;
      @include paddingBig;
    }

    &--item {
      width: 100%;

      &:first-child {
        margin-bottom: 5vh;

        @include tablet {
          margin-bottom: 0;
        }
      }

      @include tablet {
        width: 46%;
      }

      @include desktop-m {
        width: 43%;
      }
    }

    &--title {
      margin-bottom: 15px;

      @include tablet {
        margin-bottom: 30px;
      }

      &.partner0 {
        strong {
          color: #e50914;
        }
      }

      &.partner1 {
        strong {
          color: #01d965;
        }
      }
    }
  }
}
</style>
