<template>
  <div
    class="CustomGrid"
    :style="`background : ${customColor};`"
  >
    <!-- {{content }} -->
    <div
      v-if="$store.state.isMobile"
      class="CustomGrid__container"
    >
      <div class="CustomGrid__column">
        <!-- {{content.top_left_ratio}}
        {{content.top_left_vimeo_id}}
        {{content.top_left_main_image}}
        {{content.top_left_rollover_image}}
        {{content.top_left_mobile_image}}
        {{content.top_left_title}}
        {{content.top_left_subtitle}} -->
        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.top_left_vimeo_id, content.top_left_ratio)"
        >
          <img
            v-if="content.top_left_mobile_image"
            :data-src="getImageKitURL(content.top_left_mobile_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_left_mobile_image, 1, content.type ,true,position)"
            :alt="content.top_left_mobile_image.alt"
            class="CustomGrid__column--image"
          >
          <img
            class="CustomGrid__column--play"
            src="/fonts/play_white.png"
            alt="indicators"
          >
        </div>

        <h3 class="main-text-bold ProjectSectionFooter white">
          {{ content.top_left_title }}<span class="orange">.</span> <br>
          <span
            v-if="content.top_left_subtitle"
            class="regular-text grey  ProjectSectionFooter__subtitle"
          >{{ content.top_left_subtitle }}</span>
        </h3>

        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.top_center_vimeo_id, content.top_center_ratio)"
        >
          <img
            v-if="content.top_center_mobile_image"
            :data-src="getImageKitURL(content.top_center_mobile_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_center_mobile_image, 1, content.type ,true,position)"
            :alt="content.top_center_main_image.alt"
            class="CustomGrid__column--image"
          >
          <img
            class="CustomGrid__column--play"
            src="/fonts/play_white.png"
            alt="indicators"
          >
        </div>

        <h3 class="main-text-bold ProjectSectionFooter white">
          {{ content.top_center_title }}<span class="orange">.</span> <br>
          <span
            v-if="content.top_center_subtitle"
            class="regular-text grey  ProjectSectionFooter__subtitle"
          >{{ content.top_center_subtitle }}</span>
        </h3>

        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.top_right_vimeo_id, content.top_right_ratio)"
        >
          <img
            v-if="content.top_right_mobile_image"
            :data-src="getImageKitURL(content.top_right_mobile_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_right_mobile_image, 1, content.type ,true,position)"
            :alt="content.top_right_mobile_image.alt"
            class="CustomGrid__column--image"
          >
          <img
            class="CustomGrid__column--play"
            src="/fonts/play_white.png"
            alt="indicators"
          >
        </div>

        <h3 class="main-text-bold ProjectSectionFooter white">
          {{ content.top_right_title }}<span class="orange">.</span> <br>
          <span
            v-if="content.top_right_subtitle"
            class="regular-text grey  ProjectSectionFooter__subtitle"
          >{{ content.top_right_subtitle }}</span>
        </h3>

        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.bottom_left_vimeo_id, content.bottom_left_ratio)"
        >
          <img
            v-if="content.bottom_left_mobile_image"
            :data-src="getImageKitURL(content.bottom_left_mobile_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_left_mobile_image, 1, content.type ,true,position)"
            :alt="content.bottom_left_mobile_image.alt"
            class="CustomGrid__column--image"
          >
          <img
            class="CustomGrid__column--play"
            src="/fonts/play_white.png"
            alt="indicators"
          >
        </div>

        <h3 class="main-text-bold ProjectSectionFooter white">
          {{ content.bottom_left_title }}<span class="orange">.</span> <br>
          <span
            v-if="content.bottom_left_subtitle"
            class="regular-text grey  ProjectSectionFooter__subtitle"
          >{{ content.bottom_left_subtitle }}</span>
        </h3>

        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.bottom_center_vimeo_id, content.bottom_center_ratio)"
        >
          <img
            v-if="content.bottom_center_mobile_image"
            :data-src="getImageKitURL(content.bottom_center_mobile_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_center_mobile_image, 1, content.type ,true,position)"
            :alt="content.bottom_center_mobile_image.alt"
            class="CustomGrid__column--image"
          >
          <img
            class="CustomGrid__column--play"
            src="/fonts/play_white.png"
            alt="indicators"
          >
        </div>

        <h3 class="main-text-bold ProjectSectionFooter white">
          {{ content.bottom_center_title }}<span class="orange">.</span> <br>
          <span
            v-if="content.bottom_center_subtitle"
            class="regular-text grey  ProjectSectionFooter__subtitle"
          >{{ content.bottom_center_subtitle }}</span>
        </h3>

        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.bottom_right_vimeo_id, content.bottom_right_ratio)"
        >
          <img
            v-if="content.bottom_right_mobile_image"
            :data-src="getImageKitURL(content.bottom_right_mobile_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_right_mobile_image, 1, content.type ,true,position)"
            :alt="content.bottom_right_mobile_image.alt"
            class="CustomGrid__column--image"
          >
          <img
            class="CustomGrid__column--play"
            src="/fonts/play_white.png"
            alt="indicators"
          >
        </div>

        <h3 class="main-text-bold ProjectSectionFooter white">
          {{ content.bottom_right_title }}<span class="orange">.</span> <br>
          <span
            v-if="content.bottom_right_subtitle"
            class="regular-text grey  ProjectSectionFooter__subtitle"
          >{{ content.bottom_right_subtitle }}</span>
        </h3>
      </div>
    </div>

    <div
      v-else
      class="CustomGrid__container"
    >
      <div class="CustomGrid__column">
        <!-- {{content.top_left_ratio}}
        {{content.top_left_vimeo_id}}
        {{content.top_left_main_image}}
        {{content.top_left_rollover_image}}
        {{content.top_left_mobile_image}}
        {{content.top_left_title}}
        {{content.top_left_subtitle}} -->
        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.top_left_vimeo_id, content.top_left_ratio)"
        >
          <img
            v-if="content.top_left_main_image"
            :data-src="getImageKitURL(content.top_left_main_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_left_main_image, 1, content.type ,true,position)"
            :alt="content.top_left_main_image.alt"
            class="CustomGrid__column--image"
          >

          <img
            v-if="content.top_left_rollover_image"
            :data-src="getImageKitURL(content.top_left_rollover_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_left_rollover_image, 1, content.type ,true,position)"
            :alt="content.top_left_rollover_image.alt"
            class="CustomGrid__column--rollover"
          >
        </div>

        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.bottom_left_vimeo_id, content.bottom_left_ratio)"
        >
          <img
            v-if="content.bottom_left_main_image"
            :data-src="getImageKitURL(content.bottom_left_main_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_left_main_image, 1, content.type ,true,position)"
            :alt="content.bottom_left_main_image.alt"
            class="CustomGrid__column--image"
          >

          <img
            v-if="content.bottom_left_rollover_image"
            :data-src="getImageKitURL(content.bottom_left_rollover_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_left_rollover_image, 1, content.type ,true,position)"
            :alt="content.bottom_left_rollover_image.alt"
            class="CustomGrid__column--rollover"
          >
        </div>
      </div>

      <div class="CustomGrid__column">
        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.top_center_vimeo_id, content.top_center_ratio)"
        >
          <img
            v-if="content.top_center_main_image"
            :data-src="getImageKitURL(content.top_center_main_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_center_main_image, 1, content.type ,true,position)"
            :alt="content.top_center_main_image.alt"
            class="CustomGrid__column--image"
          >

          <img
            v-if="content.top_center_rollover_image"
            :data-src="getImageKitURL(content.top_center_rollover_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_center_rollover_image, 1, content.type ,true,position)"
            :alt="content.top_center_rollover_image.alt"
            class="CustomGrid__column--rollover"
          >
        </div>

        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.bottom_center_vimeo_id, content.bottom_center_ratio)"
        >
          <img
            v-if="content.bottom_center_main_image"
            :data-src="getImageKitURL(content.bottom_center_main_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_center_main_image, 1, content.type ,true,position)"
            :alt="content.bottom_center_main_image.alt"
            class="CustomGrid__column--image"
          >

          <img
            v-if="content.bottom_center_rollover_image"
            :data-src="getImageKitURL(content.bottom_center_rollover_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_center_rollover_image, 1, content.type ,true,position)"
            :alt="content.bottom_center_rollover_image.alt"
            class="CustomGrid__column--rollover"
          >
        </div>
      </div>

      <div class="CustomGrid__column">
        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.top_right_vimeo_id, content.top_right_ratio)"
        >
          <img
            v-if="content.top_right_main_image"
            :data-src="getImageKitURL(content.top_right_main_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_right_main_image, 1, content.type ,true,position)"
            :alt="content.top_right_main_image.alt"
            class="CustomGrid__column--image"
          >

          <img
            v-if="content.top_right_rollover_image"
            :data-src="getImageKitURL(content.top_right_rollover_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.top_right_rollover_image, 1, content.type ,true,position)"
            :alt="content.top_right_rollover_image.alt"
            class="CustomGrid__column--rollover"
          >
        </div>

        <div
          class="CustomGrid__column--item"
          @click="showModalVideo(content.bottom_right_vimeo_id, content.bottom_right_ratio)"
        >
          <img
            v-if="content.bottom_right_main_image"
            :data-src="getImageKitURL(content.bottom_right_main_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_right_main_image, 1, content.type ,true,position)"
            :alt="content.bottom_right_main_image.alt"
            class="CustomGrid__column--image"
          >

          <img
            v-if="content.bottom_right_rollover_image"
            :data-src="getImageKitURL(content.bottom_right_rollover_image, 1, content.type ,false,position)"
            :src="getImageKitURL(content.bottom_right_rollover_image, 1, content.type ,true,position)"
            :alt="content.bottom_right_rollover_image.alt"
            class="CustomGrid__column--rollover"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";
import imageKit from "@/mixins/image-kit";

export default {
  mixins: [marked, imageKit],
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    position:{
      type: Number,
      default: 1000
    },
    isProject: {
      type: Boolean
    }
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if (this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customDescColor() {
      if (this.content.color) {
        return this.content.color;
      } else {
        return "#000";
      }
    }
  },

  methods: {
    showModalVideo(id, ratio) {
      this.$store.state.video_id = id;
      this.$store.state.video_ratio = ratio;
      if(this.showVideoModal){
        setTimeout(() => {
          // this.showVideoModal = false;
          this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
        }, 800);
      }else {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", true);
      }

      window.history.pushState({id:1}, null, null);
    },
  },
};
</script>

<style lang="scss">
.CustomGrid {
  max-width: 100vw;
  overflow-x: hidden;
  width: 100%;
  @include paddingBig;

  @include tablet {
    @include paddingBottom;
  }

  &__container {
    display: flex;
    gap: 4rem;
    justify-content: space-between;
    width: 100%;
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--item {
      position: relative;
      display: flex;
      cursor: pointer;
      width: 100%;

      @include tablet {
        margin-bottom: 4rem;
      }
    }

    &--image {
      width: 100%;
      height: auto;
    }

    &--rollover {
      position: absolute;
      width: 100%;
      height: auto;
      opacity: 0;
      @include transition;

      &:hover {
        opacity: 1;
      }
    }

    &--play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 56px;
      width: auto;
    }
  }


  &.hide-mobile {
  display: none;

  @include tablet {
    display: block;
  }
}



}
</style>
