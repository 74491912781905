<template>
  <div id="privacy-wrapper">
    <div
      id="privacy-content"
      class="PrivacyPolicy privacy-scroller"
    >
      <section class="PrivacyPolicy__header">
        <img
          v-if="$store.state.isMobile && content.thumbnail_mobile"
          :src="getImageKitURL(content.thumbnail_mobile, 1, content.image_type)"
          :alt="content.thumbnail_mobile.alt"
        >
        <img
          v-else
          :src="getImageKitURL(content.thumbnail, 1, content.image_type)"
          :alt="content.thumbnail.alt"
        >
      </section>
      <section class="PrivacyPolicy__intro">
        <h1 class="title title-svg">
          <TextPrivacy />
        </h1>
        <div
          class="PrivacyPolicy__intro--desc"
          v-html="markdownToHtml(content.description)"
        />
      </section>

      <main class="PrivacyPolicy__main">
        <div class="PrivacyPolicy__identification">
          <div
            v-if="content.personal_identification_information"
            class="PrivacyPolicy__identification--content"
            v-html="markdownToHtml(content.personal_identification_information)"
          />
        </div>

        <div
          class="PrivacyPolicy__content"
          v-html="markdownToHtml(content.content)"
        />
      </main>
      <span id="privacy-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import marked from "@/mixins/marked";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  mixins: [imageKit, page, marked, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      privacySmoother: null,
      bgScrollAnimation: null,
    };
  },

  created() {
    seo.seoContent(this.content);
  },

  mounted() {
    this.imagesToPreload = document.querySelectorAll("img");
    this.getBackgroundImagesUrl();
    this.loadImages();
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {

      if(this.$store.state.isMobile) {
        this.toggleMenuColor("black", 0,0);
      } else {
        this.toggleMenuColor("white", 0,0);
      }

      if(this.$store.state.isMobile) {
        this.$gsap.to('.showBgHeader', {height:'100%', duration:0});
      }

      this.setScroller('#privacy-wrapper', '#privacy-content' );

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to('.showBgHeader', {
        scrollTrigger: {
          trigger: '#privacy-content',
          markers: false,
          onEnter: this.updateHeaderState,
          onEnterBack: this.updateHeaderStateBack,
          start: '50px top',
          end: 'top bottom',
          toggleActions: 'play none reverse none',
        },
        height: '100%',
        duration: 0.2,
        ease: 'Power2.out',
      });
    },

    updateHeaderState(){
      this.toggleMenuColor('black', 0.2, 0);
    },

    updateHeaderStateBack(){
      if(this.$store.state.isMobile) {
        this.toggleMenuColor('black', 0.2, 0);
      } else {
        this.toggleMenuColor('white', 0.2, 0.4);
      }
    },
  }
};
</script>

<style lang="scss">
.PrivacyPolicy {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  margin-top: 62px;

  @include headerMargin;

  @include tablet {
    margin-top: 0;
  }

  &__header {
    width: 100%;
    height: auto;
    margin-bottom: -10px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    padding: 5vh 6vw;
    background: $grey-light;
    width: 100%;

    @include tablet {
      flex-direction: row;
      padding: 10vh 8vw;
    }

    .title {
      padding-right: 4vw;
      font-size: 40px;
      width: 100%;

      @include tablet {
        width: 40%;
      }
    }

    &--desc {
      font-size: 22px;
      line-height: 1.4;
      color: $black;
      width: 100%;

      @include tablet {
        width: 60%;
      }

      a {
        color: $orange;
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 5vh 5vw;
    background: $white;
    width: 100%;

    @include tablet {
      flex-direction: row;
      padding: 10vh 8vw;
    }
  }

  &__identification {
    padding-right: 4vw;
    width: 100%;

    @include tablet {
      width: 40%;
    }

    &--title {
      font-size: 16px;
      color: $orange;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
        line-height: 1.4;
      }
    }

    &--content {
      font-size: 16px;
      //line-height: 1.4;
      color: $grey;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
      }

      p {
        line-height: 2.4;
      }
    }
  }

  &__content {
    font-size: 16px;
    color: $grey;
    width: 100%;

    @include tablet {
      width: 60%;
    }

    @include desktop-s {
      font-size: 18px;
    }

    a {
      color: $grey-dark;
    }

    p {
      margin-bottom: 15px;
      line-height: 1.4;
    }

    h2 {
      //margin-bottom: 10px;
      font-size: 16px;
      line-height: 2.4;
      color: $orange;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
      }
    }

    h3 {
      //margin-bottom: 10px;
      font-size: 16px;
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
      }
    }

    h4 {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 2.4;
      color: $black;
      font-variation-settings: "wght" 800, "wdth" 100, "opsz" 12;

      @include desktop-s {
        font-size: 18px;
      }
    }

    ul {
      margin-bottom: 15px;
      list-style: none;
    }

    li {
      margin-bottom: 15px;

      p {
        display: inline;
      }

      &::before {
        position: relative;
        display: inline;
        content: "- ";
      }
    }
  }
}
</style>
