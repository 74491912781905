export default {
  data() {
    return {
      dpr: window.devicePixelRatio,
      sfxDPR:
        window.devicePixelRatio > 1
          ? ',dpr-' + window.devicePixelRatio.toString()
          : ',dpr-1',
    };
  },

  mounted() {
    if (this.$store.state.isMobile) {
      //handle height
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  },

  methods: {
    getImageKitURL(
      img,
      cols,
      type,
      placeholder = false,
      position = false,
      positionType = 'blocks'
    ) {
      let max;
      if (positionType == 'blocks') {
        max = 3;
      } else if (positionType == 'images') {
        if (this.$store.state.isMobile) {
          max = 3;
        } else {
          max = 9;
        }
      }

      if (position !== false && position >= 0 && position < max) {
        if (placeholder) {
          // if asking for placeholder return original
          placeholder = false;
        } else {
          // if asking for original return null
          // when datasource is null it won't be passed as attribute
          return null;
        }
      }
      //console.log('image', img);
      if (!img && img.filename === undefined) {
        return false;
      }
      let imgURL = this.$store.state.baseimageUrl;
      imgURL += placeholder
        ? 'placeholder.png'
        : img.filename
        ? img.filename
        : img;
      //console.log(imgURL);
      // console.log(img, img.width, img.height, placeholder);
      imgURL += this.getImageKitWidth(cols, img.width, img.height, placeholder);
      imgURL += this.getImageKitQualitySuffix(type, placeholder);
      return imgURL;
    },

    getImageKitWidth(cols, maxWidth, maxHeight, placeholder = false) {
      if (placeholder) {
        let pW = 200;
        let pH = Math.round((parseInt(maxHeight) / parseInt(maxWidth)) * pW);
        //return '?tr=dpr-1,w-1,h-1:w-'+pW+',h-'+pH;
        return '?tr=dpr-1,w-' + pW + ',h-' + pH;
      } else {
        let w = window.innerWidth;
        if (w >= 576) {
          w = w / cols;
        }
        w = Math.ceil(w / 100) * 100;
        if (w * this.dpr > maxWidth) {
          return '?tr=w-' + maxWidth + ',dpr-1';
        } else {
          return '?tr=w-' + w + this.sfxDPR;
        }
      }
    },

    getImageDefaultURL(ratio) {
      let defaultUrl = this.$store.state.baseimageUrl;

      if (this.$store.state.isMobile) {
        return (defaultUrl += 'default-thumb-4-5.png');
      } else {
        switch (ratio) {
          case 'square':
            return (defaultUrl += 'default-thumb-1-1.png');
            break;
          case 'sixteen-by-seven':
            return (defaultUrl += 'default-thumb-16-7.png');
            break;
          case 'four-by-five':
            return (defaultUrl += 'default-thumb-4-5.png');
            break;

          default:
            return (defaultUrl += 'default-thumb-16-9.png');
            break;
        }
      }
    },

    // content is sometimes string, sometimes boolean.
    getImageKitQualitySuffix(content, placeholder) {
      let sfx = '';
      if (placeholder) {
        sfx += '&lazyPlaceholder';
      } else {
        if (content === 'lossless-image' || content === true) {
          sfx += ',lo-true';
        } else if (content === 'original-image') {
          sfx += ',orig-true';
        } else if (content === 'hq-image') {
          sfx += ',q-100';
        } else {
          sfx += '';
        }
        sfx += '&lazyOriginal';
      }
      return sfx;
    },
  },
};
