export default {
  seoContent(content, titleByDefault = '') {
    let seo_title = '';

    if (titleByDefault) {
      seo_title = `${titleByDefault} - `;
    }
    if (content && (content.seo_title || content.title)) {
      seo_title += content.seo_title ? content.seo_title : content.title;
    }

    if (seo_title) {
      document.title = seo_title;
      let elements = document.querySelectorAll(
        'meta[property="og:title"], meta[name="twitter:title"]'
      );
      for (const element of elements) {
        element.setAttribute('content', document.title);
      }
    }

    if (content?.seo_description) {
      let elements = document.querySelectorAll(
        'meta[name="description"], meta[property="og:description"], meta[name="twitter:description"]'
      );
      for (const element of elements) {
        element.setAttribute('content', content.seo_description);
      }
    }
    // console.log(content?.seo_opengraph_image?.filename)
    if (content?.seo_opengraph_image?.url) {
      //console.log(`https://cdn.binalogue.com/${content.seo_opengraph_image.filename}?tr=w-1200,h-630,f-jpeg`)
      let elements = document.querySelectorAll(
        'meta[property="og:image"], meta[property="og:image:secure_url"], meta[name="twitter:image"]'
      );
      for (const element of elements) {
        element.setAttribute(
          'content',
          `https://cdn.binalogue.com/${content.seo_opengraph_image.filename}?tr=w-1200,h-630,f-jpeg`
        );
      }
    }
  },
};
