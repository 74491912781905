<template>
  <div
    class="TheSearchIndex"
  >
    <div class="TheSearchIndex__container">
      <div class="TheSearchIndex__title">
        <!-- SEO TITLE -->
        <h1 class="hidden">
          What are you looking for?
        </h1>
        <TextSearch />
      </div>

      <main class="TheSearchIndex__input">
        <SearchInput />
      </main>
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   // value: {
  //   //   type: String,
  //   //   default: null
  //   // }
  // },
  // methods: {
  //   submit() {
  //     // if (this.value) {
  //     //   let params = encodeURIComponent(this.value).replace(/%20/g, "+");
  //     //   // this.value = "";
  //     //   // window.location.href = `/search/${params}`;
  //     //   console.log(this.value);
  //     //   this.$store.commit("TOGGLE_SEARCH_FULL", false);
  //     //   this.$inertia.get(
  //     //     this.route("search", this.value),
  //     //     {},
  //     //     { preserveState: true }
  //     //   );
  //     // }
  //   }
  // }
};
</script>

<style lang="scss">
.TheSearchIndex {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  z-index: -4;
  display: none;
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: $white;
  opacity: 0;
  // transform: translateY(-100vh);
  //background: pink;
  width: 100%;
  height: 100%;

  @include tablet {
    justify-content: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 110px 6vw 0 6vw;
    width: 100%;

    @include tablet {
      flex-direction: row;
      align-items: flex-end;
      padding: 15vh 5vw;
    }
  }

  &__title {
    margin-bottom: 5vh;
    line-height: 1.2;
    width: 83%;
    font-variation-settings: "wght" 800, "wdth" 100, "opsz" 30;

    @include tablet {
      margin-bottom: 0;
      width: 43%;
    }

    @include desktop-sm {
      margin-bottom: 0;
      width: 41%;
    }

    @include desktop-l {
      margin-bottom: 0;
      width: 39%;
    }

    @include desktop-xl {
      margin-bottom: 0;
      width: 38%;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__input {
    position: relative;
    display: flex;
    padding-bottom: 1.5vw;
    width: 100%;

    @include tablet {
      width: 50%;
    }
  }
}
</style>
