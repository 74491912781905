<template>
  <div
    v-if="content.project"
    class="indexProject HomeOneColumn"
  >
    <div
      class="indexProject__content"
    >
      <InertiaLink
        class="indexProject__image-container"
        :href="`/work/${content.project.slug}/`"
      >
        <div
          v-if="thumbnailRatio[1]"
          class="indexProject__background-video"
        >
          <backgroundVideo
            :vimeo_id="thumbnailRatio[1]"
            :vimeo_ratio="$store.state.isMobile ? 'four-by-five' : content.thumbnail_ratio"
          />
        </div>
        <img
          v-if="thumbnailRatio"
          :data-src="getImageKitURL(thumbnailRatio[0], 1, content.project.image_type,false,position)"
          :src="getImageKitURL(thumbnailRatio[0], 1, content.project.image_type,true,position)"
          :alt="content.project.title"
          class="indexProject__image"
          :class="{'opacity0':thumbnailRatio[1] }"
        >
        <img
          v-else
          :src="getImageDefaultURL(content.thumbnail_ratio)"
          class="indexProject__image"
          alt="default image"
        >
      </InertiaLink>
      <InertiaLink
        :href="`/work/${content.project.slug}/`"
        class="indexProject__text"
      >
        <h5 class="indexProject__client">
          {{ content.project.client_subtitle }}
        </h5>
        <h3 class="indexProject__title">
          {{ content.project.title }}
        </h3>
        <h4 class="indexProject__subtitle">
          {{ content.project.subtitle }}
        </h4>
      </InertiaLink>
    </div>
  </div>
</template>

<script>
import imageKit from "@/mixins/image-kit";
import { InertiaLink, usePage } from "@inertiajs/inertia-vue3";
//import { nextTick } from "vue";

export default {
  components: {
    InertiaLink,
  },
  mixins: [imageKit],

  props: {
    position:{
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    thumbnailRatio() {
      if(this.$store.state.isMobile) {
        return [this.content.project.thumbnail_4_5, this.content.project.thumb_4_5_video];
      } else {
        switch (this.content.thumbnail_ratio) {
          case "square":
            return [this.content.project.square_thumbnail, this.content.project.thumb_1_1_video];
            break;
          case "sixteen-by-seven":
            return [this.content.project.thumbnail_16_7, this.content.project.thumb_16_7_video];
            break;
          case "four-by-five":
            return [this.content.project.thumbnail_4_5, this.content.project.thumb_4_5_video];
            break;

          default:
            return [this.content.project.thumbnail_16_9, this.content.project.thumb_16_9_video];
            break;
        }
      }
    },
  },


  // mounted(){
  //   this.$nextTick(() => this.$store.commit("TOGGLE_BG_BACKGROUND_HOME", true));
  // },
};
</script>

<style lang="scss">
.HomeOneColumn {
  width: 100%;

  @include tablet {
    @include paddingMedium;
  }
}
</style>
