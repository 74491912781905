<template>
  <div class="TheFooterContact">
    <section class="TheFooterContact__main">
      <div class="TheFooterContact__contact">
        <div class="TheFooterContact__contact--newsletter">
          <FormNewsletter />
        </div>
      </div>
      <div class="TheFooterContact__rrss">
        <a
          v-for="(item, index) in globals.social_media.social_media"
          :key="`item${index}`"
          target="_blank"
          class="main-text-bold white rrss-link"
          :href="`${item.external_link}`"
        >
          {{ item.name }}
        </a>
      </div>
    </section>

    <div class="TheFooterContact__copyright">
      <InertiaLink
        :href="`${route('privacy-policy')}/`"
        class="main-text-link white"
      >
        Terms <span class="orange">&</span> Privacy Policy
      </InertiaLink>
      <p class="main-text">
        <span class="orange">©</span>{{ globals.footer.copyright }}
      </p>
    </div>
  </div>
</template>

<script>
import { InertiaLink } from "@inertiajs/inertia-vue3";

export default {
  components: {
    InertiaLink,
  },

  computed: {
    globals() {
      return this.$page.props.globals;
    },
  },
};
</script>

<style lang="scss" scoped>
.TheFooterContact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100vw;
  min-height: 50vh;
  color: $white;
  background: $black;
  width: 100%;

  &__main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto 0;
    width: 100%;

    @include paddingMobile;

    @include tablet {
      @include paddingBig;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    // background: green;
    width: 100%;

    @include tablet {
      width: 70%;
      margin-top: 0;
    }

    @include desktop-s {
      width: 50%;
    }

    svg {
      max-width: 550px;
      width: 100%;
      height: auto;

      @include tablet {
        width: 80%;
      }
    }

    &--newsletter {
      max-width: 550px;
      width: 100%;

      @include tablet {
        width: 80%;
      }
    }
  }

  &__locations {
    width: 100%;
    // background: red;

    @include tablet {
      width: 65%;
    }

    @include desktop-s {
       width: 35%;
    }

    * {
      line-height: 1.6;
    }

    &--item {
      &:first-child {
        margin-bottom: 50px;
      }
    }
  }

  &__rrss {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    // background: blue;
    width: 100%;

    @include tablet {
      padding-left: 2vw;
      margin: 0;
      width: 35%;
    }

    @include desktop-s {
       width: 15%;
    }

    * {
      display: block;
      margin-bottom: 10px;
      line-height: 1.6;
    }
  }




  &__copyright {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vh;
    width: 100%;

    @include paddingMobile;

    @include tablet {
      @include paddingMedium;
    }
  }
}
</style>
