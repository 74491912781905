<template>
  <div class="Error">
    <h1>{{ title }}</h1>
    <h2>{{ description }}</h2>
  </div>
</template>

<script>
/* Helpers */
import seo from "@/helpers/seo";

export default {
  props: {
    status: Number,
  },

  computed: {
    title() {
      return {
        503: "503: Service Unavailable",
        500: "500: Server Error",
        // 404: "404: Page Not Found",
        // 403: "403: Forbidden",
      }[this.status];
    },
    description() {
      return {
        503: "Sorry, we are doing some maintenance. Please check back soon.",
        500: "Whoops, something went wrong on our servers.",
        // 404: "Sorry, the page you are looking for could not be found.",
        // 403: "Sorry, you are forbidden from accessing this page.",
      }[this.status];
    },
  },

  created() {
    seo.seoContent(null, this.title);
  },
};
</script>

<style lang="scss" scoped>
.Error {
  display: flex;
  flex-direction: column;
  // background: pink;
  width: 100%;
}
</style>
