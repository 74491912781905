<template>
  <section class="Publications scroll-about-publications">
    <h3 class="section-title">
      Publications<span class="orange">.</span>
    </h3>

    <div
      v-if="$store.state.isMobile"
      class="Publications__grid"
    >
      <div class="Publications__column">
        <div
          v-for="(publication, index) in publications"
          :key="`publication01${index}`"
          class="Publications__item"
        >
          <InertiaLink
            v-if="publication.about_link"
            :href="customLink(publication.about_link.collection, publication.about_link.slug)"
          >
            <div class="Publications__image-container">
              <img
                v-if="publication.mobile_thumb"
                class="Publications__item--image"
                :src="$store.state.baseimageUrl + publication.mobile_thumb.filename"
                :alt="publication.about_title"
              >
              <img
                v-else
                class="Publications__item--image"
                :src="$store.state.baseimageUrl + publication.thumbnail.filename"
                :alt="publication.about_title"
              >
            </div>

            <div class="Publications__mobileText">
              <p class="medium-text-bold black">
                {{ publication.about_title }}
              </p>

              <p class="regular-text black">
                {{ publication.about_description }}
              </p>
              <p class="regular-text grey">
                {{ publication.about_label }}
              </p>
              <p
                v-if="publication.about_category"
                class="regular-text orange"
              >
                {{ publication.about_category }}
              </p>
            </div>
          </InertiaLink>

          <InertiaLink v-else>
            <div class="Publications__image-container">
              <img
                class="Publications__item--image"
                :src="$store.state.baseimageUrl + publication.thumbnail.filename"
                :alt="publication.about_title"
              >
            </div>

            <div class="Publications__mobileText">
              <p class="medium-text-bold black">
                {{ publication.about_title }}
              </p>

              <p class="regular-text black">
                {{ publication.about_description }}
              </p>
              <p class="regular-text grey">
                {{ publication.about_label }}
              </p>
              <p
                v-if="publication.about_category"
                class="regular-text orange"
              >
                {{ publication.about_category }}
              </p>
            </div>
          </InertiaLink>
        </div>
      </div>
    </div>

    <div
      v-else
      class="Publications__grid"
    >
      <div class="Publications__column">
        <div
          v-for="(publication, index) in publications"
          v-show="index % 3 === 0"
          :key="`publication01${index}`"
          class="Publications__item"
        >
          <InertiaLink
            v-if="publication.about_link"
            :href="customLink(publication.about_link.collection, publication.about_link.slug)"
          >
            <div class="Publications__image-container">
              <img
                class="Publications__item--image"
                :data-src="getImageKitURL(publication.thumbnail, 1, 'lossless-image')"
                :src="defaultImg"
                :alt="publication.about_title"
              >
            </div>

            <p class="medium-text-bold black">
              {{ publication.about_title }}
            </p>

            <p class="regular-text black">
              {{ publication.about_description }}
            </p>
            <p class="regular-text grey">
              {{ publication.about_label }}
            </p>
            <p
              v-if="publication.about_category"
              class="regular-text orange"
            >
              {{ publication.about_category }}
            </p>
          </InertiaLink>

          <InertiaLink v-else>
            <div class="Publications__image-container">
              <img
                class="Publications__item--image"
                :data-src="getImageKitURL(publication.thumbnail, 2, 'lossless-image')"
                :src="defaultImg"
                :alt="publication.about_title"
              >
            </div>

            <p class="medium-text-bold black">
              {{ publication.about_title }}
            </p>

            <p class="regular-text black">
              {{ publication.about_description }}
            </p>
            <p class="regular-text grey">
              {{ publication.about_label }}
            </p>
            <p
              v-if="publication.about_category"
              class="regular-text orange"
            >
              {{ publication.about_category }}
            </p>
          </InertiaLink>
        </div>
      </div>



      <div class="Publications__column">
        <div
          v-for="(publication, index) in publications"
          v-show="(index + 1) % 3 !== 0 && index % 3 !== 0"
          :key="`publication01${index}`"
          class="Publications__item"
        >
          <InertiaLink
            v-if="publication.about_link"
            :href="customLink(publication.about_link.collection, publication.about_link.slug)"
          >
            <div class="Publications__image-container">
              <img
                class="Publications__item--image"
                :data-src="getImageKitURL(publication.thumbnail, 3, 'lossless-image')"
                :src="defaultImg"
                :alt="publication.about_title"
              >
            </div>

            <p class="medium-text-bold black">
              {{ publication.about_title }}
            </p>

            <p class="regular-text black">
              {{ publication.about_description }}
            </p>
            <p class="regular-text grey">
              {{ publication.about_label }}
            </p>
            <p
              v-if="publication.about_category"
              class="regular-text orange"
            >
              {{ publication.about_category }}
            </p>
          </InertiaLink>

          <InertiaLink v-else>
            <div class="Publications__image-container">
              <img
                class="Publications__item--image"
                :src="$store.state.baseimageUrl + publication.thumbnail.filename"
                :alt="publication.about_title"
              >
            </div>

            <p class="medium-text-bold black">
              {{ publication.about_title }}
            </p>

            <p class="regular-text black">
              {{ publication.about_description }}
            </p>
            <p class="regular-text grey">
              {{ publication.about_label }}
            </p>
            <p
              v-if="publication.about_category"
              class="regular-text orange"
            >
              {{ publication.about_category }}
            </p>
          </InertiaLink>
        </div>
      </div>


      <div class="Publications__column">
        <div
          v-for="(publication, index) in publications"
          v-show="(index + 1) % 3 === 0"
          :key="`publication01${index}`"
          class="Publications__item"
        >
          <InertiaLink
            v-if="publication.about_link"
            :href="customLink(publication.about_link.collection, publication.about_link.slug)"
          >
            <div class="Publications__image-container">
              <img
                class="Publications__item--image"
                :src="getImageKitURL(publication.thumbnail, 3, 'lossless-image')"
                :alt="publication.about_title"
              >
            </div>

            <p class="medium-text-bold black">
              {{ publication.about_title }}
            </p>

            <p class="regular-text black">
              {{ publication.about_description }}
            </p>
            <p class="regular-text grey">
              {{ publication.about_label }}
            </p>
            <p
              v-if="publication.about_category"
              class="regular-text orange"
            >
              {{ publication.about_category }}
            </p>
          </InertiaLink>

          <InertiaLink v-else>
            <div class="Publications__image-container">
              <img
                class="Publications__item--image"
                :src="$store.state.baseimageUrl + publication.thumbnail.filename"
                :alt="publication.about_title"
              >
            </div>

            <p class="medium-text-bold black">
              {{ publication.about_title }}
            </p>

            <p class="regular-text black">
              {{ publication.about_description }}
            </p>
            <p class="regular-text grey">
              {{ publication.about_label }}
            </p>
            <p
              v-if="publication.about_category"
              class="regular-text orange"
            >
              {{ publication.about_category }}
            </p>
          </InertiaLink>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import imageKit from "@/mixins/image-kit";
import { InertiaLink } from "@inertiajs/inertia-vue3";

export default {
  components: {
    InertiaLink
  },

  mixins: [imageKit],

  props: {
    publications: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      defaultImg:
        "https://cdn.binalogue.com/placeholder.png?tr=dpr-1,w-200,h-NaN&lazyPlaceholder"
    };
  },
  methods: {
    customLink(collection, slug) {
      if (collection === "news") {
        return `/press/${slug}/`;
      } else {
        return `/work/${slug}/`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.Publications {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2vh;

  @include tablet {
    @include paddingPageTop;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @include tablet {
      @include paddingBig;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include tablet {
      width: 31%;
    }
  }

  &__mobileText {
    padding: 0 6vw;

    @include tablet {
      padding: 0 5vw;
    }
  }

  &__item {
    width: 100%;
    @include marginBottom;

    &--link {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
    }

    &--image {
      width: 100%;
      height: auto;

      @include desktop-s {
        transform: scale(1);
        transform-origin: center;
        @include transition;
      }

      &:hover {
        @include desktop-s {
          transform: scale(1.03);
        }
      }
    }
  }

  .Publications__image-container {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
  }
}
</style>
