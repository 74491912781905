<template>
  <h3
    :id="`title-${dynamicNumber}`"
    class="main-text-bold ProjectSectionFooter"
    :class="[content.mobile_settings ]"
    :style="`color : ${customDescColor};background : ${customColor}; ${customGradient} `"
  >
    {{ content.section_title }}<span
      class="orange"
      :class="{hide: content.remove_dot}"
    >.</span> <br>
    <span
      v-if="content.section_subtitle"
      class="regular-text grey ProjectSectionFooter__subtitle"
    >{{ content.section_subtitle }}</span>
  </h3>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";

export default {
  mixins: [marked],
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    isProject: {
      type: Boolean
    }
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if (this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customDescColor() {
      if (this.content.color) {
        return this.content.color;
      } else {
        return "#000";
      }
    }
  },
};
</script>

<style lang="scss">
.ProjectSectionFooter {
  max-width: 100vw;
  padding-top: 5vw;
  padding-bottom: 13vw;
  overflow-x: hidden;
  width: 100%;
  @include paddingMobile;

  .hide {
    display: none;
  }

  strong {
    color: $orange;
  }

  @include tablet {
    padding-top: 1.5vw;
    padding-bottom: 7.7vw;
    @include paddingBig;
  }

  &.hide-mobile {
    display: none;

    @include tablet {
      display: block;
    }
  }

  @include desktop-l {
    font-size: 30px;
  }

  @include desktop-xl {
    font-size: 34px;
  }


  &__subtitle {
    @include desktop-l {
      font-size: 30px;
    }

    @include desktop-xl {
      font-size: 34px;
    }
  }
}
</style>
