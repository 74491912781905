<template>
  <div
    class="TheModalContactForm"
  >
    <div class="TheModalContactForm__dismiss">
      <IconClose @click="closeModal" />
    </div>
    <FormContact />
  </div>
</template>

<script>
export default {
  methods:{
    closeModal(){
      this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", false);
      this.$store.commit('TOGGLE_IS_MODAL_CONTACT_FORM', false)
    },
  },
};
</script>

<style lang="scss" scoped>
.TheModalContactForm {
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 15vh;
  overflow-y: scroll;
  background: $black;
  width: 100vw;

  @include tablet {
    padding-top: 25vh;
    overflow-y: hidden;
  }

  &__dismiss {
    position: absolute;
    top: 10vh;
    right: 5vw;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    width: 100%;
    height: 30px;
    //background: red;

    svg {
      cursor: pointer;
      fill: $white;
      width: 30px;
      height: auto;
    }
  }
}
</style>
