<template>
  <div id="job-wrapper">
    <div
      id="job-content"
      class="SingleJob job-scroller"
    >
      <section class="SingleJob__main">
        <div class="Contact__jobs--lang regular-text grey">
          <button
            class="regular-text"
            :class="{ orange: langEN }"
            @click="toogleLang"
          >
            en
          </button>
          /
          <button
            class="regular-text"
            :class="{ orange: !langEN }"
            @click="toogleLang"
          >
            es
          </button>
        </div>

        <div class="job">
          <div class="job__header js-english">
            <h1
              class="job__left title"
            >
              {{ content.title }}<span class="orange">.</span>
            </h1>
          </div>
          <div class="job__header js-spanish">
            <h1
              class="job__left title"
            >
              {{ content.spanish_title }}<span class="orange">.</span>
            </h1>
          </div>
          <div class="job__left">
            <div class="js-english">
              <h4
                v-if="content.job_reference"
                class="main-text-bold orange"
              >
                Reference {{ content.job_reference }}
              </h4>
              <p
                v-if="content.publication_date"
                class="regular-text grey"
              >
                {{ formatDate(content.publication_date) }}
              </p>
              <h4
                v-if="content.english_location"
                class="main-text-bold"
              >
                Location
              </h4>
              <p
                v-if="content.english_location"
                class="regular-text grey"
              >
                {{ content.english_location }}
              </p>

              <h4
                v-if="content.english_type"
                class="main-text-bold"
              >
                Type
              </h4>
              <p
                v-if="content.english_type"
                class="regular-text grey"
              >
                {{ content.english_type }}
              </p>
              <h4
                v-if="content.english_duration"
                class="main-text-bold"
              >
                Duration
              </h4>
              <p
                v-if="content.english_duration"
                class="regular-text grey"
              >
                {{ content.english_duration }}
              </p>
              <h4
                v-if="content.english_workday"
                class="main-text-bold"
              >
                Workday
              </h4>
              <p
                v-if="content.english_workday"
                class="regular-text grey"
              >
                {{ content.english_workday }}
              </p>

              <h4
                v-if="content.english_salary"
                class="main-text-bold"
              >
                Salary
              </h4>
              <p
                v-if="content.english_salary"
                class="regular-text grey"
              >
                {{ content.english_salary }}
              </p>

              <h4
                v-if="content.english_incorporation"
                class="main-text-bold"
              >
                Incorporation
              </h4>
              <p
                v-if="content.english_incorporation"
                class="regular-text grey"
              >
                {{ content.english_incorporation }}
              </p>

              <h4
                class="main-text-bold"
              >
                Apply
              </h4>
              <a
                :href="customMailTo"
                target="_blank"
                class="regular-text orange"
              >
                jobs@binalogue.com
              </a>
            </div>

            <div class="js-spanish">
              <h4
                v-if="content.job_reference"
                class="main-text-bold orange"
              >
                Referencia {{ content.job_reference }}
              </h4>
              <p
                v-if="content.publication_date"
                class="regular-text grey"
              >
                {{ formatDate(content.publication_date) }}
              </p>
              <h4
                v-if="content.spanish_location"
                class="main-text-bold"
              >
                Ubicación
              </h4>
              <p
                v-if="content.spanish_location"
                class="regular-text grey"
              >
                {{ content.spanish_location }}
              </p>

              <h4
                v-if="content.spanish_type"
                class="main-text-bold"
              >
                Tipo
              </h4>
              <p
                v-if="content.spanish_type"
                class="regular-text grey"
              >
                {{ content.spanish_type }}
              </p>
              <h4
                v-if="content.spanish_duration"
                class="main-text-bold"
              >
                Duración
              </h4>
              <p
                v-if="content.spanish_duration"
                class="regular-text grey"
              >
                {{ content.spanish_duration }}
              </p>
              <h4
                v-if="content.spanish_workday"
                class="main-text-bold"
              >
                Horario
              </h4>
              <p
                v-if="content.spanish_workday"
                class="regular-text grey"
              >
                {{ content.spanish_workday }}
              </p>

              <h4
                v-if="content.spanish_salary"
                class="main-text-bold"
              >
                Salario
              </h4>
              <p
                v-if="content.spanish_salary"
                class="regular-text grey"
              >
                {{ content.spanish_salary }}
              </p>

              <h4
                v-if="content.spanish_incorporation"
                class="main-text-bold"
              >
                Incorporación
              </h4>
              <p
                v-if="content.spanish_incorporation"
                class="regular-text grey"
              >
                {{ content.spanish_incorporation }}
              </p>

              <h4
                class="main-text-bold"
              >
                Aplicar
              </h4>
              <a
                :href="customMailTo"
                target="_blank"
                class="regular-text orange"
              >
                jobs@binalogue.com
              </a>
            </div>
          </div>

          <div class="job__right">
            <div class=" job__right--container js-english">
              <div
                v-if="content.english_content"
                v-html="markdownToHtml(content.english_content)"
              />
              <div class="mail-container">
                <a
                  :href="customMailTo"
                  target="_blank"
                  class="main-text-bold"
                >
                  Apply: <span class="regular-text orange">jobs@binalogue.com</span>
                </a>
              </div>
            </div>

            <div class="job__right--container js-spanish">
              <div
                v-if="content.spanish_content"
                v-html="markdownToHtml(content.spanish_content)"
              />
              <div class="mail-container">
                <a
                  :href="customMailTo"
                  target="_blank"
                  class="main-text-bold"
                >
                  Aplicar: <span class="regular-text orange">jobs@binalogue.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <span id="job-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import moment from "moment";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import page from "@/mixins/page";
import marked from "@/mixins/marked";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  mixins: [page, marked, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      langEN: true,
      // scroll animations
      bgScrollAnimation: null,
    };
  },

  computed: {

    customMailTo(){
      let customMailUrl= "mailto:jobs@binalogue.com?subject=";
      let encodeUrl = encodeURIComponent(this.content.job_reference + " - " + this.content.title + " - Binasite");
      // return customMailUrl + encodeUrl ;
      return customMailUrl + encodeUrl;
    },
  },

  created() {
    seo.seoContent(this.content);
  },

  mounted() {
    this.imagesToPreload = document.querySelectorAll("img");
    this.getBackgroundImagesUrl();
    this.loadImages();
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.toggleMenuColor("black", 0,0);
      this.setScroller('#job-wrapper', '#job-content' );

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to('.showBgHeader', {
        scrollTrigger: {
          trigger: '#job-content',
          markers: false,
          start: '50px top',
          end: 'top bottom',
          toggleActions: 'play none reverse none',
        },
        height: '100%',
        duration: 0.2,
        ease: 'Power2.out',
      });
    },

    formatDate(date) {
      return moment(date).format("MMMM DD, YYYY");
    },


    toogleLang(){
      this.langEN = !this.langEN;

      if(this.langEN) {
        this.$gsap.to('.js-spanish', { opacity:0, duration: 0.3})
        this.$gsap.to('.js-english', { display: 'block', duration: 0, delay:0.3});
        this.$gsap.to('.js-spanish', { display:'none', duration: 0, delay:0.3});
        this.$gsap.to('.js-english', { opacity:1, duration: 0.3, delay:0.4})
      } else {
        this.$gsap.to('.js-english', { opacity:0, duration: 0.3})
        this.$gsap.to('.js-spanish', { display: 'block', duration: 0, delay:0.3});
        this.$gsap.to('.js-english', { display:'none', duration: 0, delay:0.3});
        this.$gsap.to('.js-spanish', { opacity:1, duration: 0.3, delay:0.4})
      }

    },
  }
};
</script>

<style lang="scss" scoped>
.SingleJob {
  display: flex;
  flex-direction: column;
  // background: pink;
  width: 100%;
  @include headerMargin;

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include paddingBig;
    @include paddingPageTop;
    @include paddingBottom;
  }
}
</style>
