import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex'
import { useCookies } from 'vue3-cookies';
import { useState, useGtag } from "vue-gtag-next";

const allowedCookies = ref();

export default function allowCookies() {
  const store = useStore();
  const { cookies } = useCookies();
  const { isEnabled } = useState();

  store.commit('TOGGLE_THE_COOKIE_BANNER', !cookies.isKey('allow_cookies'));

  isEnabled.value = (cookies.get('allow_cookies') == "true");
  allowedCookies.value = cookies.get('allow_cookies');

  const enable = () => {
    isEnabled.value = true;
  }

  watch(allowedCookies, () => {
    if (allowedCookies.value !== null) {
      cookies.set('allow_cookies', allowedCookies.value);
      store.commit('TOGGLE_THE_COOKIE_BANNER', false);

      if (allowedCookies.value === true) {
        enable();
      }

    } else {
      store.commit('TOGGLE_THE_COOKIE_BANNER', true);
    }
  });

  const showBanner = computed(() => {
    return allowedCookies.value === null;
  });

  const acceptCookies = () => (allowedCookies.value = true);
  const deniedCookies = () => (allowedCookies.value = false);

  return {
    allowedCookies,
    showBanner,
    acceptCookies,
    deniedCookies,
  };
}
