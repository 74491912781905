<template>
  <div
    :id="`news-metadata${dynamicNumber}`"
    class="metadata news-metadata-scroll"
    :style="`background : ${customColor}`"
  >
    <div class="metadata__left--news">
      <h3 class="main-text grey">
        <span
          v-for="category in customCategory(content.news_filters)"
          :key="category"
          class="orange"
        >
          {{ category }}
        </span>.
        {{ formatDate(content.publication_date) }}
      </h3>
      <h1 class="indexProject__title">
        {{ content.title }}<span class="orange">.</span>
      </h1>
    </div>

    <div class="metadata__right--news">
      <div
        v-if="content.blurb"
        class="metadata__description main-text"
        v-html="markdownToHtml(content.blurb)"
      />

      <div
        v-for="(item, index) in content.metadata"
        :key="`item${index}`"
        class="metadata__right--item"
      >
        <!-- {{ item.title }} -->
        <p
          class="main-text-bold"
          v-html="customTitle(item.title)"
        />
        <h4
          v-if="item.title === 'role'"
          class="regular-text grey"
        >
          {{ item.role }}
        </h4>

        <div v-if="item.title === 'client' || 'agency' || 'production' || 'Agency'">
          <h4>
            <a
              v-for="(contact, idx) in item.contacts"
              :key="`contact${idx}`"
              :href="contact.external_link"
              target="_blank"
              class="regular-text grey"
            >
              <span v-if="idx>0 && idx !== item.contacts.length -1">, </span> <span v-if="idx === item.contacts.length -1 && idx !==0">& </span>{{ contact.title }}
            </a>
          </h4>
        </div>

        <div
          v-if="item.title === 'credit'"
          class="regular-text grey credit"
          v-html="markdownToHtml(item.credits)"
        />

        <div
          v-if="item.title === 'featured_projects' || item.title === 'Featured Projects'"
          class="regular-text grey credit"
        >
          <h4>
            <InertiaLink
              v-for="(featured, idx) in item.featured_projects"
              :key="`featured${idx}`"
              :href="`/work/${featured.slug}/`"
              target="_blank"
              class="regular-text grey block"
            >
              {{ featured.title }} - {{ featured.subtitle }}
            </InertiaLink>
          </h4>
        </div>

        <div
          v-if="item.title === 'Mentions'"
          class="regular-text grey"
        >
          <h4>
            <a
              v-for="(mention, idx) in item.mentions"
              :key="`mention${idx}`"
              :href="mention.external_link"
              target="_blank"
              class="regular-text grey"
            >
              {{ mention.title }}
            </a>
          </h4>
        </div>

        <!-- <div
          v-if="item.custom_content"
          class="regular-text grey credit"
          v-html="markdownToHtml(item.custom_content)"
        /> -->

        <div v-if="item.custom_content !== 'default'">
          <h4
            class="regular-text grey credit"
            v-html="markdownToHtml(item.custom_content)"
          />
        </div>
      </div>

      <div
        v-if="content.awards"
        class="metadata__right--item"
      >
        <p class="main-text-bold">
          Awards
        </p>
        <h4
          v-for="(award, i) in content.awards"
          :key="`award${i}`"
          class="regular-text grey award"
        >
          <strong
            v-if="award.single_title"
            :class="{firstAward : i ===0}"
          >
            {{ award.single_title }}
          </strong>
          <span
            v-if="award.single_description"
          >
            {{ award.single_description }} <br>
          </span>
          <a
            :href="award.single_link"
            target="_blank"
            class="link"
          >
            {{ award.single_label }}
          </a>
        </h4>
      </div>

      <div
        v-if="content.publications"
        class="metadata__right--item"
      >
        <p class="main-text-bold">
          Publications
        </p>
        <h4
          v-for="(publication, idx) in content.publications"
          :key="`award${idx}`"
          class="regular-text grey publication"
        >
          <strong
            v-if="publication.single_title"
            :class="{firstAward : idx ===0}"
          >
            {{ publication.single_title }}
          </strong>
          <span
            v-if="publication.single_description"
          >
            {{ publication.single_description }} <br>
          </span>
          <a
            :href="publication.single_link"
            target="_blank"
            class="link"
          >
            {{ publication.single_label }}
          </a>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
/* Vendor */
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Mixins */
import marked from "@/mixins/marked";
import moment from 'moment'

export default {
  components: {
    InertiaLink,
  },

  mixins: [marked],
  props: {
    content: {
      type: Object,
      default: () => {},
    },

    contentMetadata: {
      type: Object,
      default: () => {},
    },
  },

  computed: {

    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.contentMetadata.bg_color) {
        return this.contentMetadata.bg_color;
      } else {
        return "transparent";
      }
    },

  },

  mounted(){
    Array.from(document.links)
      .filter(link => link.hostname != window.location.hostname)
      .forEach(link => link.target = '_blank');
  },

  methods: {
    formatDate(date) {
      return moment(date).format('MMMM DD, YYYY');
    },

    customTitle(title) {
      switch (title) {
        case 'agency':
          return "Agency";
          break;
        case 'artist':
          return "Artist";
          break;
        case 'client':
          return "Client";
          break;
        case 'credits':
          return "Credits";
          break;
        case 'featured_projects':
          return "Featured Projects";
          break;
        case 'mentions':
          return "Mentions";
          break;
        case 'production':
          return "Production Company";
          break;
        case 'role':
          return "Our Role";
          break;
        default:
          return title;
      }
    },

    customCategory(categories){
      let returnCats = [];
      for (let i = 0; i < categories.length; i++) {
        const cat= categories[i];
        switch (cat) {
          case 'awards':
            returnCats.push('Awards')
            break;
          case 'news':
            returnCats.push('News')
            break;
          case 'project-feature':
            returnCats.push('Project Feature')
            break;
          case 'talk':
            returnCats.push('Talk')
            break;
          case 'interview':
            returnCats.push('Interview')
            break;
          default:
            returnCats.push(cat)
        }
      }

      return returnCats;
    },
  },


};
</script>

<style lang="scss">
.metadata {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
   max-width: 100vw;
    overflow-x: hidden;
  // @include paddingTop;
  @include paddingBig;
  @include paddingBottom;

  &__main {
    width: 100%;
    @include paddingBottom;
  }

  &__left {
    width: 53%;

    &--news {
      width: 100%;
      @include paddingMobileBottom;


      @include tablet-m {
        width: 35%;
      }
    }
  }

  &__description {
    p {
      margin-bottom: 15px;
    }

    strong {
      font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
    }
  }

  &__right {
    width: 35%;

    &--news {
      width: 100%;

      @include tablet-m {
        width: 55%;
        margin-top: 50px;
      }
    }

    &--item {
      margin-bottom: 15px;
    }
  }

  .credit {
      strong {
        font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
        display: block;
      }

      // p {
      //   margin-bottom: 20px;
      // }

      a {
        color: $grey;
        @include transition;
        &:hover {
          color: $orange;
        }
      }
    }

  .regular-text.grey.award  strong {
    font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
    display: block;
    margin-top: 15px;

    &.firstAward {
      margin-top: 0;
    }
  }

  .regular-text.grey.publication  strong {
    font-variation-settings: "wght" 700, "wdth" 100, "opsz" 30;
    display: block;
    margin-top: 15px;

    &.firstAward {
      margin-top: 0;
    }
  }
}
</style>
