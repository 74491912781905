<template>
  <section class="Awards scroll-about-awards">
    <h3 class="section-title white">
      Awards<span class="orange">.</span>
    </h3>

    <div
      v-if="$store.state.isMobile"
      class="Awards__grid"
    >
      <div class="Awards__column">
        <div
          v-for="(award, index) in awards"
          :key="`award${index}`"
          class="Awards__item"
        >
          <img
            class="Awards__item--image"
            :src="$store.state.baseimageUrl + award.thumbnail"
            :alt="award.thumbnail.alt"
          >

          <div
            v-for="(related,i) in award.related_awards"
            :key="`related${i}`"
            class="Awards__item--related white"
          >
            <InertiaLink
              v-if="related.about_link_article"
              class="Awards__item--related-link"
              :href="`/press/${related.about_link_article.slug}/`"
            >
              <p
                target="_blank"
                class="medium-text-bold white"
              >
                {{ related.about_title }}
              </p>
              <p class="regular-text grey">
                {{ related.about_description }}
              </p>
            </InertiaLink>
            <div
              v-else
              class="Awards__item--related-div"
            >
              <p
                target="_blank"
                class="medium-text-bold white"
              >
                {{ related.about_title }}
              </p>
              <p class="regular-text grey">
                {{ related.about_description }}
              </p>
            </div>

            <p
              v-if="related.about_link"
              class="regular-text grey"
            >
              <InertiaLink
                v-if="related.about_link.collection === 'news'"
                :href="`/press/${related.about_link.slug}/`"
                class="grey regular-text-link "
              >
                {{ related.about_label }}
              </InertiaLink>

              <InertiaLink
                v-else
                :href="`/work/${related.about_link.slug}/`"
                class="grey regular-text-link "
              >
                {{ related.about_label }}
              </InertiaLink>
            </p>
          </div>
        </div>
      </div>
    </div>


    <div
      v-else
      class="Awards__grid"
    >
      <div class="Awards__column">
        <div
          v-for="(award, index) in awards"
          v-show="index % 3 === 0"
          :key="`award${index}`"
          class="Awards__item"
        >
          <img
            class="Awards__item--image"
            :src="$store.state.baseimageUrl + award.thumbnail"
            :alt="award.thumbnail.alt"
          >

          <div
            v-for="(related,i) in award.related_awards"
            :key="`related${i}`"
            class="Awards__item--related white"
          >
            <InertiaLink
              v-if="related.about_link_article"
              class="Awards__item--related-link"
              :href="`/press/${related.about_link_article.slug}/`"
            >
              <p
                target="_blank"
                class="medium-text-bold white"
              >
                {{ related.about_title }}
              </p>
              <p class="regular-text grey">
                {{ related.about_description }}
              </p>
            </InertiaLink>
            <div
              v-else
              class="Awards__item--related-div"
            >
              <p
                target="_blank"
                class="medium-text-bold white"
              >
                {{ related.about_title }}
              </p>
              <p class="regular-text grey">
                {{ related.about_description }}
              </p>
            </div>

            <p
              v-if="related.about_link"
              class="regular-text grey"
            >
              <InertiaLink
                v-if="related.about_link.collection === 'news'"
                :href="`/press/${related.about_link.slug}/`"
                class="regular-text-link grey"
              >
                {{ related.about_label }}
              </InertiaLink>

              <InertiaLink
                v-else
                :href="`/work/${related.about_link.slug}/`"
                class="regular-text-link grey"
              >
                {{ related.about_label }}
              </InertiaLink>
            </p>
          </div>
        </div>
      </div>
      <div class="Awards__column">
        <div
          v-for="(award, index) in awards"
          v-show="(index + 1) % 3 !== 0 && index % 3 !== 0"
          :key="`award${index}`"
          class="Awards__item"
        >
          <img
            class="Awards__item--image"
            :src="$store.state.baseimageUrl + award.thumbnail"
            :alt="award.thumbnail.alt"
          >

          <div
            v-for="(related,i) in award.related_awards"
            :key="`related${i}`"
            class="Awards__item--related"
          >
            <InertiaLink
              v-if="related.about_link_article"
              class="Awards__item--related-link"
              :href="`/press/${related.about_link_article.slug}/`"
            >
              <p
                target="_blank"
                class="medium-text-bold white"
              >
                {{ related.about_title }}
              </p>
              <p class="regular-text grey">
                {{ related.about_description }}
              </p>
            </InertiaLink>
            <div
              v-else
              class="Awards__item--related-div"
            >
              <p
                target="_blank"
                class="medium-text-bold white"
              >
                {{ related.about_title }}
              </p>
              <p class="regular-text grey">
                {{ related.about_description }}
              </p>
            </div>

            <p
              v-if="related.about_link"
              class="regular-text grey"
            >
              <InertiaLink
                v-if="related.about_link.collection === 'news'"
                :href="`/press/${related.about_link.slug}/`"
                class="regular-text-link grey"
              >
                {{ related.about_label }}
              </InertiaLink>

              <InertiaLink
                v-else
                :href="`/work/${related.about_link.slug}/`"
                class="regular-text-link grey"
              >
                {{ related.about_label }}
              </InertiaLink>
            </p>
          </div>
        </div>
      </div>
      <div class="Awards__column">
        <div
          v-for="(award, index) in awards"
          v-show="(index + 1) % 3 === 0"
          :key="`award${index}`"
          class="Awards__item"
        >
          <img
            class="Awards__item--image"
            :src="$store.state.baseimageUrl + award.thumbnail"
            :alt="award.thumbnail.alt"
          >

          <div
            v-for="(related,i) in award.related_awards"
            :key="`related${i}`"
            class="Awards__item--related"
          >
            <InertiaLink
              v-if="related.about_link_article"
              class="Awards__item--related-link"
              :href="`/press/${related.about_link_article.slug}/`"
            >
              <p
                target="_blank"
                class="medium-text-bold white"
              >
                {{ related.about_title }}
              </p>
              <p class="regular-text grey">
                {{ related.about_description }}
              </p>
            </InertiaLink>
            <div
              v-else
              class="Awards__item--related-div"
            >
              <p
                target="_blank"
                class="medium-text-bold white"
              >
                {{ related.about_title }}
              </p>
              <p class="regular-text grey">
                {{ related.about_description }}
              </p>
            </div>

            <p
              v-if="related.about_link"
              class="regular-text grey"
            >
              <InertiaLink
                v-if="related.about_link.collection === 'news'"
                :href="`/press/${related.about_link.slug}/`"
                class="regular-text-link grey"
              >
                {{ related.about_label }}
              </InertiaLink>

              <InertiaLink
                v-else
                :href="`/work/${related.about_link.slug}/`"
                class="regular-text-link grey"
              >
                {{ related.about_label }}
              </InertiaLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { InertiaLink } from "@inertiajs/inertia-vue3";
import imageKit from "@/mixins/image-kit";

export default {
  components: {
    InertiaLink
  },

  mixins: [imageKit],

  props: {
    awards: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.Awards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2vh;
  background: $black;
  width: 100%;

  @include tablet {
    @include paddingPageTop;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    @include paddingMobile;

    @include tablet {
      @include paddingBig;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include tablet {
      width: 30%;
    }
  }

  &__item {
    width: 100%;
    @include marginBottom;

    &--image {
      margin-bottom: 5px;
      width: 80%;
      height: auto;

      @include tablet {
        margin-bottom: 25px;
        width: 90%;
      }
    }

    &--related {
      margin-bottom: 10px;
    }
  }

  .Awards__item--related-link:hover {
    .regular-text {
      @include transition;

      color: $orange;
    }
  }
}
</style>
