<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 559 220"
  >
    <g id="txt">
      <g class="step01-a">
        <path
          d="M.3,82.34V.5H18.17V82.34ZM10.41,48.2V32.69H61.19V48.2ZM52.74,82.34V.5H70.61V82.34Z"
          fill="#fff"
        />
        <path
          d="M108.58,83.72A33.72,33.72,0,0,1,92.3,79.9a27.23,27.23,0,0,1-11-10.83,32.76,32.76,0,0,1-3.94-16.35,32.68,32.68,0,0,1,3.94-16.26,27.37,27.37,0,0,1,11-10.89,33.51,33.51,0,0,1,16.28-3.85,33.45,33.45,0,0,1,16.21,3.84,27.15,27.15,0,0,1,11,10.88,32.84,32.84,0,0,1,3.92,16.29,33,33,0,0,1-3.92,16.34,27.2,27.2,0,0,1-11,10.85A33.77,33.77,0,0,1,108.58,83.72Zm0-13.33a14.15,14.15,0,0,0,7.49-2,12.94,12.94,0,0,0,5-5.92,23.33,23.33,0,0,0,1.81-9.74,23.63,23.63,0,0,0-1.8-9.74,13.35,13.35,0,0,0-5-6,13.88,13.88,0,0,0-7.5-2A14.21,14.21,0,0,0,101,37a13,13,0,0,0-5,6,23.93,23.93,0,0,0-1.77,9.77A23.63,23.63,0,0,0,96,62.51a12.91,12.91,0,0,0,5,5.92A14.45,14.45,0,0,0,108.58,70.39Z"
          fill="#fff"
        />
        <path
          d="M145.88,82.34V.5h16.58V82.34Z"
          fill="#fff"
        />
        <path
          d="M191.71,84a29.1,29.1,0,0,1-11.24-2A16.23,16.23,0,0,1,173,76a17.36,17.36,0,0,1-2.66-9.81,17.17,17.17,0,0,1,2.23-8.88A16.45,16.45,0,0,1,179.73,51a38.74,38.74,0,0,1,13-3.28l9.6-1a17.54,17.54,0,0,0,4.58-1,5.66,5.66,0,0,0,2.7-2,5.85,5.85,0,0,0,.93-3.33l2.84,3.65a24,24,0,0,1-1,6.93,6.69,6.69,0,0,1-2.82,3.63A13.6,13.6,0,0,1,204,56.3l-7.1.86a19.18,19.18,0,0,0-5.7,1.47,6.81,6.81,0,0,0-3.06,2.59,7.37,7.37,0,0,0-1,3.85,7.54,7.54,0,0,0,1.05,4.07,6.59,6.59,0,0,0,3.15,2.56,13.51,13.51,0,0,0,5.23.88,16.34,16.34,0,0,0,7-1.47,11.57,11.57,0,0,0,5-4.44,13.62,13.62,0,0,0,1.87-7.4h3.39V66.8a21.86,21.86,0,0,1-3.84,9.27,18.19,18.19,0,0,1-7.5,5.89A26.93,26.93,0,0,1,191.71,84Zm33.91-1.09a19,19,0,0,1-8.38-1.58,9.63,9.63,0,0,1-4.64-4.91,21.87,21.87,0,0,1-1.47-8.74l-.6-1.44V40.44a6.72,6.72,0,0,0-1-3.79,5.86,5.86,0,0,0-3.19-2.23,18.77,18.77,0,0,0-5.79-.73,19.28,19.28,0,0,0-6.11.8,6.52,6.52,0,0,0-3.51,2.72,11.15,11.15,0,0,0-1.42,5.19H173a22.92,22.92,0,0,1,3.2-11.28,18.17,18.17,0,0,1,8.94-7q6-2.44,15.62-2.44,9.2,0,15,2.18A17.07,17.07,0,0,1,224.26,30,17.09,17.09,0,0,1,227,39.75V67.08a4.16,4.16,0,0,0,.39,1.91,2.55,2.55,0,0,0,1.19,1.15,4.62,4.62,0,0,0,2,.39H233V82.34c-1.25.17-2.48.31-3.71.4S226.81,82.89,225.62,82.89Z"
          fill="#fff"
        />
        <path
          d="M239.82,99.93V91.22l3.16-.06a6.08,6.08,0,0,0,3.19-.93A5,5,0,0,0,248,87.69a13.23,13.23,0,0,0,.6-4.34V79.47l2.93,2.87H239.82V65h18.91V82.63A22.87,22.87,0,0,1,257,92.2a11.8,11.8,0,0,1-5.19,5.62,19.6,19.6,0,0,1-8.68,2Z"
          fill="#ff7f40"
        />
      </g>

      <g class="step01-b">
        <path
          d="M295.16,82.34,276.05,23h17.76l5.66,21.35q1.88,7.31,3.19,12.72T305.42,69h-2.24q1.57-6.75,3.12-12.62t3.29-12L315.65,23h14.8l6.09,21.32q1.81,6.34,3.36,12.34T343,68.94l-2.3,0q1.26-5.72,2.84-12.26t3.1-12.37L352.25,23H370L350.9,82.33H334.3l-6.78-23.94q-1.57-5.61-2.83-10.37t-2.8-11.26l2.27,0q-1.47,6-2.8,11.14t-2.89,10.53l-6.72,23.88Z"
          fill="#fff"
        />
        <path
          d="M400.74,83.72A33.08,33.08,0,0,1,384.68,80,26,26,0,0,1,374,69.24a34.21,34.21,0,0,1-3.76-16.47A34.37,34.37,0,0,1,374,36.46a26.56,26.56,0,0,1,10.59-10.93,31.88,31.88,0,0,1,16-3.88,31.54,31.54,0,0,1,15.95,3.88A26.24,26.24,0,0,1,427,36.41a34.31,34.31,0,0,1,3.65,16.08c0,.71,0,1.47-.06,2.27s-.09,1.66-.14,2.56h-51V46.94h39l-4.11,2.33a19.55,19.55,0,0,0-1.62-8.09,12.2,12.2,0,0,0-4.7-5.4,14,14,0,0,0-7.56-1.94,14.32,14.32,0,0,0-7.6,1.92A12.4,12.4,0,0,0,388,41.55a24.36,24.36,0,0,0-1.74,9.7V55.5a20.79,20.79,0,0,0,1.74,9A11.87,11.87,0,0,0,393,69.94a16.27,16.27,0,0,0,8,1.82,16.86,16.86,0,0,0,5.88-1,11,11,0,0,0,4.22-2.72,11.19,11.19,0,0,0,2.51-4.27l15.66,0a24.82,24.82,0,0,1-15.19,17.5A34.64,34.64,0,0,1,400.74,83.72Z"
          fill="#fff"
        />
        <path
          d="M433.53,35.39V26.71l3.16-.06a6.09,6.09,0,0,0,3.19-1,5.06,5.06,0,0,0,1.84-2.54,13.06,13.06,0,0,0,.6-4.32V14.9l2.93,2.9H433.53V.5h18.91V18.12a22.66,22.66,0,0,1-1.74,9.54,11.84,11.84,0,0,1-5.19,5.6,19.59,19.59,0,0,1-8.68,2.07Z"
          fill="#ff7f40"
        />
        <path
          d="M459.07,82.34V23h16.15V38.4l.43,0V82.34Zm14-36.95a31.1,31.1,0,0,1,2.11-11.68,18.6,18.6,0,0,1,6.52-8.43,18.41,18.41,0,0,1,11-3.16c.65,0,1.27,0,1.87.07s1.21.11,1.84.19V37.52h-4a20.65,20.65,0,0,0-9.08,1.81,12.58,12.58,0,0,0-5.72,5.5,19.53,19.53,0,0,0-2,9.3H473V45.39Z"
          fill="#fff"
        />
        <path
          d="M528.93,83.72A33.08,33.08,0,0,1,512.87,80a26,26,0,0,1-10.66-10.73,34.21,34.21,0,0,1-3.76-16.47,34.37,34.37,0,0,1,3.74-16.31,26.56,26.56,0,0,1,10.59-10.93,31.88,31.88,0,0,1,16-3.88,31.54,31.54,0,0,1,16,3.88,26.24,26.24,0,0,1,10.43,10.88,34.31,34.31,0,0,1,3.65,16.08c0,.71,0,1.47-.06,2.27s-.09,1.66-.14,2.56h-51V46.94h39l-4.11,2.33a19.55,19.55,0,0,0-1.62-8.09,12.2,12.2,0,0,0-4.7-5.4,14,14,0,0,0-7.56-1.94,14.32,14.32,0,0,0-7.6,1.92,12.4,12.4,0,0,0-4.86,5.79,24.36,24.36,0,0,0-1.74,9.7V55.5a20.79,20.79,0,0,0,1.74,9,11.82,11.82,0,0,0,5.06,5.47,16.27,16.27,0,0,0,8,1.82,16.86,16.86,0,0,0,5.88-1,11,11,0,0,0,4.22-2.72,11.34,11.34,0,0,0,2.52-4.27l15.66,0a24.84,24.84,0,0,1-15.18,17.5A34.82,34.82,0,0,1,528.93,83.72Z"
          fill="#fff"
        />
        <g>
          <rect
            x="504.69"
            y="180.71"
            width="16.35"
            height="16.35"
            fill="#ff7f40"
          />
          <rect
            x="75.4"
            y="115.34"
            width="16.35"
            height="16.35"
            fill="#ff7f40"
          />
          <path
            d="M.21,115.34H38.67c15.57,0,26.1,5,26.1,20.26,0,8-4,13.62-11.1,17.05,10,2.86,15,10.53,15,20.72,0,16.6-14.08,23.7-28.85,23.7H.21Zm18,33.08h18.2c6.3,0,11-2.86,11-9.73,0-7.78-5.95-9.38-12.36-9.38H18.18Zm0,34.68H37.41c7.09,0,13.28-2.29,13.28-10.76s-5.27-11.68-12.93-11.68H18.18Z"
            fill="#fff"
          />
          <rect
            x="75.41"
            y="137.89"
            width="16.34"
            height="59.18"
            fill="#fff"
          />
          <path
            d="M99.83,137.89h15.45v8.24h.34a21.11,21.11,0,0,1,18.09-9.84c17.28,0,21.63,9.73,21.63,24.38v36.4H139.09V163.65c0-9.73-2.86-14.54-10.42-14.54-8.81,0-12.59,4.92-12.59,16.94v31H99.83Z"
            fill="#fff"
          />
          <path
            d="M163.69,156.09c.92-15.23,14.54-19.8,27.82-19.8,11.79,0,26,2.63,26,16.82V183.9c0,5.38.57,10.76,2.06,13.16H203.07a24.08,24.08,0,0,1-1.14-5.72c-5.15,5.38-12.71,7.32-19.92,7.32-11.21,0-20.14-5.61-20.14-17.74,0-13.39,10.07-16.6,20.14-18s19.23-1.14,19.23-7.78c0-7-4.81-8-10.53-8-6.18,0-10.19,2.52-10.76,8.93Zm37.55,12c-2.75,2.4-8.48,2.52-13.51,3.43s-9.61,2.75-9.61,8.7,4.69,7.55,9.95,7.55c12.71,0,13.17-10.07,13.17-13.62Z"
            fill="#fff"
          />
          <rect
            x="225.66"
            y="115.34"
            width="16.25"
            height="81.73"
            fill="#fff"
          />
          <path
            d="M279.19,136.29c18.66,0,30.68,12.36,30.68,31.25s-12,31.13-30.68,31.13-30.56-12.36-30.56-31.13,12-31.25,30.56-31.25m0,50.13c11.1,0,14.42-9.5,14.42-18.89s-3.32-19-14.42-19-14.31,9.5-14.31,19,3.33,18.89,14.31,18.89"
            fill="#fff"
          />
          <path
            d="M374.31,193.29c0,9.73-3.43,26.21-30.68,26.21-11.67,0-25.3-5.49-26.1-19h16.14c1.48,6.07,6.41,8.13,12.13,8.13,9,0,13.16-6.18,13.05-14.65V186.2h-.23c-3.55,6.18-10.65,9.16-17.74,9.16-17.74,0-25.3-13.51-25.3-29.76,0-15.34,8.81-29.3,25.41-29.3,7.78,0,13.74,2.63,17.63,9.5h.23v-7.9H374.3v55.39Zm-15.45-26.56c0-9.62-3.32-18.2-13.85-18.2-9.16,0-13.16,8-13.16,16.83,0,8.47,3.2,17.74,13.16,17.74,9.27,0,13.85-7.9,13.85-16.37"
            fill="#fff"
          />
          <path
            d="M436.19,197.07H420.73v-8.24h-.35a21.11,21.11,0,0,1-18.09,9.84c-17.28,0-21.63-9.73-21.63-24.38v-36.4h16.25v33.42c0,9.73,2.87,14.54,10.42,14.54,8.81,0,12.59-4.92,12.59-16.94v-31h16.26v59.18Z"
            fill="#fff"
          />
          <path
            d="M472.32,136.29c-18,0-30.22,13.5-30.22,31.24,0,18.31,11.56,31.14,30.22,31.14,12.74,0,22.16-5.41,27-18H484.73c-2,3-6.47,5.7-11.84,5.7-9,0-14.07-4.69-14.53-15h42.7c1.13-18.2-8.6-35.13-28.74-35.13m-14,24.83c.23-4.58,3.2-12.59,13.51-12.59,7.9,0,11.45,4.35,12.93,12.59Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
