<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="67.19"
    height="67.2"
    viewBox="0 0 67.19 67.2"
  >
    <polygon
      points="67.19 4.91 62.28 0 33.59 28.69 4.91 0.01 0 4.91 28.69 33.6 0 62.28 4.91 67.19 33.59 38.51 62.28 67.2 67.19 62.29 38.5 33.6 67.19 4.91"
      fill=""
    />
  </svg>
</template>
