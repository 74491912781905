<template>
  <div
    :id="`slider-${dynamicNumber}`"
    class="projectSlider"
    :style="`background : ${customColor}; ${customGradient} `"
    :class="[content.horizontal_margin, content.vertical_margin, content.mobile_settings, `black-${content.black_indicators}`]"
  >
    <div
      class="projectSlider"
      :class="{outside: !content.navigation_inside}"
    >
      <agile
        ref="agileCarousel"
        :options="agileSlider"
        class="projectSlider__carousel"
        @after-change="showCurrentSlide($event)"
      >
        <div
          v-for="(image, index) in content.images"
          :key="`image${index}`"
          class="projectSlider__item slide"
        >
          <img
            :style="`width: ${content.image_width}%`"
            :src="getImageKitURL(image, content.items_to_show, content.image_type,false,position)"
            :alt="image.alt"
            class="projectSlider__item--image"
          >
        </div>
        <template
          #prevButton
        >
          <div
            class="sliderControls"
            @mouseover="mouseEnter()"
            @mouseleave="mouseLeave()"
          >
            prev
          </div>
        </template>
        <template #nextButton>
          <div
            class="sliderControls"
            @mouseover="mouseEnterNext()"
            @mouseleave="mouseLeaveNext()"
          >
            next
          </div>
        </template>
      </agile>

      <div
        class="CarouselDots__project"
        :class="{inside: content.navigation_inside}"
      >
        <div
          v-for="(images, index) in content.images"
          :key="`dot${index}`"
          class="CarouselDots__item"
          @click="goToSlide(index)"
        >
          <!-- {{ index }} -->
          <span
            class="CarouselDots__highlight"
            :class="{active: ChangeCurrentSlide === index, outside: !content.navigation_inside}"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Vendor */
import { VueAgile } from "vue-agile";

/* Mixins */
import imageKit from "@/mixins/image-kit";

export default {
  components: {
    agile: VueAgile
  },

  mixins: [imageKit],
  props: {
    position: {
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // ChangeAutoplay: false,
      ChangeCurrentSlide: 1,
      agileSlider: {
        slidesToShow: 1,
        autoplay: true,
        timing: "ease-in-out",
        dots: false,
        navButtons: true,
        autoplaySpeed: 6000,
        infinite: true,
        pauseOnHover: false,
        throttleDelay: 300,
        responsive: [
          {
            breakpoint: 576,
            settings: { slidesToShow: this.content.items_to_show }
          }
        ]
        // centermode: true,
      }
    };
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if (this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    }
  },

  methods: {
    reloadSlider() {
      this.goToSlide(0);
    },

    goToSlide(index) {
      this.$refs.agileCarousel.goTo(index);
      this.ChangeCurrentSlide = this.$refs.agileCarousel.currentSlide;
    },

    showCurrentSlide(event) {
      this.ChangeCurrentSlide = event.currentSlide;
    },

    mouseEnter() {
      // alert('eo');
      this.$gsap.to(".cursorPrev", { opacity: 1, duration: 0.2 });

      if (this.content.black_indicators) {
        this.$gsap.to(".cursorPrev svg", { fill: "black", duration: 0 });
      } else {
        this.$gsap.to(".cursorPrev svg", { fill: "white", duration: 0 });
      }
    },

    mouseLeave() {
      this.$gsap.to(".cursorPrev", { opacity: 0, duration: 0.2 });
    },

    mouseEnterNext() {
      this.$gsap.to(".cursorNext", { opacity: 1, duration: 0.2 });

      if (this.content.black_indicators) {
        this.$gsap.to(".cursorNext svg", { fill: "black", duration: 0 });
      } else {
        this.$gsap.to(".cursorNext svg", { fill: "white", duration: 0 });
      }
    },

    mouseLeaveNext() {
      this.$gsap.to(".cursorNext", { opacity: 0, duration: 0.2 });
    }
  }
};
</script>

<style lang="scss">
.projectSlider {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100vw;
  //overflow-x: hidden;

  &.outside {
    @media (max-width: 576px) {
      margin-bottom: 12vw;
    }
  }

  &__carousel {
    width: 100%;
    height: auto;
    position: relative;
    //  @include marginBottom;
  }

  &__item {
    display: flex;
    justify-content: center;
    width: 20%;

    &--image {
      // padding: 0 5%;
      width: 100%;
      height: auto;
    }
  }

  &.super {
    @include paddingSuper;
  }

  &.big {
    @include paddingBig;
  }

  &.standard {
    @include paddingMedium;
  }

  &.small {
    @include paddingSmall;
  }

  &.bottom-text {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }
  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

  &.hide-mobile {
    display: none;

    @include tablet {
      display: flex;
    }
  }
}
</style>
