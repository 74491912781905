<template>
  <div
    class="ModalVideo"
    :class="ratio"
    @click="closeModal"
  >
    <InertiaLink
      class="ModalVideo__logo"
      :href="route('homepage')"
      @click="closeModal"
    >
      <LogoBinalogueWhiteAvatar />
    </InertiaLink>

    <div
      :id="`div${vimeo}`"
      class="ModalVideo__container"
    >
      <vueVimeoPlayer
        ref="player"
        :style="{ height: videoHeight + 'px', width: videoWidth + 'px' }"
        :video-id="vimeo"
        :loop="loop"
        controls
        :options="vimeoOptions"
        @mouseover="mouseEnter"
        @mouseleave="mouseLeave"
      />
    </div>

    <IconClose
      class="close"
    />
    <span class="cursor cursorClose">
      <IconClose />
    </span>
  </div>
</template>

<script>
/* Vendor */
import { InertiaLink } from "@inertiajs/inertia-vue3"
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { inject } from '@vue/runtime-core';

/* Mixins */
import imageKit from "@/mixins/image-kit";

export default {
  components: {
    vueVimeoPlayer,
    InertiaLink,
  },
  mixins: [imageKit],
  props: {
    vimeo: {
      type: String,
      default: '',
    },

    ratio: {
      type: String,
      default: '',
    },

    autoplay: {
      type:Boolean,
      default: false,
    },

    loop: {
      type:Boolean,
      default: false,
    },

    controls: {
      type:Boolean,
      default: false,
    }
  },
  setup(){
    const gsap = inject('$gsap');
    const tlModalVideo = gsap.timeline();
    const tlModalVideoOut = gsap.timeline();

    return {
      tlModalVideo,
      tlModalVideoOut,
    }
  },
  data() {
    return {
      headerColorInit: null,
      videoWidth: 0,
      videoHeight: 0,
      plus: 9,
      divide: 16,
      scrollPosition:0,
      vimeoOptions: {
        title: false,
        byline: false,
        portrait: false,
        color: 'F48650',
      }
    };
  },

  mounted() {
    this.$refs.player.play();
    this.tlModalVideo.to('.ModalVideo', {opacity:1, duration: 0.3, ease:"power2.inOut"},0);
    this.tlModalVideo.to('.ModalVideo__container', {scale:1, duration: 0.7,opacity:1, delay: 0.2, ease:"power2.out"});
    this.getVideoSize();

    if(!this.$store.state.isMobile) {
      this.cursorCloseAnimations();
    }
    // this.pauseScroll();
    window.addEventListener("resize", this.getVideoSize);

    setTimeout(() => {
      this.$store.commit("TOGGLE_BG_VIDEO", false);
      document.body.classList.add('horizontal');

      if(this.$store.state.isMobile) {
        this.scrollPosition = window.pageYOffset;
        document.querySelector('body').style.overflow = 'hidden';
        document.querySelector('body').style.position = 'fixed';
        document.querySelector('body').style.width = '100%';
        document.querySelector('body').style.top = `-${this.scrollPosition}px`;
      }
    }, 500);

    window.addEventListener('keyup', this.escapeVideo, false);
  },

  methods: {
    cursorCloseAnimations(){
      //cursor animation
      // this.$gsap.to('.cursorClose', {opacity:1, delay: 0.8, duration: 0.2});
      this.$gsap.set(".cursorClose", {xPercent: -50, yPercent: -50});

      const play = document.querySelector(".cursorClose");
      const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
      const mouse = { x: pos.x, y: pos.y };
      const speed = 0.3;

      const fpms = 60 / 1000;

      const xSet = this.$gsap.quickSetter(play, "x", "px");
      const ySet = this.$gsap.quickSetter(play, "y", "px");

      window.addEventListener("mousemove", e => {
        mouse.x = e.x;
        mouse.y = e.y;
      });

      this.$gsap.ticker.add((time, deltaTime) => {

        const delta = deltaTime * fpms;
        const dt = 1.0 - Math.pow(1.0 - speed, delta);

        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        xSet(pos.x);
        ySet(pos.y);
      });
    },

    mouseEnter(){
      this.$gsap.to('.cursorClose', {opacity:0, duration: 0.2});
    },

    mouseLeave(){
      this.$gsap.to('.cursorClose', {opacity:1, duration: 0.2});
    },

    closeModal() {
      this.tlModalVideo.to('.ModalVideo__container', {scale:1.4, opacity:0, duration: 0.6, ease:"power2.inOut"});
      this.tlModalVideoOut.to('.ModalVideo', {opacity:0, duration: 0.6, delay:0.3, ease:"power4.in"});
      // this.tlModalVideoOut.to('.ModalVideo__container', {opacity:0, duration: 0.5, ease:"power4.in"},0.1);
      this.$store.commit("TOGGLE_BG_VIDEO", true);
      document.body.classList.remove('horizontal');
      // this.playScroll();

      if(this.$store.state.isMobile) {
        document.querySelector('body').style.removeProperty('overflow');
        document.querySelector('body').style.removeProperty('position');
        document.querySelector('body').style.removeProperty('width');
        window.scrollTo(0, this.scrollPosition);
      }


      setTimeout(() => {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
      }, 1000);

    },

    getVideoSize() {
      switch (this.ratio) {
        case "four-by-three":
          this.divide = 4;
          this.plus = 3;
          break;
        case "four-by-five":
          this.divide = 4;
          this.plus = 5;
          break;
        case "sixteen-by-five":
          this.divide = 16;
          this.plus = 5;
          break;
        case "sixteen-by-seven":
          this.divide = 16;
          this.plus = 7;
          break;
        case "two-by-one":
          this.divide = 2;
          this.plus = 1;
          break;
        case "square":
          this.divide = 1;
          this.plus = 1;
          break;
        case "sixteen-mm":
          this.divide = 2048;
          this.plus = 1240;
          break;
        case "nineteentwenty-by-thirteenfifty":
          this.divide = 1920;
          this.plus = 1350;
          break;
        case "nine-by-sixteen":
          this.divide = 9;
          this.plus = 16;
          break;
        case "nineteentwenty-by-sixfifty":
          this.divide = 1920;
          this.plus = 650;
          break;
        default:
          this.divide = 16;
          this.plus = 9;
          break;
      }
      this.videoWidth = document.getElementById(
        "div" + this.vimeo
      ).offsetWidth;
      this.videoHeight = Math.ceil((this.videoWidth * this.plus) / this.divide);
    },

    escapeVideo(event) {
      if(event.keyCode === 27) {
        this.$refs.player.pause();
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss">
.ModalVideo {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vh 5vw 18vh;
  // transform: scaleY(0);
  cursor: none;
  // cursor: url("/fonts/close-100.png"), auto;
  background: $black;
  opacity: 0;
  width: 100vw;
  height: 100vh;

  @include desktop-s {
    padding: 2vh 15vw;
  }

  &.four-by-three {
      padding: 5vh 5vw 18vh;

    @media (max-height: 570px) {
     padding: 1vh 25vw 2vh;
    }

    @media (max-height: 400px) {
     padding: 1vh 28vw 2vh;
    }

    @include desktop-s {
      padding: 2vh 15vw;
    }

  }

  &.nine-by-sixteen {
      padding: 5vh 5vw 5vh;

    @media (max-height: 570px) {
     padding: 1vh 25vw 2vh;
    }

    @media (max-height: 400px) {
     padding: 1vh 28vw 2vh;
    }

    @include desktop-s {
      padding: 2vh calc(50% - 20vh);
    }

  }

  &.square {
      padding: 5vh 5vw 18vh;

    @media (max-height: 570px) {
     padding: 1vh 35vw 2vh;
    }

    @media (max-height: 400px) {
     padding: 1vh 37vw 2vh;
    }

    @include desktop-s {
      padding: 2vh 27vw;
    }

  }

  @media (max-height: 570px) {
     padding: 1vh 20vw 2vh;
  }

  @media (max-height: 400px) {
     padding: 1vh 22vw 2vh;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    cursor: auto;
  }

  .ModalVideo__container {
    opacity:0;
    //  background: pink;
    transform: scale(1.4);
  }

  &__logo {
    position: absolute;
    top:2px;
    left:5vw;
    margin-top: 12px;

    svg {
      height: 22px;
      width: auto;

      @include tablet {
        height: 32px;
      }
    }

    @include tablet {
      // top: 0;
      margin-top: 20px;
    }

    @include desktop-m {
      margin-top: 25px;
    }
  }

  // cursor options
  .cursorClose {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display:none;
    pointer-events: none;
    opacity: 0;

    @include tablet {
      display: block;
    }

    svg {
      opacity:1;
      fill: $white;
      height: 40px;
      width: auto;
    }
  }

  .close {
    position: absolute;
    top:2.5vh;
    right: 6.5vw;
    color: $white;
    fill: $white;
    width: 27px;
    height: auto;

    svg {
      fill: $white;
    }

    @include tablet-m {
      display: none;

       @media (max-height: 570px) {
         top:4.5vh;
         display: block;
      }
    }
  }
}
</style>
