<template>
  <div class="SearchInput">
    <BaseInputSearch
      id="search"
      ref="search"
      v-model="value"
      class="SearchInput__input"
      type="text"
      placeholder="Search"
      aria-label="Search"
      required
      @keyup.enter="submit()"
    />
    <BaseButton
      type="button"
      class="SearchInput__btn"
      @click="submit()"
    >
      <IconSearch />
    </BaseButton>
  </div>
</template>

<script>
import { inject } from "@vue/runtime-core";
export default {
  setup() {
    const gsap = inject("$gsap");
    const tlSearch = gsap.timeline();
    return {
      tlSearch
    };
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    submit() {
      if (this.value) {
        let params = encodeURIComponent(this.value).replace(/%20/g, "+");

        this.$inertia.get(
          this.route("search", params),
          {},
          {
            preserveState: false,
            preserveScroll: true,
            onStart: (visit) => {
              this.value = "";
              this.$store.commit("TOGGLE_SEARCH_ACTIVE", false);
              this.$store.commit("TOGGLE_SEARCH_HIDDEN", true);
            },
            onFinish: () => {
              this.value = "";
            }
          }
        );
      }
    },

    setup() {}
  }
};
</script>

<style lang="scss">
.SearchInput {
  position: relative;
  display: flex;
  width: 100%;

  &__input {
    position: relative;
    font-family: $helvetica;
    font-size: 28px;
    color: $orange;
    background: transparent;
    border: 0;
    border-bottom: 2px solid $black;
    border-radius: 0;
    outline: 0;
    width: 100%;
    font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
    -webkit-appearance: none;

    @include desktop-sm {
      font-size: 40px;
    }

    @include desktop-m {
      font-size: 44px;
    }

    @include desktop-l {
      font-size: 58px;
    }

    @include desktop-xl {
      font-size: 78px;
    }

    &::placeholder {
      font-family: $helvetica;
      font-size: 28px;
      color: $orange;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      @include desktop-sm {
        font-size: 40px;
      }

      @include desktop-m {
        font-size: 44px;
      }

      @include desktop-l {
        font-size: 58px;
      }

      @include desktop-xl {
        font-size: 78px;
      }
    }
  }

  &__btn {
    position: absolute;
    right: 0;
    bottom: 5px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 30px;
    height: 30px;

    svg {
      width: 100%;
      height: auto;
    }

    @include desktop-sm {
      width: 40px;
      height: 40px;
    }

    @include desktop-m {
      width: 44px;
      height: 44px;
    }

    @include desktop-l {
      padding: 10px;
      width: 60px;
      height: 60px;
    }

    @include desktop-xl {
      padding: 12px;
      width: 80px;
      height: 80px;
    }
  }
}
</style>
