<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="23.35"
    height="29.81"
    viewBox="0 0 23.35 29.81"
  >
    <path
      d="M1.37,1.23,20.73,14.72.86,28.58"
      transform="translate(0 0)"
      fill="none"
      stroke="#ff7f40"
      stroke-miterlimit="10"
      stroke-width="3"
    />
  </svg>
</template>
