<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="581.23"
    height="164.11"
    viewBox="0 0 581.23 164.11"
    alt="Get in touch"
  >
    <g id="txt">
      <path
        d="M38.87,81.89a35.63,35.63,0,0,1-19.13-5,33.13,33.13,0,0,1-12.53-14A46.58,46.58,0,0,1,2.86,42.3,44.5,44.5,0,0,1,7.41,21.8,33.08,33.08,0,0,1,20.49,7.93a39,39,0,0,1,20-5,41,41,0,0,1,17,3.37A30.12,30.12,0,0,1,75.49,30H58.69a15.63,15.63,0,0,0-3.51-6.91,16.44,16.44,0,0,0-6.31-4.39,21.91,21.91,0,0,0-8.2-1.5A20.3,20.3,0,0,0,29.85,20.1a18.89,18.89,0,0,0-7.29,8.54A33.17,33.17,0,0,0,20,42.51a33.28,33.28,0,0,0,2.52,13.58,19,19,0,0,0,7.22,8.64,20.33,20.33,0,0,0,11.11,3,22.05,22.05,0,0,0,9.84-2.17,17.54,17.54,0,0,0,7.1-6.32,19.66,19.66,0,0,0,3-9.82H68.4v8.72a32.58,32.58,0,0,1-6.16,13.61A25.8,25.8,0,0,1,52,79.47,34,34,0,0,1,38.87,81.89Zm25.78-1.75L62.59,68.29V51.15H41.76V38.72H76.1V80.14Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M110,81.41A30.41,30.41,0,0,1,95.15,78a23.9,23.9,0,0,1-9.83-9.89,31.55,31.55,0,0,1-3.47-15.19,31.62,31.62,0,0,1,3.44-15,24.45,24.45,0,0,1,9.77-10.08,29.38,29.38,0,0,1,14.74-3.58,29.06,29.06,0,0,1,14.71,3.58,24.14,24.14,0,0,1,9.62,10,31.68,31.68,0,0,1,3.37,14.83c0,.65,0,1.35-.06,2.09s-.08,1.53-.13,2.36h-47V47.49h35.93l-3.79,2.15a17.87,17.87,0,0,0-1.5-7.46,11.3,11.3,0,0,0-4.33-5,12.82,12.82,0,0,0-7-1.79,13.21,13.21,0,0,0-7,1.77,11.56,11.56,0,0,0-4.48,5.34,22.46,22.46,0,0,0-1.6,9v3.92a19.11,19.11,0,0,0,1.6,8.27,11,11,0,0,0,4.67,5,17,17,0,0,0,12.77.81A9.85,9.85,0,0,0,121.79,63h14.45a22.92,22.92,0,0,1-14,16.14A32.26,32.26,0,0,1,110,81.41Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M138.7,37.18V25.44h34.72V37.18Zm27.09,43.3A31.75,31.75,0,0,1,155.37,79a11.29,11.29,0,0,1-6-4.61,15.4,15.4,0,0,1-2-8.31V4.77h15.29V63.71a5.48,5.48,0,0,0,.65,2.89,3.4,3.4,0,0,0,2,1.44,12.69,12.69,0,0,0,3.63.41h4.51V80.14h-.74c-1.17.11-2.33.18-3.5.23S166.88,80.48,165.79,80.48Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <rect
        x="196.01"
        y="22.52"
        width="15.29"
        height="54.7"
        fill="#fff"
      />
      <path
        d="M219.58,80.14V25.44h14.87V37.29h.4V80.14Zm35.75-33.23a11.83,11.83,0,0,0-1.07-5.34,7.32,7.32,0,0,0-3.14-3.23,10.8,10.8,0,0,0-5.12-1.1,11.46,11.46,0,0,0-5.38,1.33,10.44,10.44,0,0,0-4.15,4.13,13.55,13.55,0,0,0-1.6,6.86h-4.56V36.44H233a20.47,20.47,0,0,1,4.31-6.52,18.89,18.89,0,0,1,6.37-4.25,20.86,20.86,0,0,1,8-1.5,21.38,21.38,0,0,1,10.15,2.25,15.12,15.12,0,0,1,6.52,6.83,25.36,25.36,0,0,1,2.28,11.38V80.14h-15.3Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M292.88,37.18V25.44H327.6V37.18ZM320,80.48A31.41,31.41,0,0,1,309.55,79a11.29,11.29,0,0,1-6-4.61,15.49,15.49,0,0,1-2-8.31V4.77h15.29V63.71a5.4,5.4,0,0,0,.64,2.89,3.48,3.48,0,0,0,2,1.44,12.69,12.69,0,0,0,3.63.41h4.51V80.14a2.54,2.54,0,0,0-.39,0h-.36c-1.16.11-2.33.18-3.5.23S321.06,80.48,320,80.48Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M360,81.41a31.17,31.17,0,0,1-15-3.52,25.14,25.14,0,0,1-10.16-10,30.27,30.27,0,0,1-3.63-15.08,30.18,30.18,0,0,1,3.63-15,25.21,25.21,0,0,1,10.16-10,33.36,33.36,0,0,1,30,0,25,25,0,0,1,10.14,10,30.4,30.4,0,0,1,3.61,15,30.39,30.39,0,0,1-3.61,15.06,25,25,0,0,1-10.14,10A30.94,30.94,0,0,1,360,81.41Zm0-12.3a13,13,0,0,0,6.91-1.8,12,12,0,0,0,4.62-5.46,21.35,21.35,0,0,0,1.67-9,21.86,21.86,0,0,0-1.65-9,12.21,12.21,0,0,0-4.63-5.54A12.78,12.78,0,0,0,360,36.49a13,13,0,0,0-7,1.86,12,12,0,0,0-4.62,5.51,22,22,0,0,0-1.63,9,21.69,21.69,0,0,0,1.64,9A11.92,11.92,0,0,0,353,67.3,13.22,13.22,0,0,0,360,69.11Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M410.91,81.41a21.34,21.34,0,0,1-10.14-2.25,15.27,15.27,0,0,1-6.52-6.83A25.39,25.39,0,0,1,392,61V25.44h15.29V58.67a11.79,11.79,0,0,0,1,5.33,7.21,7.21,0,0,0,3.15,3.24,10.87,10.87,0,0,0,5.1,1.1A11.39,11.39,0,0,0,422,67a10.39,10.39,0,0,0,4.14-4.12,13.59,13.59,0,0,0,1.6-6.87h4.59V69.14h-2.71a20.91,20.91,0,0,1-4.33,6.53,18.63,18.63,0,0,1-6.36,4.26A21,21,0,0,1,410.91,81.41Zm17.23-1.27V68.29h-.45V25.44H443v54.7Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M476.52,81.41a29.6,29.6,0,0,1-14.69-3.51,24.06,24.06,0,0,1-9.71-10,31.88,31.88,0,0,1-3.42-15.13,32,32,0,0,1,3.42-15.12,24,24,0,0,1,9.75-10,30.16,30.16,0,0,1,14.84-3.51,31.23,31.23,0,0,1,13.44,2.72,22.33,22.33,0,0,1,9.1,7.6,24.18,24.18,0,0,1,4,11.25h-15.4a12.61,12.61,0,0,0-2.25-5.17,9.78,9.78,0,0,0-3.94-3.06,13.13,13.13,0,0,0-5.18-1,11.76,11.76,0,0,0-6.58,1.78,11.25,11.25,0,0,0-4.23,5.39,24.13,24.13,0,0,0-1.48,9.1A24.34,24.34,0,0,0,465.71,62a11.13,11.13,0,0,0,4.22,5.35,11.69,11.69,0,0,0,6.51,1.75,13.35,13.35,0,0,0,5.37-1,9.89,9.89,0,0,0,4-3.24A13.07,13.07,0,0,0,488,59.23h15.24A25.12,25.12,0,0,1,499,70.92a23.12,23.12,0,0,1-9.25,7.75A30.47,30.47,0,0,1,476.52,81.41Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M508.61,80.14V4.67h15.3V80.14Zm35.75-33.23a11.7,11.7,0,0,0-1.07-5.34,7.32,7.32,0,0,0-3.14-3.23,10.76,10.76,0,0,0-5.11-1.1,11.34,11.34,0,0,0-5.38,1.33,10.37,10.37,0,0,0-4.15,4.13,13.55,13.55,0,0,0-1.6,6.86h-4.56V36.44h2.83a20.92,20.92,0,0,1,4.27-6.52,18.47,18.47,0,0,1,6.29-4.25,20.49,20.49,0,0,1,8-1.5,21.38,21.38,0,0,1,10.15,2.25,15.2,15.2,0,0,1,6.51,6.83,25.36,25.36,0,0,1,2.28,11.38V80.14H544.36Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <polygon
        points="568.63 54.22 563.8 22.89 563.8 1.75 580.29 1.75 580.29 22.89 575.44 54.22 568.63 54.22"
        fill="#fff"
      />
      <path
        d="M8.4,152.88V107.14h7.15v45.74Zm21.75-21.06a8,8,0,0,0-.77-3.69,4.87,4.87,0,0,0-2.24-2.2,8.07,8.07,0,0,0-3.62-.73,7.9,7.9,0,0,0-3.83,1,7.72,7.72,0,0,0-3,3,10.11,10.11,0,0,0-1.16,5H12v-6.93h2A14.08,14.08,0,0,1,16.56,123a11.44,11.44,0,0,1,9.17-4,13.3,13.3,0,0,1,6.2,1.35,9.42,9.42,0,0,1,4,4,14.14,14.14,0,0,1,1.39,6.52v22H30.15Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M56.83,153.65a17.36,17.36,0,0,1-8.69-2.14,14.91,14.91,0,0,1-5.93-6.06,20.7,20.7,0,0,1,0-18.26,15,15,0,0,1,5.92-6.07,17.25,17.25,0,0,1,8.7-2.12,17.35,17.35,0,0,1,8.66,2.14,15,15,0,0,1,5.91,6.07,20.72,20.72,0,0,1,0,18.27,15,15,0,0,1-5.91,6.06A17.22,17.22,0,0,1,56.83,153.65Zm0-6a9.12,9.12,0,0,0,4.91-1.31A8.52,8.52,0,0,0,65,142.48a16.9,16.9,0,0,0,0-12.32,8.68,8.68,0,0,0-3.26-3.88A9,9,0,0,0,56.83,125a9.14,9.14,0,0,0-4.94,1.32,8.63,8.63,0,0,0-3.27,3.87,17,17,0,0,0,0,12.33,8.63,8.63,0,0,0,3.26,3.86,9.14,9.14,0,0,0,4.95,1.28Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M77.68,152.88V107.14h7.15v45.74Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M100.56,153.7a16,16,0,0,1-6.26-1.13,9,9,0,0,1-4.12-3.35,9.52,9.52,0,0,1-1.46-5.37A9.39,9.39,0,0,1,90,139a9.17,9.17,0,0,1,4-3.5,21.27,21.27,0,0,1,7.22-1.86l5-.53a9.13,9.13,0,0,0,2.72-.63,3.5,3.5,0,0,0,1.62-1.3,4,4,0,0,0,.58-2.13l2,1.07a13.87,13.87,0,0,1-.59,4.34,4.35,4.35,0,0,1-1.82,2.33,8.88,8.88,0,0,1-3.56,1l-4.43.55a13.17,13.17,0,0,0-3.91,1,4.41,4.41,0,0,0-2,1.7,5.54,5.54,0,0,0,0,5.11,4.07,4.07,0,0,0,2,1.66,8.4,8.4,0,0,0,3.32.58,10.83,10.83,0,0,0,4.45-.93,7.8,7.8,0,0,0,3.35-2.85,8.28,8.28,0,0,0,1.28-4.72h2.12v4a13.77,13.77,0,0,1-2.41,5.36,10.78,10.78,0,0,1-4.28,3.34A14.79,14.79,0,0,1,100.56,153.7Zm17.65-.58a8.89,8.89,0,0,1-4-.76,4.64,4.64,0,0,1-2.21-2.29,9.93,9.93,0,0,1-.69-4l-.24-1V129a4.52,4.52,0,0,0-.63-2.51,3.78,3.78,0,0,0-2-1.47,12,12,0,0,0-3.85-.49,12.62,12.62,0,0,0-4,.53,4.25,4.25,0,0,0-2.33,1.77,7,7,0,0,0-.93,3.37H90.15a12.78,12.78,0,0,1,1.69-6,9.61,9.61,0,0,1,4.64-3.85,20.53,20.53,0,0,1,8.13-1.36,20.8,20.8,0,0,1,7.78,1.22,8.85,8.85,0,0,1,4.38,3.41,9.72,9.72,0,0,1,1.36,5.21v17a2.15,2.15,0,0,0,.19,1,1.34,1.34,0,0,0,.62.6,2.47,2.47,0,0,0,1.07.21h1.62V153q-.8.1-1.68.18A7.21,7.21,0,0,1,118.21,153.12Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M163.32,158.45a30.24,30.24,0,0,1-3.82,1.21,35.48,35.48,0,0,1-4.22.74,41.88,41.88,0,0,1-4.48.24,30.73,30.73,0,0,1-14.8-3.45,24.49,24.49,0,0,1-9.9-9.61,30,30,0,0,1,0-28.1,25.2,25.2,0,0,1,9.86-9.81,29.35,29.35,0,0,1,14.59-3.56,31.62,31.62,0,0,1,14.22,3.11,24,24,0,0,1,9.79,8.67,23,23,0,0,1,3.52,12.57,26.32,26.32,0,0,1-1.39,8.8,13.61,13.61,0,0,1-4.15,6.13,10.17,10.17,0,0,1-6.67,2.25,10.66,10.66,0,0,1-4.42-.86,7,7,0,0,1-3.07-2.53,8,8,0,0,1-1.3-4V125h-.77v-5.25h6.49V137a8.4,8.4,0,0,0,.41,2.86,3.38,3.38,0,0,0,1.24,1.69,3.53,3.53,0,0,0,2,.55,4.1,4.1,0,0,0,3.12-1.39,9,9,0,0,0,2-4,24.74,24.74,0,0,0,.65-6,19,19,0,0,0-2.61-9.91,18,18,0,0,0-7.52-6.9,25.17,25.17,0,0,0-11.54-2.51A23,23,0,0,0,139,114.2a19.7,19.7,0,0,0-7.74,7.83,23.53,23.53,0,0,0-2.75,11.45,23.16,23.16,0,0,0,2.85,11.66,19.42,19.42,0,0,0,8,7.66A24.75,24.75,0,0,0,151,155.48a35,35,0,0,0,6.46-.58,31.09,31.09,0,0,0,5.83-1.68Zm-14.7-10.79A11.28,11.28,0,0,1,142.5,146a11,11,0,0,1-4.14-4.93,20.91,20.91,0,0,1,0-15.48,11.29,11.29,0,0,1,4.14-5,11.06,11.06,0,0,1,6.13-1.71,9.37,9.37,0,0,1,4.2.9,8.18,8.18,0,0,1,3.07,2.68,14.41,14.41,0,0,1,2,4.43l.32,13a11.66,11.66,0,0,1-2.05,4.2,9.12,9.12,0,0,1-3.32,2.65,9.78,9.78,0,0,1-4.23.87Zm1.32-5.41a5.78,5.78,0,0,0,3.55-1.08,6.66,6.66,0,0,0,2.21-3.09,13.89,13.89,0,0,0,.73-4.83,13.57,13.57,0,0,0-.74-4.77,6.53,6.53,0,0,0-2.22-3.08,6.42,6.42,0,0,0-7.13,0,6.34,6.34,0,0,0-2.19,3.08,16.23,16.23,0,0,0,0,9.61,6.32,6.32,0,0,0,2.19,3.09,5.93,5.93,0,0,0,3.6,1.07Z"
        transform="translate(-2.85 -2.92)"
        fill="#ff7f40"
      />
      <path
        d="M181.56,152.88V107.14h7.15v20.43h-.8V145.1h.53v7.78Zm17.43.77a12,12,0,0,1-7.25-2.18,13.4,13.4,0,0,1-4.52-6.08,24.93,24.93,0,0,1-1.53-9.09v-.13a24.26,24.26,0,0,1,1.54-9,13.23,13.23,0,0,1,4.54-6A12,12,0,0,1,199,119a13.33,13.33,0,0,1,12.73,8.17,24.64,24.64,0,0,1,0,18.34A13.32,13.32,0,0,1,199,153.65Zm-1.67-6a7.9,7.9,0,0,0,4.66-1.38,8.66,8.66,0,0,0,3.06-4,16.16,16.16,0,0,0,1.08-6.19,15.32,15.32,0,0,0-1.06-6.06,8.17,8.17,0,0,0-3.05-3.79,8.55,8.55,0,0,0-4.69-1.28,8.25,8.25,0,0,0-4.7,1.34,8.54,8.54,0,0,0-3.08,3.89,17.74,17.74,0,0,0,0,12.23,8.52,8.52,0,0,0,3.07,3.89,8.2,8.2,0,0,0,4.71,1.34Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M217.84,116.16V109H225v7.21Zm0,36.72V119.73H225v33.15Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M229.34,152.88V119.73h6.91v7.88h.24v25.28Zm21.75-21.06a8,8,0,0,0-.77-3.69,4.87,4.87,0,0,0-2.24-2.2,8.07,8.07,0,0,0-3.62-.73,7.9,7.9,0,0,0-3.83,1,7.72,7.72,0,0,0-3,3,10.11,10.11,0,0,0-1.16,5H232.9v-6.93h2a14,14,0,0,1,2.58-4.31,11.81,11.81,0,0,1,4-3,12.35,12.35,0,0,1,5.18-1.08,13.14,13.14,0,0,1,6.19,1.35,9.31,9.31,0,0,1,4,4,14.14,14.14,0,0,1,1.39,6.52v22h-7.15Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M273.77,153.7a16,16,0,0,1-6.26-1.13,9,9,0,0,1-4.12-3.35,10.46,10.46,0,0,1-.22-10.27,9.17,9.17,0,0,1,4-3.5,21.27,21.27,0,0,1,7.22-1.86l5-.53a9.13,9.13,0,0,0,2.72-.63,3.57,3.57,0,0,0,1.62-1.3,3.9,3.9,0,0,0,.57-2.13l2,1.07a13.87,13.87,0,0,1-.59,4.34,4.39,4.39,0,0,1-1.82,2.33,8.88,8.88,0,0,1-3.56,1l-4.43.55a13.17,13.17,0,0,0-3.91,1,4.37,4.37,0,0,0-2.05,1.7,5.54,5.54,0,0,0,0,5.11,4.07,4.07,0,0,0,2,1.66,8.4,8.4,0,0,0,3.32.58,10.83,10.83,0,0,0,4.45-.93,7.8,7.8,0,0,0,3.35-2.85,8.27,8.27,0,0,0,1.27-4.72h2.12v4a13.61,13.61,0,0,1-2.4,5.36,10.78,10.78,0,0,1-4.28,3.34A14.86,14.86,0,0,1,273.77,153.7Zm17.65-.58a8.89,8.89,0,0,1-4-.76,4.64,4.64,0,0,1-2.21-2.29,9.77,9.77,0,0,1-.7-4l-.24-1V129a4.6,4.6,0,0,0-.62-2.51,3.8,3.8,0,0,0-2-1.47,12,12,0,0,0-3.85-.49,12.62,12.62,0,0,0-4,.53,4.25,4.25,0,0,0-2.33,1.77,7,7,0,0,0-.93,3.37h-7.12a12.78,12.78,0,0,1,1.69-6,9.61,9.61,0,0,1,4.64-3.85,20.53,20.53,0,0,1,8.13-1.36,20.8,20.8,0,0,1,7.78,1.22,8.83,8.83,0,0,1,4.37,3.41,9.64,9.64,0,0,1,1.37,5.21v17a2.15,2.15,0,0,0,.19,1,1.34,1.34,0,0,0,.62.6,2.47,2.47,0,0,0,1.07.21h1.62V153q-.8.1-1.68.18a7.3,7.3,0,0,1-1.75,0Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M298.13,152.88V107.14h7.15v45.74Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M326.27,153.65a17.36,17.36,0,0,1-8.69-2.14,14.83,14.83,0,0,1-5.92-6.06,20.7,20.7,0,0,1,0-18.26,15,15,0,0,1,5.92-6.07,18.63,18.63,0,0,1,17.36,0,14.93,14.93,0,0,1,5.9,6.07,20.72,20.72,0,0,1,0,18.27,14.9,14.9,0,0,1-5.9,6.06A17.25,17.25,0,0,1,326.27,153.65Zm0-6a9.15,9.15,0,0,0,4.92-1.31,8.54,8.54,0,0,0,3.26-3.87,17,17,0,0,0,0-12.32,8.73,8.73,0,0,0-3.25-3.88,9.87,9.87,0,0,0-9.86,0,8.63,8.63,0,0,0-3.27,3.87,17.13,17.13,0,0,0,0,12.33,8.63,8.63,0,0,0,3.26,3.86,9.15,9.15,0,0,0,4.94,1.33Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M361.54,153.42a13.37,13.37,0,0,1-12.71-8.13,24,24,0,0,1,0-18.19,13.93,13.93,0,0,1,5.06-6,13.63,13.63,0,0,1,7.63-2.12,12.16,12.16,0,0,1,7.23,2.14,13.43,13.43,0,0,1,4.6,6,24.27,24.27,0,0,1,1.63,9v.11a24.33,24.33,0,0,1-1.59,9.05,13.28,13.28,0,0,1-4.59,6A12.11,12.11,0,0,1,361.54,153.42Zm1.9,13.61a19,19,0,0,1-7.66-1.42,11.54,11.54,0,0,1-5-4.06,12.38,12.38,0,0,1-2.06-6.2h7.13a6.94,6.94,0,0,0,1.13,3.36,5.58,5.58,0,0,0,2.57,2,10.26,10.26,0,0,0,3.91.67,9.89,9.89,0,0,0,4.66-1,6.41,6.41,0,0,0,2.84-3,11.4,11.4,0,0,0,1-4.95v-8.32h.8V127h-.55v-7.26h6.94V152.3a16.22,16.22,0,0,1-1.76,7.77,12.11,12.11,0,0,1-5.27,5.14,18.58,18.58,0,0,1-8.68,1.79Zm-.21-19.45a8.23,8.23,0,0,0,4.7-1.34,8.57,8.57,0,0,0,3.07-3.91,16,16,0,0,0,1.08-6.16,15.82,15.82,0,0,0-1.08-6.12,8.57,8.57,0,0,0-3.07-3.91,8.25,8.25,0,0,0-4.7-1.34,8,8,0,0,0-4.66,1.38,8.85,8.85,0,0,0-3.07,4,16,16,0,0,0-1.09,6.2,15.37,15.37,0,0,0,1.08,6.11,8.21,8.21,0,0,0,3.06,3.8A8.42,8.42,0,0,0,363.23,147.58Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M395,153.65a13.23,13.23,0,0,1-6.21-1.36,9.2,9.2,0,0,1-4-4,14,14,0,0,1-1.39-6.52v-22h7.15v21a7.88,7.88,0,0,0,.78,3.7,4.92,4.92,0,0,0,2.24,2.2,8.24,8.24,0,0,0,3.63.72,7.86,7.86,0,0,0,3.82-1,7.47,7.47,0,0,0,3-3,10,10,0,0,0,1.16-5h3.58v6.92h-2a14.11,14.11,0,0,1-2.6,4.32,11.68,11.68,0,0,1-4,3A12.2,12.2,0,0,1,395,153.65Zm10.42-.77V145h-.24V119.73h7.14v33.15Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M432.76,153.65a16.82,16.82,0,0,1-8.47-2.1,14.48,14.48,0,0,1-5.71-6,21.74,21.74,0,0,1,0-18.29,14.56,14.56,0,0,1,5.64-6.1,16.21,16.21,0,0,1,8.47-2.16,15.7,15.7,0,0,1,8.53,2.25,14.59,14.59,0,0,1,5.52,6.2,20.29,20.29,0,0,1,1.91,8.94v2.13h-28.4V133.3H443.9l-2.5,1.41a12.69,12.69,0,0,0-1-5.16,8.16,8.16,0,0,0-3-3.55,8.59,8.59,0,0,0-4.79-1.29,8.77,8.77,0,0,0-4.68,1.22,8.05,8.05,0,0,0-3.12,3.68,15,15,0,0,0-1.16,6.11V137a14.69,14.69,0,0,0,1.13,6.14,7.87,7.87,0,0,0,3.19,3.65,9.7,9.7,0,0,0,4.95,1.2,9.94,9.94,0,0,0,3.66-.64,7.14,7.14,0,0,0,4.27-4.66h7a14.62,14.62,0,0,1-2.86,5.77,13.63,13.63,0,0,1-5.13,3.86A17.47,17.47,0,0,1,432.76,153.65Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M451.56,152.88v-8H460v8Z"
        transform="translate(-2.85 -2.92)"
        fill="#ff7f40"
      />
      <path
        d="M479.08,153.65a16.4,16.4,0,0,1-8.41-2.13,14.52,14.52,0,0,1-5.65-6.05,22,22,0,0,1,0-18.35,14.28,14.28,0,0,1,5.65-6,16.49,16.49,0,0,1,8.49-2.12,17,17,0,0,1,7.5,1.55,13,13,0,0,1,5.21,4.38,14.53,14.53,0,0,1,2.43,6.6H487a8.48,8.48,0,0,0-1.55-3.59,7,7,0,0,0-2.79-2.17,8.81,8.81,0,0,0-3.58-.72,7.66,7.66,0,0,0-7.56,5,15.86,15.86,0,0,0-1.1,6.28,15.57,15.57,0,0,0,1.11,6.29,7.79,7.79,0,0,0,7.58,5,9,9,0,0,0,3.66-.74,7,7,0,0,0,2.81-2.25,8.6,8.6,0,0,0,1.55-3.76h7.22a14.68,14.68,0,0,1-2.54,6.78,13.63,13.63,0,0,1-5.31,4.45A16.79,16.79,0,0,1,479.08,153.65Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M514.8,153.65a17.36,17.36,0,0,1-8.69-2.14,14.91,14.91,0,0,1-5.93-6.06,20.7,20.7,0,0,1,0-18.26,15,15,0,0,1,5.92-6.07,18.61,18.61,0,0,1,17.35,0,15,15,0,0,1,5.91,6.07,20.72,20.72,0,0,1,0,18.27,15,15,0,0,1-5.91,6.06A17.2,17.2,0,0,1,514.8,153.65Zm0-6a9.12,9.12,0,0,0,4.91-1.31,8.48,8.48,0,0,0,3.26-3.87,16.9,16.9,0,0,0,0-12.32,8.68,8.68,0,0,0-3.26-3.88A8.93,8.93,0,0,0,514.8,125a9.14,9.14,0,0,0-4.94,1.32,8.63,8.63,0,0,0-3.27,3.87,17,17,0,0,0,0,12.33,8.63,8.63,0,0,0,3.26,3.86,9.14,9.14,0,0,0,4.95,1.28Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <path
        d="M535.69,152.88V119.73h6.9v8.41h.24v24.75Zm20.57-21.11a8.58,8.58,0,0,0-.7-3.67,4.6,4.6,0,0,0-2-2.18,6.94,6.94,0,0,0-3.32-.72,6.86,6.86,0,0,0-3.56,1,7.25,7.25,0,0,0-2.73,3.15,12.09,12.09,0,0,0-1.07,5.37h-3.67v-7.26h2.2a15.21,15.21,0,0,1,2.41-4.51,10.33,10.33,0,0,1,8.46-4,11.85,11.85,0,0,1,6.06,1.47,9.57,9.57,0,0,1,3.83,4.09,13.23,13.23,0,0,1,1.31,6V152.9h-7.17Zm20.59,0a8.62,8.62,0,0,0-.7-3.68,4.56,4.56,0,0,0-2-2.18,7.16,7.16,0,0,0-3.34-.71,6.72,6.72,0,0,0-3.6,1,7.29,7.29,0,0,0-2.7,3.14,12.09,12.09,0,0,0-1,5.37h-3.68v-6.65l1.35-.06a16.33,16.33,0,0,1,2.55-4.67,11.65,11.65,0,0,1,3.9-3.21,11,11,0,0,1,5.13-1.18,12.13,12.13,0,0,1,6.16,1.46,9.42,9.42,0,0,1,3.85,4.08,13.39,13.39,0,0,1,1.31,6.1v22.28h-7.15Z"
        transform="translate(-2.85 -2.92)"
        fill="#fff"
      />
      <rect
        x="196.01"
        y="1.85"
        width="15.29"
        height="15.29"
        fill="#ff7f40"
      />
      <rect
        x="564.07"
        y="61.27"
        width="15.95"
        height="15.95"
        fill="#ff7f40"
      />
    </g>
  </svg>
</template>
