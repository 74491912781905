<template>
  <div
    :id="`video-${dynamicNumber}`"
    class="videoPopUp pop-up-scroll"
    :style="`background : ${customColor}; ${customGradient}`"
    :class="`${content.horizontal_margin} ${content.vertical_margin} mobile-remove-${content.mobile_remove_margin}`"

    @click="shoModalVideo"
  >
    <img
      v-if="$store.state.isMobile && content.mobile_thumbnail"
      :data-src="getImageKitURL(content.mobile_thumbnail, 1, content.image_type,false,position)"
      :src="getImageKitURL(content.mobile_thumbnail, 1, content.image_type,true,position)"
      :alt="content.mobile_thumbnail.alt"
      class="videoPopUp__image"
      :class="`black-${content.black_indicators}`"
      @mouseover="mouseEnter"
      @mouseleave="mouseLeave"
    >
    <img
      v-else
      :data-src="getImageKitURL(content.thumbnail, 1, content.image_type,false,position)"
      :src="getImageKitURL(content.thumbnail, 1, content.image_type,true,position)"
      :alt="content.thumbnail.alt"
      class="videoPopUp__image"
      :class="`black-${content.black_indicators}`"
      @mouseover="mouseEnter"
      @mouseleave="mouseLeave"
    >
    <span
      v-if="$store.state.isMobile"
      class="playMobile"
      :class="`black-${content.black_indicators} ${content.vertical_margin}`"
    >
      <!-- <textPlay /> -->
      <img
        v-if="content.black_indicators"
        src="/fonts/play_black.png"
        alt="indicators"
      >
      <img
        v-else
        src="/fonts/play_white.png"
        alt="indicators"
      >
    </span>
  </div>
</template>

<script>
/* Mixins */
import imageKit from "@/mixins/image-kit";
import marked from "@/mixins/marked";

export default {
  mixins: [imageKit, marked],
  props: {
    position:{
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {},
    },
    isProject: {
      type: Boolean,
    }
  },
  data() {
    return {
      showVideoModal: false,
      // mouse
      mouse: {},
    }
  },

  computed: {
    dynamicNumber(){
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if(this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },
  },

  mounted(){
    window.addEventListener("popstate",this.detectHistory);

  },

  methods: {
    shoModalVideo() {
      this.$store.state.video_id = this.content.vimeo_id;
      this.$store.state.video_autoplay = this.content.autoplay;
      this.$store.state.video_controls = this.content.controls;
      this.$store.state.video_loop = this.content.loop;
      this.$store.state.video_ratio = this.content.ratio;
      if(this.showVideoModal){
        setTimeout(() => {
          // this.showVideoModal = false;
          this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
        }, 800);
      }else {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", true);
      }

      window.history.pushState({id:1}, null, null);
    },

    mouseEnter(){

      this.$gsap.to('.cursorPlay', {opacity:1, duration: 0.2});

      if(this.content.black_indicators) {
        this.$gsap.to('.cursorPlay svg', {fill:'black', duration: 0});
      } else {
        this.$gsap.to('.cursorPlay svg', {fill:'white', duration: 0});
      }
    },

    mouseLeave(){
      this.$gsap.to('.cursorPlay', {opacity:0, duration: 0.2});
    },

    detectHistory() {
      this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
      document.body.classList.remove('horizontal');

      if(this.$store.state.isMobile) {
        document.querySelector('body').style.removeProperty('overflow');
        document.querySelector('body').style.removeProperty('position');
        document.querySelector('body').style.removeProperty('width');
        window.scrollTo(0, this.scrollPosition);
      }
    }
  },

};
</script>

<style lang="scss">
.videoPopUp {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 100vw;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  // cursor: cell;

  &.super {
    @include paddingSuper;
  }

  &.big {
    @include paddingBig;
  }

  &.standard {
    @include paddingMedium;
  }

  &.small {
    @include paddingSmall;
  }

    &.bottom-text {

    @include paddingMobileBottom;

    @include tablet {
       @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }

  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {

    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {

    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

  &__image {
    // cursor: url("/fonts/play-100.png"), auto;
    display: block;
    width: 100%;
    height: auto;
    cursor:none;

    &.black-true {
      // cursor: url("/fonts/play-100-black.png"), auto;
    }
  }

  .playMobile {
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 0;
    transform: translate(-50%,-50%);

    img {
      height: 56px;
      width: auto;
    }

    svg {
      fill: $white;
       width: auto;
      height: 47px;
    }

    &.black-true {
      svg {
        fill: $black;
      }

    }

    &.bottom-standard,
     &.bottom-big,
     &.bottom-super-big,
     &.bottom-small {
      @include paddingMobileBottom;

      @include tablet {
        padding-bottom: 0
      }

    }

  }

  &.mobile-remove-true {
    @media (max-width: 576px) {
      padding-bottom: 0;
    }
  }

  &:hover {
    .play {
      opacity: 1;
    }
  }
}
.js-modal-video {
  position: fixed;
  top:0;
  left: 0;
  z-index: 12;
}
</style>
