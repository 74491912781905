<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="25"
    viewBox="0 0 17 25"
  >
    <path
      d="M17,8.91A8.71,8.71,0,0,0,8.5,0,8.72,8.72,0,0,0,0,8.91a9.15,9.15,0,0,0,1.44,5v0h0L8.5,25l7.06-11.12A9.16,9.16,0,0,0,17,8.91Zm-8.5,5a4.85,4.85,0,0,1-4.74-5A4.85,4.85,0,0,1,8.5,4a4.86,4.86,0,0,1,4.73,5A4.84,4.84,0,0,1,8.5,13.88Z"
      fill="#ff7f40"
    />
  </svg>
</template>
