<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="265.09"
    height="82.04"
    viewBox="0 0 265.09 82.04"
  >
    <g id="txt">
      <path
        d="M25.52,82.42V2H43.09V82.42Zm9.94-33.56V33.61H85.38V48.86ZM77.08,82.42V2H94.65V82.42Z"
        transform="translate(-1.91 -1.97)"
      />
      <path
        d="M129.14,83.78a33.11,33.11,0,0,1-16-3.76A26.6,26.6,0,0,1,102.3,69.37,32.28,32.28,0,0,1,98.43,53.3a32.23,32.23,0,0,1,3.87-16,26.78,26.78,0,0,1,10.84-10.7,33,33,0,0,1,16-3.78,32.85,32.85,0,0,1,15.93,3.77,26.74,26.74,0,0,1,10.81,10.69,32.32,32.32,0,0,1,3.85,16,32.44,32.44,0,0,1-3.85,16.06A26.61,26.61,0,0,1,145.07,80,33,33,0,0,1,129.14,83.78Zm0-13.11a13.9,13.9,0,0,0,7.36-1.92,12.72,12.72,0,0,0,4.93-5.82,23.15,23.15,0,0,0,1.78-9.58,23.25,23.25,0,0,0-1.77-9.57,13,13,0,0,0-4.93-5.91,13.56,13.56,0,0,0-7.37-2,13.9,13.9,0,0,0-7.49,2,12.75,12.75,0,0,0-4.93,5.88,23.52,23.52,0,0,0-1.73,9.6,23.24,23.24,0,0,0,1.75,9.57,12.62,12.62,0,0,0,4.91,5.82A14.07,14.07,0,0,0,129.14,70.67Z"
        transform="translate(-1.91 -1.97)"
      />
      <path
        d="M163.55,82.42V2h16.3V82.42Z"
        transform="translate(-1.91 -1.97)"
      />
      <path
        d="M204.34,84a28.75,28.75,0,0,1-11-2A15.9,15.9,0,0,1,186,76.08a17.14,17.14,0,0,1-2.61-9.65,17,17,0,0,1,2.19-8.73,16,16,0,0,1,7.06-6.13,38.31,38.31,0,0,1,12.75-3.22l9.44-.93a17.35,17.35,0,0,0,4.5-1,5.57,5.57,0,0,0,2.66-2,5.78,5.78,0,0,0,.92-3.28l2.8,3.59a23.11,23.11,0,0,1-1,6.81,6.56,6.56,0,0,1-2.77,3.57,13.51,13.51,0,0,1-5.41,1.6l-7,.85A18.78,18.78,0,0,0,203.9,59a6.69,6.69,0,0,0-3,2.54,7.24,7.24,0,0,0-.93,3.78,7.38,7.38,0,0,0,1,4A6.56,6.56,0,0,0,204.06,72a13.46,13.46,0,0,0,5.14.86,16.16,16.16,0,0,0,6.88-1.44A11.37,11.37,0,0,0,221,67a13.43,13.43,0,0,0,1.84-7.27h3.33v7.4a21.61,21.61,0,0,1-3.77,9.11A17.9,17.9,0,0,1,215,82,26.69,26.69,0,0,1,204.34,84Zm33.34-1a18.75,18.75,0,0,1-8.23-1.55,9.48,9.48,0,0,1-4.57-4.83A21.7,21.7,0,0,1,223.44,68l-.59-1.42V41.23a6.52,6.52,0,0,0-1-3.72,5.76,5.76,0,0,0-3.13-2.19,23,23,0,0,0-11.69.07,6.27,6.27,0,0,0-3.44,2.67,11,11,0,0,0-1.4,5.1H185.93a22.35,22.35,0,0,1,3.15-11.09,17.84,17.84,0,0,1,8.78-6.91q6-2.4,15.35-2.4,9,0,14.77,2.15a16.65,16.65,0,0,1,8.36,6.1A16.8,16.8,0,0,1,239,40.56V67.42a4.2,4.2,0,0,0,.38,1.88,2.51,2.51,0,0,0,1.17,1.13,4.43,4.43,0,0,0,2,.38h2.37V82.42c-1.22.17-2.44.3-3.64.4S238.85,83,237.68,83Z"
        transform="translate(-1.91 -1.97)"
      />
      <polygon points="12.41 24.52 17.58 57.91 17.58 80.45 0 80.45 0 57.91 5.15 24.52 12.41 24.52" />
      <rect
        x="0.29"
        width="17.01"
        height="17.01"
        fill="#ff7f40"
      />
      <polygon points="252.69 55.93 247.52 22.54 247.52 0 265.09 0 265.09 22.54 259.95 55.93 252.69 55.93" />
      <rect
        x="247.8"
        y="63.44"
        width="17.01"
        height="17.01"
        fill="#ff7f40"
      />
    </g>
  </svg>
</template>
