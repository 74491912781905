<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 906 558"
  >
    <g id="txt">
      <g>
        <path
          d="M28,97.58,1.37.5H23.05l12.1,49.26q1.6,7.1,3.46,16.31t3.84,19.24h-4.4Q40.19,74.22,42,65t3.29-15.51L57.41.5H78.68L90.85,49.59q2,9,3.75,17.62T98,85.31H93.81q1.95-10.26,3.63-18.68T101,49.52l11.9-49h21.75L108.1,97.58h-23L71.32,42.94c-.89-3.75-1.79-7.89-2.73-12.41S66.68,21,65.66,15.36l5-.92q-1.64,8.93-3,15.82T64.81,42.87L51,97.58Z"
          fill="#fff"
        />
        <path
          d="M163.67,99.22a39.19,39.19,0,0,1-19-4.45A30.91,30.91,0,0,1,132,82q-4.47-8.28-4.47-19.53a40.73,40.73,0,0,1,4.43-19.34,31.52,31.52,0,0,1,12.56-13,37.83,37.83,0,0,1,19-4.6,37.43,37.43,0,0,1,18.92,4.6,31.14,31.14,0,0,1,12.37,12.9,40.58,40.58,0,0,1,4.33,19.07c0,.84,0,1.74-.07,2.69s-.1,2-.17,3H138.4V55.59h46.22l-4.87,2.76a23,23,0,0,0-1.93-9.59,14.58,14.58,0,0,0-5.57-6.41,16.6,16.6,0,0,0-9-2.3,17,17,0,0,0-9,2.28,14.71,14.71,0,0,0-5.76,6.87,28.87,28.87,0,0,0-2.06,11.51v5a24.74,24.74,0,0,0,2.06,10.64,14,14,0,0,0,6,6.49A19.42,19.42,0,0,0,164,85a19.87,19.87,0,0,0,7-1.12,13.09,13.09,0,0,0,5-3.22,13.35,13.35,0,0,0,3-5.06l18.58,0a29.92,29.92,0,0,1-6.41,12.58,30.24,30.24,0,0,1-11.61,8.18A41.61,41.61,0,0,1,163.67,99.22Z"
          fill="#fff"
        />
        <path
          d="M202.56,41.88V31.58l3.75-.07a7.23,7.23,0,0,0,3.78-1.16,6,6,0,0,0,2.18-3A15.36,15.36,0,0,0,213,22.2V17.56L216.47,21H202.56V.5H225V21.4a27,27,0,0,1-2.06,11.32,14.17,14.17,0,0,1-6.15,6.65,23.37,23.37,0,0,1-10.3,2.46Z"
          fill="#ff7f40"
        />
        <path
          d="M232.86,97.58V27.22H252V45.46l.51,0V97.58Zm16.57-43.83a37,37,0,0,1,2.51-13.86,22.19,22.19,0,0,1,7.74-10q5.24-3.75,13.07-3.75c.77,0,1.51,0,2.22.08s1.43.13,2.18.22v18c-.84,0-1.65,0-2.44,0s-1.54,0-2.27,0a24.53,24.53,0,0,0-10.77,2.15A14.88,14.88,0,0,0,254.89,53a23.13,23.13,0,0,0-2.35,11h-3.1V53.75Z"
          fill="#fff"
        />
        <path
          d="M315.73,99.22a39.19,39.19,0,0,1-19.05-4.45A30.91,30.91,0,0,1,284,82q-4.47-8.28-4.47-19.53A40.73,40.73,0,0,1,284,43.17a31.52,31.52,0,0,1,12.56-13,41.31,41.31,0,0,1,37.89,0,31.14,31.14,0,0,1,12.37,12.9,40.58,40.58,0,0,1,4.33,19.07c0,.84,0,1.74-.07,2.69s-.1,2-.17,3H290.46V55.59h46.22l-4.87,2.76a23,23,0,0,0-1.93-9.59,14.58,14.58,0,0,0-5.57-6.41,16.53,16.53,0,0,0-9-2.3,17,17,0,0,0-9,2.28,14.71,14.71,0,0,0-5.76,6.87,28.87,28.87,0,0,0-2.06,11.51v5a24.74,24.74,0,0,0,2.06,10.64,14,14,0,0,0,6,6.49A19.39,19.39,0,0,0,316,85a19.87,19.87,0,0,0,7-1.12,13.09,13.09,0,0,0,5-3.22,13.35,13.35,0,0,0,3-5.06l18.58,0a29.92,29.92,0,0,1-6.41,12.58,30.24,30.24,0,0,1-11.61,8.18A41.52,41.52,0,0,1,315.73,99.22Z"
          fill="#fff"
        />
        <path
          d="M411.65,99.53a34.48,34.48,0,0,1-13.33-2.39A19.17,19.17,0,0,1,389.44,90a20.61,20.61,0,0,1-3.15-11.64,20.43,20.43,0,0,1,2.64-10.53,19.5,19.5,0,0,1,8.52-7.4q5.88-2.9,15.39-3.89l11.39-1.12a21.08,21.08,0,0,0,5.44-1.18,6.8,6.8,0,0,0,3.2-2.37A6.93,6.93,0,0,0,434,47.92l3.38,4.33a28.1,28.1,0,0,1-1.21,8.22,8,8,0,0,1-3.34,4.31,16.39,16.39,0,0,1-6.53,1.92l-8.42,1a22.57,22.57,0,0,0-6.77,1.74,8.18,8.18,0,0,0-3.63,3.07,8.72,8.72,0,0,0-1.12,4.57,9,9,0,0,0,1.24,4.82,7.73,7.73,0,0,0,3.73,3,16.22,16.22,0,0,0,6.2,1,19.39,19.39,0,0,0,8.3-1.74A13.72,13.72,0,0,0,431.76,79,16.19,16.19,0,0,0,434,70.2h4v8.93a25.9,25.9,0,0,1-4.55,11,21.52,21.52,0,0,1-8.9,7A31.73,31.73,0,0,1,411.65,99.53Zm40.22-1.3a22.58,22.58,0,0,1-9.94-1.87,11.38,11.38,0,0,1-5.5-5.83,26,26,0,0,1-1.74-10.36l-.72-1.7V47.88a7.93,7.93,0,0,0-1.18-4.5A7,7,0,0,0,429,40.74a22.26,22.26,0,0,0-6.87-.87,22.75,22.75,0,0,0-7.24,1A7.71,7.71,0,0,0,410.74,44a13.37,13.37,0,0,0-1.69,6.15H389.42a27.17,27.17,0,0,1,3.8-13.38,21.57,21.57,0,0,1,10.6-8.33q7.17-2.89,18.53-2.9,10.9,0,17.81,2.59t10.09,7.36a20.3,20.3,0,0,1,3.19,11.52V79.47a4.93,4.93,0,0,0,.46,2.27,3,3,0,0,0,1.41,1.36,5.45,5.45,0,0,0,2.39.46h2.86v14c-1.48.2-2.94.36-4.4.48S453.28,98.23,451.87,98.23Z"
          fill="#fff"
        />
        <path
          d="M35.09,234.28A28.94,28.94,0,0,1,19,229.83,28.86,28.86,0,0,1,8.41,217.18a45.66,45.66,0,0,1-3.73-19.11A45.36,45.36,0,0,1,8.43,179a29.05,29.05,0,0,1,10.62-12.63,28.8,28.8,0,0,1,16-4.45q9.21,0,15.56,4.6a27.84,27.84,0,0,1,9.56,12.68,50.87,50.87,0,0,1,3.27,18.58v.27a51.56,51.56,0,0,1-3.17,18.77,27.84,27.84,0,0,1-9.55,12.78Q44.4,234.27,35.09,234.28ZM41.6,264A46.26,46.26,0,0,1,24,261a26.54,26.54,0,0,1-11.61-8.78,27.12,27.12,0,0,1-5-13.89l19.74,0a12.6,12.6,0,0,0,2.18,6.12,10.73,10.73,0,0,0,4.82,3.65,19.73,19.73,0,0,0,7.31,1.21,19.25,19.25,0,0,0,8.68-1.76,11.34,11.34,0,0,0,5.23-5.32,20.33,20.33,0,0,0,1.74-8.95V214.46h2V179.83h-1.5l0-16.26H77v68.65a33.91,33.91,0,0,1-4,17,26.64,26.64,0,0,1-12,11Q53,264,41.6,264Zm-.51-44.69a15.36,15.36,0,0,0,8.79-2.47,15.58,15.58,0,0,0,5.66-7.28,30.3,30.3,0,0,0,2-11.56,29.94,29.94,0,0,0-2-11.44,15.59,15.59,0,0,0-5.66-7.24,15.47,15.47,0,0,0-8.85-2.49,14.94,14.94,0,0,0-8.74,2.56,16,16,0,0,0-5.67,7.38,30.34,30.34,0,0,0-2,11.54,29.65,29.65,0,0,0,2,11.47,15.17,15.17,0,0,0,5.66,7.12A15.64,15.64,0,0,0,41.09,219.31Z"
          fill="#fff"
        />
        <path
          d="M83.42,233.94V136.86h19.67v97.08Z"
          fill="#fff"
        />
        <path
          d="M147.51,235.57A40.05,40.05,0,0,1,128.2,231a32.27,32.27,0,0,1-13.07-12.85,42.4,42.4,0,0,1,0-38.69,32.44,32.44,0,0,1,13.07-12.92A39.73,39.73,0,0,1,147.51,162a39.67,39.67,0,0,1,19.22,4.55,32.26,32.26,0,0,1,13,12.9,42.61,42.61,0,0,1,0,38.71,32.12,32.12,0,0,1-13,12.87A39.81,39.81,0,0,1,147.51,235.57Zm0-15.81a16.65,16.65,0,0,0,8.88-2.32,15.44,15.44,0,0,0,6-7,27.81,27.81,0,0,0,2.15-11.56,28.11,28.11,0,0,0-2.13-11.56,15.64,15.64,0,0,0-6-7.12,16.46,16.46,0,0,0-8.9-2.39,16.77,16.77,0,0,0-9,2.39,15.43,15.43,0,0,0-5.95,7.09,28.42,28.42,0,0,0-2.1,11.59,28,28,0,0,0,2.11,11.54,15.33,15.33,0,0,0,5.93,7A17,17,0,0,0,147.51,219.76Z"
          fill="#fff"
        />
        <path
          d="M191.07,233.94V136.86h19.67v43.8h-2.05v36.07h1.4l0,17.21Zm41.14,1.63q-9.21,0-15.42-4.72a28,28,0,0,1-9.27-13,54.89,54.89,0,0,1-3.05-19.11v-.27a53.83,53.83,0,0,1,3-19,27.67,27.67,0,0,1,9.31-12.9q6.24-4.67,15.41-4.67a28.88,28.88,0,0,1,16.19,4.53,29.24,29.24,0,0,1,10.65,12.85,47,47,0,0,1,3.73,19.43,47.23,47.23,0,0,1-3.72,19.45,28.07,28.07,0,0,1-26.86,17.37Zm-5.79-15.88a15.13,15.13,0,0,0,8.74-2.52,16,16,0,0,0,5.69-7.31,29.77,29.77,0,0,0,2-11.47,28.59,28.59,0,0,0-2-11.25,15.22,15.22,0,0,0-5.66-7,15.81,15.81,0,0,0-8.78-2.37,14.75,14.75,0,0,0-14.49,9.59,29.05,29.05,0,0,0-2,11.33,29.36,29.36,0,0,0,2,11.37,14.71,14.71,0,0,0,14.5,9.63Z"
          fill="#fff"
        />
        <path
          d="M293.33,235.88A34.48,34.48,0,0,1,280,233.49a19.17,19.17,0,0,1-8.88-7.14,22.72,22.72,0,0,1-.51-22.17,19.5,19.5,0,0,1,8.52-7.4q5.88-2.9,15.39-3.89l11.38-1.12a21,21,0,0,0,5.44-1.18,6.8,6.8,0,0,0,3.2-2.37,6.93,6.93,0,0,0,1.11-3.95L319,188.6a28.1,28.1,0,0,1-1.21,8.22,8,8,0,0,1-3.34,4.31,16.39,16.39,0,0,1-6.53,1.92l-8.42,1a22.57,22.57,0,0,0-6.77,1.74,8.18,8.18,0,0,0-3.63,3.07,8.72,8.72,0,0,0-1.12,4.57,8.91,8.91,0,0,0,1.24,4.82,7.73,7.73,0,0,0,3.73,3,16.24,16.24,0,0,0,6.21,1,19.39,19.39,0,0,0,8.3-1.74,13.72,13.72,0,0,0,5.95-5.27,16.19,16.19,0,0,0,2.22-8.78h4v8.93a25.9,25.9,0,0,1-4.55,11,21.52,21.52,0,0,1-8.9,7A32,32,0,0,1,293.33,235.88Zm40.23-1.3a22.58,22.58,0,0,1-9.94-1.87,11.44,11.44,0,0,1-5.51-5.83,26,26,0,0,1-1.74-10.36l-.72-1.7V184.23a7.93,7.93,0,0,0-1.18-4.5,7,7,0,0,0-3.78-2.64,22.26,22.26,0,0,0-6.87-.87,22.75,22.75,0,0,0-7.24.95,7.71,7.71,0,0,0-4.16,3.22,13.37,13.37,0,0,0-1.69,6.15H271.1a27.17,27.17,0,0,1,3.8-13.38,21.57,21.57,0,0,1,10.6-8.33q7.17-2.9,18.53-2.9,10.91,0,17.81,2.59t10.09,7.36a20.3,20.3,0,0,1,3.19,11.52v32.42a4.93,4.93,0,0,0,.46,2.27,3,3,0,0,0,1.41,1.36,5.45,5.45,0,0,0,2.39.46h2.86v14c-1.48.2-2.94.36-4.4.48S335,234.58,333.56,234.58Z"
          fill="#fff"
        />
        <path
          d="M348.52,233.94V136.86h19.67v97.08Z"
          fill="#fff"
        />
        <path
          d="M445.4,235.57a38.16,38.16,0,0,1-18.9-4.52A30.9,30.9,0,0,1,414,218.22a45.21,45.21,0,0,1,0-38.91,30.77,30.77,0,0,1,12.54-12.85,38.74,38.74,0,0,1,19.09-4.52,40,40,0,0,1,17.28,3.49,28.8,28.8,0,0,1,11.71,9.78,31.11,31.11,0,0,1,5.2,14.47H460a16.33,16.33,0,0,0-2.9-6.65,12.48,12.48,0,0,0-5.06-3.94,16.93,16.93,0,0,0-6.66-1.28,15.25,15.25,0,0,0-8.47,2.28,14.57,14.57,0,0,0-5.44,6.94,37.1,37.1,0,0,0,0,23.5,14.35,14.35,0,0,0,5.42,6.89,15.21,15.21,0,0,0,8.38,2.25,16.86,16.86,0,0,0,6.9-1.35,12.71,12.71,0,0,0,5.13-4.16,16.83,16.83,0,0,0,2.86-7.14h19.6a32.28,32.28,0,0,1-5.47,15,29.77,29.77,0,0,1-11.9,10A38.81,38.81,0,0,1,445.4,235.57Z"
          fill="#fff"
        />
        <path
          d="M487.5,233.94V163.58h19.16v18.24l.51,0v52.09Zm16.56-43.84a37,37,0,0,1,2.51-13.86,22.19,22.19,0,0,1,7.74-10q5.24-3.75,13.07-3.75c.77,0,1.51,0,2.21.08s1.43.13,2.18.22v18c-.84,0-1.65,0-2.44,0l-2.27,0a24.53,24.53,0,0,0-10.77,2.15,14.88,14.88,0,0,0-6.78,6.53,23.13,23.13,0,0,0-2.35,11h-3.1V190.1Z"
          fill="#fff"
        />
        <path
          d="M570.37,235.57a39.26,39.26,0,0,1-19.06-4.45,30.91,30.91,0,0,1-12.65-12.73q-4.47-8.28-4.46-19.53a40.73,40.73,0,0,1,4.43-19.34,31.52,31.52,0,0,1,12.56-13,37.83,37.83,0,0,1,19-4.6,37.43,37.43,0,0,1,18.92,4.6,31.14,31.14,0,0,1,12.37,12.9,40.58,40.58,0,0,1,4.33,19.07c0,.84,0,1.74-.07,2.69s-.1,2-.17,3H545.1V191.93h46.22l-4.87,2.76a22.85,22.85,0,0,0-1.93-9.59,14.58,14.58,0,0,0-5.57-6.41,16.64,16.64,0,0,0-9-2.3,17,17,0,0,0-9,2.28,14.71,14.71,0,0,0-5.76,6.87,28.87,28.87,0,0,0-2.06,11.51v5a24.74,24.74,0,0,0,2.06,10.64,14,14,0,0,0,6,6.49,19.42,19.42,0,0,0,9.46,2.16,19.87,19.87,0,0,0,7-1.12,13.09,13.09,0,0,0,5-3.22,13.35,13.35,0,0,0,3-5.06l18.58,0a29.92,29.92,0,0,1-6.41,12.58,30.24,30.24,0,0,1-11.61,8.18A41.14,41.14,0,0,1,570.37,235.57Z"
          fill="#fff"
        />
        <path
          d="M636.32,235.88A34.48,34.48,0,0,1,623,233.49a19.17,19.17,0,0,1-8.88-7.14,22.72,22.72,0,0,1-.51-22.17,19.5,19.5,0,0,1,8.52-7.4q5.88-2.9,15.39-3.89l11.39-1.12a21.08,21.08,0,0,0,5.44-1.18,6.24,6.24,0,0,0,4.31-6.32L662,188.6a28.1,28.1,0,0,1-1.21,8.22,8,8,0,0,1-3.34,4.31,16.39,16.39,0,0,1-6.53,1.92l-8.42,1a22.57,22.57,0,0,0-6.77,1.74,8.18,8.18,0,0,0-3.63,3.07,8.72,8.72,0,0,0-1.13,4.57,9,9,0,0,0,1.24,4.82,7.73,7.73,0,0,0,3.73,3,16.22,16.22,0,0,0,6.2,1,19.39,19.39,0,0,0,8.3-1.74,13.72,13.72,0,0,0,5.95-5.27,16.21,16.21,0,0,0,2.22-8.78h4v8.93a25.9,25.9,0,0,1-4.55,11,21.52,21.52,0,0,1-8.9,7A31.49,31.49,0,0,1,636.32,235.88Zm40.23-1.3a22.61,22.61,0,0,1-9.94-1.87,11.38,11.38,0,0,1-5.5-5.83,26,26,0,0,1-1.74-10.36l-.72-1.7V184.23a7.93,7.93,0,0,0-1.18-4.5,7,7,0,0,0-3.78-2.64,22.26,22.26,0,0,0-6.87-.87,22.7,22.7,0,0,0-7.24.95,7.71,7.71,0,0,0-4.16,3.22,13.37,13.37,0,0,0-1.69,6.15H614.1a27.17,27.17,0,0,1,3.8-13.38,21.57,21.57,0,0,1,10.6-8.33q7.17-2.9,18.53-2.9,10.9,0,17.81,2.59t10.09,7.36a20.22,20.22,0,0,1,3.19,11.52v32.42a4.93,4.93,0,0,0,.46,2.27,3,3,0,0,0,1.41,1.36,5.45,5.45,0,0,0,2.39.46h2.86v14c-1.48.2-2.94.36-4.4.48S678,234.58,676.55,234.58Z"
          fill="#fff"
        />
        <path
          d="M683.91,178.68v-15.1h44.66v15.1H683.91Zm34.84,55.7a40.38,40.38,0,0,1-13.4-1.86,14.64,14.64,0,0,1-7.74-5.93q-2.57-4.08-2.56-10.69V142h19.67V212.8a7,7,0,0,0,.84,3.72,4.43,4.43,0,0,0,2.54,1.86,16.46,16.46,0,0,0,4.67.53h5.8v15l-.49,0-.46,0c-1.5.14-3,.23-4.5.29S720.16,234.38,718.75,234.38Z"
          fill="#fff"
        />
        <path
          d="M736.78,156.66V139.41h19.67v17.25Zm0,77.28V163.58h19.67v70.36Z"
          fill="#fff"
        />
        <path
          d="M788.29,233.94l-26.45-70.36h21.1l12.41,37.16q2,6.34,3.75,12.82T803,229l-6.89.07q2-8.28,3.89-15.13t3.89-13.29l12.2-37.09h20.45L810.14,234H788.29Z"
          fill="#fff"
        />
        <path
          d="M870.3,235.57a39.26,39.26,0,0,1-19.06-4.45,30.91,30.91,0,0,1-12.65-12.73q-4.47-8.28-4.47-19.53a40.73,40.73,0,0,1,4.43-19.34,31.52,31.52,0,0,1,12.56-13,41.31,41.31,0,0,1,37.89,0,31.14,31.14,0,0,1,12.37,12.9,40.58,40.58,0,0,1,4.33,19.07c0,.84,0,1.74-.07,2.69s-.1,2-.17,3H845V191.93h46.22l-4.87,2.76a23,23,0,0,0-1.93-9.59,14.58,14.58,0,0,0-5.57-6.41,16.64,16.64,0,0,0-9-2.3,17,17,0,0,0-9,2.28,14.71,14.71,0,0,0-5.76,6.87,28.87,28.87,0,0,0-2.06,11.51v5a24.74,24.74,0,0,0,2.06,10.64,14,14,0,0,0,6,6.49,19.39,19.39,0,0,0,9.46,2.16,19.91,19.91,0,0,0,7-1.12,13.09,13.09,0,0,0,5-3.22,13.35,13.35,0,0,0,3-5.06l18.58,0a29.44,29.44,0,0,1-18,20.76A41,41,0,0,1,870.3,235.57Z"
          fill="#fff"
        />
        <path
          d="M34.51,383.79a41.41,41.41,0,0,1-17.66-3.61A29,29,0,0,1,4.68,370a26.48,26.48,0,0,1-4.4-15.08,26,26,0,0,1,2.9-12.34A29.07,29.07,0,0,1,11.55,333a56.9,56.9,0,0,1,13.89-7.38L36.11,335a51.85,51.85,0,0,0-8.47,5.25,19.13,19.13,0,0,0-5,5.76,14.72,14.72,0,0,0-1.77,7.23,13.44,13.44,0,0,0,1.93,7,13.68,13.68,0,0,0,5.62,5.16,19,19,0,0,0,8.85,1.93,24.61,24.61,0,0,0,6.85-.92A22.91,22.91,0,0,0,50,363.86a23.8,23.8,0,0,0,4.81-4l11.11,12.27A40.66,40.66,0,0,1,57,378.46a46.1,46.1,0,0,1-10.55,4A50,50,0,0,1,34.51,383.79ZM21,327.58a61.15,61.15,0,0,1-5.76-7.45A29.1,29.1,0,0,1,12,313.28a22.85,22.85,0,0,1-1.07-7,22.15,22.15,0,0,1,3.51-12.24,23.7,23.7,0,0,1,10-8.56,34.53,34.53,0,0,1,15.05-3.1,36.21,36.21,0,0,1,14.88,2.9,23.25,23.25,0,0,1,10.07,8.16A21,21,0,0,1,68,305.55,21.94,21.94,0,0,1,65.3,316a30.38,30.38,0,0,1-8,9.26,50.13,50.13,0,0,1-12.83,7.26L35,322.19a45.45,45.45,0,0,0,8.47-4.6,18.2,18.2,0,0,0,5-5.13,11,11,0,0,0,1.69-5.85,8.28,8.28,0,0,0-1.28-4.53A8.57,8.57,0,0,0,45.39,299a12.13,12.13,0,0,0-10.14,0A8.29,8.29,0,0,0,31.84,302a8.61,8.61,0,0,0-1.21,4.6,10.82,10.82,0,0,0,.68,3.61,19.55,19.55,0,0,0,2.1,4.07,38,38,0,0,0,3.6,4.59l56.55,62.76H69.21Zm41.24,24a36.3,36.3,0,0,0,2.42-5.91,40.3,40.3,0,0,0,1.4-6.17,58.51,58.51,0,0,0,.55-6.15H84.38a60,60,0,0,1-1.5,11.54,56.4,56.4,0,0,1-3.54,10.48A47.53,47.53,0,0,1,74,364.4Z"
          fill="#ff7f40"
        />
        <path
          d="M134,409.49V311.32h19l0,16.94h-1.4v36.1h2.05v45.13Zm41.15-26.18q-9.21,0-15.43-4.72a28,28,0,0,1-9.27-13,55.13,55.13,0,0,1-3.05-19.11v-.27a53.83,53.83,0,0,1,3-19,27.67,27.67,0,0,1,9.31-12.9q6.24-4.67,15.41-4.67a28.88,28.88,0,0,1,16.19,4.53A29.24,29.24,0,0,1,202,327.06a52.65,52.65,0,0,1,0,38.88,28.1,28.1,0,0,1-26.86,17.37Zm-5.8-15.89a15.13,15.13,0,0,0,8.74-2.52,16,16,0,0,0,5.69-7.31,29.77,29.77,0,0,0,2-11.47,28.59,28.59,0,0,0-2-11.25,15.22,15.22,0,0,0-5.66-7,15.81,15.81,0,0,0-8.78-2.37,14.75,14.75,0,0,0-14.49,9.59,29.05,29.05,0,0,0-2,11.33,29.36,29.36,0,0,0,2,11.37,14.71,14.71,0,0,0,14.5,9.63Z"
          fill="#fff"
        />
        <path
          d="M211.73,381.67V311.31h19.16v18.24l.51,0v52.09Zm16.56-43.83A37,37,0,0,1,230.8,324a22.19,22.19,0,0,1,7.74-10q5.24-3.75,13.07-3.75c.77,0,1.51,0,2.22.08s1.43.13,2.18.22v18c-.84,0-1.65,0-2.44,0l-2.27,0a24.53,24.53,0,0,0-10.77,2.15,14.88,14.88,0,0,0-6.78,6.53,23.13,23.13,0,0,0-2.35,11h-3.1V337.84Z"
          fill="#fff"
        />
        <path
          d="M294.8,383.31a40.05,40.05,0,0,1-19.31-4.53,32.27,32.27,0,0,1-13.07-12.85,42.4,42.4,0,0,1,0-38.69,32.44,32.44,0,0,1,13.07-12.92,43,43,0,0,1,38.53,0,32.26,32.26,0,0,1,13,12.9,42.61,42.61,0,0,1,0,38.71,32.12,32.12,0,0,1-13,12.87A39.78,39.78,0,0,1,294.8,383.31Zm0-15.82a16.65,16.65,0,0,0,8.88-2.32,15.44,15.44,0,0,0,5.95-7,27.81,27.81,0,0,0,2.15-11.56A28.11,28.11,0,0,0,309.65,335a15.64,15.64,0,0,0-5.95-7.12,16.46,16.46,0,0,0-8.9-2.39,16.79,16.79,0,0,0-9,2.39,15.43,15.43,0,0,0-5.95,7.09,28.42,28.42,0,0,0-2.1,11.59,28,28,0,0,0,2.11,11.54,15.33,15.33,0,0,0,5.93,7A17,17,0,0,0,294.8,367.49Z"
          fill="#fff"
        />
        <path
          d="M368.94,383.31a28.88,28.88,0,0,1-16.19-4.53,29.29,29.29,0,0,1-10.67-12.87,52.16,52.16,0,0,1,0-38.84,29.28,29.28,0,0,1,10.67-12.85,28.83,28.83,0,0,1,16.16-4.53q9.13,0,15.37,4.69a28.13,28.13,0,0,1,9.34,12.9,54.06,54.06,0,0,1,3.14,19v.27a54.69,54.69,0,0,1-3.07,19.09,28,28,0,0,1-9.31,13Q378.14,383.32,368.94,383.31Zm5.8-15.82a15.43,15.43,0,0,0,8.79-2.46,15.65,15.65,0,0,0,5.66-7.14,33.68,33.68,0,0,0,0-22.7,15.42,15.42,0,0,0-5.66-7.18,15.61,15.61,0,0,0-8.85-2.46,15.13,15.13,0,0,0-8.74,2.52,15.89,15.89,0,0,0-5.68,7.29,29.93,29.93,0,0,0-2,11.49,28.6,28.6,0,0,0,2,11.27,15.06,15.06,0,0,0,5.66,7A15.85,15.85,0,0,0,374.74,367.49Zm16.32,14.18,0-17.21h1.4V328.39h-2v-43.8h19.67v97.08Z"
          fill="#fff"
        />
        <path
          d="M440.83,383.31a27.41,27.41,0,0,1-13-2.9,19.57,19.57,0,0,1-8.39-8.78q-3-5.88-2.95-14.64V311.31h19.67v42.75a15.23,15.23,0,0,0,1.38,6.87,9.29,9.29,0,0,0,4.06,4.16,14,14,0,0,0,6.56,1.41,14.59,14.59,0,0,0,6.94-1.72,13.35,13.35,0,0,0,5.32-5.3,17.4,17.4,0,0,0,2.06-8.83h5.9v16.87h-3.48a27.13,27.13,0,0,1-5.57,8.4,24,24,0,0,1-8.18,5.47A26.88,26.88,0,0,1,440.83,383.31ZM463,381.67l0-15.24h-.58V311.31h19.67v70.36Z"
          fill="#fff"
        />
        <path
          d="M523.32,383.31a38.16,38.16,0,0,1-18.9-4.52A30.9,30.9,0,0,1,491.93,366a45.21,45.21,0,0,1,0-38.91,30.77,30.77,0,0,1,12.54-12.85,38.74,38.74,0,0,1,19.09-4.52,40,40,0,0,1,17.28,3.49A28.8,28.8,0,0,1,552.55,323a31.11,31.11,0,0,1,5.2,14.47H537.94a16.33,16.33,0,0,0-2.9-6.65,12.48,12.48,0,0,0-5.06-3.94,16.93,16.93,0,0,0-6.66-1.28,15.25,15.25,0,0,0-8.47,2.28,14.57,14.57,0,0,0-5.44,6.94,37.1,37.1,0,0,0,0,23.5,14.35,14.35,0,0,0,5.42,6.89,15.21,15.21,0,0,0,8.38,2.25,16.86,16.86,0,0,0,6.9-1.35,12.71,12.71,0,0,0,5.13-4.16,16.83,16.83,0,0,0,2.86-7.14h19.6a32.28,32.28,0,0,1-5.47,15,29.77,29.77,0,0,1-11.9,10A38.78,38.78,0,0,1,523.32,383.31Z"
          fill="#fff"
        />
        <path
          d="M560.55,326.42v-15.1h44.66v15.1H560.55Zm34.84,55.7a40.38,40.38,0,0,1-13.4-1.86,14.6,14.6,0,0,1-7.74-5.93q-2.55-4.08-2.56-10.69V289.77h19.67v70.77a7,7,0,0,0,.84,3.72,4.43,4.43,0,0,0,2.54,1.86,16.46,16.46,0,0,0,4.67.53h5.8v15l-.49,0-.46,0c-1.5.14-3,.23-4.5.29S596.79,382.12,595.39,382.12Z"
          fill="#fff"
        />
        <path
          d="M612.06,304.4V287.15h19.67V304.4Zm0,77.27V311.31h19.67v70.36Z"
          fill="#fff"
        />
        <path
          d="M676.14,383.31a40.05,40.05,0,0,1-19.31-4.53,32.27,32.27,0,0,1-13.07-12.85,42.4,42.4,0,0,1,0-38.69,32.44,32.44,0,0,1,13.07-12.92,43,43,0,0,1,38.54,0,32.26,32.26,0,0,1,13,12.9,42.61,42.61,0,0,1,0,38.71,32.12,32.12,0,0,1-13,12.87A39.82,39.82,0,0,1,676.14,383.31Zm0-15.82a16.65,16.65,0,0,0,8.88-2.32,15.44,15.44,0,0,0,6-7,27.81,27.81,0,0,0,2.15-11.56A28.11,28.11,0,0,0,691,335a15.64,15.64,0,0,0-6-7.12,16.46,16.46,0,0,0-8.9-2.39,16.79,16.79,0,0,0-9,2.39,15.43,15.43,0,0,0-6,7.09,28.42,28.42,0,0,0-2.1,11.59,28,28,0,0,0,2.11,11.54,15.33,15.33,0,0,0,5.93,7A17,17,0,0,0,676.14,367.49Z"
          fill="#fff"
        />
        <path
          d="M719,381.67V311.31h19.12l0,15.24.51,0v55.09Zm46-42.74a15.23,15.23,0,0,0-1.38-6.87,9.34,9.34,0,0,0-4-4.16,13.92,13.92,0,0,0-6.58-1.41,14.63,14.63,0,0,0-6.92,1.72,13.25,13.25,0,0,0-5.33,5.3,17.4,17.4,0,0,0-2.06,8.83h-5.86V325.47h3.44a26.49,26.49,0,0,1,5.54-8.39,24.28,24.28,0,0,1,8.2-5.47,27,27,0,0,1,10.29-1.93,27.52,27.52,0,0,1,13.06,2.9,19.47,19.47,0,0,1,8.39,8.78q2.93,5.88,2.93,14.64v45.68H765V338.93Z"
          fill="#fff"
        />
        <path
          d="M40.47,531.05a38.19,38.19,0,0,1-18.9-4.52A30.9,30.9,0,0,1,9.08,513.7a45.21,45.21,0,0,1,0-38.91,30.73,30.73,0,0,1,12.55-12.85,38.74,38.74,0,0,1,19.09-4.52A40,40,0,0,1,58,460.91a28.8,28.8,0,0,1,11.71,9.78,31.11,31.11,0,0,1,5.2,14.47H55.1a16.33,16.33,0,0,0-2.9-6.65,12.48,12.48,0,0,0-5.06-3.94,16.93,16.93,0,0,0-6.66-1.28A15.27,15.27,0,0,0,32,475.57a14.57,14.57,0,0,0-5.44,6.94,37.1,37.1,0,0,0,0,23.5A14.35,14.35,0,0,0,32,512.9a15.22,15.22,0,0,0,8.39,2.25,16.86,16.86,0,0,0,6.9-1.35,12.71,12.71,0,0,0,5.13-4.16,16.83,16.83,0,0,0,2.86-7.14h19.6a32.28,32.28,0,0,1-5.47,15,29.77,29.77,0,0,1-11.9,10A38.93,38.93,0,0,1,40.47,531.05Z"
          fill="#fff"
        />
        <path
          d="M118,531.05a40.05,40.05,0,0,1-19.31-4.53,32.27,32.27,0,0,1-13.07-12.85,42.4,42.4,0,0,1,0-38.69,32.44,32.44,0,0,1,13.07-12.92A39.73,39.73,0,0,1,118,457.49,39.67,39.67,0,0,1,137.17,462a32.26,32.26,0,0,1,13,12.9,42.61,42.61,0,0,1,0,38.71,32.12,32.12,0,0,1-13,12.87A39.75,39.75,0,0,1,118,531.05Zm0-15.82a16.65,16.65,0,0,0,8.88-2.32,15.44,15.44,0,0,0,6-7,27.81,27.81,0,0,0,2.15-11.56,28.11,28.11,0,0,0-2.13-11.56,15.64,15.64,0,0,0-6-7.12,16.46,16.46,0,0,0-8.9-2.39,16.79,16.79,0,0,0-9,2.39,15.43,15.43,0,0,0-6,7.09,28.42,28.42,0,0,0-2.1,11.59A28,28,0,0,0,103,505.87a15.33,15.33,0,0,0,5.93,7A17,17,0,0,0,118,515.23Z"
          fill="#fff"
        />
        <path
          d="M160.83,529.41V459.05H180l0,18.24.51,0v52.09Zm43.77-42.85a16.63,16.63,0,0,0-1.24-6.8,8.67,8.67,0,0,0-3.7-4.12,12.17,12.17,0,0,0-6-1.38,12.2,12.2,0,0,0-11.24,7.17,20.77,20.77,0,0,0-1.88,9.36H174V474h4.23a28.31,28.31,0,0,1,5.3-9,22.35,22.35,0,0,1,7.64-5.61,23.76,23.76,0,0,1,9.7-1.93,25.28,25.28,0,0,1,12.42,2.91,19.67,19.67,0,0,1,8.1,8.4,28,28,0,0,1,2.83,13V529.4H204.6Zm43.77,0a16.76,16.76,0,0,0-1.24-6.82,8.66,8.66,0,0,0-3.68-4.13,12.29,12.29,0,0,0-6.08-1.36,12.19,12.19,0,0,0-11.27,7.19,20.8,20.8,0,0,0-1.86,9.34h-6.48V474.21l2.49-.07a32.48,32.48,0,0,1,5.62-8.76,24.51,24.51,0,0,1,8-5.86,24.23,24.23,0,0,1,10.21-2.11,26,26,0,0,1,12.82,3,20,20,0,0,1,8.25,8.64A29.21,29.21,0,0,1,268,482.43v47H248.37V486.56Z"
          fill="#fff"
        />
        <path
          d="M275.68,557.23V459.06h19l0,16.94h-1.4v36.1h2.05v45.13Zm41.14-26.18q-9.21,0-15.43-4.72a28,28,0,0,1-9.27-13,55.13,55.13,0,0,1-3.05-19.11V494a53.83,53.83,0,0,1,3-19,27.67,27.67,0,0,1,9.31-12.9q6.24-4.66,15.41-4.67A28.88,28.88,0,0,1,333,462a29.24,29.24,0,0,1,10.65,12.85,52.65,52.65,0,0,1,0,38.88,28.1,28.1,0,0,1-26.86,17.37ZM311,515.16a15.13,15.13,0,0,0,8.74-2.52,16,16,0,0,0,5.69-7.31,29.77,29.77,0,0,0,2-11.47,28.59,28.59,0,0,0-2-11.25,15.22,15.22,0,0,0-5.66-7,15.81,15.81,0,0,0-8.78-2.37,14.75,14.75,0,0,0-14.49,9.59,29.05,29.05,0,0,0-2,11.33,29.36,29.36,0,0,0,2,11.37,14.71,14.71,0,0,0,14.5,9.63Z"
          fill="#fff"
        />
        <path
          d="M377.26,531.35A34.48,34.48,0,0,1,363.93,529a19.17,19.17,0,0,1-8.88-7.14,22.72,22.72,0,0,1-.51-22.17,19.5,19.5,0,0,1,8.52-7.4q5.88-2.89,15.39-3.89l11.39-1.12a21.08,21.08,0,0,0,5.44-1.18,6.24,6.24,0,0,0,4.31-6.32l3.37,4.33a28.1,28.1,0,0,1-1.21,8.22,8,8,0,0,1-3.34,4.31,16.39,16.39,0,0,1-6.53,1.92l-8.42,1a22.57,22.57,0,0,0-6.77,1.74,8.18,8.18,0,0,0-3.63,3.07,8.72,8.72,0,0,0-1.13,4.57,9,9,0,0,0,1.24,4.82,7.73,7.73,0,0,0,3.73,3,16.22,16.22,0,0,0,6.2,1,19.39,19.39,0,0,0,8.3-1.74,13.72,13.72,0,0,0,6-5.27,16.19,16.19,0,0,0,2.22-8.78h4V511a25.9,25.9,0,0,1-4.55,11,21.52,21.52,0,0,1-8.9,7A31.56,31.56,0,0,1,377.26,531.35Zm40.22-1.29a22.61,22.61,0,0,1-9.94-1.87,11.38,11.38,0,0,1-5.5-5.83A26,26,0,0,1,400.3,512l-.72-1.7V479.72a7.93,7.93,0,0,0-1.18-4.5,7,7,0,0,0-3.78-2.64,22.26,22.26,0,0,0-6.87-.87,22.7,22.7,0,0,0-7.24,1,7.71,7.71,0,0,0-4.16,3.22,13.37,13.37,0,0,0-1.69,6.15H355a27.17,27.17,0,0,1,3.8-13.38,21.57,21.57,0,0,1,10.6-8.33q7.17-2.9,18.53-2.9,10.91,0,17.81,2.59t10.09,7.36A20.22,20.22,0,0,1,419,478.89v32.42a4.93,4.93,0,0,0,.46,2.27,3,3,0,0,0,1.41,1.36,5.45,5.45,0,0,0,2.39.46h2.86v14c-1.48.2-2.94.36-4.4.48S418.89,530.06,417.48,530.06Z"
          fill="#fff"
        />
        <path
          d="M433.13,529.41V459.05h19.12l0,15.24.51,0v55.09Zm46-42.75a15.23,15.23,0,0,0-1.38-6.87,9.34,9.34,0,0,0-4-4.16,13.92,13.92,0,0,0-6.58-1.41,14.63,14.63,0,0,0-6.92,1.72,13.25,13.25,0,0,0-5.33,5.3,17.4,17.4,0,0,0-2.06,8.83h-5.86V473.2h3.44a26.49,26.49,0,0,1,5.54-8.39,24.28,24.28,0,0,1,8.2-5.47,27,27,0,0,1,10.29-1.93,27.52,27.52,0,0,1,13.06,2.9,19.47,19.47,0,0,1,8.39,8.78q2.92,5.88,2.93,14.64v45.68H479.12V486.66Z"
          fill="#fff"
        />
        <path
          d="M503.73,542.26h9.17a13.78,13.78,0,0,0,5.23-.89,8.3,8.3,0,0,0,3.51-2.64,14.54,14.54,0,0,0,2.2-4.35l3.37-10.06.2,11.86L498.67,459h21.1l11.56,37.09q1.74,5.7,3.27,11.74t3,12.32l-3.68.07c1.23-5,2.36-9.46,3.41-13.47s2-7.56,2.93-10.67L551.58,459h20.56L544,536.24q-3.11,8.46-6.82,12.94a20.38,20.38,0,0,1-9.34,6.39q-5.62,1.9-14.86,1.91c-1.57,0-3.08,0-4.53-.07s-3-.11-4.7-.2v-15Z"
          fill="#fff"
        />
        <path
          d="M563.45,529.41V508.89h22.43v20.52Z"
          fill="#ff7f40"
        />
      </g>
    </g>
  </svg>
</template>
