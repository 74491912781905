<template>
  <div class="checkbox__container">
    <div
      class="checkbox"
      :class="{ active: checkActive }"
    />
    <input
      v-model="proxyChecked"
      type="checkbox"
      :value="value"
      class="main-check"
      @click="toggleCheck"
    >
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

  props: {
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    value: {
      type: [Array, Boolean],
      default: null,
    },
  },
  emits: ["update:checked"],

  data() {
    return {
      checkActive: false,
    };
  },

  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },

      set(val) {
        this.$emit("update:checked", val);
      },
    },
  },

  methods: {
    toggleCheck() {
      this.checkActive = !this.checkActive;
    },
  },
});
</script>
