<template>
  <div
    :id="`three-columns-${dynamicNumber}`"
    class="threeColumns"
    :style="`background : ${customColor}; ${customGradient}`"
    :class="`${content.horizontal_margin} ${content.vertical_margin} ${content.vertical_align} middle-${content.middle_margin} reverse-${content.mobile_reverse_order} mobile-remove-${content.mobile_remove_margin}`"
  >
    <div
      id="column-left"
      class="threeColumns__left"
      :class="columnLeft.mobile_settings"
      :style="`padding-bottom: ${mobileMargin}; width:${customWidthLeft}%`"
    >
      <div
        v-if="columnLeft.content === 'image' || columnLeft.content === 'lossless-image' || columnLeft.content === 'original-image' || columnLeft.content === 'hq-image'"
        class="threeColumns__left--image"
        :style="customPaddingLeft"
      >
        <img
          v-if="$store.state.isMobile && columnLeft.alternate_content && columnLeft.mobile_image"
          :data-src="getImageKitURL(columnLeft.mobile_image, 1, columnLeft.content,false,position)"
          :src="getImageKitURL(columnLeft.mobile_image, 1, columnLeft.content,true,position)"
          :alt="columnLeft.mobile_image.alt"
          class="oneColumn__image--item"
        >
        <img
          v-else
          :data-src="getImageKitURL(columnLeft.image, 3, columnLeft.content,false,position)"
          :src="getImageKitURL(columnLeft.image, 3, columnLeft.content,true,position)"
          :alt="columnLeft.image.alt"
          class="threeColumns__left--image--item"
        >
      </div>


      <div
        v-if="columnLeft.content === 'description'"
        class="main-text threeColumns__description"
        :style="`color : ${customLeftColor}`"
        :class="columnLeft.description_padding"
        v-html="markdownToHtml(columnLeft.description)"
      />

      <div
        v-if="columnLeft.content === 'custom-description'"
        class="main-text oneColumn__custom-description"
        :style="`color : ${customLeftColor}`"
        :class="columnLeft.description_padding"

        v-html="columnLeft.custom_description"
      />

      <div
        v-if="columnLeft.content === 'empty'"
        :class="{'hidden': $store.state.isMobile}"
      />

      <div
        v-if="columnLeft.content === 'bg-video'"
        class="threeColumns__video"
      >
        <backgroundVideo
          v-if="$store.state.isMobile && columnLeft.alternate_content && columnLeft.mobile_vimeo_id"
          :vimeo_id="columnLeft.mobile_vimeo_id"
          :vimeo_ratio="columnLeft.mobile_vimeo_ratio"
          :content-width="customWidthLeft"
        />

        <backgroundVideo
          v-else-if="columnLeft.vimeo_id"
          :vimeo_id="columnLeft.vimeo_id"
          :vimeo_ratio="columnLeft.vimeo_ratio"
          :content-width="customWidthLeft"
        />
      </div>

      <div
        v-if="columnLeft.content === 'pop-up-video'"
        :style="customPaddingLeft"
        class="oneColumn__pop"
        @click="shoModalVideoLeft"
      >
        <img
          v-if="columnLeft.pop_up_image"
          :data-src="getImageKitURL(columnLeft.pop_up_image, 3, columnLeft.content,false,position)"
          :src="getImageKitURL(columnLeft.pop_up_image, 3, columnLeft.content,true,position)"
          :alt="columnLeft.pop_up_image.alt"
          class="oneColumn__pop--image"
        >
        <img
          class="oneColumn__pop--play"
          src="/fonts/play_white.png"
          alt="indicators"
        >
        <img
          v-if="columnLeft.pop_up_rollover"
          :style="customPaddingLeft"
          :data-src="getImageKitURL(columnLeft.pop_up_rollover, 3, columnLeft.content,false,position)"
          :src="getImageKitURL(columnLeft.pop_up_rollover, 3, columnLeft.content,true,position)"
          :alt="columnLeft.pop_up_rollover.alt"
          class="oneColumn__pop--rollover"
        >
      </div>
    </div>

    <div
      id="column-center"
      class="threeColumns__center"
      :class="columnCenter.mobile_settings"
      :style="`padding-bottom: ${mobileMargin}; width:${customWidthCenter}%`"
    >
      <div
        v-if="columnCenter.content === 'image' || columnCenter.content === 'lossless-image' || columnCenter.content === 'original-image' || columnCenter.content === 'hq-image'"
        class="threeColumns__center--image"
        :style="customPaddingCenter"
      >
        <img
          v-if="$store.state.isMobile && columnCenter.alternate_content && columnCenter.mobile_image"
          :data-src="getImageKitURL(columnCenter.mobile_image, 1, columnCenter.content,false,position)"
          :src="getImageKitURL(columnCenter.mobile_image, 1, columnCenter.content,true,position)"
          :alt="columnCenter.mobile_image.alt"
          class="oneColumn__image--item"
        >
        <img
          v-else
          :data-src="getImageKitURL(columnCenter.image, 3, columnCenter.content,false,position)"
          :src="getImageKitURL(columnCenter.image, 3, columnCenter.content,true,position)"
          :alt="columnCenter.image.alt"
          class="threeColumns__center--image--item"
        >
      </div>

      <div
        v-if="columnCenter.content === 'description'"
        class="main-text threeColumns__description"
        :style="`color : ${customCenterColor}`"
        :class="columnCenter.description_padding"
        v-html="markdownToHtml(columnCenter.description)"
      />

      <div
        v-if="columnCenter.content === 'custom-description'"
        class="main-text oneColumn__custom-description"
        :style="`color : ${customCenterColor}`"
        :class="columnCenter.description_padding"

        v-html="columnCenter.custom_description"
      />

      <div
        v-if="columnLeft.content === 'empty'"
        :class="{'hidden': $store.state.isMobile}"
      />

      <div
        v-if="columnCenter.content === 'bg-video'"
        class="threeColumns__video"
      >
        <!-- {{ content }} -->
        <backgroundVideo
          v-if="$store.state.isMobile && columnCenter.alternate_content && columnCenter.mobile_vimeo_id"
          :vimeo_id="columnCenter.mobile_vimeo_id"
          :vimeo_ratio="columnCenter.mobile_vimeo_ratio"
          :content-width="customWidthCenter"
        />

        <backgroundVideo
          v-else-if="columnCenter.vimeo_id"
          :vimeo_id="columnCenter.vimeo_id"
          :vimeo_ratio="columnCenter.vimeo_ratio"
          :content-width="customWidthCenter"
        />
      </div>

      <div
        v-if="columnCenter.content === 'pop-up-video'"
        :style="customPaddingCenter"
        class="oneColumn__pop"
        @click="shoModalVideoCenter"
      >
        <img
          v-if="columnCenter.pop_up_image"
          :data-src="getImageKitURL(columnCenter.pop_up_image, 3, columnCenter.content,false,position)"
          :src="getImageKitURL(columnCenter.pop_up_image, 3, columnCenter.content,true,position)"
          :alt="columnCenter.pop_up_image.alt"
          class="oneColumn__pop--image"
        >
        <img
          class="oneColumn__pop--play"
          src="/fonts/play_white.png"
          alt="indicators"
        >
        <img
          v-if="columnCenter.pop_up_rollover"
          :style="customPaddingCenter"
          :data-src="getImageKitURL(columnCenter.pop_up_rollover, 3, columnCenter.content,false,position)"
          :src="getImageKitURL(columnCenter.pop_up_rollover, 3, columnCenter.content,true,position)"
          :alt="columnCenter.pop_up_rollover.alt"
          class="oneColumn__pop--rollover"
        >
      </div>
    </div>

    <div
      id="column-right"
      class="threeColumns__right"
      :class="columnRight.mobile_settings"
      :style="`${customPaddingRight}; width:${customWidthRight}%`"
    >
      <div
        v-if="columnRight.content === 'image' || columnRight.content === 'lossless-image' || columnRight.content === 'original-image' || columnRight.content === 'hq-image'"
        class="threeColumns__right--image"
      >
        <img
          v-if="$store.state.isMobile && columnRight.alternate_content && columnRight.mobile_image"
          :data-src="getImageKitURL(columnRight.mobile_image, 1, columnRight.content,false,position)"
          :src="getImageKitURL(columnRight.mobile_image, 1, columnRight.content,true,position)"
          :alt="columnRight.mobile_image.alt"
          class="oneColumn__image--item"
        >

        <img
          v-else
          :data-src="getImageKitURL(columnRight.image, 3, columnRight.content,false,position)"
          :src="getImageKitURL(columnRight.image, 3, columnRight.content,true,position)"
          :alt="columnRight.image.alt"
          class="threeColumns__right--image--item"
        >
      </div>

      <div
        v-if="columnRight.content === 'description'"
        class="main-text threeColumns__description"
        :style="`color : ${customRightColor}`"
        :class="columnRight.description_padding"
        v-html="markdownToHtml(columnRight.description)"
      />

      <div
        v-if="columnRight.content === 'custom-description'"
        class="main-text oneColumn__custom-description"
        :style="`color : ${customRightColor}`"
        :class="columnRight.description_padding"

        v-html="columnRight.custom_description"
      />

      <div
        v-if="columnLeft.content === 'empty'"
        :class="{'hidden': $store.state.isMobile}"
      />

      <div
        v-if="columnRight.content === 'bg-video'"
        class="threeColumns__video"
      >
        <backgroundVideo
          v-if="$store.state.isMobile && columnRight.alternate_content_right && columnRight.mobile_vimeo_id"
          :vimeo_id="columnRight.mobile_vimeo_id"
          :vimeo_ratio="columnRight.mobile_vimeo_ratio"
          :content-width="customWidthRight"
        />

        <backgroundVideo
          v-else-if="columnRight.vimeo_id"
          :vimeo_id="columnRight.vimeo_id"
          :vimeo_ratio="columnRight.vimeo_ratio"
          :content-width="customWidthRight"
        />
      </div>

      <div
        v-if="columnRight.content === 'pop-up-video'"
        :style="customPaddingRight"
        class="oneColumn__pop"
        @click="shoModalVideoRight"
      >
        <img
          v-if="columnRight.pop_up_image"
          :data-src="getImageKitURL(columnRight.pop_up_image, 3, columnRight.content,false,position)"
          :src="getImageKitURL(columnRight.pop_up_image, 3, columnRight.content,true,position)"
          :alt="columnRight.pop_up_image.alt"
          class="oneColumn__pop--image"
        >
        <img
          class="oneColumn__pop--play"
          src="/fonts/play_white.png"
          alt="indicators"
        >
        <img
          v-if="columnRight.pop_up_rollover"
          :style="customPaddingRight"
          :data-src="getImageKitURL(columnRight.pop_up_rollover, 3, columnRight.content,false,position)"
          :src="getImageKitURL(columnRight.pop_up_rollover, 3, columnRight.content,true,position)"
          :alt="columnRight.pop_up_rollover.alt"
          class="oneColumn__pop--rollover"
        >
      </div>
    </div>
    <!-- {{ content }} -->
  </div>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";
import imageKit from "@/mixins/image-kit";

export default {
  mixins: [marked, imageKit],
  props: {
    position:{
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {}
    },
    isProject: {
      type: Boolean
    }
  },
  data() {
    return {
      showVideoModalLeft: false,
      showVideoModalCenter: false,
      showVideoModalRight: false,
      // mouse
      mouse: {},
    }
  },
  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if (this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customLeftColor() {
      if (this.columnLeft.description_color) {
        return this.columnLeft.description_color;
      } else {
        return "#000";
      }
    },

    customCenterColor() {
      if (this.columnCenter.description_color) {
        return this.columnCenter.description_color;
      } else {
        return "#000";
      }
    },

    customRightColor() {
      if (this.columnRight.description_color) {
        return this.columnRight.description_color;
      } else {
        return "#000";
      }
    },

    customPaddingLeft() {
      if (this.$store.state.isMobile) {
        return "padding:0";
      } else if (this.columnLeft.padding_settings === "img-pd-left") {
        return `padding-left : ${this.columnLeft.padding_width}vw`;
      } else if (this.columnLeft.padding_settings === "img-pd-right") {
        return `padding-right : ${this.columnLeft.padding_width}vw`;
      } else if (this.columnLeft.padding_settings === "img-pd-top") {
        return `padding-top : ${this.columnLeft.padding_width}vw`;
      } else if (this.columnLeft.padding_settings === "img-pd-bottom") {
        return `padding-bottom : ${this.columnLeft.padding_width}vw`;
      } else {
        return "padding:0";
      }
    },

    customPaddingCenter() {
      if (this.$store.state.isMobile) {
        return "padding:0";
      } else if (this.columnCenter.padding_settings === "img-pd-left") {
        return `padding-left : ${this.columnCenter.padding_width}vw`;
      } else if (this.columnCenter.padding_settings === "img-pd-right") {
        return `padding-right : ${this.columnCenter.padding_width}vw`;
      } else if (this.columnCenter.padding_settings === "img-pd-top") {
        return `padding-top : ${this.columnCenter.padding_width}vw`;
      } else if (this.columnCenter.padding_settings === "img-pd-bottom") {
        return `padding-bottom : ${this.columnCenter.padding_width}vw`;
      } else {
        return "padding:0";
      }
    },

    customPaddingRight() {
      if (this.$store.state.isMobile) {
        return "padding:0";
      } else if (this.columnRight.padding_settings === "img-pd-left") {
        return `padding-left : ${this.columnRight.padding_width}vw`;
      } else if (this.columnRight.padding_settings === "img-pd-right") {
        return `padding-right : ${this.columnRight.padding_width}vw`;
      } else if (this.columnRight.padding_settings === "img-pd-top") {
        return `padding-top : ${this.columnRight.padding_width}vw`;
      } else if (this.columnRight.padding_settings === "img-pd-bottom") {
        return `padding-bottom : ${this.columnRight.padding_width}vw`;
      } else {
        return "padding:0";
      }
    },

    mobileMargin() {
      if (this.content.middle_margin && this.$store.state.isMobile) {
        return "5vw";
      } else {
        return 0;
      }
    },

    customWidthLeft() {
      if (this.$store.state.isMobile) {
        return "100";
      } else if (this.content.custom_width) {
        return this.content.width_left;
      } else if (this.content.middle_margin) {
        return "32";
      } else {
        return "33.3333";
      }
    },

    customWidthCenter() {
      if (this.$store.state.isMobile) {
        return "100";
      } else if (this.content.custom_width) {
        return this.content.width_center;
      } else if (this.content.middle_margin) {
        return "32";
      } else {
        return "33.3333";
      }
    },

    customWidthRight() {
      if (this.$store.state.isMobile) {
        return "100";
      } else if (this.content.custom_width) {
        return this.content.width_right;
      } else if (this.content.middle_margin) {
        return "32";
      } else {
        return "33.3333";
      }
    },

    columnLeft() {
      return this.content.column_01[0];
    },

    columnCenter() {
      return this.content.column_02[0];
    },

    columnRight() {
      return this.content.column_03[0];
    }
  },

  methods: {
    setCustomWidth() {
      if (!this.$store.state.isMobile) {
        document.getElementById("column-left").style.width =
          this.content.width_left + "px";
        document.getElementById("column-center").style.width =
          this.content.width_center + "px";
        document.getElementById("column-right").style.width =
          this.content.width_right + "px";
      }
    },

    shoModalVideoLeft() {
      this.$store.state.video_id = this.columnLeft.pop_up_vimeo_id;;
      this.$store.state.video_ratio = this.columnLeft.pop_up_video_ratio;
      if(this.showVideoModalLeft){
        setTimeout(() => {
          // this.showVideoModal = false;
          this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
        }, 800);
      }else {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", true);
      }

      window.history.pushState({id:1}, null, null);
    },

    shoModalVideoCenter() {
      this.$store.state.video_id = this.columnCenter.pop_up_vimeo_id;;
      this.$store.state.video_ratio = this.columnCenter.pop_up_video_ratio;
      if(this.showVideoModalCenter){
        setTimeout(() => {
          // this.showVideoModal = false;
          this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
        }, 800);
      }else {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", true);
      }

      window.history.pushState({id:1}, null, null);
    },

    shoModalVideoRight() {
      this.$store.state.video_id = this.columnRight.pop_up_vimeo_id;;
      this.$store.state.video_ratio = this.columnRight.pop_up_video_ratio;
      if(this.showVideoModalRight){
        setTimeout(() => {
          // this.showVideoModal = false;
          this.$store.commit("TOGGLE_IS_MODAL_VIDEO", false);
        }, 800);
      }else {
        this.$store.commit("TOGGLE_IS_MODAL_VIDEO", true);
      }

      window.history.pushState({id:1}, null, null);
    },
  }
};
</script>

<style lang="scss">
.threeColumns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  &.reverse-true {
    flex-direction: column-reverse;

    @include tablet {
      flex-direction: row;
    }
  }

  &.standard {
    @include paddingMedium;
  }

  &.small {
    @include paddingSmall;
  }

  &.big {
    @include paddingBig;
  }

  &.super {
    @include paddingSuper;
  }

  &.bottom-text {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }
  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

  &.top {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }

  &__description {
    @include paddingMobile;
    @include paddingMobileBottom;

    padding-top: 5vw;

    @include tablet {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    h1,
    h2,
    h3 {
      padding-top: 7.7vw;
      padding-bottom: 2vw;
      font-size: 32px;
      font-variation-settings: "wght" 750, "wdth" 100, "opsz" 30;
      width: 100%;
      line-height: 1.1;

      strong {
        margin-left: -8px;
        color: $orange;
      }

      @include tablet {
        padding-bottom: 0.3vw;
        font-size: 48px;

        strong {
          margin-left: -10px;
          color: $orange;
        }
      }

      @include desktop-sm {
        font-size: 54px;
        strong {
          margin-left: -12px;
          color: $orange;
        }
      }

      @include desktop-l {
        font-size: 64px;

        strong {
          margin-left: -15px;
          color: $orange;
        }
      }

      @include desktop-xl {
        font-size: 74px;

        strong {
          margin-left: -15px;
          color: $orange;
        }
      }
    }

    em {
      strong {
        display: block;
        height: 0;
        margin-bottom: 3vw;
        overflow: hidden;

        @include tablet {
          margin-bottom: 2vw;
        }
      }
    }

    @include tablet {
      &.padding-left {
        padding-left: 5vw;
      }

      &.padding-right {
        padding-right: 5vw;
      }

      &.padding-bottom {
        padding-bottom: 5vw;
      }

      &.padding-top {
        padding-top: 5vw;
      }
    }
  }

  &__left,
  &__center,
  &__right {
    width: 100%;

    @include tablet {
      width: 32%;
      margin-bottom: 0;
    }

    &--image {
      width: 100%;
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.stretch {
    align-items: stretch;

    .threeColumns__left--image,
    .threeColumns__center--image,
    .threeColumns__right--image {
      height: 100%;

      &--item {
        object-fit: cover;
      }
    }

    // .threeColumns__left--image--item,
    // .threeColumns__center--image--item,
    // .threeColumns__right--image--item {
    //   object-fit: cover;
    // }
  }

  &.middle-false {
    .threeColumns__left,
    .threeColumns__center,
    .threeColumns__right {
      display: flex;
      margin-bottom: 0;
      width: 100%;

      @include tablet {
        width: 33.3333%;
      }

      &.hide-mobile {
        display: none;

        @include tablet {
          display: flex;
        }
      }
    }
  }

  &.hide-mobile {
    display: none;

    @include tablet {
      display: flex;
    }
  }

  &.mobile-remove-true {
    @media (max-width: 576px) {
      padding-bottom: 0;
    }
  }
}
</style>
