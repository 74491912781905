/*
|------------------------------------------------------------------------------
| Project Dependencies
|------------------------------------------------------------------------------
|
| First we will load all of this project's JavaScript dependencies which
| includes Vue and other libraries. It is a great starting point when
| building robust, powerful web applications using Vue and Laravel.
|
*/

/* Vue */
import { createApp, h } from 'vue';

/* Vendor */
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import VueMobileDetection from 'vue-mobile-detection';

/* Inertia Plugins */
import InertiaProgress from '@/plugins/inertia-progress';

/* Vue Plugins */
import VueCookies from '@/plugins/vue-cookies';
import VueGtm from '@/plugins/vue-gtm';
import VueGsap from '@/plugins/vue-gsap';
import VueLodash from '@/plugins/vue-lodash';
import VueMeta from '@/plugins/vue-meta';
import VueSmoothScrollbar from '@/plugins/vue-smooth-scrollbar';

/* Vue Helpers */
import mixin from '@/helpers/vue-mixin';

/* Vue Instance */
import { store } from '@/store/store';

/*
|------------------------------------------------------------------------------
| Vue Application
|------------------------------------------------------------------------------
|
| Finally, we will create a fresh Vue application instance and attach it to
| the main page.
|
*/

createInertiaApp({
  resolve: (name) => require(`@/pages/${name}`),
  setup({ el, app, plugin }) {
    const appVue = createApp({
      render: () =>
        h(app, {
          initialPage: JSON.parse(el.dataset.page),
          resolveComponent: (name) =>
            import(`@/pages/${name}`).then((module) => module.default),
        }),
    })
      .use(plugin)
      .use(InertiaProgress)
      .use(store)
      .use(VueCookies)
      .use(VueGsap)
      .use(VueMeta)
      .use(VueGtm)
      .use(VueLodash)
      .use(VueMobileDetection)
      .use(VueSmoothScrollbar)
      .mixin(mixin);

    if (process.env.MIX_APP_ENV === 'production') {
      appVue.config.devtools = false;
      appVue.config.debug = false;
      appVue.config.silent = true;
      appVue.config.productionTip = false;
    }

    /**
     * The following block of code may be used to automatically register your
     * Vue components. It will recursively scan the Vue components directory and
     * automatically register them with their "basename".
     */
    const components = require.context('./components/', true, /\.vue$/i);
    // appVue.component(AuthLoginForm, )
    components.keys().forEach((fileName) => {
      // Get component config.
      const componentConfig = components(fileName);

      // Get component name.
      const componentName = fileName.split('/').pop().split('.')[0];

      // Register component globally.
      appVue.component(
        componentName,
        componentConfig.default || componentConfig
      );
    });

    return appVue.mount(el);
  },
});
