<template>
  <svg
    width="244"
    height="41"
    viewBox="0 0 244 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    alt="Binalogue"
  >
    <path
      d="M6.43875 0H0.00878906V6.42995H6.43875V0Z"
      fill="#FF7F40"
    />
    <path
      d="M243.321 25.7119H236.891V32.1419H243.321V25.7119Z"
      fill="#FF7F40"
      class="hideInAvatar"
    />
    <path
      d="M74.5013 0H68.0713V6.42995H74.5013V0Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M6.42996 12.8682H0V32.1421H6.42996V12.8682Z"
      class="toggleLogo "
      fill="white"
    />
    <path
      d="M16.0587 12.8687V25.7127H28.8629V6.43874H9.63672V0.00878906H35.3009V32.1347H9.63672V12.8687H16.0587Z"
      class="toggleLogo"
      fill="white"
    />
    <path
      d="M38.5 0H53.62C59.7396 0 63.8777 1.98151 63.8777 7.95787C63.8777 11.141 62.2861 13.3135 59.5167 14.6664C63.432 15.7964 65.4135 18.8045 65.4135 22.8152C65.4135 29.3407 59.843 32.1339 54.0656 32.1339H38.5V0ZM45.5666 13.0111H52.7287C55.2036 13.0111 57.0498 11.8811 57.0498 9.18339C57.0498 6.11961 54.7102 5.49093 52.1875 5.49093H45.5666V13.0111ZM45.5666 26.6509H53.1345C55.9198 26.6509 58.3549 25.7517 58.3549 22.4173C58.3549 19.083 56.2858 17.8256 53.2618 17.8256H45.5666V26.6509Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M74.4933 8.87305H68.0713V32.1419H74.4933V8.87305Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M77.6777 8.87336H83.7496V12.1122H83.8849C84.642 10.9133 85.693 9.92787 86.9382 9.24957C88.1834 8.57126 89.5813 8.22261 90.9992 8.23674C97.7952 8.23674 99.5062 12.0645 99.5062 17.826V32.1501H93.1399V18.9958C93.1399 15.168 92.0178 13.282 89.0416 13.282C85.5799 13.282 84.0918 15.2158 84.0918 19.9428V32.1422H77.7255L77.6777 8.87336Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M102.792 16.0271C103.151 10.0428 108.506 8.23633 113.727 8.23633C118.366 8.23633 123.944 9.27086 123.944 14.8573V26.9612C123.944 29.078 124.175 31.1948 124.74 32.1418H118.263C118.02 31.4124 117.868 30.6561 117.809 29.8897C115.788 32.0065 112.811 32.7704 109.978 32.7704C105.57 32.7704 102.021 30.5661 102.021 25.7914C102.021 20.5233 105.999 19.2659 109.978 18.7248C113.957 18.1837 117.538 18.2792 117.538 15.669C117.538 12.9235 115.644 12.4858 113.4 12.4858C110.965 12.4858 109.421 13.4726 109.167 15.9953L102.792 16.0271ZM117.554 20.754C116.472 21.701 114.228 21.7408 112.246 22.0989C110.265 22.457 108.458 23.1812 108.458 25.5208C108.458 27.8604 110.305 28.4971 112.382 28.4971C117.379 28.4971 117.554 24.5181 117.554 23.1335V20.754Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M127.326 0.374023H133.692V32.5159H127.326V0.374023Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M148.216 8.23633C155.553 8.23633 160.28 13.0986 160.28 20.5233C160.28 27.948 155.553 32.7704 148.216 32.7704C140.879 32.7704 136.191 27.9082 136.191 20.5233C136.191 13.1384 140.918 8.23633 148.216 8.23633ZM148.216 27.9559C152.577 27.9559 153.882 24.2157 153.882 20.5233C153.882 16.8308 152.577 13.0588 148.216 13.0588C143.855 13.0588 142.582 16.791 142.582 20.5233C142.582 24.2555 143.895 27.9559 148.216 27.9559Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M185.618 30.6552C185.618 34.4829 184.265 41.0004 173.554 41.0004C168.962 41.0004 163.606 38.8359 163.288 33.528H169.654C170.235 35.9153 172.169 36.7111 174.429 36.7111C177.986 36.7111 179.61 34.284 179.562 30.9496V27.8938H179.474C178.082 30.3209 175.288 31.4908 172.495 31.4908C165.516 31.4908 162.548 26.1829 162.548 19.7847C162.548 13.7527 166.017 8.26172 172.543 8.26172C175.599 8.26172 177.946 9.29623 179.474 12.0019H179.562V8.89834H185.642L185.618 30.6552ZM179.538 20.2144C179.538 16.4344 178.233 13.0524 174.095 13.0524C170.49 13.0524 168.914 16.2355 168.914 19.6653C168.914 22.9997 170.179 26.6444 174.095 26.6444C177.739 26.6365 179.538 23.5488 179.538 20.2144Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M209.945 32.1419H203.873V28.903H203.722C202.964 30.101 201.913 31.0853 200.668 31.7622C199.423 32.4392 198.025 32.7863 196.608 32.7705C189.812 32.7705 188.101 28.9428 188.101 23.2211V8.89692H194.467V22.0354C194.467 25.8631 195.597 27.7571 198.565 27.7571C202.035 27.7571 203.515 25.8154 203.515 21.0884V8.87305H209.881L209.945 32.1419Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
    <path
      d="M224.156 8.23633C217.09 8.23633 212.275 13.5522 212.275 20.5233C212.275 27.7252 216.819 32.7704 224.156 32.7704C229.17 32.7704 232.87 30.6457 234.772 25.7118H229.035C228.49 26.4258 227.785 27.0012 226.976 27.3912C226.167 27.7811 225.277 27.9746 224.379 27.9559C220.83 27.9559 218.849 26.1097 218.666 22.0592H235.457C235.902 14.8971 232.083 8.23633 224.156 8.23633ZM218.666 18.0086C218.675 17.3307 218.822 16.6618 219.097 16.0421C219.371 15.4224 219.769 14.8645 220.264 14.4022C220.76 13.9399 221.344 13.5825 221.982 13.3517C222.619 13.1208 223.297 13.0212 223.973 13.0588C227.085 13.0588 228.478 14.7618 229.067 18.0086H218.666Z"
      class="toggleLogo hideInAvatar"
      fill="white"
    />
  </svg>
</template>
