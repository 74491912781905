<template>
  <div class="TheCookieBanner">
    <div class="TheCookieBanner__container">
      <p class="main-text-bold white">
        We use cookies for analytics to improve your browsing experience.
      </p>

      <div class="TheCookieBanner__buttons">
        <button
          type="button"
          class="main-text-link link"
          @click="deniedCookies"
        >
          Reject
        </button>

        <button
          type="button"
          class="main-text btn"
          @click="acceptCookies"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useCookies from "@/helpers/vue-cookies";
import { useState } from "vue-gtag-next";

export default {
  setup() {
    const { isEnabled } = useState();
    const { acceptCookies, deniedCookies } = useCookies();

    return {
      acceptCookies,
      deniedCookies,
      isEnabled
    };
  },

  mounted() {
    /* eslint-disable */
    this.$gsap.to(".TheCookieBanner", {opacity: 1,duration: 0.3,delay: 0.4});
    /* eslint-enable */
  }
};
</script>

<style lang="scss" >
.TheCookieBanner {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 12;
  max-width: 400px;
  padding: 20px;
  background: $black;
  opacity: 0;
  width: min-content;

  @include tablet {
    bottom: 30px;
    left: 30px;
    padding: 30px;
  }

  @include desktop-xl {
    max-width: 450px;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5vw;
  }

  .btn {
    padding: 5px 25px;
    margin-left: 2vw;
    font-family: "HelveticaNowVar", sans-serif;
    color: $white;
    background: $orange;

    @include tablet {
      padding: 5px 20px;
    }

    @include desktop-l {
      padding: 8px 30px;
      margin-left: 3vw;
    }
  }

  .medium-text {
    line-height: 1.2;

    @include desktop-l {
      line-height: 1.2;
    }
  }

  .link {
    // text-decoration: underline;
    padding: 5px 15px 5px 0;
    color: $white;

    @include tablet {
      padding: 5px 25px 5px 0;
    }

    @include desktop-l {
      padding: 8px 30px 8px 0;
    }
  }
}
</style>
