export default {
  props: {
    auth: {
      type: Object,
      default: () => {},
    },

    csrfToken: {
      type: String,
    },

    errors: {
      type: Object,
      default: () => {},
    },

    flash: {
      type: Object,
      default: () => {},
    },

    hasErrors: {
      type: Boolean,
    },

    hasErrorsOrExceptions: {
      type: Boolean,
    },

    hasExceptions: {
      type: Boolean,
    },

    meta: {
      type: Object,
      default: () => {},
    },

    request: {
      type: Object,
      default: () => {},
    },
  },

  metaInfo() {
    return {
      title: this.$page.props.meta.title,
    };
  },

  methods: {
    async setupPageLoader() {
      return [await this.loadFake()];
    },
  },
};
