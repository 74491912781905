<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="192.29"
    height="68.8"
    viewBox="0 0 192.29 68.8"
  >
    <g>
      <path
        d="M0,67.65V0H30.57A28.87,28.87,0,0,1,43.85,2.81a19.44,19.44,0,0,1,8.35,7.86,23.6,23.6,0,0,1,2.85,11.78A23.82,23.82,0,0,1,52.2,34.31a19.36,19.36,0,0,1-8.35,7.85A28.91,28.91,0,0,1,30.56,45H14.77V67.63ZM29.17,32.47a13.54,13.54,0,0,0,5.89-1.16,7.92,7.92,0,0,0,3.61-3.4,11.4,11.4,0,0,0,1.18-5.44A11.26,11.26,0,0,0,38.63,17,7.91,7.91,0,0,0,35,13.64a13.66,13.66,0,0,0-5.88-1.14H14.77v20Z"
        transform="translate(0 0.01)"
      />
      <path
        d="M90,18.08V30.59H86.72a17.13,17.13,0,0,0-7.5,1.5,10.32,10.32,0,0,0-4.73,4.55,16.05,16.05,0,0,0-1.64,6.8c0,.28,0,.57,0,.86V67.63l-13.7,0v-49H72.5V27.3a14.69,14.69,0,0,1,14.44-9.44,13.3,13.3,0,0,1,1.54.06C89,18,89.48,18,90,18.08Z"
        transform="translate(0 0.01)"
      />
      <path
        d="M138.6,29.73a21.76,21.76,0,0,0-8.63-9,26,26,0,0,0-13.18-3.21,26.26,26.26,0,0,0-13.21,3.21,22,22,0,0,0-8.76,9,28.41,28.41,0,0,0-3.08,13.48,28.19,28.19,0,0,0,3.11,13.61,21.48,21.48,0,0,0,8.72,8.87,27.49,27.49,0,0,0,13.28,3.09,29,29,0,0,0,11.06-2,20.57,20.57,0,0,0,12.55-14.46H127.52a9.57,9.57,0,0,1-2.08,3.53A9.22,9.22,0,0,1,122,58.12a13.88,13.88,0,0,1-4.86.79,13.58,13.58,0,0,1-6.59-1.51,9.82,9.82,0,0,1-4.18-4.53A17,17,0,0,1,104.9,47h36.58c0-.75.12-1.46.12-2.12V43A28.35,28.35,0,0,0,138.6,29.73Zm-33.49,8.65a19.18,19.18,0,0,1,1.21-4.45,10.27,10.27,0,0,1,4-4.78,11.73,11.73,0,0,1,6.28-1.6,11.54,11.54,0,0,1,6.25,1.61,10,10,0,0,1,3.9,4.46A16.1,16.1,0,0,1,128,38.38Z"
        transform="translate(0 0.01)"
      />
      <path
        d="M192.29,18.61l-18.4,49-15.23,0-18.43-49h14.7l8.65,25.89c.92,3,1.79,5.92,2.61,8.93.12.43.23.86.35,1.31.1-.36.19-.71.29-1.06.87-3.19,1.77-6.27,2.71-9.27L178,18.61Z"
        transform="translate(0 0.01)"
      />
    </g>
  </svg>
</template>
