import { marked } from 'marked';
export default {
  methods: {
    markdownToHtml(text) {
      if (text) {
        return marked(text);
      } else {
        return '';
      }
    },
  },
};
