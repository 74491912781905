<template>
  <div class="FormNewsletter">
    <p class="FormNewsletter__title">
      Subscribe to newsletter
    </p>
    <!-- Form -->
    <form
      class="FormNewsletter__form"
      @submit.prevent="submit"
    >
      <!-- Newsletter -->
      <com-input
        id="newsletter"
        ref="newsletter"
        v-model="form.newsletter"
        :class="{ 'is-invalid': form.errors.newsletter }"
        type="email"
        placeholder="Your email"
        required
        autocomplete="email"
        class="FormNewsletter__input"
        @change="validateEmail"
      />
      <com-button
        class="FormNewsletter__btn"
        type="button"
        @click="submit()"
      >
        Send
      </com-button>
      <!-- Email error -->

      <!-- <com-input-error :message="form.errors.newsletter" /> -->
    </form>
    <p
      v-if="errorEmail"
      class="main-text orange FormNewsletter__error"
    >
      *{{ errorEmail }}
    </p>
  </div>
</template>

<script>
import ComInput from "@/components/Input";
// import ComInputError from "@/components/InputError";
import ComButton from "@/components/Button";

export default {
  components: {
    ComInput,
    // ComInputError,
    ComButton
  },

  props: {},

  setup() {},

  data() {
    return {
      form: this.$inertia.form({
        newsletter: null
      }),
      errorEmail: "",
      validEmail: false
    };
  },

  methods: {
    validateEmail() {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (this.form.newsletter.match(regex)) {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
    },
    submit() {
      if (this.validEmail) {
        this.form
          .transform((data) => ({
            ...data
          }))
          .post(this.route("newsletter"), {
            preserveScroll: true,
            onSuccess: () => {
              this.errorEmail = "";
              this.$store.commit("TOGGLE_IS_LOADING", false);
              this.form.reset();
            },
            onError: () => {
              if (this.form.errors.newsletter) {
                this.$refs.newsletter.focus();
              }
            }
          });
      } else {
        this.$refs.newsletter.focus();
        this.errorEmail = "The email you have provided is invalid";
      }
    }
  }
};
</script>

<style lang="scss">
.FormNewsletter {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;

  @include desktop-s {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 15px;
    font-size: 20px;
    color: $orange;
    font-variation-settings: "wght" 300, "wdth" 100, "opsz" 30;

    @include tablet {
      font-size: 24px;
    }

    @include desktop-sm {
      font-size: 34px;
    }

    @include desktop-l {
      margin-bottom: 25px;
      font-size: 40px;
    }
  }

  &__form {
    position: relative;
  }

  &__input {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.4;
    color: $white;
    background: transparent;
    border: 0;
    border-bottom: 1px solid $grey;
    border-radius: 0;
    outline: 0;
    width: 100%;
    font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
    -webkit-appearance: none;

    @include tablet {
      font-size: 20px;
    }

    @include desktop-sm {
      font-size: 28px;
    }

    @include desktop-l {
      font-size: 34px;
    }

    &::placeholder {
      color: $grey;
    }
  }

  &__btn {
    position: absolute;
    top: 2px;
    right: 0;
    z-index: 3;
    padding: 0 5px;
    font-size: 16px;
    line-height: 1.2;
    color: $white;
    background: transparent;
    background: $black;
    font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

    @include tablet {
      top: 3px;
      font-size: 20px;
    }

    @include desktop-sm {
      font-size: 28px;
    }

    @include desktop-l {
      top: 5px;
      font-size: 34px;
    }
  }
}
</style>
