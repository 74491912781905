<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="68.21"
    height="67.68"
    viewBox="0 0 68.21 67.68"
  >
    <path
      d="M69.5,64.09,55.13,49.72a29.91,29.91,0,0,0,6.26-18.3v-.05a30.05,30.05,0,1,0-11.1,23.32L64.59,69ZM7.71,31.37A23.68,23.68,0,1,1,31.39,55.05,23.68,23.68,0,0,1,7.71,31.37Z"
      transform="translate(-1.29 -1.32)"
      class="toggleLogo"
      fill="white"
    />
  </svg>
</template>
