<template>
  <div
    id="form-pass-wrapper"
  >
    <div
      id="form-pass-content"
      class="ProtectForm"
    >
      <form
        class="ProtectForm__form"
        @submit.prevent="submitForm"
      >
        <input
          id="password"
          ref="password"
          v-model="form.password"
          class="ProtectForm__input"
          type="password"
          placeholder="Password"
        >
      </form>

      <span id="form-pass-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
// import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
// import loader from "@/mixins/loader";

export default {
  name: "ProtectForm",

  mixins: [page, scrollGlobal],

  layout: mainLayout,
  // components: {
  //   mainLayout,
  //   InertiaLink,
  //   agile: VueAgile
  // },
  data() {
    return {
      form: this.$inertia.form({
        password: null,
        token: this.$page.props.request.query.token
      })
    };
  },
  mounted() {
    this.$store.commit("TOGGLE_IS_LOADING", false);

    setTimeout(() => {
      this.toggleMenuColor("black", 0, 0);
      this.setScroller('#form-pass-wrapper', '#form-pass-content' );
    }, 100);
  },
  methods: {
    submitForm() {
      console.log("antes llamada", this.form.password);
      window.location.href = this.route("work.list", {
        slug: this.$page.props.project,
        password: this.form.password
      });
      // this.$inertia.get(
      //   this.route("work.index", {
      //     slug: this.$page.props.project,
      //     password: this.form.password
      //   }),

      //   {
      //     onSuccess: (e) => {
      //       console.log("correcto");
      //       this.$store.commit("TOGGLE_IS_LOADING", false);
      //     },
      //     onError: (e) => {
      //       console.log("mal", e);
      //       this.$store.commit("TOGGLE_IS_LOADING", false);
      //     }
      //   }
      // );
    }
  }
};
</script>

<style lang="scss">
.ProtectForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white;
  width: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 12vh 5vw;
    background: $white;
    height: 100%;
    width: 100%;
  }

  &__input {
    position: relative;
    font-family: $helvetica;
    font-size: 28px;
    color: $orange;
    background: transparent;
    border: 0;
    border-bottom: 2px solid $black;
    border-radius: 0;
    outline: 0;
    width: fit-content;
    font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
    -webkit-appearance: none;

    @include desktop-sm {
      font-size: 40px;
    }

    @include desktop-m {
      font-size: 44px;
    }

    @include desktop-l {
      font-size: 58px;
    }

    @include desktop-xl {
      font-size: 78px;
    }

    &::placeholder {
      font-family: $helvetica;
      font-size: 28px;
      color: $orange;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      @include desktop-sm {
        font-size: 40px;
      }

      @include desktop-m {
        font-size: 44px;
      }

      @include desktop-l {
        font-size: 58px;
      }

      @include desktop-xl {
        font-size: 78px;
      }
    }
  }
}
</style>
