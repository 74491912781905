<template>
  <div id="work-filter-wrapper">
    <div
      id="work-filter-content"
      class="WorkFilterMaster WorkFilter work-filter-scroller"
    >
      <!-- SEO TITLE -->
      <h1 class="hidden">
        Work
      </h1>
      <section class="filters">
        <InertiaLink
          class="filters__item"
          :href="`${route('work.index')}/`"
          :class="{ active: $page.url === '/work' }"
        >
          Featured
        </InertiaLink>
        <InertiaLink
          v-for="filter in content.work_filters"
          :key="filter"
          class="filters__item"
          :href="`/work/${filter}/`"
          :class="{
            active: $page.url.includes(filter),
          }"
        >
          <span
            v-for="tax in taxonomies"
            :key="tax.title"
          >
            <span v-if="filter === tax.slug">
              {{ tax.title }}
            </span>
          </span>
        </InertiaLink>
      </section>

      <section class="WorkFilter__container">
        <div
          v-for="(filteredProject, index) in content.projects_taxonomies"
          :key="`filteredProject${index}`"
          class="WorkFilter__item"
        >
          <InertiaLink
            class="WorkFilter__item--link"
            :href="`/work/${filteredProject.slug}/`"
          >
            <div class="WorkFilter__item--image-container">
              <img
                v-if="filteredProject.thumbnail_4_5"
                :src="getImageKitURL(filteredProject.thumbnail_4_5, 3, filteredProject.image_type,true,index,'images')"
                :data-src="getImageKitURL(filteredProject.thumbnail_4_5, 3, filteredProject.image_type,false,index,'images')"
                :alt="filteredProject.title"
                class="WorkFilter__item--image"
              >
              <img
                v-else
                :src="`${$store.state.baseimageUrl}default-4-5.png`"
                class="WorkFilter__item--image"
                alt="default image"
              >
            </div>

            <h5 class="small-subtitle grey WorkFilter__text">
              {{ filteredProject.client_subtitle }}
            </h5>
            <h3 class="small-title black WorkFilter__text">
              {{ filteredProject.title }}
            </h3>
            <h4 class="small-subtitle orange WorkFilter__text">
              {{ filteredProject.subtitle }}
            </h4>
          </InertiaLink>
        </div>
      </section>
      <span id="work-filter-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [imageKit, page, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    },
    taxonomies: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    let filter = this.taxonomies.find(
      (tax) => tax.slug === this.$page.props.filter
    );
    seo.seoContent(this.content, filter.title);
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();
    });
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.toggleMenuColor("black", 0, 0);
      this.setScroller("#work-filter-wrapper", "#work-filter-content");

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#work-filter-content",
          markers: false,
          start: "50px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });
    }
  }
};
</script>

<style lang="scss">
.WorkFilter {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  padding-top: 12vh;

  @include headerMargin;

  &__container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include tablet {
      @include paddingMedium;
      @include paddingBottom;
    }
  }

  &__item {
    width: 100%;
    @include marginBottom;

    @include tablet {
      flex-grow: 0;
      margin-right: 2.33%;
      width: 31.66%;
    }

    &--image-container {
      display: flex;
      margin-bottom: 5px;
      overflow: hidden;
      width: 100%;
      height: auto;

      @include tablet {
        margin-bottom: 15px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;

      @include desktop-s {
        transform: scale(1);
        transform-origin: center;
        @include transition;
      }

      &:hover {
        @include desktop-s {
          transform: scale(1.03);
        }
      }
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__text {
    @include paddingMobile;

    @include tablet {
      padding: 0;
    }
  }
}
</style>
