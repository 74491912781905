<template>
  <div
    :id="`youtube-${dynamicNumber}`"
    class="ProjectYoutube"
    :class="`${content.horizontal_margin} ${content.vertical_margin}`"
    :style="`background : ${customColor}; ${customGradient} `"
  >
    <iframe
      width="560"
      height="315"
      :src="`https://www.youtube.com/embed/${content.youtube_id}`"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
    <div class="ProjectYoutube__cover" />
    <!-- {{ content }} -->
  </div>
</template>

<script>
/* Mixins */
import marked from "@/mixins/marked";


export default {
  mixins: [marked],
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    dynamicNumber(){
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },

    customGradient() {
      if(this.content.gradient_code) {
        return this.content.gradient_code;
      } else {
        return "";
      }
    },

    customDescColor() {
      if (this.content.color) {
        return this.content.color;
      } else {
        return "#000";
      }
    },
  },

};
</script>

<style lang="scss">
.ProjectYoutube {
  position: relative;
  display: block;
  flex-direction: column;
  flex-grow: 1;
   max-width: 100vw;
    overflow-x: hidden;
  opacity: 1;
  width: 100%;

  &__cover {
    // position: absolute;
    // left: 0;
    // z-index: 2;
    // width: 100%;
    // height: 100%;
    //background: rgba(red, 0.5);
  }

  iframe {
    width: 100vw;
    height: 56.25vw;
  }


  &.super {
    @include paddingSuper;

    iframe {
      @include tablet{
        width: 76vw;
        height: 42.75vw;
      }

      @include desktop-l{
        width: 72vw;
        height: 40.5vw;
      }
    }
  }

  &.big {
    @include paddingBig;

    iframe{
      @include tablet{
        width: 83vw;
        height: 46.68vw;
      }
    }
  }

  &.standard {
    @include paddingMedium;

    iframe {
      @include tablet{
        width: 90vw;
        height: 60.62vw;
      }
    }
  }

  &.small {
    @include paddingSmall;

    iframe {
      @include tablet{
        width: 96vw;
        height: 54vw;
      }
    }
  }

    &.bottom-text {

    @include paddingMobileBottom;

    @include tablet {
       @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }

  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {

    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {

    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }
}
</style>
