<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="8.61mm"
    height="10.08mm"
    viewBox="0 0 24.4 28.56"
  >
    <path d="M19.8,7.6A7.6,7.6,0,1,1,12.2,0a7.6,7.6,0,0,1,7.6,7.6h0" />
    <path
      d="M0,28.56A10.75,10.75,0,0,1,10.7,17.85h3A10.75,10.75,0,0,1,24.4,28.56Z"
    />
  </svg>
</template>
