<template>
  <div id="work-wrapper">
    <div
      id="work-content"
      class="WorkMaster Work work-scroller"
    >
      <!-- SEO TITLE -->
      <h1 class="hidden">
        Work
      </h1>
      <section class="filters">
        <InertiaLink
          class="filters__item"
          :href="`${route('work.index')}/`"
          :class="{ active: $page.url === '/work/' }"
        >
          Featured
        </InertiaLink>
        <InertiaLink
          v-for="filter in content.work_filters"
          :key="filter"
          class="filters__item"
          :href="`/work/${filter}/`"
          :class="{
            active: $page.url.includes(filter),
          }"
        >
          <span
            v-for="tax in taxonomies"
            :key="tax.title"
          >
            <span v-if="filter === tax.slug">
              {{ tax.title }}
            </span>
          </span>
        </InertiaLink>
      </section>

      <section class="Work__bard">
        <div
          v-for="(bardItem, index) in content.home_bard"
          :key="`bardItem${index}`"
          class="Work__bard--container"
        >
          <!-- {{ bardItem }} -->
          <div v-if="bardItem.type === 'set'">
            <div
              v-if="bardItem.attrs.enabled !== false || $page.props.auth.user"
              :class="{outline: bardItem.attrs.enabled === false && $page.props.auth.user}"
            >
              <component
                :is="bardItem.attrs.values.type"
                :position="index"
                :content="bardItem.attrs.values"
              />
            </div>
          </div>
        </div>
      </section>
      <span id="work-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [page, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    },
    taxonomies: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    seo.seoContent(this.content);
  },
  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();

    });
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.toggleMenuColor("black", 0, 0);
      this.setScroller("#work-wrapper", "#work-content");

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#work-content",
          markers: false,
          start: "40px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });

      setTimeout(() => {
        this.toggleMenuColor("black", 0, 0);
      }, 500);

    }
  }
};
</script>

<style lang="scss">
.Work {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  @include paddingPageTop;
  @include headerMargin;

  &__bard {
    width: 100%;

    &--container {
      width: 100%;
      max-width: 100vw;
    }
  }
}
</style>
