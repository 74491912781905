<template>
  <input
    ref="inputSearch"
    class=""
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  >
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type:String,
      default:''
    }
  },

  emits: ["update:modelValue"],

  watch: {
    '$store.state.isSearchActive'() {
      // reset scroll on page change
      if(!this.$store.state.isMobile){
        this.focus();
      }

    },
  },

  mounted(){
    // this.$refs.inputSearch.focus();
  },

  methods: {
    focus() {
      this.$refs.inputSearch.focus();
    },
  },
});
</script>
