<template>
  <div
    :id="`html-video-${dynamicNumber}`"
    :width="videoWidth"
    :height="videoHeight"
    class="htmlVideo"
  >
    <video
      :width="videoVimeoWidth"
      :height="videoVimeoHeight"
      playsinline
      muted="true"
      loop="true"
      autoplay="true"
    >
      <source
        :src="htmlUrl"
        type="video/mp4"
      >
    </video>
  </div>
</template>

<script>

import { nextTick } from "vue";
export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    },

    htmlUrl: {
      type:String,
      default:''
    },
    htmlRatio: {
      type:String,
      default:''
    },

    htmlWidth: {
      type:String,
      default:''
    },
  },

  data() {
    return {
      isShownBgVideo: false,
      videoWidth: 2500,
      videoHeight: 900,
      videoVimeoWidth: 2500,
      videoVimeoHeight: 1406,
      plus: 9,
      divide: 16,
    };
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },
  },



  mounted() {
    this.getVideoSize(true);
    this.$nextTick(() => this.getVideoSize(true));

    window.addEventListener("resize", this.resize);
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },

  methods: {


    updateVideo() {
      this.isShownBgVideo = true;
    },

    resize(){
      this.getVideoSize(false);
    },

    getVideoSize(scrollbarWidth) {
      switch (this.htmlRatio) {
        case "four-by-three":
          this.divide = 4;
          this.plus = 3;
          break;
        case "four-by-five":
          this.divide = 4;
          this.plus = 5;
          break;
        case "sixteen-by-five":
          this.divide = 16;
          this.plus = 5;
          break;
        case "sixteen-by-seven":
          this.divide = 16;
          this.plus = 7;
          break;
        case "two-by-one":
          this.divide = 2;
          this.plus = 1;
          break;
        case "square":
          this.divide = 1;
          this.plus = 1;
          break;
        case "sixteen-mm":
          this.divide = 2048;
          this.plus = 1240;
          break;
        case "nineteentwenty-by-thirteenfifty":
          this.divide = 1920;
          this.plus = 1350;
          break;
        case "nine-by-sixteen":
          this.divide = 9;
          this.plus = 16;
          break;
        case "nineteentwenty-by-sixfifty":
          this.divide = 1920;
          this.plus = 650;
          break;
        default:
          this.divide = 16;
          this.plus = 9;
          break;
      }

      if (document.getElementById("html-video-" + this.dynamicNumber)) {


        console.log('dynamic', this.dynamicNumber);
        if(this.$store.state.isMobile) {
          this.videoWidth = Math.ceil(
            document.getElementById("html-video-" + this.dynamicNumber).offsetWidth
          );

          this.videoHeight = Math.ceil(
            (this.videoWidth * this.plus) / this.divide
          );
        } else {
          this.videoWidth = Math.ceil(
            document.getElementById("html-video-" + this.dynamicNumber).offsetWidth
          );

          console.log('video width no scrollbar ', this.videoWidth);
          if (scrollbarWidth) {
            this.videoWidth -= (15* this.htmlWidth)/100;

            console.log('video width', this.videoWidth);
          }
          this.videoHeight = Math.floor(
            (this.videoWidth * this.plus) / this.divide
          );
        }



        this.videoVimeoHeight = this.videoHeight;
        this.videoVimeoWidth = this.videoWidth;
      }
    }
  }
};
</script>


<style lang="scss">
.htmlVideo {
  display: flex;
}
</style>
