<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="580"
    height="94"
    viewBox="0 0 580 94"
  >


    <g id="txt">
      <g>
        <rect
          x="2.03"
          y="1.84"
          width="14.1"
          height="14.1"
          fill="#ff7f40"
        />
        <rect
          x="521.36"
          y="58.2"
          width="14.1"
          height="14.1"
          fill="#ff7f40"
          class="dot-first"
        />
        <rect
          x="542.49"
          y="58.2"
          width="14.1"
          height="14.1"
          fill="#ff7f40"
          class="loader-dot dot-first"
        />
        <rect
          x="563.63"
          y="58.2"
          width="14.1"
          height="14.1"
          fill="#ff7f40"
          class="loader-dot dot-first"
        />
        <rect
          x="151.26"
          y="1.84"
          width="14.1"
          height="14.1"
          fill="#fff"
        />
        <rect
          x="2.02"
          y="30.05"
          width="14.09"
          height="42.25"
          fill="#fff"
        />
        <polygon
          points="37.23 30.05 37.23 58.2 65.3 58.2 65.3 15.94 23.15 15.94 23.15 1.84 79.42 1.84 79.42 72.28 23.15 72.28 23.15 30.05 37.23 30.05"
          fill="#fff"
        />
        <path
          d="M86.43,1.83h33.16c13.42,0,22.5,4.35,22.5,17.47,0,6.91-3.46,11.75-9.57,14.71,8.58,2.46,12.93,9.07,12.93,17.86,0,14.3-12.14,20.42-24.87,20.42H86.43Zm15.49,28.52h15.7c5.43,0,9.47-2.46,9.47-8.39,0-6.7-5.13-8.08-10.66-8.08H101.92Zm0,29.9H118.5c6.12,0,11.45-2,11.45-9.27s-4.54-10.07-11.15-10.07H101.92Z"
          fill="#fff"
        />
        <rect
          x="151.26"
          y="21.28"
          width="14.09"
          height="51.02"
          fill="#fff"
        />
        <path
          d="M172.31,21.28h13.32v7.1h.3a18.19,18.19,0,0,1,15.59-8.48c14.9,0,18.65,8.38,18.65,21V72.29h-14V43.48c0-8.39-2.46-12.53-9-12.53-7.6,0-10.85,4.24-10.85,14.6V72.29h-14Z"
          fill="#fff"
        />
        <path
          d="M227.37,37c.79-13.13,12.54-17.07,24-17.07,10.17,0,22.4,2.26,22.4,14.5V61c0,4.63.5,9.27,1.78,11.34H261.32a21,21,0,0,1-1-4.93c-4.44,4.64-10.95,6.32-17.17,6.32-9.67,0-17.36-4.84-17.36-15.3,0-11.55,8.68-14.31,17.36-15.49s16.58-1,16.58-6.71c0-6-4.15-6.91-9.08-6.91-5.33,0-8.78,2.17-9.28,7.7Zm32.37,10.36c-2.37,2.07-7.3,2.17-11.65,3s-8.28,2.37-8.28,7.5,4,6.51,8.58,6.51c11,0,11.35-8.68,11.35-11.74Z"
          fill="#fff"
        />
        <path
          d="M280.8,1.83h14V72.29h-14Z"
          fill="#fff"
        />
        <path
          d="M327,19.9c16.09,0,26.45,10.65,26.45,26.93S343,73.68,327,73.68,300.6,63,300.6,46.83,311,19.9,327,19.9Zm0,43.22c9.57,0,12.44-8.19,12.44-16.29S336.52,30.45,327,30.45s-12.34,8.19-12.34,16.38S317.48,63.12,327,63.12Z"
          fill="#fff"
        />
        <path
          d="M409,69c0,8.38-3,22.6-26.45,22.6-10.06,0-21.8-4.74-22.5-16.38h13.92c1.28,5.23,5.52,7,10.46,7,7.79,0,11.34-5.33,11.25-12.63V62.92h-.2c-3.06,5.33-9.18,7.9-15.29,7.9-15.3,0-21.81-11.65-21.81-25.66,0-13.23,7.6-25.26,21.91-25.26,6.7,0,11.84,2.26,15.19,8.18h.2v-6.8H409Zm-13.32-22.9c0-8.29-2.86-15.69-11.94-15.69-7.9,0-11.35,6.91-11.35,14.51,0,7.3,2.76,15.29,11.35,15.29C391.68,60.25,395.63,53.45,395.63,46.14Z"
          fill="#fff"
        />
        <path
          d="M462.3,72.29H449v-7.1h-.29a18.22,18.22,0,0,1-15.59,8.49c-14.91,0-18.66-8.39-18.66-21V21.28h14V50.09c0,8.39,2.47,12.53,9,12.53,7.6,0,10.85-4.24,10.85-14.6V21.28h14Z"
          fill="#fff"
        />
        <path
          d="M493.45,19.9C478,19.9,467.4,31.54,467.4,46.83c0,15.79,10,26.85,26.05,26.85,11,0,19.1-4.66,23.26-15.48H504.15a12.53,12.53,0,0,1-10.21,4.91c-7.79,0-12.13-4-12.53-12.92h36.81C519.21,34.5,510.82,19.9,493.45,19.9Zm-12,21.41c.2-4,2.76-10.86,11.65-10.86,6.8,0,9.86,3.75,11.14,10.86Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>
