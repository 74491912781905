<template>
  <div class="FormContact">
    <!-- Form -->
    <form
      class="FormContact__form"
      @submit.prevent="submit"
    >
      <div
        v-if="actualStep == 1"
        class="FormContact__slide"
      >
        <!-- Name -->
        <p class="FormContact__title">
          Your Name<span class="orange">.</span>
        </p>
        <div class="FormContact__input-container">
          <com-input
            id="name"
            ref="name"
            v-model="form.name"
            class="FormContact__input"
            :class="{ 'is-invalid': form.errors.name }"
            type="text"
            required
            autocomplete="name"
          />
          <button
            class="FormContact__btn"
            @click="nextStep()"
          >
            <IconFormArrow />
          </button>
        </div>
        <!-- Name error -->
        <p
          v-if="errorName"
          class="main-text orange FormContact__error"
        >
          {{ errorName }}
        </p>
      </div>

      <div
        v-if="actualStep == 2"
        class="FormContact__slide"
      >
        <p class="FormContact__title">
          Your Email<span class="orange">.</span>
        </p>
        <div class="FormContact__input-container">
          <com-input
            id="email"
            ref="email"
            v-model="form.email"
            class="FormContact__input"
            :class="{ 'is-invalid': form.errors.email }"
            type="email"
            placeholder="Email"
            required
            autocomplete="email"
            @change="validateEmail"
          />
          <!-- Email error -->
          <button
            class="FormContact__btn"
            @click="nextStep()"
          >
            <IconFormArrow />
          </button>
        </div>
        <p
          v-if="errorEmail"
          class="main-text orange FormContact__error"
        >
          {{ errorEmail }}
        </p>
      </div>

      <div
        v-if="actualStep == 3"
        class="FormContactNew__slide last"
      >
        <p class="FormContact__title">
          Tell us about your project<span class="orange">.</span>
        </p>
        <textarea
          id="content"
          ref="content"
          v-model="form.content"
          class="FormContact__area"
          :class="{ 'is-invalid': form.errors.content }"
          cols="30"
          rows="8"
          placeholder="Tell us about your project"
          required
        />
        <!-- Content error -->
        <com-input-error :message="form.errors.content" />
        <div class="FormContact__checkboxes">
          <!-- Privacy -->
          <div class="FormContact__checkline">
            <com-checkbox
              id="privacy"
              v-model:checked="form.privacy"
              name="privacy"
              :class="{ 'is-invalid': form.errors.privacy }"
            />
            <label
              class="FormContact__checktext"
              for="privacy"
            >I have read and agree to the
              <InertiaLink
                :href="`${route('privacy-policy')}/`"
                class="FormContact__checklink"
              >
                Privacy Policy
              </InertiaLink></label>
            <!-- Privacy error -->
          </div>
          <!-- Subscription -->
          <div class="FormContact__checkline">
            <com-checkbox
              id="subscription"
              v-model:checked="form.subscription"
              name="subscription"
            />
            <label
              class="FormContact__checktext"
              for="subscription"
            >Subscribe to newsletter</label>
          </div>
        </div>
        <com-input-error :message="form.errors.privacy" />
        <!-- Button -->
        <div>
          <p
            v-if="errorPrivacy"
            class="main-text orange FormContact__error"
          >
            *{{ errorPrivacy }}
          </p>
        </div>
        <com-button
          class="FormContact__submit"
          type="button"
          @click="nextStep()"
        >
          Send
        </com-button>
      </div>
    </form>
  </div>
</template>

<script>
import ComValidationErrors from "@/components/ValidationErrors";
import ComInput from "@/components/Input";
import ComInputError from "@/components/InputError";
import ComCheckbox from "@/components/Checkbox";
import ComButton from "@/components/Button";
import { InertiaLink } from "@inertiajs/inertia-vue3";

export default {
  components: {
    // ComValidationErrors,
    ComInput,
    ComInputError,
    ComCheckbox,
    ComButton,
    InertiaLink
  },

  props: {},

  setup() {},

  data() {
    return {
      form: this.$inertia.form({
        name: null,
        email: null,
        content: null,
        privacy: false,
        subscription: false
      }),
      actualStep: 1,
      errorName: "",
      errorEmail: "",
      errorPrivacy: "",
      validEmail: false
    };
  },

  mounted() {
    this.$refs.name.focus();
  },

  methods: {
    nextStep() {
      switch (this.actualStep) {
        case 1:
          if (this.form.name === null || this.form.name === "") {
            this.errorName = "Please fill in your name";
          } else {
            this.actualStep += 1;
            this.$nextTick(() => this.$refs.email.focus());
          }
          break;
        case 2:
          if (this.form.email === null || this.form.email === "") {
            this.errorEmail = "Please fill in your email";
          } else if (!this.validEmail) {
            this.errorEmail = "The email you have provided is invalid";
          } else if (this.form.email != "" && this.validEmail) {
            this.actualStep += 1;
            this.$nextTick(() => this.$refs.content.focus());
          }
          break;
        case 3:
          if (this.form.privacy === false) {
            this.errorPrivacy =
              "Please accept the privacy policy before continuing.";
          } else {
            this.submit();
          }
      }
    },
    validateEmail() {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (this.form.email.match(regex)) {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
    },

    submit() {
      this.form
        .transform((data) => ({
          ...data
        }))
        .post(this.route("contact.store"), {
          preserveScroll: true,
          only: ["flash"],
          onSuccess: () => {
            this.$store.commit("TOGGLE_IS_MODAL_CONTACT_FORM", false);
            this.$store.commit("TOGGLE_IS_PAUSE_SCROLL", false);
            this.$store.commit("TOGGLE_IS_LOADING", false);
            this.form.reset();
          }
          // onError: () => {
          //   if (this.form.errors.name) {
          //     this.$refs.name.focus();
          //   } else if (this.form.errors.email) {
          //     this.$refs.email.focus();
          //   } else if (this.form.errors.content) {
          //     this.$refs.content.focus();
          //   }
          // },
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.FormContact {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: red;

  @include paddingMobile;

  @include tablet {
    padding-right: 20vw;
    padding-left: 20vw;
  }

  &__form {
    width: 100%;
    max-width: 1300px;
  }

  &__title {
    margin-bottom: 5vh;
    font-size: 48px;
    line-height: 1;
    color: $white;
    letter-spacing: -1.5px;
    font-variation-settings: "wght" 800, "wdth" 100, "opsz" 12;

    @include tablet {
      font-size: 62px;
      line-height: 1.2;
      letter-spacing: -2px;
    }

    @include desktop-l {
      font-size: 74px;
      letter-spacing: -3px;
    }
  }

  &__input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__input {
    width: 90%;
    position: relative;
    font-family: $helvetica;
    font-size: 28px;
    color: $orange;
    background: transparent;
    border: 0;
    // border-bottom: 2px solid $white;
    border-radius: 0;
    outline: 0;
    font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
    -webkit-appearance: none;

    @include desktop-sm {
      font-size: 40px;
    }

    @include desktop-l {
      font-size: 44px;
    }

    @include desktop-xl {
      font-size: 48px;
    }

    &::placeholder {
      font-family: $helvetica;
      font-size: 28px;
      color: $orange;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      @include desktop-sm {
        font-size: 40px;
      }

      @include desktop-l {
        font-size: 44px;
      }

      @include desktop-xl {
        font-size: 48px;
      }
    }
  }

  &__area {
    position: relative;
    margin-bottom: 15px;
    font-family: $helvetica;
    font-size: 18px;
    color: $orange;
    resize: none;
    background: transparent;
    border: 0;
    // border-bottom: 2px solid $white;
    border-radius: 0;
    outline: 0;
    width: 100%;
    font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;
    -webkit-appearance: none;

    @include desktop-sm {
      font-size: 26px;
    }

    @include desktop-l {
      font-size: 34px;
    }

    @include desktop-xl {
      font-size: 38px;
    }

    &::placeholder {
      font-family: $helvetica;
      font-size: 20px;
      color: $orange;
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      @include desktop-sm {
        font-size: 30px;
      }

      @include desktop-l {
        font-size: 34px;
      }

      @include desktop-xl {
        font-size: 38px;
      }
    }
  }

  &__btn {
    height: 35px;

    svg {
      height: 100%;
      width: auto;
    }

    @include desktop-sm {
      height: 46px;
    }

    @include desktop-l {
      height: 50px;
    }

    @include desktop-xl {
      height: 56px;
    }
  }

  &__error {
    position: absolute;
    top: 105%;
  }

  &__checkboxes {
    @include desktop-sm {
      display: flex;
    }
  }

  &__submit {
    font-size: 20px;
    font-variation-settings: "wght" 600, "wdth" 100, "opsz" 30;
    color: $orange;
    cursor: pointer;

    @include desktop-sm {
      font-size: 30px;
    }

    @include desktop-l {
      font-size: 34px;
    }

    @include desktop-xl {
      font-size: 38px;
    }
  }

  &__checkline {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:first-child {
      margin-right: 40px;
    }
  }

  &__checktext {
    margin-bottom: 5px;
    font-size: 16px;
    color: $white;
    font-variation-settings: "wght" 500, "wdth" 100, "opsz" 12;
  }

  &__checklink {
    color: $orange;

    &:hover {
      color: $white;
    }
  }

  // TAGS

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: $white;
    -webkit-box-shadow: 0 0 0 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
</style>
